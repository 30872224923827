
<div class="console_modal tov_support_dialog">
    <div class="modal-header">
        <h4 class="modal-title"><i class="far fa-question-circle" aria-hidden="true"></i> Support</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="body_content">
            <form #supportForm="ngForm" class="support_form" (ngSubmit)="sendRequest()" novalidate [spinnerLoader]="supportSubscription?.closed">
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="supportTitle" class="form-label">Title *</label>
                        <input name="supportTitle" type="text" id="supportTitle" class="form-control" required [(ngModel)]="support.title" #supportTitle="ngModel" [ngClass]="{'is-invalid': supportTitle.invalid && supportForm.submitted}">
                        <div class="invalid-feedback" *ngIf="supportTitle.errors?.required">Title is required</div>
                    </div>
                    <div class="col-12">
                        <label for="supportDescription" class="form-label">Description *</label>
                        <textarea name="supportDescription" id="supportDescription" rows="11" class="form-control" required placeholder="I need help to understand some test results..." [(ngModel)]="support.description" #supportDescription="ngModel" [ngClass]="{'is-invalid': supportDescription.invalid && supportForm.submitted}"></textarea>
                        <div class="invalid-feedback" *ngIf="supportDescription.errors?.required">Description is required</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn brand_default" (click)="supportForm.onSubmit($event)">Send</button>
    </div>
</div>
