import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonApiService } from "../../../services/common-api.service";
import { NotificationService } from '@common-ui';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'setting-alerts',
  templateUrl: './setting-alerts.component.html',
  styleUrls: ['./setting-alerts.component.scss'],
})
export class SettingAlertsComponent implements OnInit, OnDestroy {
  @ViewChild('alertForm', { static: true }) alertForm: NgForm;

  subscriptions: object = {};

  userEmail: string;
  notificationAlert: any = {
    enabled: false,
    users: [],
  };

  userSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        this.commonApiService.getBillingUsers().pipe(
          map((users) => users.filter((user) => user.email.toLowerCase().indexOf(term.toLocaleLowerCase()) > -1)),
          catchError(() => {
            return of([]);
          })
        )
      )
    );

  userSuggestionFormat = (value: any) => value.email;
  userSuggestionSelect = () => '';

  constructor(private notificationService: NotificationService, private commonApiService: CommonApiService) {}

  ngOnInit() {
    this.getNotifiedUser();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  chooseUser($event) {
    if (this.notificationAlert.users.some((user) => user.id === $event.item.id)) return;
    this.notificationAlert.users.push($event.item);
  }

  removeUser(userId) {
    this.notificationAlert.users = this.notificationAlert.users.filter((user) => user.id != userId);
    this.userEmail = '';
  }

  getNotifiedUser() {
    this.subscriptions['NotifiedUser'] = this.commonApiService.getNotifiedUser().subscribe((notifiedUsers: any) => {
      this.notificationAlert = {
        users: notifiedUsers,
        enabled: notifiedUsers.length > 0,
        defaultNotified: notifiedUsers.find((user) => user.id === -1)?.email,
      };
    });
  }

  setEmailAlert() {
    if (!this.notificationAlert.users.length && this.notificationAlert.enabled) return;

    const params = {
      enabled: this.notificationAlert.enabled,
    };
    const data = this.notificationAlert.users.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.id);
      return accumulator;
    }, []);

    this.subscriptions['AlertEmail'] = this.commonApiService.updateNotifiedUser(data, params).subscribe(() => {
      this.notificationService.showToast('Alert correctly updated', { type: 'success' });
      this.alertForm.resetForm();
      this.getNotifiedUser();
    });
  }
}
