import { AgentType } from "../enums/agents.enum";
import { AgentLang } from "./agent-lang.type";


export interface Agent {
  accountId: number;
  agentId: string;
  agentType: AgentType;
  id: number;
  locationId: string;
  multiLang: boolean;
  name: string;
  projectId: string;
  supportedLang: AgentLang[];
}