<div class="row section_design section_design_am">
    <div class="col-12 breadcrumbs">
        <p><i aria-hidden="true" class="fal fa-long-arrow-left" (click)="back()"></i>{{ board?.journey?.name }}<i aria-hidden="true" class="far fa-angle-right"></i>Action Mapping</p>
    </div>
    <div class="col-9 design_body">
        <div class="box_wrapper board_actions">
            <nav class="nav_board">
                <ul class="list-unstyled action_list">
                    <li class="input_item">
                        <input
                            name="action"
                            [disabled]="actionList?.length === 0"
                            type="text"
                            id="action"
                            class="form-control"
                            [autoClose]="true"
                            [(ngModel)]="actionName"
                            [ngModelOptions]="{ standalone: true }"
                            [ngbPopover]="selectActionContent"
                            triggers="manual"
                            #popAction="ngbPopover"
                            [popoverClass]="'popover_actions'"
                            [placement]="'bottom-left'"
                            [autoClose]="'outside'"
                            (click)="popAction.open()"
                            [attr.placeholder]="getPlaceholder(popAction)"
                        />
                        <ng-template #selectActionContent>
                            <ul class="list-unstyled pop_list">
                                <li *ngFor="let action of actionList | fullTextSearch: actionName:'actionName'">
                                    <p (click)="selectAction(action)">{{ action.actionName }}</p>
                                </li>
                            </ul>
                        </ng-template>
                    </li>
                    <li>
                        <button class="btn brand_light" (click)="openModalSettings()"><i class="fas fa-xs fa-plus" aria-hidden="true"></i> New</button>
                    </li>
                </ul>
                <div class="journey_action">
                    <button type="button" class="btn brand_light" (click)="openModalSettings(selectedAction)" [disabled]="!selectedAction"><i class="fal fa-cog" aria-hidden="true"></i></button>
                </div>
            </nav>

            <div #drawBoard class="drawing_board" [spinnerLoader]="subscriptions['Connector']?.closed">
                <connectors-board #boardRef
                    [products]="products"
                    [journey]="board?.journey"
                    [connectedList]="connectedList"
                    [parentData]="{ parentId: connector?.id, parentType: connector?.productCode, parentChildId: selectedAction?.id }"
                    (updateBoard)="getConnector()"
                    (droppedConnector)="onConnectorDropped.next($event)"
                    [connectors]="selectedAction?.connectors"
                ></connectors-board>
            </div>
        </div>
    </div>
    <div class="col-3 h-100">
        <products-sidebar [onConnectorDropped]="onConnectorDropped" (addProduct)="addProduct($event)" [connectedList]="connectedList" [products]="products"></products-sidebar>
    </div>
</div>

<ng-template #notPurchased> This connector is no longer included in your license </ng-template>

<template #sidebarConnectorContainer> </template>
