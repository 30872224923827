import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map, shareReplay } from 'rxjs/operators';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-data-transfer-connector-modal',
  templateUrl: './data-transfer-connector-modal.component.html',
  styleUrls: ['./data-transfer-connector-modal.component.scss'],
})
export class DataTransferConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private connector: any;

  subscriptions: object = {};

  sessionEntitiesObserver = this.connectorsService.getSessionEntities().pipe(
    shareReplay(1),
    map((r) => r.data)
  );

  datatransferForm = this.fb.group({
    elements: this.fb.array([]),
  });
  transferSubmitted: boolean = false;

  get transferElements() {
    return this.datatransferForm.get('elements') as FormArray;
  }

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private connectorsService: ConnectorsService) {}

  ngOnInit() {
    if (this.connector.id) {
      this.processActionsArray(this.connector.elements);
    } else {
      this.addAction();
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getEntityId(nluId: string) {
    let entityId = nluId.split('/');
    return entityId[entityId.length - 1];
  }

  bindFormChanges(actionIndex: number) {
    const element: AbstractControl = this.transferElements.at(actionIndex);

    this.subscriptions[`AllKeysChanges_${actionIndex}`] = element.get('allValue').valueChanges.subscribe(() => this.transferAllKeys(actionIndex));
    this.subscriptions[`ActionChanges_${actionIndex}`] = element.get('action').valueChanges.subscribe(() => this.transferAction(actionIndex));
    this.subscriptions[`DynamicChanges_${actionIndex}`] = element.get('dynamic').valueChanges.subscribe(() => this.transferDynamic(actionIndex));
  }

  transferAllKeys(elementIndex: number) {
    const element: AbstractControl = this.transferElements.at(elementIndex);

    if (element.get('allValue').value && element.get('action').value !== 'context') {
      element.get('value').setValue('');
      element.get('value').disable();
    } else {
      element.get('value').enable();
    }
  }

  transferAction(elementIndex: number) {
    const element: AbstractControl = this.transferElements.at(elementIndex);

    if (element.get('action').value === 'clear_entity') {
      element.get('path').setValue('');
      element.get('path').disable();
    } else {
      element.get('path').enable();
    }

    const valueControl: AbstractControl = element.get('value');
    valueControl.clearValidators();
    if (element.get('action').value === 'context') {
      valueControl.setValidators([Validators.required, Validators.pattern('([a-zA-Z0-9_-]+)([.]+)([a-zA-Z0-9_-]+)')]);
    } else {
      valueControl.setValidators([Validators.required, Validators.pattern('[^ ]+')]);
    }
    valueControl.updateValueAndValidity();

    this.transferAllKeys(elementIndex);
  }

  transferDynamic(elementIndex: number) {
    const element: AbstractControl = this.transferElements.at(elementIndex);

    if (element.get('dynamic').value) {
      element.get('allValue').setValue(false);
      element.get('allValue').disable();

      if (['parameters', 'session', 'cache'].indexOf(element.get('action').value) !== -1) {
        element.get('value').setValue('');
        element.get('value').disable();
      }

      element
        .get('path')
        .setValidators([
          Validators.required,
          Validators.pattern(/^(\$\[(.*?\[%\].*?)\]|\$\{(.*?\[%\].*?)\}|\$\{\{(.*?\[%\].*?)\}\}|\$\((.*?\[%\].*?)\)|\{\{(.*?\[%\].*?)\}\})$/),
        ]);
    } else {
      element.get('allValue').enable();

      if (['parameters', 'session', 'cache'].indexOf(element.get('action').value) !== -1) {
        element.get('value').enable();
      }

      element.get('path').setValidators([Validators.required]);
    }

    element.get('path').updateValueAndValidity();
  }

  addAction() {
    this.transferElements.push(
      this.fb.group({
        path: ['', Validators.required],
        action: ['', Validators.required],
        override: [false],
        allValue: [false],
        dynamic: [false],
        value: ['', Validators.required],
      })
    );
    this.bindFormChanges(this.transferElements.length - 1);
  }

  removeAction(actionIndex) {
    this.transferElements.removeAt(actionIndex);

    this.subscriptions[`AllKeysChanges_${actionIndex}`].unsubscribe();
    this.subscriptions[`ActionChanges_${actionIndex}`].unsubscribe();
    this.subscriptions[`DynamicChanges_${actionIndex}`].unsubscribe();
  }

  processActionsArray(arrayData: Array<any>) {
    if (arrayData.length) {
      arrayData.forEach(() => this.addAction());
      this.transferElements.patchValue(arrayData);
    }
  }

  saveConnector() {
    this.transferSubmitted = true;
    if (this.datatransferForm.invalid) return;

    const data = { ...this.connector, ...this.datatransferForm.value };

    if (this.connector.id) {
      this.subscriptions['SaveConnector'] = this.connectorsService.editConnector('dataTransfer', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.subscriptions['SaveConnector'] = this.connectorsService.addConnector('dataTransfer', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }
}
