<section
    class="flow_column"
    [spinnerLoader]="subscriptions['moveConnector']?.closed"
    cdkDropList
    (cdkDropListEntered)="listEntered($event)"
    (cdkDropListSorted)="listEntered($event)"
    (cdkDropListDropped)="connectorDropped($event)"
    id="main-list"
    [cdkDropListConnectedTo]="connectedList"
    [cdkDropListData]="{ connectors: connectors }"
>
    <div class="draggable_flow" (cdkDragStarted)="startMove($event)" *ngFor="let connector of connectors; let parentIndex = index; let lastRow = last" cdkDrag [cdkDragData]="connector">
        <section class="flow_row">
            <article class="connector_box code_{{ connector.productCode | lowercase }}">
                <header class="box_status">
                    <i class="fas fa-exclamation-triangle alert_warning" aria-hidden="true" *ngIf="connector.allIntents || !connector.purchased" [ngbTooltip]="!connector.purchased ? notPurchased : allIntents" container="body"></i>
                </header>
                <div class="box_grab" cdkDragHandle>
                    <button class="btn"><i class="fas fa-grip-vertical" aria-hidden="true"></i></button>
                </div>
                <div class="box_action">
                    <button type="button" class="btn" (click)="setupConnector(connector)" [disabled]="!boardService.isConnectorPermitted(connector.productCode, 'edit')">
                        <i class="fas fa-cog" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn" (click)="deleteConnector(connector)" [disabled]="!boardService.isConnectorPermitted(connector.productCode, 'delete')">
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                    </button>
                </div>
                <figure class="box_image">
                    <img src="./images/design-connectors/connector_{{ connector.productCode }}.png" alt="" />
                </figure>
                <footer class="box_description">
                    <h6 *ngIf="connector.productCode !== 'RL'">
                        <span #connectorName>{{ connector.productName }}</span>
                    </h6>
                    <h6 *ngIf="connector.productCode === 'RL'">
                        <span #connectorName>{{ connector.name || connector.productName }}</span>
                    </h6>
                </footer>
                <button type="button" class="btn add_connector" *ngIf="lastRow" [ngbPopover]="popConnectorsContent" popoverClass="board_pop_connectors" container="body" [placement]="['bottom', 'top']">
                    <i class="fas fa-plus-circle" aria-hidden="true"></i>
                </button>
                <button
                    type="button"
                    class="btn add_connector rule_child"
                    *ngIf="!connector.connectors?.length && connector.productCode === 'RL'"
                    [ngbPopover]="popRulesContent"
                    triggers="manual"
                    #popRuleConnector="ngbPopover"
                    popoverClass="board_pop_connectors"
                    container="body"
                    [placement]="['right-top', 'right-bottom']"
                    (click)="togglePopRules(parentIndex)"
                >
                    <i class="fas fa-plus-circle" aria-hidden="true"></i>
                </button>
            </article>
            <div
                cdkDropList
                [cdkDropListConnectedTo]="connectedList"
                (cdkDropListDropped)="connectorDropped($event)"
                (cdkDropListEntered)="listEntered($event)"
                (cdkDropListSorted)="listEntered($event)"
                [cdkDropListData]="{ connectors: connector.connectors, parent: connector }"
                id="list-rule-{{ connector.id }}"
                cdkDropListOrientation="horizontal"
                class="horizontal_flow"
            >
                <ng-container *ngIf="connector.productCode === 'RL'">
                    <div class="draggable_flow" *ngFor="let rule of connector.connectors; let childIndex = index; let lastRule = last" cdkDrag [cdkDragData]="rule" (cdkDragStarted)="startMove($event)">
                        <span class="material-icons flow_arrow rule_direction">keyboard_backspace</span>
                        <article class="connector_box code_{{ rule.productCode | lowercase }}">
                            <div class="box_grab" cdkDragHandle>
                                <button class="btn"><i class="fas fa-grip-vertical" aria-hidden="true"></i></button>
                            </div>

                            <div class="box_action">
                                <button type="button" class="btn" (click)="setupConnector(rule)" [disabled]="!boardService.isConnectorPermitted(rule.productCode, 'edit')">
                                    <i class="fas fa-cog" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn" (click)="deleteConnector(rule)" [disabled]="!boardService.isConnectorPermitted(rule.productCode, 'delete')">
                                    <i class="far fa-trash-alt" aria-hidden="true"></i>
                                </button>
                            </div>
                            <figure class="box_image">
                                <img src="./images/design-connectors/connector_{{ rule.productCode }}.png" alt="" />
                            </figure>
                            <footer class="box_description">
                                <h6>
                                    <span #connectorName>{{ rule.productName }}</span>
                                </h6>
                            </footer>
                            <button
                                type="button"
                                class="btn add_connector rule_child"
                                *ngIf="lastRule"
                                [ngbPopover]="popRulesContent"
                                triggers="manual"
                                #popRuleConnector="ngbPopover"
                                popoverClass="board_pop_connectors"
                                container="body"
                                [placement]="['right-top', 'right-bottom']"
                                (click)="togglePopRules(parentIndex)"
                            >
                                <i class="fas fa-plus-circle" aria-hidden="true"></i>
                            </button>
                            <ng-container *cdkDragPreview>
                                <drag-preview [movingConnector]="movingConnector"></drag-preview>
                            </ng-container>
                            <ng-container *cdkDragPlaceholder>
                                <drag-placeholder></drag-placeholder>
                            </ng-container>
                        </article>
                    </div>
                </ng-container>
            </div>
        </section>
        <span class="material-icons flow_arrow row_direction" *ngIf="!lastRow">keyboard_backspace</span>
        <ng-container *cdkDragPreview>
            <drag-preview [code]="connector.productCode" [name]="connector.productName"></drag-preview>
        </ng-container>
        <ng-container *cdkDragPlaceholder>
            <drag-placeholder></drag-placeholder>
        </ng-container>
    </div>
</section>

<ng-template #popRulesContent let-parentIndex="parentIndex">
    <ul class="list-unstyled connectors_list">
        <li *ngFor="let connector of products | filterProperties: { visibility: true, ruleEnabled: true }" (click)="addToBoard(connector, parentIndex)">
            {{ connector.name }}
        </li>
    </ul>
</ng-template>

<ng-template #popConnectorsContent>
    <ul class="list-unstyled connectors_list">
        <li *ngFor="let product of products | filterProperties: { visibility: true }" (click)="addToBoard(product)">{{ product.name }}</li>
    </ul>
</ng-template>

<template #sidebarConnectorContainer> </template>

<ng-template #allIntents> This rule is applied to all intents. If you want to apply a behavior to all intents, you don't need to use a rule. You can consider deselecting some intents or removing the rule </ng-template>
<ng-template #notPurchased> This connector is no longer included in your license </ng-template>
