import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { GenericConnector, ProductCodeType, UserAccountService } from '@core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActionMappingConnectorComponent } from '../components/connectors/action-mapping-connector/action-mapping-connector.component';
import { CodeEditorSidebarComponent } from '../components/connectors/code-editor-sidebar/code-editor-sidebar.component';
import { DataProcessingConnectorModalComponent } from '../components/connectors/data-processing-connector-modal/data-processing-connector-modal.component';
import { DataTransferConnectorModalComponent } from '../components/connectors/data-transfer-connector-modal/data-transfer-connector-modal.component';
import { DialogflowConnectorModalComponent } from '../components/connectors/dialogflow-connector-modal/dialogflow-connector-modal.component';
import { DispatcherConnectorModalComponent } from '../components/connectors/dispatcher-connector-modal/dispatcher-connector-modal.component';
import { DlpConnectorModalComponent } from '../components/connectors/dlp-connector-modal/dlp-connector-modal.component';
import { ErrorHandlerConnectorSidebarComponent } from '../components/connectors/error-handler-connector-sidebar/error-handler-connector-sidebar.component';
import { EventCallerConnectorModalComponent } from '../components/connectors/event-caller-connector-modal/event-caller-connector-modal.component';
import { GenericRuleModalComponent } from '../components/connectors/generic-rule-modal/generic-rule-modal.component';
import { HttpConnectorModalComponent } from '../components/connectors/http-connector-modal/http-connector-modal.component';
import { NluConnectorModalComponent } from '../components/connectors/nlu-connector-modal/nlu-connector-modal.component';
import { RasaConnectorModalComponent } from '../components/connectors/rasa-connector-modal/rasa-connector-modal.component';
import { SalesforceConnectorModalComponent } from '../components/connectors/salesforce-connector-modal/salesforce-connector-modal.component';
import { SendgridConnectorModalComponent } from '../components/connectors/sendgrid-connector-modal/sendgrid-connector-modal.component';
import { SidebarConnectorContainerComponent } from '../components/connectors/sidebar-connector-container/sidebar-connector-container.component';
import { SmtpConnectorModalComponent } from '../components/connectors/smtp-connector-modal/smtp-connector-modal.component';
import { TranslateConnectorModalComponent } from '../components/connectors/translate-connector-modal/translate-connector-modal.component';
import { TrooveConnectorModalComponent } from '../components/connectors/troove-connector-modal/troove-connector-modal.component';
import { TwilioSmsModalComponent } from '../components/connectors/twilio-sms-modal/twilio-sms-modal.component';
import { ZendeskConnectorModalComponent } from '../components/connectors/zendesk-connector-modal/zendesk-connector-modal.component';
import { ConnectorPermissions } from '../enums/connector-permissions.enum';


@Injectable({
  providedIn: 'root',
})
export class ConnectorsUtilityService {
  private connectorsSettings: {
    [key in Partial<ProductCodeType>]?: { instance: any; type: 'modal' | 'sidebar' | 'fullPage'; size?: 'lg' | 'xl' };
  } = {
    E: {
      type: 'modal',
      size: 'lg',
      instance: SmtpConnectorModalComponent,
    },
    R: {
      type: 'modal',
      size: 'lg',
      instance: HttpConnectorModalComponent,
    },
    NL: {
      type: 'modal',
      size: 'lg',
      instance: NluConnectorModalComponent,
    },
    RL: {
      type: 'modal',
      size: 'xl',
      instance: GenericRuleModalComponent,
    },
    ES: {
      type: 'modal',
      size: 'lg',
      instance: DialogflowConnectorModalComponent,
    },

    CX: {
      type: 'modal',
      size: 'lg',
      instance: DialogflowConnectorModalComponent,
    },
    DLP: {
      type: 'modal',
      size: 'lg',
      instance: DlpConnectorModalComponent,
    },
    T: {
      type: 'modal',
      size: 'lg',
      instance: TranslateConnectorModalComponent,
    },
    Q: {
      type: 'modal',
      size: 'lg',
      instance: TrooveConnectorModalComponent,
    },
    DT: {
      type: 'modal',
      size: 'xl',
      instance: DataTransferConnectorModalComponent,
    },
    DP: {
      type: 'modal',
      size: 'xl',
      instance: DataProcessingConnectorModalComponent,
    },
    ZD: {
      type: 'modal',
      size: 'lg',
      instance: ZendeskConnectorModalComponent,
    },
    SF: {
      type: 'modal',
      size: 'xl',
      instance: SalesforceConnectorModalComponent,
    },
    SG: {
      type: 'modal',
      size: 'lg',
      instance: SendgridConnectorModalComponent,
    },
    SFS: {
      type: 'modal',
      size: 'xl',
      instance: SalesforceConnectorModalComponent,
    },
    D: {
      type: 'modal',
      size: 'lg',
      instance: DispatcherConnectorModalComponent,
    },
    EC: {
      type: 'modal',
      size: 'lg',
      instance: EventCallerConnectorModalComponent,
    },
    TW: {
      type: 'modal',
      size: 'lg',
      instance: TwilioSmsModalComponent,
    },
    CE: {
      type: 'sidebar',
      instance: CodeEditorSidebarComponent,
    },
    EH: {
      type: 'sidebar',
      instance: ErrorHandlerConnectorSidebarComponent,
    },
    AM: {
      type: 'fullPage',
      instance: ActionMappingConnectorComponent,
    },
    RS: {
      type: 'modal',
      instance: RasaConnectorModalComponent
    }
  };

  constructor(private modalService: NgbModal, private userAccountService: UserAccountService) {}

  openConnectorModal({
    productCode,
    connector,
    containerRef,
  }: {
    productCode;
    connector: GenericConnector;
    containerRef: ViewContainerRef;
  }): Observable<{ refresh: boolean; nextConnector?: GenericConnector }> {
    let modalRef;

    const settings = this.connectorsSettings[productCode];

    if (settings.type === 'modal') {
      modalRef = this.modalService.open(settings.instance, { size: settings.size });
    } else if (settings.type === 'sidebar') {
      containerRef.clear();
      const componentRef: ComponentRef<SidebarConnectorContainerComponent> = containerRef.createComponent(SidebarConnectorContainerComponent);
      componentRef.instance.closed.subscribe(() => componentRef.destroy());
      modalRef = { componentInstance: componentRef.instance, result: componentRef.instance.closed };
      modalRef.componentInstance.connectorInstance = settings.instance;
    }

    modalRef.componentInstance.connector = connector;

    return from(modalRef.result).pipe<any>(catchError(() => of({ refresh: false })));
  }

  isConnectorPermitted(connectorCode: string, action: 'edit' | 'delete') {
    if (action === 'edit') {
      return (
        this.userAccountService.isAuthorized(`design.processflow.${ConnectorPermissions[connectorCode]}.read`) ||
        this.userAccountService.isAuthorized(`design.processflow.${ConnectorPermissions[connectorCode]}.write`)
      );
    } else if (action === 'delete') {
      return this.userAccountService.isAuthorized(`design.processflow.${ConnectorPermissions[connectorCode]}.write`);
    }
  }
}
