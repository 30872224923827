import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';

export function infoTypesValidator(formArray: FormArray): ValidationErrors | null {
  const transformationLength = (formArray.at(1).get('googleInfoType') as FormArray).length;
  const customTransformationLength = (formArray.at(2).get('customInfoType') as FormArray).value.filter((item) => item.enableCustom).length;

  return transformationLength + customTransformationLength === 0 ? { infoTypes: true } : null;
}

export function uniqueNameValidator(form: FormGroup): ValidationErrors | null {
  const formArray = form.parent as FormArray;

  const duplicatedForm = formArray?.controls.find((itemForm) => itemForm !== form && itemForm.value.name === form.value.name);
  if (duplicatedForm) return { uniqueName: true };
  return null;
}
