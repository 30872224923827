import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements AfterViewInit, OnDestroy {
  @Input() legend: boolean;
  @Input() chartTitle: string;

  _dataset: { data: number[]; labels: string[] | string[][]; colors: string[] };
  get dataset(): { data: number[]; labels: string[] | string[][]; colors: string[] } {
    return this._dataset;
  }
  @Input() set dataset(dataset: { data: number[]; labels: string[] | string[][]; colors: string[] }) {
    this._dataset = dataset;
    if (this.chartRef) {
      this.updateChart();
    }
  }

  @ViewChild('doughnutChartWrapper') doughnut: ElementRef;
  chartRef;

  ngAfterViewInit() {
    this.chartRef = new Chart(this.doughnut.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.dataset.labels,
        datasets: [{ backgroundColor: this.dataset.colors, data: this.dataset.data }],
      },

      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: this.legend ? 1.75 : 1,
        radius: '90%',
        cutout: '80%',
        borderColor: '#fff',
        plugins: {
          legend: {
            display: this.legend,
            position: 'right',
            labels: {
              generateLabels: (chart) =>
                (chart.data.labels || [])
                  .map((label, i) => ({
                    text: `${label[0]}${label[1] || ''}`,
                    fillStyle: chart.data.datasets[0].backgroundColor[i],
                    lineWidth: 0,
                  }))
                  .filter((_, i) => chart.data.datasets[0].data[i]),

              padding: 5,
              color: '#000000',
              font: {
                size: 11,
                family: 'Poppins, sans-serif',
              },
              boxWidth: 8,
              usePointStyle: true,
              pointStyle: 'circle',
              textAlign: 'left',
            },
          },

          tooltip: {
            displayColors: true,
            usePointStyle: true,
            pointStyle: 'circle',
            backgroundColor: 'rgba(255, 255, 255)',
            bodyColor: 'rgba(0, 0, 0)',
            titleColor: 'rgba(0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0.8)',
            borderWidth: 0.5,
            callbacks: {
              title: (context) => context[0].label[0],
              label: (context) => `${context.formattedValue} ${context.label[1] || ''}`,
            },
          },
        },
      },
      plugins: [
        {
          id: 'centerText',
          afterDatasetsDraw: (chart) => {
            if (this.chartTitle) {
              const {
                ctx,
                chartArea: { top, width, height },
              } = chart;

              ctx.save();

              ctx.font = `18px Poppins, sans-serif`;
              ctx.fillStyle = 'rgb(33, 37, 41)';
              ctx.textAlign = 'center';
              ctx.fillText(this.chartTitle, width / 2, height / 2 + top + 5);

              ctx.restore();
            }
          },
        },
      ],
    });
  }

  ngOnDestroy() {
    if (this.chartRef instanceof Chart) this.chartRef.destroy();
  }
  updateChart() {
    this.chartRef.data.labels = this.dataset.labels;
    this.chartRef.data.datasets = [{ backgroundColor: this.dataset.colors, data: this.dataset.data }];
    this.chartRef.update();
  }
}
