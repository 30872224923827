<nav class="navbar console_topbar">
    <section class="menu_nav">
        <button class="navbar-toggler sidemenu_switch" type="button" (click)="toggleMenu(stretchMenu)">
            <i class="fas fa-bars" aria-hidden="true"></i>
        </button>
        <a href="#" [routerLink]="['']" class="navbar-brand brand_logo"></a>
    </section>

    <section class="user_nav">
        <button id="glossaryButton" type="button" class="btn glossary_switch" (click)="toggleGlossary(openGlossary)"><i class="far fa-atlas" aria-hidden="true"></i></button>

        <button id="tutorialButton" class="btn tutorial" [ngbPopover]="popTutorial" [autoClose]="true" [placement]="'bottom'" [popoverClass]="'tutorial_popover'">
            <i class="far fa-book-open" aria-hidden="true"></i>
        </button>

        <button id="chatButton" type="button" class="btn chat_switch" (click)="toggleChat(openChat)"><i class="far fa-comment" aria-hidden="true"></i></button>
        <form class="journey_form" [spinnerLoader]="subscriptions['Journey']?.closed" [size]="'sm'">
            <div ngbDropdown>
                <button type="button" class="form-select custom_select_dropdown journey_dropodown" id="dropdownSeverities" ngbDropdownToggle>{{ activeJourney?.name }}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownSeverities">
                    <button ngbDropdownItem [ngClass]="{ active: item.id === activeJourney?.id }" *ngFor="let item of journeys" (click)="switchJourney(item)">
                        <i class="far fa-network-wired me-2" aria-hidden="true" *ngIf="item.dispatcher"></i>{{ item.name }}
                    </button>
                </div>
            </div>
        </form>
        <button class="btn profile_switch" [ngbPopover]="popUser" [autoClose]="'outside'" [placement]="'bottom-right'" [popoverClass]="'pop_user'">
            <span class="user_name">{{ user?.firstName }} {{ user?.lastName }}</span>
            <i class="fas fa-user user_avatar" aria-hidden="true"></i>
        </button>
    </section>
</nav>

<ng-template #popUser>
    <article class="account_info">
        <header class="info_head">
            <figure class="head_image">
                <i class="fas fa-user-circle fa-6x" aria-hidden="true"></i>
            </figure>
            <h5 class="head_title">{{ user?.firstName }} {{ user?.lastName }}</h5>
            <h6 class="head_subtitle">
                <small>{{ user?.email }}</small>
            </h6>
            <ul class="list-unstyled account_action">
                <li>
                    <button type="button" class="btn" (click)="modalSettings()"><i class="material-icons" aria-hidden="true">settings</i></button>
                </li>
                <li>
                    <button type="button" class="btn" (click)="modalSupport()"><i class="material-icons" aria-hidden="true">help_outline</i></button>
                </li>
                <li>
                    <button type="button" class="btn" (click)="logout()"><i class="material-icons" aria-hidden="true">power_settings_new</i></button>
                </li>
            </ul>
        </header>
        <div class="info_body" [spinnerLoader]="subscriptions['SetAccount']?.closed">
            <ul class="list-unstyled account_list">
                <li *ngFor="let account of userAccounts" [ngClass]="{ active: user?.accountId === account.id }">
                    <a href="javascript:void(0)" (click)="switchAccount(account)">{{ account.name }}</a>
                </li>
            </ul>
        </div>
        <footer class="info_bottom">
            <a href="https://tellya.ai/" target="_blank" rel="noopener noreferrer" class="bottom_remainder">Tellya Website</a> -
            <a href="https://docs.tellya.ai/" target="_blank" rel="noopener noreferrer" class="bottom_remainder">Documentation</a>
        </footer>
    </article>
</ng-template>

<ng-template #popTutorial>
    <ul class="list-unstyled">
        <li>
            <a href="javascript:void(0)" (click)="startTutorial(tutorials.PLATFORM_OVERVIEW)">Platform overview</a>
        </li>

        <ng-container *roleRestriction="['design.intentdesign.write', 'design.intentdesign.read']">
            <li *journeyRestriction="['ES']">
                <a *ngIf="licenseService.isPurchased('M_I', 'Module'); else noLicenseTemplate" href="javascript:void(0)" (click)="startTutorial(tutorials.CREATE_INTENTS)">Create intents (Basic)</a>

                <ng-template #noLicenseTemplate>
                    <a href="javascript:void(0)" (click)="noLicenseModal()">Create intents (Basic)</a>
                </ng-template>
            </li>
        </ng-container>
        <ng-container *roleRestriction="['analytics.view', 'analytics.customreport.write']">
            <li>
                <a href="javascript:void(0)" (click)="startTutorial(tutorials.ANALYTICS)">Analytics</a>
            </li>
        </ng-container>
        <ng-container *journeyRestriction="[{type: 'ES'}, {type: 'CX', fulfillment: false}]">
            <li *roleRestriction="['training.write', 'training.read']">
                <a href="javascript:void(0)" (click)="startTutorial(tutorials.TRAINING)">Training</a>
            </li>
        </ng-container>

        <ng-container *roleRestriction="['healthcheck.regressiontest.write', 'healthcheck.regressiontest.tester', 'healthcheck.regressiontest.read']">
            <li *journeyRestriction="['ES']">
                <a href="javascript:void(0)" (click)="startTutorial(tutorials.REGRESSION_TEST)">Regression test</a>
            </li>
        </ng-container>

        <ng-container *roleRestriction="['healthcheck.validation.view']">
            <li *journeyRestriction="['ES','CX']">
                <a href="javascript:void(0)" (click)="startTutorial(tutorials.VALIDATION)">Validation</a>
            </li>
        </ng-container>
    </ul>
</ng-template>
