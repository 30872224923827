<div class="container-fluid tov_survey">
    <div class="row g-0">
        <div class="col">
            <ng-template [spinnerLoader]="subscriptions['SurveyResults']?.closed"></ng-template>
            <ng-template [spinnerLoader]="subscriptions['ExportResults']?.closed"></ng-template>
            <ng-template [spinnerLoader]="subscriptions['ImportResults']?.closed"></ng-template>

            <section class="survey_preamble" *ngIf="currentStep === 0">
                <header class="preamble_head">
                    <nav class="head_topbar">
                        <ul class="list-unstyled tov_language">
                            <li *ngFor="let language of tovLanguages" [ngClass]="{'active': language === translateService.currentLang}" (click)="changeLanguage(language)">
                                {{language}}
                            </li>
                        </ul>
                        <a href="#" class="return_home" [routerLink]="['/dashboard']"><i class="fal fa-times" aria-hidden="true"></i></a>
                    </nav>
                    <h4>{{'TONE_OF_VOICE.TITLE' | translate}}</h4>
                    <h2>{{'TONE_OF_VOICE.SUBTITLE' | translate}}</h2>
                </header>
                <div class="preamble_body">
                    <article class="body_card">
                        <header class="card_head">
                            <figure class="head_image"><img src="./assets/tone-of-voice/user.svg" alt=""></figure>
                            <h5 class="head_title" [innerHTML]="'TONE_OF_VOICE.STEP_0.BOT_PERSONA.TITLE' | translate"></h5>
                        </header>
                        <p class="card_paragraph">{{'TONE_OF_VOICE.STEP_0.BOT_PERSONA.PARAGRAPH' | translate}}</p>
                    </article>
                    <article class="body_card">
                        <header class="card_head">
                            <figure class="head_image"><img src="./assets/tone-of-voice/megaphone.svg" alt=""></figure>
                            <h5 class="head_title" [innerHTML]="'TONE_OF_VOICE.STEP_0.BUILDER_TEST.TITLE' | translate"></h5>
                        </header>
                        <p class="card_paragraph">{{'TONE_OF_VOICE.STEP_0.BUILDER_TEST.PARAGRAPH' | translate}}</p>
                    </article>
                    <article class="body_card">
                        <header class="card_head">
                            <figure class="head_image"><img src="./assets/tone-of-voice/robot.svg" alt=""></figure>
                            <h5 class="head_title" [innerHTML]="'TONE_OF_VOICE.STEP_0.VIRTUAL_AGENT.TITLE' | translate"></h5>
                        </header>
                        <p class="card_paragraph">{{'TONE_OF_VOICE.STEP_0.VIRTUAL_AGENT.PARAGRAPH' | translate}}</p>
                    </article>
                </div>
                <footer class="preamble_bottom">
                    <button type="button" class="btn tov_default" (click)="nextStep()">{{'TONE_OF_VOICE.STEP_0.START_TEST' | translate}}</button>
                    <a href="javascript:void(0)" class="export_results">{{'TONE_OF_VOICE.STEP_0.IMPORT_TEST' | translate}} <input type="file" (change)="importResults($event.target)"></a>
                </footer>
            </section>

            <section class="survey_questions" *ngIf="currentStep > 0">
                <aside class="questions_sidebar">
                    <header class="sidebar_head">
                        <h6>{{'TONE_OF_VOICE.TITLE' | translate}}</h6>
                        <h3>{{'TONE_OF_VOICE.SUBTITLE' | translate}}</h3>
                        <div class="head_progress" *ngIf="currentStep !== 4">
                            <ngb-progressbar type="info" [value]="completeProgress" height=".5rem" class="progress_bar"></ngb-progressbar>
                            <span>{{completeProgress | number: '1.0-0'}}%</span>
                        </div>
                    </header>
                    <ul class="list-unstyled step_list" *ngIf="currentStep !== 4">
                        <li><i class="far fa-check" aria-hidden="true" [ngClass]="{'completed': surveyTypology.valid}"></i> {{'TONE_OF_VOICE.TYPOLOGY' | translate}}</li>
                        <li><i class="far fa-check" aria-hidden="true" [ngClass]="{'completed': surveySkills.valid}"></i> {{'TONE_OF_VOICE.SKILLS' | translate}}</li>
                        <li>
                            <i class="far fa-check" aria-hidden="true" [ngClass]="{'completed': surveyQuestions.valid}"></i> {{'TONE_OF_VOICE.PERSONALITY' | translate}}
                            <ul class="list-unstyled questions_list" *ngIf="currentStep === 3">
                                <li *ngFor="let question of surveyQuestions.controls; let i = index" [ngClass]="{'completed': surveyQuestions.at(i).valid}" (click)="nextQuestion(i)">{{'TONE_OF_VOICE.QUESTION' | translate}} {{i + 1}}</li>
                            </ul>
                        </li>
                    </ul>
                    <dl class="results_list" *ngIf="currentStep === 4">
                        <dt>{{'TONE_OF_VOICE.STEP_1.TYPE_CHARACTER.TITLE' | translate}}</dt>
                        <dd>{{'TONE_OF_VOICE.STEP_1.TYPE_CHARACTER.' + surveyForm.value.typology.character | translate}}</dd>

                        <dt>{{'TONE_OF_VOICE.STEP_1.PERSONALITY.TITLE' | translate}}</dt>
                        <dd>{{'TONE_OF_VOICE.STEP_1.PERSONALITY.' + surveyForm.value.typology.personality | translate}}</dd>

                        <dt>{{'TONE_OF_VOICE.STEP_1.GENDER.TITLE' | translate}}</dt>
                        <dd>{{'TONE_OF_VOICE.STEP_1.GENDER.' + surveyForm.value.typology.gender | translate}}</dd>

                        <dt>{{'TONE_OF_VOICE.STEP_1.AGE.TITLE' | translate}}</dt>
                        <dd>{{surveyForm.value.typology.age}} {{'COMMON.REGISTRY.YEARS' | translate}}</dd>

                        <dt>{{'TONE_OF_VOICE.STEP_1.JOB_TITLE.TITLE' | translate}}</dt>
                        <dd>{{surveyForm.value.typology.jobTitle}}</dd>

                        <dt>{{'TONE_OF_VOICE.STEP_1.JOB_DESCRIPTION.TITLE' | translate}}</dt>
                        <dd>{{surveyForm.value.typology.jobDescription}}</dd>
                    </dl>
                    <footer class="sidebar_bottom">
                        <a href="javascript:void(0)" (click)="resetForm()"><i class="fal fa-arrow-left" aria-hidden="true"></i> {{'TONE_OF_VOICE.BACK_HOME' | translate}}</a>
                    </footer>
                </aside>
                <form class="survey_form" [formGroup]="surveyForm" [ngSwitch]="currentStep" *ngIf="currentStep < 4" novalidate>
                    <div class="questions_body" *ngSwitchCase="1" formGroupName="typology">
                        <article class="query_box">
                            <header class="box_head">
                                <h4>{{'TONE_OF_VOICE.STEP_1.TYPE_CHARACTER.TITLE' | translate}}</h4>
                            </header>
                            <p class="query_paragraph">{{'TONE_OF_VOICE.STEP_1.TYPE_CHARACTER.QUERY' | translate}}</p>
                            <footer class="query_bottom">
                                <div class="btn-group tov_choice" role="group">
                                    <input type="radio" formControlName="character" value="CHOICE_1" class="btn-check" id="ACharacterRadio" autocomplete="off">
                                    <label class="btn choice_button" for="ACharacterRadio"  [ngClass]="{ 'border border-danger': surveyTypology.get('character').invalid && stepSubmitted }">
                                        <i class="button_option" aria-hidden="true">A</i>
                                        {{ 'TONE_OF_VOICE.STEP_1.TYPE_CHARACTER.CHOICE_1' | translate }}
                                        <i class="fal fa-gem button_symbol" aria-hidden="true"></i
                                    ></label>
                                    <input type="radio" formControlName="character" value="CHOICE_2" class="btn-check" id="BCharacterRadio" autocomplete="off">
                                    <label class="btn choice_button" for="BCharacterRadio" [ngClass]="{ 'border border-danger': surveyTypology.get('character').invalid && stepSubmitted }">
                                        <i class="button_option" aria-hidden="true">B</i>
                                        {{ 'TONE_OF_VOICE.STEP_1.TYPE_CHARACTER.CHOICE_2' | translate }}
                                        <i class="fal fa-robot button_symbol" aria-hidden="true"></i
                                    ></label>
                                    <input type="radio" formControlName="character" value="CHOICE_3" class="btn-check" id="CCharacterRadio" autocomplete="off">
                                    <label class="btn choice_button" for="CCharacterRadio" [ngClass]="{ 'border border-danger': surveyTypology.get('character').invalid && stepSubmitted }">
                                        <i class="button_option" aria-hidden="true">C</i>
                                        {{ 'TONE_OF_VOICE.STEP_1.TYPE_CHARACTER.CHOICE_3' | translate }}
                                        <i class="fal fa-user-tie button_symbol" aria-hidden="true"></i
                                    ></label>
                                </div>
                                <small class="choice_invalid" *ngIf="surveyTypology.get('character').errors?.required && stepSubmitted">
                                    {{ 'COMMON.FORMS.VALIDATION.REQUIRED' | translate: {label: translateService.instant('TONE_OF_VOICE.STEP_1.TYPE_CHARACTER.TITLE')} }}
                                </small>
                            </footer>
                        </article>

                        <article class="query_box">
                            <header class="box_head">
                                <h4>{{'TONE_OF_VOICE.STEP_1.PERSONALITY.TITLE' | translate}}</h4>
                            </header>
                            <p class="query_paragraph">{{'TONE_OF_VOICE.STEP_1.PERSONALITY.QUERY' | translate}}</p>
                            <footer class="query_bottom">
                                <div class="btn-group tov_choice" role="group">
                                    <input type="radio" formControlName="personality" value="CHOICE_1" class="btn-check" id="APersonalityRadio" autocomplete="off">
                                    <label class="btn choice_button" for="APersonalityRadio"  [ngClass]="{ 'border border-danger': surveyTypology.get('personality').invalid && stepSubmitted }">
                                        <i class="button_option" aria-hidden="true">A</i>
                                        {{ 'TONE_OF_VOICE.STEP_1.PERSONALITY.CHOICE_1' | translate }}
                                    </label>
                                    <input type="radio" formControlName="personality" value="CHOICE_2" class="btn-check" id="BPersonalityRadio" autocomplete="off">
                                    <label class="btn choice_button" for="BPersonalityRadio" [ngClass]="{ 'border border-danger': surveyTypology.get('personality').invalid && stepSubmitted }">
                                        <i class="button_option" aria-hidden="true">B</i>
                                        {{ 'TONE_OF_VOICE.STEP_1.PERSONALITY.CHOICE_2' | translate }}
                                    </label>
                                </div>
                                <small class="choice_invalid" *ngIf="surveyTypology.get('personality').errors?.required && stepSubmitted">
                                    {{ 'COMMON.FORMS.VALIDATION.REQUIRED' | translate: {label: translateService.instant('TONE_OF_VOICE.STEP_1.PERSONALITY.TITLE')} }}
                                </small>
                            </footer>
                        </article>

                        <article class="query_box">
                            <header class="box_head">
                                <h4>{{'TONE_OF_VOICE.STEP_1.JOB_TITLE.TITLE' | translate}}</h4>
                            </header>
                            <p class="query_paragraph">{{'TONE_OF_VOICE.STEP_1.JOB_TITLE.QUERY' | translate}}</p>
                            <footer class="query_bottom">
                                <input type="text" class="form-control" placeholder="{{'TONE_OF_VOICE.STEP_1.JOB_TITLE.TITLE' | translate}}..." required formControlName="jobTitle" [ngClass]="{'is-invalid': surveyTypology.get('jobTitle').invalid && stepSubmitted}">
                                <div class="invalid-feedback" *ngIf="surveyTypology.get('jobTitle').errors?.required">
                                    {{ 'COMMON.FORMS.VALIDATION.REQUIRED' | translate: {label: translateService.instant('TONE_OF_VOICE.STEP_1.JOB_TITLE.TITLE')} }}
                                </div>
                            </footer>
                        </article>

                        <article class="query_box">
                            <header class="box_head">
                                <h4>{{'TONE_OF_VOICE.STEP_1.JOB_DESCRIPTION.TITLE' | translate}}</h4>
                            </header>
                            <p class="query_paragraph">{{'TONE_OF_VOICE.STEP_1.JOB_DESCRIPTION.QUERY' | translate}}</p>
                            <footer class="query_bottom">
                                <textarea class="form-control" rows="5" placeholder="{{'TONE_OF_VOICE.STEP_1.JOB_DESCRIPTION.TITLE' | translate}}..." required formControlName="jobDescription" [ngClass]="{'is-invalid': surveyTypology.get('jobDescription').invalid && stepSubmitted}"></textarea>
                                <div class="invalid-feedback" *ngIf="surveyTypology.get('jobDescription').errors?.required">
                                    {{ 'COMMON.FORMS.VALIDATION.REQUIRED' | translate: {label: translateService.instant('TONE_OF_VOICE.STEP_1.JOB_DESCRIPTION.TITLE')} }}
                                </div>
                            </footer>
                        </article>

                        <article class="query_box">
                            <header class="box_head">
                                <h4>{{'TONE_OF_VOICE.STEP_1.AGE.TITLE' | translate}}</h4>
                            </header>
                            <p class="query_paragraph">{{'TONE_OF_VOICE.STEP_1.AGE.QUERY' | translate}}</p>
                            <footer class="query_bottom">

                                <input type="number" class="form-control" placeholder="{{'TONE_OF_VOICE.STEP_1.AGE.TITLE' | translate}}..." required formControlName="age" [ngClass]="{'is-invalid': surveyTypology.get('age').invalid && stepSubmitted}">
                                <div class="invalid-feedback" *ngIf="surveyTypology.get('age').errors?.required">
                                    {{ 'COMMON.FORMS.VALIDATION.REQUIRED' | translate: {label: translateService.instant('TONE_OF_VOICE.STEP_1.AGE.TITLE')} }}
                                </div>
                            </footer>
                        </article>

                        <article class="query_box">
                            <header class="box_head">
                                <h4>{{'TONE_OF_VOICE.STEP_1.GENDER.TITLE' | translate}}</h4>
                            </header>
                            <p class="query_paragraph">{{'TONE_OF_VOICE.STEP_1.GENDER.QUERY' | translate}}</p>
                            <footer class="query_bottom">
                                <div class="btn-group tov_choice" role="group">
                                    <input type="radio" formControlName="gender" value="CHOICE_1" class="btn-check" id="AGenderRadio" autocomplete="off">
                                    <label class="btn choice_button" for="AGenderRadio"  [ngClass]="{ 'border border-danger': surveyTypology.get('gender').invalid && stepSubmitted }">
                                        <i class="button_option" aria-hidden="true">A</i>
                                        {{ 'TONE_OF_VOICE.STEP_1.GENDER.CHOICE_1' | translate }}
                                    </label>
                                    <input type="radio" formControlName="gender" value="CHOICE_2" class="btn-check" id="BGenderRadio" autocomplete="off">
                                    <label class="btn choice_button" for="BGenderRadio" [ngClass]="{ 'border border-danger': surveyTypology.get('gender').invalid && stepSubmitted }">
                                        <i class="button_option" aria-hidden="true">B</i>
                                        {{ 'TONE_OF_VOICE.STEP_1.GENDER.CHOICE_2' | translate }}
                                    </label>
                                    <input type="radio" formControlName="gender" value="CHOICE_3" class="btn-check" id="CGenderRadio" autocomplete="off">
                                    <label class="btn choice_button" for="CGenderRadio" [ngClass]="{ 'border border-danger': surveyTypology.get('gender').invalid && stepSubmitted }">
                                        <i class="button_option" aria-hidden="true">C</i>
                                        {{ 'TONE_OF_VOICE.STEP_1.GENDER.CHOICE_3' | translate }}
                                    </label>
                                </div>
                                <small class="choice_invalid" *ngIf="surveyTypology.get('gender').errors?.required && stepSubmitted">
                                    {{ 'COMMON.FORMS.VALIDATION.REQUIRED' | translate: {label: translateService.instant('TONE_OF_VOICE.STEP_1.GENDER.TITLE')} }}
                                </small>
                            </footer>
                        </article>
                    </div>

                    <div class="questions_body" *ngSwitchCase="2" formArrayName="skills">
                        <article class="query_box">
                            <header class="box_head">
                                <h4>{{'TONE_OF_VOICE.STEP_2.SKILLS.TITLE' | translate}}</h4>
                            </header>
                            <p class="query_paragraph">{{'TONE_OF_VOICE.STEP_2.SKILLS.QUERY' | translate}}</p>
                            <footer class="query_bottom">
                                <table class="table table-borderless skills_table" aria-describedby="Skills Table">
                                    <thead class="table_head">
                                        <tr>
                                            <th scope="col" class="col_title">{{'TONE_OF_VOICE.STEP_2.SKILLS.LABEL' | translate}}</th>
                                            <th scope="col">1</th><th scope="col">2</th><th scope="col">3</th><th scope="col">4</th><th scope="col">5</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let skill of surveySkills.controls; let i = index" [formGroupName]="i">
                                            <td class="row_title">
                                                <span>{{'TONE_OF_VOICE.STEP_2.SKILLS.' + skill.get('key').value | translate}}</span>
                                                <i class="fal fa-exclamation-circle" aria-hidden="true" *ngIf="skill.get('value').invalid && stepSubmitted"></i>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input type="radio" id="{{skill.get('key').value}}One" required class="form-check-input" [value]="1" formControlName="value">
                                                    <label class="form-check-label" for="{{skill.get('key').value}}One"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input type="radio" id="{{skill.get('key').value}}Two" required class="form-check-input" [value]="2" formControlName="value">
                                                    <label class="form-check-label" for="{{skill.get('key').value}}Two"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input type="radio" id="{{skill.get('key').value}}Three" required class="form-check-input" [value]="3" formControlName="value">
                                                    <label class="form-check-label" for="{{skill.get('key').value}}Three"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input type="radio" id="{{skill.get('key').value}}Four" required class="form-check-input" [value]="4" formControlName="value">
                                                    <label class="form-check-label" for="{{skill.get('key').value}}Four"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input type="radio" id="{{skill.get('key').value}}Five" required class="form-check-input" [value]="5" formControlName="value">
                                                    <label class="form-check-label" for="{{skill.get('key').value}}Five"></label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <small class="text-danger" *ngIf="surveySkills.errors?.exceededMaximum">{{'TONE_OF_VOICE.STEP_2.SKILLS.SUM_VALIDATION' | translate}}</small>
                            </footer>
                        </article>
                    </div>

                    <div class="questions_body single_query" *ngSwitchCase="3" formArrayName="personality">
                        <ng-template ngFor let-question [ngForOf]="surveyQuestions.controls" let-i="index">
                            <article class="query_box single_request" *ngIf="i === currentQuestion" [formGroupName]="i">
                                <p class="query_paragraph">{{'TONE_OF_VOICE.QUESTION' | translate}} {{currentQuestion + 1}}/{{surveyQuestions.length}}</p>
                                <header class="box_head">
                                    <h4>{{tovQuestions[i].question}}</h4>
                                </header>
                                <footer class="query_bottom">
                                    <div class="btn-group tov_choice" role="group">
                                        <input type="radio" formControlName="value" (change)="nextQuestion()" value="CHOICE_1" class="btn-check" id="AQuestion{{i}}Radio" autocomplete="off">
                                        <label class="btn choice_button" for="AQuestion{{i}}Radio"  [ngClass]="{ 'border border-danger': surveyQuestions.at(i).get('value').invalid && stepSubmitted }">
                                            <i class="button_option" aria-hidden="true">A</i>
                                            {{tovQuestions[i].option_2}}
                                        </label>
                                        <input type="radio" formControlName="value" (change)="nextQuestion()" value="CHOICE_2" class="btn-check" id="BQuestion{{i}}Radio" autocomplete="off">
                                        <label class="btn choice_button" for="BQuestion{{i}}Radio" [ngClass]="{ 'border border-danger': surveyQuestions.at(i).get('value').invalid && stepSubmitted }">
                                            <i class="button_option" aria-hidden="true">B</i>
                                            {{tovQuestions[i].option_1}}
                                        </label>
                                    </div>
                                    <small class="choice_invalid" *ngIf="surveyQuestions.at(i).get('value').errors?.required && stepSubmitted">
                                        {{ 'COMMON.FORMS.VALIDATION.REQUIRED' | translate: {label: translateService.instant('TONE_OF_VOICE.QUESTION') + ' ' + (currentQuestion + 1)} }}
                                    </small>
                                </footer>
                            </article>
                        </ng-template>
                    </div>

                    <div class="text-center" *ngIf="currentStep !== 3">
                        <a href="javascript:void(0)" class="text-uppercase me-4" (click)="prevStep()" *ngIf="currentStep !== 1">{{'COMMON.PREV' | translate}}</a>
                        <button type="button" class="btn text-uppercase tov_default" (click)="nextStep()">{{'COMMON.NEXT' | translate}}</button>
                    </div>
                </form>

                <div class="questions_result" *ngIf="currentStep === 4">                    
                    <header class="result_head">
                        <h3>{{'TONE_OF_VOICE.STEP_4.RESULTS' | translate}}</h3>
                    </header>
                    <article class="result_box">
                        <h4>{{'TONE_OF_VOICE.STEP_4.PERSONALITY.TITLE' | translate}}</h4>
                        <p>{{'TONE_OF_VOICE.STEP_4.PERSONALITY.DESCRIPTION' | translate}}</p>
                    </article>
                    <article class="result_stats">
                        <header class="stats_head">
                            <h3>{{tovResults.personalityName}}</h3>
                            <h4>{{tovResults.personalityType}}</h4>
                        </header>
                        <div class="stats_chart">
                            <p *ngFor="let progress of tovResults.categoryResults">
                                <ngb-progressbar [value]="progress.firstCategoryPerc"><span>{{progress.firstCategoryName}}</span><span>{{progress.firstCategoryPerc}}%</span></ngb-progressbar>
                                <span>{{progress.secondCategoryName}}</span>
                            </p>
                        </div>
                        <p class="stats_paragraph">{{tovResults.personalityDescription}}</p>
                    </article>
                    <article class="result_box">
                        <h4>{{'TONE_OF_VOICE.STEP_4.SKILLS.TITLE' | translate}}</h4>
                        <p>{{'TONE_OF_VOICE.STEP_4.SKILLS.TITLE' | translate}}</p>
                    </article>
                    <div class="results_chart">
                        <canvas id="chart_survey" #chartWrapper></canvas>
                    </div>
                    <footer class="result_bottom">
                        <button type="button" class="btn tov_default" (click)="exportResults()">{{'TONE_OF_VOICE.STEP_4.EXPORT' | translate}}</button>
                        <a href="javascript:void(0)" (click)="modalSupport()">{{'COMMON.ASK_NOOVLE' | translate}}</a>
                    </footer>
                </div>
            </section>
        </div>
    </div>
</div>

<footer class="result_bottom">
    <button type="button" class="btn tov_default" (click)="exportResults()">{{'TONE_OF_VOICE.STEP_4.EXPORT' | translate}}</button>
    <a href="javascript:void(0)" (click)="modalSupport()">{{'COMMON.ASK_NOOVLE' | translate}}</a>
</footer>
