<div class="console_modal http_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">HTTP Rest</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="connectorSubscription?.closed">
        <div class="body_content">
            <form class="http_form" [formGroup]="httpForm" novalidate>
                <steps-completion [phases]="httpPhases" [stepsForm]="stepsForm" [onMoveStep]="moveStep" (stepsCompleted)="saveConnector($event)"></steps-completion>

                <section class="connector_steps" formArrayName="steps" *roleRestriction="'design.processflow.httprest.write'; disableElements: true">
                    <article formGroupName="0" *ngIf="httpPhases.current === 0">
                        <div class="row">
                            <div class="col-4">
                                <label for="httpMethod" class="form-label">Method</label>
                                <select id="httpMethod" class="form-select" formControlName="method">
                                    <option [value]="'POST'">POST</option>
                                    <option [value]="'GET'">GET</option>
                                    <option [value]="'PUT'">PUT</option>
                                    <option [value]="'DELETE'">DELETE</option>
                                </select>
                            </div>
                            <div class="col-8">
                                <label for="url" class="form-label">URL*</label>
                                <input type="text" class="form-control" required formControlName="url" [ngClass]="{'is-invalid': stepOne.controls.url.invalid && httpPhases.steps[0].submitted}">
                                <div class="invalid-feedback" *ngIf="stepOne.controls.url.errors?.required">URL is required</div>
                            </div>
                        </div>
                    </article>

                    <article class="dynamic_fields" formGroupName="1" *ngIf="httpPhases.current === 1">
                        <h6 class="fields_head">{{httpHeaders.length}} header key</h6>
                        <div class="alert alert-light" role="alert" *ngIf="httpHeaders.length === 0">No headers are set</div>

                        <div class="fields_body" formArrayName="headers">
                            <div class="row mb-3" *ngFor="let header of httpHeaders.controls; let i = index; let last = last">
                                <div class="col-5" [formGroupName]="i">
                                    <input type="text" class="form-control" required placeholder="Key" formControlName="key" [ngClass]="{'is-invalid': httpHeaders.get([i, 'key']).invalid && httpPhases.steps[1].submitted}">
                                    <div class="invalid-feedback" *ngIf="httpHeaders.get([i, 'key']).errors?.required">Key is required</div>
                                </div>
                                <div class="col-6" [formGroupName]="i">
                                    <input type="text" class="form-control" required placeholder="Value" formControlName="value" [ngClass]="{'is-invalid': httpHeaders.get([i, 'value']).invalid && httpPhases.steps[1].submitted}">
                                    <div class="invalid-feedback" *ngIf="httpHeaders.get([i, 'value']).errors?.required">Value is required</div>
                                </div>
                                <div class="col-1">
                                    <button class="btn" (click)="httpHeaders.removeAt(i)"><i class="far fa-times-circle" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <button class="btn" (click)="addHeader()"><small><i class="fas fa-plus" aria-hidden="true"></i> Add</small></button>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article class="dynamic_fields" formGroupName="2" *ngIf="httpPhases.current === 2">
                        <h6 class="fields_head">{{httpParams.length}} Query Params</h6>
                        <div class="alert alert-light" role="alert" *ngIf="httpParams.length === 0">No Query Params are set</div>

                        <div class="fields_body" formArrayName="queryParams">
                            <div class="row mb-3" *ngFor="let header of httpParams.controls; let i = index; let last = last" [ngClass]="{'mb-3': !last}">
                                <div class="col-5" [formGroupName]="i">
                                    <input type="text" class="form-control" required placeholder="Key" formControlName="key" [ngClass]="{'is-invalid': httpParams.get([i, 'key']).invalid && httpPhases.steps[2].submitted}">
                                    <div class="invalid-feedback" *ngIf="httpParams.get([i, 'key']).errors?.required">Key is required</div>
                                </div>
                                <div class="col-6" [formGroupName]="i">
                                    <input type="text" class="form-control" required placeholder="Value" formControlName="value" [ngClass]="{'is-invalid': httpParams.get([i, 'value']).invalid && httpPhases.steps[2].submitted}">
                                    <div class="invalid-feedback" *ngIf="httpParams.get([i, 'value']).errors?.required">Value is required</div>
                                </div>
                                <div class="col-1">
                                    <button class="btn" (click)="httpParams.removeAt(i)"><i class="far fa-times-circle" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <button class="btn" (click)="addParam()"><small><i class="fas fa-plus" aria-hidden="true"></i> Add</small></button>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article formGroupName="3" *ngIf="httpPhases.current === 3">
                        <label for="httpPayload" class="form-label">JSON Payload</label>
                        <textarea id="httpPayload" class="form-control" rows="10" formControlName="payload"></textarea>
                    </article>
                </section>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="nextStep()" [hidden]="httpPhases.current === 3">Next</button>
        <button type="button" class="btn" (click)="nextStep()" [hidden]="httpPhases.current !== 3" *roleRestriction="'design.processflow.httprest.write'; disableElements: true">Save</button>
    </div>
</div>