import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  constructor(private http: HttpClient, private commonService: CommonService, @Inject(ENVIRONMENT) private env: Environment) {}

  getInteractionHistory(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('pageSize', pageSize.toString()).set('pageIndex', pageNumber.toString()),
    };

    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.sessionId) httpOptions.params = httpOptions.params.append('sessionId', params.sessionId);
    if (params.dfIntentName) httpOptions.params = httpOptions.params.append('dfIntentName', params.dfIntentName);
    if (params.userQuery) httpOptions.params = httpOptions.params.append('userQuery', params.userQuery);
    if (params.rating) httpOptions.params = httpOptions.params.append('operatorRatings', params.rating);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);

    return this.http.get(`${this.env.API.orchestrator_analysis}/messages/view/history`, httpOptions);
  }

  getEntities(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('intentName', params.intentName).set('agentLang', params.agentLang),
    };
    return this.http.get(`${this.env.API.orchestrator_analysis}/nlu/integrations/intents/parameters`, httpOptions);
  }

  getInteractions(sessionId: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('sessionId', sessionId),
    };

    return this.http.get(`${this.env.API.orchestrator_analysis}/messages/view`, httpOptions);
  }

  getIntents(params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('onlyName', params?.onlyName ? 'true' : 'false'),
    };

    if (params?.journeyApiKey) httpOptions.params = httpOptions.params.append('journeyApiKey', params.journeyApiKey);

    return this.http.get(`${this.env.API.orchestrator_analysis}/nlu/integrations/intents/search`, httpOptions);
  }

  getAgentsBot(type?: 'DIALOGFLOW_ES' | 'DIALOGFLOW_CX'| 'RASA'): Observable<any> {
    let httpOptions;
    if (type) {
      httpOptions = {
        params: new HttpParams().set('type', type),
      };
    }

    return this.http.get(`${this.env.API.orchestrator_configurator}/agentbot`, httpOptions);
  }
  getAllFlow(journeyApiKey?: string) {
    const httpOptions = {
      params: new HttpParams(),
    };

    if (journeyApiKey) httpOptions.params = httpOptions.params.append('journeyApiKey', journeyApiKey);
    return this.http.get<any>(`${this.env.API.orchestrator_configurator}/flow/list`, httpOptions);
  }

  downloadFile(section: 'analytics' | 'flow' | 'history' | 'intents', filePath: string, fileName: string) {
    const httpOptions = {
      params: new HttpParams().set('fileName', filePath),
      responseType: 'blob' as const,
    };

    return this.http.get(`${this.env.API.orchestrator_configurator}/${section}/downloadExport`, httpOptions).pipe(
      tap((response) => {
        this.commonService.downloadFile(response, 'blob', fileName);
      })
    );
  }

  createIntent(data: object): Observable<any> {
    return this.http.post(`${this.env.API.orchestrator_configurator}/intents`, data);
  }

  //ADMIN
  exportLogsOnStorage(data: FormData, params: any) {
    const httpOptions = {
      params: new HttpParams().set('journeyApiKey', params.journeyApiKey).set('bucketName', params.bucketName).set('projectId', params.projectId),
    };

    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.connectors && params.connectors.length) httpOptions.params = httpOptions.params.append('connectors', params.connectors);
    if (params.severity && params.severity.length) httpOptions.params = httpOptions.params.append('severity', params.severity);
    if (params.textLog) httpOptions.params = httpOptions.params.append('textLog', params.textLog);

    return this.http.post(`${this.env.API.orchestrator_analysis}/gcplogging/executeExportLogForGCP`, data, httpOptions);
  }

  exportAuditLogsOnStorage(data: FormData, params: any) {
    const httpOptions = {
      params: new HttpParams().set('journeyApiKey', params.journeyApiKey).set('bucketName', params.bucketName).set('projectId', params.projectId),
    };

    if (params.activityType) httpOptions.params = httpOptions.params.append('criteria.activityType', params.activityType);
    if (params.journeyName) httpOptions.params = httpOptions.params.append('criteria.journeyName', params.journeyName);

    if (params.component) httpOptions.params = httpOptions.params.append('criteria.component', params.component);
    if (params.eventType) httpOptions.params = httpOptions.params.append('criteria.eventType', params.eventType);
    if (params.principal) httpOptions.params = httpOptions.params.append('criteria.principal', params.principal);
    if (params.startDate) httpOptions.params = httpOptions.params.append('criteria.startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('criteria.endDate', params.endDate);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('criteria.sortBy', params.sortBy);

    return this.http.post(`${this.env.API.orchestrator_configurator}/audits/exportGCP`, data, httpOptions);
  }

  //TAG SERVICE
  getTags(params: { name?: string; type?: string; journeyApiKey?: string; isLogic?: boolean; editable?: boolean } = {}): Observable<any> {
    const httpOptions = {
      params: new HttpParams(),
    };
    if (params.name) httpOptions.params = httpOptions.params.append('name', params.name);
    if (params.type) httpOptions.params = httpOptions.params.append('type', params.type);
    if (params.journeyApiKey) httpOptions.params = httpOptions.params.append('journeyApiKey', params.journeyApiKey);
    if (typeof params.isLogic === 'boolean') httpOptions.params = httpOptions.params.append('isLogic', params.isLogic.toString());
    if (typeof params.editable === 'boolean') httpOptions.params = httpOptions.params.append('editable', params.editable.toString());

    return this.http.get(`${this.env.API.orchestrator_configurator}/tags/getAll`, httpOptions);
  }

  getTrainerTagsTree(): Observable<any> {
    return this.http.get(`${this.env.API.orchestrator_configurator}/tagUser/getTagTree`);
  }

  //INTENT SERVICE
  uploadIntentsData(file: File, params: any) {
    const httpOptions = {
      params: new HttpParams().set('journeyApiKey', params.journeyApiKey),
      responseType: 'text' as 'text',
    };
    const data = new FormData();
    data.append('file', file, file.name);

    return this.http.post(`${this.env.API.orchestrator_configurator}/intents/uploadConfiguration`, data, httpOptions);
  }

  importIntentsData(data: any, params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('journeyApiKey', params.journeyApiKey).set('fileName', params.fileName),
      responseType: 'text' as 'text',
    };

    return this.http.post(`${this.env.API.orchestrator_configurator}/intents/importConfiguration`, data, httpOptions);
  }

  //CX ELEMENTS SERVICE
  uploadFlowsData(file: File, params: any) {
    const httpOptions = {
      params: new HttpParams().set('journeyApiKey', params.journeyApiKey),
      responseType: 'text' as 'text',
    };
    const data = new FormData();
    data.append('file', file, file.name);

    return this.http.post(`${this.env.API.orchestrator_configurator}/flow/uploadConfiguration`, data, httpOptions);
  }

  importFlowsData(data: any, params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('journeyApiKey', params.journeyApiKey).set('fileName', params.fileName),
      responseType: 'text' as 'text',
    };

    return this.http.post(`${this.env.API.orchestrator_configurator}/flow/importConfiguration`, data, httpOptions);
  }

  //MONITORING SERVICE
  getBillingUsers(): Observable<any> {
    return this.http.get(`${this.env.API.orchestrator_configurator}/planning/billing`);
  }

  getNotifiedUser() {
    return this.http.get(`${this.env.API.orchestrator_configurator}/planning/notifiedUser`);
  }

  updateNotifiedUser(data: any, params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('enabled', params.enabled.toString()),
    };

    return this.http.put(`${this.env.API.orchestrator_configurator}/planning/notifiedUser`, data, httpOptions);
  }

  //BILLING
  getBillingConsumtions(startDate: number, endDate: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString()),
    };
    return this.http.get(`${this.env.API.orchestrator_analysis}/billing/consumption`, httpOptions);
  }

  //TRAINING
  getEntityNames(params: any): Observable<Array<string>> { //STANDALONE
    const httpOptions = {
      params: new HttpParams().set('agentLang', params.agentLang),
    };
    return this.http.get<Array<string>>(`${this.env.API.orchestrator_configurator}/entities/getAllNames`, httpOptions);
  }
}
