import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-custom-report-modal',
  templateUrl: './custom-report-modal.component.html',
  styleUrls: ['./custom-report-modal.component.scss'],
})
export class CustomReportModalComponent implements OnDestroy {
  @Output() saved = new EventEmitter<any>();

  types = ['Date', 'number', 'string'];

  indexFieldSelected;
  reportFormSubmitted = false;
  subscriptions = {};

  reportForm = this.fb.group({
    userReportName: this.fb.control('', Validators.required),
    fields: this.fb.array([], [Validators.required, Validators.minLength(1)]),
  });

  get field() {
    return this.fb.group({
      name: this.fb.control('', Validators.required),
      type: this.fb.control('', Validators.required),
    });
  }

  get fields() {
    return this.reportForm.get('fields') as FormArray;
  }
  get userReportName() {
    return this.reportForm.get('userReportName');
  }

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private analyticsService: AnalyticsService) {}

  saveReport() {
    this.reportFormSubmitted = true;
    if (this.reportForm.valid) {
      this.subscriptions['CreateReport'] = this.analyticsService.createReport(this.reportForm.value).subscribe((data: any) => {
        this.saved.emit(data);
        this.activeModal.close();
      });
    }
  }

  addField() {
    this.fields.push(this.field);
    this.indexFieldSelected = this.fields.length - 1;
  }

  removeAction(index: number) {
    this.fields.removeAt(index);
  }

  checkKey(event) {
    const regex = new RegExp('[a-zA-Z0-9_]');
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }
}
