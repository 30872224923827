<ng-template [spinnerLoader]="subscriptions['Connector']?.closed"></ng-template>
<ng-template [spinnerLoader]="subscriptions['RemoveConnector']?.closed"></ng-template>
<ng-template [spinnerLoader]="subscriptions['RemoveFlow']?.closed"></ng-template>

<div class="error_handler_sidebar" [formGroup]="errForm" [spinnerLoader]="subscriptions['AddFlow']?.closed" *roleRestriction="'design.processflow.httperrorhandler.write'; disableElements: true">
    <div class="sidebar_content" formArrayName="flows">
        <article class="dynamic_fields border-0">
            <h6 class="fields_head">{{ flows.controls.length }} errors</h6>

            <div class="fields_body" cdkDropListGroup>
                <error-handler-item *ngFor="let flow of flows.controls; let flowIndex = index" [handlerGroup]="flow" [handlerIndex]="flowIndex" [errorSumbitted]="submitted" [errorConnector]="connector" [sidebarContainerRef]="sidebarConnectorContainer" (reloadConnector)="getConnector()" (deleteHandler)="removeFlow($event)"></error-handler-item>

                <hr />

                <form class="row new_flow_form" #inputForm="ngForm" (ngSubmit)="addFlow(inputForm)">
                    <div class="col-6">
                        <div class="mb-2 position-relative">
                            <input
                                type="text"
                                aria-describedby="errorCodesHelp"
                                class="form-control"
                                required
                                pattern="(\d{1}(\d{2}|\d{1}\*{1}|\*{2}))(,\s*(\d{1}(\d{2}|\d{1}\*{1}|\*{2})))*"
                                placeholder="Error code..."
                                [(ngModel)]="newFlow"
                                name="errorFlow"
                                #errorFlow="ngModel"
                                [ngClass]="{ 'is-invalid': errorFlow.invalid && inputForm.submitted }"
                                (keydown.enter)="inputForm.onSubmit($event)"
                            />
                            <button type="submit" class="btn add_item">+ Add</button>
                            <div class="invalid-feedback" *ngIf="errorFlow.errors?.required">Error code is required</div>
                            <div class="invalid-feedback" *ngIf="errorFlow.errors?.pattern">Format not valid, Ie: 4**, 40*, 501</div>
                            <small id="errorCodesHelp" class="form-text text-muted" *ngIf="errorFlow.valid || !inputForm.submitted">Ie: 4**, 40*, 501</small>
                        </div>
                    </div>
                </form>
            </div>
        </article>
    </div>
    <div class="sidebar_bottom">
        <button class="btn" (click)="updateConnector()" *roleRestriction="'design.processflow.httperrorhandler.write'; disableElements: true">Save</button>
    </div>
</div>

<template #sidebarConnectorContainer> </template>
