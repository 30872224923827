<div class="console_modal event_caller_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Event Caller</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="connectorSubscription?.closed">
        <div class="body_content">
            <form [formGroup]="eventCallerForm" *roleRestriction="'design.processflow.eventcaller.write'; disableElements: true">
                <section class="connector_steps">
                    <div class="mb-3">
                        <label for="eventName" class="form-label">Event name *</label>
                        <input type="text" id="eventName" class="form-control" formControlName="eventName" [ngClass]="{ 'is-invalid': eventCallerForm.get('eventName').invalid && submitted }" />
                        <div class="invalid-feedback" *ngIf="eventCallerForm.get('eventName').errors?.required">URL is required</div>
                    </div>

                    <div class="dynamic_fields" *journeyRestriction="'ES'">
                        <h6 class="fields_head">{{ parameters.length }} params</h6>
                        <div class="alert alert-light" role="alert" *ngIf="parameters.length === 0">No parameters are set</div>

                        <div class="fields_body" formArrayName="parameters">
    
                            <div class="row " *ngFor="let meta of parameters.controls;  let i = index; let last = last" [ngClass]="{'mb-3': !last}">
                                <div class="col-5" [formGroupName]="i">
                                    <input type="text" class="form-control" required placeholder="Key" formControlName="key" [ngClass]="{ 'is-invalid': parameters.at(i).get('key').invalid && submitted }"/>
                                    <div class="invalid-feedback" *ngIf="parameters.at(i).get('key').errors?.required">Key is required</div>
                                </div>
                                <div class="col-6" [formGroupName]="i">
                                    <input type="text" class="form-control" required placeholder="value" formControlName="value" [ngClass]="{ 'is-invalid': parameters.at(i).get('value').invalid && submitted }"/>
                                    <div class="invalid-feedback" *ngIf="parameters.at(i).get('value').errors?.required">Value is required</div>
                                </div>
                                <div class="col-1">
                                    <button class="btn" (click)="parameters.removeAt(i)"><i class="far fa-times-circle" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <button class="btn" (click)="addParameter()">
                                        <small><i class="fas fa-plus" aria-hidden="true"></i> Add</small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="saveConnector()" *roleRestriction="'design.processflow.eventcaller.write'; disableElements: true">Save</button>
    </div>
</div>
