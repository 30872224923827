
<div class="console_modal training_dialog conversation_interactions" [spinnerLoader]="subscriptions['CreateReport']?.closed">
    <div class="modal-header">
        <section class="head_top">
            <h4 class="modal-title">New Custom report</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
                <i class="fal fa-times" aria-hidden="true"></i>
            </button>
        </section>
    </div>
    <div class="modal-body">
        <form [formGroup]="reportForm" id="reportForm" class="conversation_form" autocomplete="off">
            <div class="row mb-3">
                <div class="col-4">
                    <label for="userReportName" class="form-label">Custom report name *</label>
                    <input id="userReportName" class="form-control" autocomplete="off"  formControlName="userReportName" required [ngClass]="{'is-invalid': reportFormSubmitted && userReportName.invalid}"/>
                    <div class="invalid-feedback" *ngIf="userReportName.errors?.required">Custom report name is required</div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label">Data model*</label>

                    <div class="dynamic_fields wide_fields table_fields" formArrayName="fields">
                        <div class="fields_head">
                            <div class="row g-0">
                                <div class="offset-1 col-3">Field Name</div>
                                <div class="col-3">Type</div>
                                <div class="col text-end">{{fields.length}} {{fields.length === 1 ? 'field' : 'fields'}}</div>
                            </div>
                        </div>

                        <div class="fields_body">
                            <div class="row g-0 p-2 align-items-center" (click)="indexFieldSelected = index" [ngClass]="{'field_selected': indexFieldSelected === index}" *ngFor="let field of fields.controls; let index = index" [formGroupName]="index">
                                <div class="col-1">{{index + 1}}</div>
                                <div class="col-3 px-2">
                                    <input class="form-control" [id]="'fieldName_' + index" formControlName="name" (keypress)="checkKey($event)" required [ngClass]="{'is-invalid': reportFormSubmitted && field.get('name').invalid}">
                                    <div class="invalid-feedback" *ngIf="field.get('name').errors?.required">Field name is required</div>
                                </div>
                                <div class="col-3 px-2">
                                    <select class="form-select" [id]="'fieldType_' + index" formControlName="type" required [ngClass]="{'is-invalid': reportFormSubmitted && field.get('type').invalid}">
                                        <option [value]="null" hidden></option>
                                        <option *ngFor="let type of types">{{type}}</option>
                                    </select>
                                    <div class="invalid-feedback" *ngIf="field.get('type').errors?.required">Field name is required</div>
                                </div>
                                <div class="col ms-auto text-end" *ngIf="indexFieldSelected === index">
                                    <button type="button" class="btn" (click)="removeAction(index)"><i class="fal fa-trash" aria-hidden="true"></i></button>
                                </div>
                            </div>
    
                            <button class="btn" (click)="addField()"><small class="add_field"><i class="fas fa-plus" aria-hidden="true"></i> Add field</small></button>
                            <div class="invalid-feedback d-block" *ngIf="reportFormSubmitted && fields.errors?.required">Add almost one field</div>
                        </div>
    
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn brand_default" (click)="saveReport()">Save</button>
    </div>
</div>
