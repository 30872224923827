
export const TEST_MESSAGES_HISTORY = {
    data: [
        {
            agentBotType: 'Dialogflow ES',
            timestamp: 1651496127126,
            userQuery: null,
            dfOutputMessage: 'Benvenuto in Tellya',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/support","parameters":{"nome":"","nome.original":""}},{"lifespan":998,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/request-context","parameters":{"Param":"2","isPresent":"1","nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '3291ccc5-c340-40ef-accc-c14d543c0cb6',
            sessionId: '1651496126472211C927B951',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30068,
            created: 1651496127000,
            updated: 1651496127000,
            idConversation: 13885,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 998.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/request-context',
                    parameters: { Param: '2', isPresent: '1', nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: true,
        },
        {
            agentBotType: 'Dialogflow ES',
            timestamp: 1651496126829,
            userQuery: null,
            dfOutputMessage: 'Ciao sono il tuo assistente!;<speak>Dimmi tutto!</speak>',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/support","parameters":{"nome":"","nome.original":""}},{"lifespan":998,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/request-context","parameters":{"isPresent":"1","nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '89fc9030-4c44-46f0-906f-7a9fbcf2c15b',
            sessionId: '1651496126472211C927B951',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30067,
            created: 1651496127000,
            updated: 1651496127000,
            idConversation: 13885,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 998.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/request-context',
                    parameters: { isPresent: '1', nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: false,
        },
        {
            agentBotType: 'Dialogflow CX',
            timestamp: 1651496126454,
            userQuery: 'Welcome',
            dfOutputMessage: 'Ciao, sono tuo assistente virtuale! \uD83E\uDD16\nCome posso aiutarti?;<speak>Dimmi tutto!</speak>',
            dfFlowName: 'Default Start Flow',
            dfPageName: 'Start Page',
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '22db87e8-4cfb-4ff4-8998-970523b22b3c',
            sessionId: '1651496126472211C927B951',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30066,
            created: 1651496127000,
            updated: 1651496127000,
            idConversation: 13885,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496126472211C927B951/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: false,
        },
        {
            agentBotType: 'Dialogflow CX',
            timestamp: 1651496084018,
            userQuery: null,
            dfOutputMessage: 'Benvenuto in Tellya',
            dfFlowName: 'Default Start Flow',
            dfPageName: 'Start Page',
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'ceec7e16-695c-4276-a722-27fb8431e0cc',
            sessionId: '1651496083746206B6C5980B',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30065,
            created: 1651496084000,
            updated: 1651496084000,
            idConversation: 13884,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496083746206B6C5980B/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496083746206B6C5980B/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496083746206B6C5980B/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 998.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496083746206B6C5980B/contexts/request-context',
                    parameters: { isPresent: '1', nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: true,
        },
        {
            timestamp: 1651496083669,
            userQuery: 'Welcome',
            dfOutputMessage: 'Ciao, sono tuo assistente virtuale! \uD83E\uDD16\nCome posso aiutarti?;<speak>Dimmi tutto!</speak>',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651496083746206B6C5980B/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651496083746206B6C5980B/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651496083746206B6C5980B/contexts/support","parameters":{"nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '1f877733-1d71-470c-9ed8-56c942b30753',
            sessionId: '1651496083746206B6C5980B',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30064,
            created: 1651496084000,
            updated: 1651496084000,
            idConversation: 13884,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496083746206B6C5980B/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496083746206B6C5980B/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651496083746206B6C5980B/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: false,
        },
        {
            timestamp: 1651496070413,
            userQuery: 'Cambia il soggetto del ticket',
            dfOutputMessage: 'What is the caseId?',
            dfOutputContext:
                '[{"lifespan":2,"name":"projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/9eb09b70-8a0f-449b-9e30-40ecfac59982_id_dialog_context","parameters":{"caseId2":"","caseId":"","caseId2.original":"","caseId.original":""}},{"lifespan":2,"name":"projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/salesforce-update-case_dialog_context","parameters":{"caseId2":"","caseId":"","caseId2.original":"","caseId.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/salesforce-update-case_dialog_params_caseid","parameters":{"caseId2":"","caseId":"","caseId2.original":"","caseId.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/provider","parameters":{"caseId2":"","caseId":"","nome":"","caseId2.original":"","nome.original":"","caseId.original":""}},{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/session","parameters":{"caseId2":"","caseId":"","nome":"","caseId2.original":"","nome.original":"","caseId.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/support","parameters":{"caseId2":"","caseId":"","nome":"","caseId2.original":"","nome.original":"","caseId.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/__system_counters__","parameters":{"no-input":0.0,"no-match":0.0,"caseId2":"","caseId":"","caseId2.original":"","caseId.original":""}}]',
            dfInputContext: null,
            dfEventName: '',
            dfActionName: 'updateCase',
            dfIntentName: 'salesforce-update-case',
            dfEntityMatch: '{"caseId2":"","caseId":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'ce8d156c-701b-4694-a015-2f60030f5294',
            sessionId: '165149606468888B0C3889C8',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 0.7328667640686035,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30063,
            created: 1651496070000,
            updated: 1651496070000,
            idConversation: 13883,
            dfOutputContextJson: [
                {
                    lifespan: 2.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/9eb09b70-8a0f-449b-9e30-40ecfac59982_id_dialog_context',
                    parameters: { caseId2: '', caseId: '', 'caseId2.original': '', 'caseId.original': '' },
                },
                {
                    lifespan: 2.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/salesforce-update-case_dialog_context',
                    parameters: { caseId2: '', caseId: '', 'caseId2.original': '', 'caseId.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/salesforce-update-case_dialog_params_caseid',
                    parameters: { caseId2: '', caseId: '', 'caseId2.original': '', 'caseId.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/provider',
                    parameters: { caseId2: '', caseId: '', nome: '', 'caseId2.original': '', 'nome.original': '', 'caseId.original': '' },
                },
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/session',
                    parameters: { caseId2: '', caseId: '', nome: '', 'caseId2.original': '', 'nome.original': '', 'caseId.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/support',
                    parameters: { caseId2: '', caseId: '', nome: '', 'caseId2.original': '', 'nome.original': '', 'caseId.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/__system_counters__',
                    parameters: { 'no-input': 0.0, 'no-match': 0.0, caseId2: '', caseId: '', 'caseId2.original': '', 'caseId.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { caseId2: '', caseId: '' },
            isShownMessage: true,
        },
        {
            timestamp: 1651496064800,
            userQuery: 'Welcome',
            dfOutputMessage: 'Benvenuto in Tellya',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/support","parameters":{"nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'cf37f780-0323-4de1-bfb0-cef7e1733dcb',
            sessionId: '165149606468888B0C3889C8',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30062,
            created: 1651496064000,
            updated: 1651496064000,
            idConversation: 13883,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165149606468888B0C3889C8/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: true,
        },
        {
            timestamp: 1651496017575,
            userQuery: null,
            dfOutputMessage: 'Benvenuto in Tellya',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/support","parameters":{"nome":"","nome.original":""}},{"lifespan":998,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/request-context","parameters":{"Param":"welcome","nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '8a5b9825-607b-49b8-95fc-5c861712e84b',
            sessionId: '16514960173982C14F9AF918',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30061,
            created: 1651496017000,
            updated: 1651496017000,
            idConversation: 13882,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 998.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/request-context',
                    parameters: { Param: 'welcome', nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: true,
        },
        {
            timestamp: 1651496017381,
            userQuery: 'Welcome',
            dfOutputMessage: 'Ciao sono il tuo assistente!;<speak>Dimmi tutto!</speak>',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/support","parameters":{"nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '102ddcd0-7dfc-42d8-b162-23d71275f336',
            sessionId: '16514960173982C14F9AF918',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30060,
            created: 1651496017000,
            updated: 1651496017000,
            idConversation: 13882,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514960173982C14F9AF918/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: false,
        },
        {
            timestamp: 1651495796995,
            userQuery: 'Welcome',
            dfOutputMessage: 'Benvenuto in Tellya',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651495796932DE5B612DDF4/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651495796932DE5B612DDF4/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651495796932DE5B612DDF4/contexts/support","parameters":{"nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'f73df526-a4bb-402c-b6fb-53a3e7238090',
            sessionId: '1651495796932DE5B612DDF4',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30059,
            created: 1651495797000,
            updated: 1651495797000,
            idConversation: 13881,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651495796932DE5B612DDF4/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651495796932DE5B612DDF4/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651495796932DE5B612DDF4/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: true,
        },
        {
            timestamp: 1651495423251,
            userQuery: null,
            dfOutputMessage: 'Benvenuto in Tellya',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/support","parameters":{"nome":"","nome.original":""}},{"lifespan":998,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/request-context","parameters":{"Prova":"prova","nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '986ef27d-c450-4abf-8c62-c5f09bab55ec',
            sessionId: '16514954228595D474B3400B',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30056,
            created: 1651495423000,
            updated: 1651495423000,
            idConversation: 13879,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 998.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/request-context',
                    parameters: { Prova: 'prova', nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: true,
        },
        {
            timestamp: 1651495422867,
            userQuery: 'Welcome',
            dfOutputMessage: 'Ciao, sono tuo assistente virtuale! \uD83E\uDD16\nCome posso aiutarti?;<speak>Dimmi tutto!</speak>',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/support","parameters":{"nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '1cabed63-11b5-4fb0-9400-4c3bc693c19d',
            sessionId: '16514954228595D474B3400B',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30055,
            created: 1651495423000,
            updated: 1651495423000,
            idConversation: 13879,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514954228595D474B3400B/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: false,
        },
        {
            timestamp: 1651495353540,
            userQuery: 'Welcome',
            dfOutputMessage: 'Benvenuto in Tellya',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514953534985D2366CAB59/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514953534985D2366CAB59/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514953534985D2366CAB59/contexts/support","parameters":{"nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '0c85f40d-9f32-4ed1-a73f-79dff5db7ebd',
            sessionId: '16514953534985D2366CAB59',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30054,
            created: 1651495353000,
            updated: 1651495353000,
            idConversation: 13878,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514953534985D2366CAB59/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514953534985D2366CAB59/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514953534985D2366CAB59/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: true,
        },
        {
            timestamp: 1651487993252,
            userQuery: 'b',
            dfOutputMessage: 'Perdonami ma non credo di aver capito. Prova a riformulare la tua richiesta.',
            dfOutputContext:
                '[{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__","parameters":{"no-input":0.0,"no-match":8.0}}]',
            dfInputContext: null,
            dfEventName: '',
            dfActionName: '',
            dfIntentName: 'Default Fallback Intent',
            dfEntityMatch: '{}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'c407e447-a12c-4b4b-972b-00bcee0a6f98',
            sessionId: '165148605028687686B9526A',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: true,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30039,
            created: 1651487993000,
            updated: 1651487993000,
            idConversation: 13868,
            dfOutputContextJson: [
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__',
                    parameters: { 'no-input': 0.0, 'no-match': 8.0 },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            isShownMessage: true,
        },
        {
            timestamp: 1651487992883,
            userQuery: 'dfsb',
            dfOutputMessage: 'Perdonami ma non credo di aver capito. Prova a riformulare la tua richiesta.',
            dfOutputContext:
                '[{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__","parameters":{"no-input":0.0,"no-match":7.0}}]',
            dfInputContext: null,
            dfEventName: '',
            dfActionName: '',
            dfIntentName: 'Default Fallback Intent',
            dfEntityMatch: '{}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'b233b505-8dd6-472c-831a-66b18afd4ca2',
            sessionId: '165148605028687686B9526A',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: true,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30038,
            created: 1651487992000,
            updated: 1651487992000,
            idConversation: 13868,
            dfOutputContextJson: [
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__',
                    parameters: { 'no-input': 0.0, 'no-match': 7.0 },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            isShownMessage: true,
        },
        {
            timestamp: 1651487992713,
            userQuery: 'dfs',
            dfOutputMessage: 'Perdonami ma non credo di aver capito. Prova a riformulare la tua richiesta.',
            dfOutputContext:
                '[{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__","parameters":{"no-input":0.0,"no-match":6.0}}]',
            dfInputContext: null,
            dfEventName: '',
            dfActionName: '',
            dfIntentName: 'Default Fallback Intent',
            dfEntityMatch: '{}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'ab14b160-3acc-4751-9988-0c35bf03cabc',
            sessionId: '165148605028687686B9526A',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: true,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30037,
            created: 1651487992000,
            updated: 1651487992000,
            idConversation: 13868,
            dfOutputContextJson: [
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__',
                    parameters: { 'no-input': 0.0, 'no-match': 6.0 },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            isShownMessage: true,
        },
        {
            timestamp: 1651487992653,
            userQuery: 'bsdfb',
            dfOutputMessage: 'Perdonami ma non credo di aver capito. Prova a riformulare la tua richiesta.',
            dfOutputContext:
                '[{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__","parameters":{"no-input":0.0,"no-match":5.0}}]',
            dfInputContext: null,
            dfEventName: '',
            dfActionName: '',
            dfIntentName: 'Default Fallback Intent',
            dfEntityMatch: '{}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'c77bc110-5b86-41a7-8c71-c792d3de9486',
            sessionId: '165148605028687686B9526A',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: true,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30035,
            created: 1651487992000,
            updated: 1651487992000,
            idConversation: 13868,
            dfOutputContextJson: [
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__',
                    parameters: { 'no-input': 0.0, 'no-match': 5.0 },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            isShownMessage: true,
        },
        {
            timestamp: 1651487992639,
            userQuery: 'b',
            dfOutputMessage: 'Perdonami ma non credo di aver capito. Prova a riformulare la tua richiesta.',
            dfOutputContext:
                '[{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__","parameters":{"no-input":0.0,"no-match":4.0}}]',
            dfInputContext: null,
            dfEventName: '',
            dfActionName: '',
            dfIntentName: 'Default Fallback Intent',
            dfEntityMatch: '{}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '30abcb42-fe0b-4521-81c8-b7fe87d15ead',
            sessionId: '165148605028687686B9526A',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: true,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30036,
            created: 1651487992000,
            updated: 1651487992000,
            idConversation: 13868,
            dfOutputContextJson: [
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__',
                    parameters: { 'no-input': 0.0, 'no-match': 4.0 },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            isShownMessage: true,
        },
        {
            timestamp: 1651487992463,
            userQuery: 'sdfbhssdfbsdfbdfsb',
            dfOutputMessage: 'Perdonami ma non credo di aver capito. Prova a riformulare la tua richiesta.',
            dfOutputContext:
                '[{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__","parameters":{"no-input":0.0,"no-match":3.0}}]',
            dfInputContext: null,
            dfEventName: '',
            dfActionName: '',
            dfIntentName: 'Default Fallback Intent',
            dfEntityMatch: '{}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '42232e43-3afe-4af4-a01c-586736fbe7e2',
            sessionId: '165148605028687686B9526A',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: true,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30034,
            created: 1651487992000,
            updated: 1651487992000,
            idConversation: 13868,
            dfOutputContextJson: [
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__',
                    parameters: { 'no-input': 0.0, 'no-match': 3.0 },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            isShownMessage: true,
        },
        {
            timestamp: 1651487992012,
            userQuery: 'sdfbhssdfbsdfb',
            dfOutputMessage: 'Perdonami ma non credo di aver capito. Prova a riformulare la tua richiesta.',
            dfOutputContext:
                '[{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__","parameters":{"no-input":0.0,"no-match":2.0}}]',
            dfInputContext: null,
            dfEventName: '',
            dfActionName: '',
            dfIntentName: 'Default Fallback Intent',
            dfEntityMatch: '{}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '69daefb9-b777-4361-a73a-8a9efacf4402',
            sessionId: '165148605028687686B9526A',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: true,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30033,
            created: 1651487992000,
            updated: 1651487992000,
            idConversation: 13868,
            dfOutputContextJson: [
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__',
                    parameters: { 'no-input': 0.0, 'no-match': 2.0 },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            isShownMessage: true,
        },
        {
            timestamp: 1651487991645,
            userQuery: 'sdfbhs',
            dfOutputMessage: 'Perdonami ma non credo di aver capito. Prova a riformulare la tua richiesta.',
            dfOutputContext:
                '[{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__","parameters":{"no-input":0.0,"no-match":1.0}}]',
            dfInputContext: null,
            dfEventName: '',
            dfActionName: '',
            dfIntentName: 'Default Fallback Intent',
            dfEntityMatch: '{}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: '7bacf7b2-d49d-4f9c-ba03-1079e6d3a6a9',
            sessionId: '165148605028687686B9526A',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: true,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30032,
            created: 1651487991000,
            updated: 1651487991000,
            idConversation: 13868,
            dfOutputContextJson: [
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/__system_counters__',
                    parameters: { 'no-input': 0.0, 'no-match': 1.0 },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            isShownMessage: true,
        },
        {
            timestamp: 1651486044318,
            userQuery: 'Ciao',
            dfOutputMessage: 'Benvenuto in Tellya',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/support","parameters":{"nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: '',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'b166889e-c7d8-43e1-acee-35095f37dab5',
            sessionId: '165148605028687686B9526A',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30026,
            created: 1651486044000,
            updated: 1651486044000,
            idConversation: 13868,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: true,
        },
        {
            timestamp: 1651486041197,
            userQuery: 'Welcome',
            dfOutputMessage: 'Benvenuto in Tellya',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/support","parameters":{"nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'c7522416-3cf9-4879-8717-879d2741256b',
            sessionId: '165148605028687686B9526A',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 30025,
            created: 1651486041000,
            updated: 1651486041000,
            idConversation: 13868,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/165148605028687686B9526A/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: true,
        },
        {
            timestamp: 1651481448080,
            userQuery: 'Welcome',
            dfOutputMessage: 'Benvenuto in Tellya',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651481447500359BFE02FBF/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651481447500359BFE02FBF/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/1651481447500359BFE02FBF/contexts/support","parameters":{"nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'db5c735a-fc67-4a1f-b570-9eb57bf608f7',
            sessionId: '1651481447500359BFE02FBF',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 29987,
            created: 1651481448000,
            updated: 1651481448000,
            idConversation: 13849,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651481447500359BFE02FBF/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651481447500359BFE02FBF/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1651481447500359BFE02FBF/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: true,
        },
        {
            timestamp: 1651478711277,
            userQuery: 'Welcome',
            dfOutputMessage: 'Benvenuto in Tellya',
            dfOutputContext:
                '[{"lifespan":99,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514787109595D77E001ABB/contexts/session","parameters":{"nome":"","nome.original":""}},{"lifespan":5,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514787109595D77E001ABB/contexts/provider","parameters":{"nome":"","nome.original":""}},{"lifespan":1,"name":"projects/tellya-stage-botgeneral/agent/sessions/16514787109595D77E001ABB/contexts/support","parameters":{"nome":"","nome.original":""}}]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            dfActionName: 'provaAction',
            dfIntentName: 'Default Welcome Intent',
            dfEntityMatch: '{"nome":""}',
            lang: 'it',
            editedUserQuery: null,
            idMessage: 'aff6b845-21d9-4147-8898-3c4c99f2adde',
            sessionId: '16514787109595D77E001ABB',
            operatorComment: null,
            operatorOutputMessage: null,
            journeyApiKey: 'a3cb266c85b03274b6dd4ec652ba4854',
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            fallback: false,
            fromOperator: true,
            trusted: false,
            edited: false,
            forwardNlu: null,
            operatorRatings: null,
            id: 29985,
            created: 1651478711000,
            updated: 1651478711000,
            idConversation: 13848,
            dfOutputContextJson: [
                {
                    lifespan: 99.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514787109595D77E001ABB/contexts/session',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 5.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514787109595D77E001ABB/contexts/provider',
                    parameters: { nome: '', 'nome.original': '' },
                },
                {
                    lifespan: 1.0,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/16514787109595D77E001ABB/contexts/support',
                    parameters: { nome: '', 'nome.original': '' },
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: { nome: '' },
            isShownMessage: true,
        },
    ],
    count: 25,
    totalElements: 7848,
};

export const TEST_INTEGRATIONS_INTENTS = {
    singleResult: {
        id: 'projects/unicredit-air-dev/agent/intents/625cbcb3-e6be-4ea9-b73d-71fdba2a3e3e',
        name: 'codice.recupero',
        parameters: [
            {
                id: 'c88f8196-73bc-4978-9090-7e66e5bd1f6f',
                name: 'codice',
                value: '$codice',
                entityType: '@codice',
            },
            {
                id: 'f2385f84-5b6e-432b-a5a6-f43cf7fc53b3',
                name: 'codice_carte',
                value: '$codice_carte',
                entityType: '@codice_carte',
            },
            {
                id: 'fe29bf3d-2f94-4fdc-8f0c-34227e576c05',
                name: 'canale',
                value: '$canale',
                entityType: '@canale',
            },
        ],
    },
};

export const TEST_INTENTS = {
    data: [
        {
            id: 'projects/unicredit-air-dev/agent/intents/02df5632-4b87-4482-907e-ab725c92e637',
            name: 'ezfeed-preform.position.yes',
            language: 'it',
        },
        {
            id: 'projects/unicredit-air-dev/agent/intents/02ee13c1-f520-4ff3-82bd-4f39b6857cc7',
            name: 'bmc.sblocco',
            language: 'it',
        },
        {
            id: 'projects/unicredit-air-dev/agent/intents/08516764-0bb3-42fd-86c0-59d7ddab2421',
            name: 'carte.condizioni-costo',
            language: 'it',
        },
        {
            id: 'projects/tellya-stage-botgeneral/agent/intents/a5ef0e37-ff1e-45c4-bbba-8a297c79a28b',
            name: 'Default Welcome Intent',
            language: 'it',
        },
    ],
    count: 3,
};

export const TEST_MESSAGES = {
    data: [
        {
            id: 23258,
            created: 1645191548000,
            updated: 1645191548000,
            idConversation: 11624,
            timestamp: 1645191548869,
            fallback: false,
            fromOperator: false,
            edited: false,
            userQuery: '',
            dfOutputMessage: '',
            dfOutputContext: '[]',
            dfInputContext: null,
            dfEventName: 'Welcome',
            editedDfIntentName: 'Default Welcome Intent',
            dfActionName: 'null',
            dfIntentName: 'Default Welcome Intent',
            dfFlowName: 'Default Start Flow',
            dfPageName: 'Start Page',
            editedUserQuery: '',
            idMessage: 'b080df65-7b2b-48c7-9d0b-bd0a1a212f50',
            operatorOutputMessage: null,
            operatorComment: null,
            dfEntityMatch: '{}',
            lang: 'it',
            trusted: true,
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            operatorRatings: null,
            dfOutputContextJson: [
                {
                    lifespan: 99,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1644917593152/contexts/session',
                    parameters: {},
                },
                {
                    lifespan: 5,
                    name: 'projects/tellya-stage-botgeneral/agent/sessions/1644917593152/contexts/provider',
                    parameters: {},
                },
            ],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            editedDfEntityJson: {},
            isShownMessage: true,
        },
        {
            id: 23259,
            created: 1645191552000,
            updated: 1645191552000,
            idConversation: 11624,
            timestamp: 1645191552214,
            fallback: false,
            fromOperator: false,
            edited: false,
            userQuery: 'ciao',
            dfOutputMessage: '',
            dfOutputContext: '[]',
            dfInputContext: null,
            dfEventName: null,
            editedDfIntentName: 'Default Welcome Intent',
            dfActionName: null,
            dfIntentName: 'Default Welcome Intent',
            dfFlowName: '00000000-0000-0000-0000-000000000000',
            dfPageName:
                'projects/tellya-stage-botgeneral/locations/europe-west1/agents/5b675207-453b-4ab0-ab5a-1746331e8d66/flows/00000000-0000-0000-0000-000000000000/pages/START_PAGE',
            editedUserQuery: 'ciao',
            idMessage: '51b2030d-e819-4e81-a710-b7683545f978',
            operatorOutputMessage: null,
            operatorComment: null,
            dfEntityMatch: '{}',
            lang: 'it',
            trusted: true,
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            operatorRatings: null,
            dfOutputContextJson: [],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            editedDfEntityJson: {},
            isShownMessage: true,
        },
        {
            id: 23260,
            created: 1645191552000,
            updated: 1645191552000,
            idConversation: 11624,
            timestamp: 1645191552430,
            fallback: false,
            fromOperator: false,
            edited: false,
            userQuery: 'ciao',
            dfOutputMessage: 'Ciao;ecco il link di [[NOOVLE]]',
            dfOutputContext: '[]',
            dfInputContext: null,
            dfEventName: null,
            dfActionName: null,
            dfIntentName: 'Default Welcome Intent',
            editedDfIntentName: 'Default Welcome Intent',
            dfFlowName: 'Default Start Flow',
            dfPageName: 'Start Page',
            editedUserQuery: 'ciao',
            idMessage: '26fecf3f-7fba-430f-b9f7-c8dc224befcc',
            operatorOutputMessage: null,
            operatorComment: null,
            dfEntityMatch: '{}',
            lang: 'it',
            trusted: true,
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            operatorRatings: null,
            dfOutputContextJson: [],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            editedDfEntityJson: {},
            isShownMessage: false,
        },
        {
            id: 23261,
            created: 1645191552000,
            updated: 1645191552000,
            idConversation: 11624,
            timestamp: 1645191552430,
            fallback: false,
            fromOperator: false,
            edited: false,
            userQuery: null,
            dfOutputMessage: 'prova action mapping',
            dfOutputContext: '[]',
            dfInputContext: null,
            dfEventName: 'evento_pluto',
            dfActionName: null,
            dfIntentName: '',
            editedDfIntentName: '',
            dfFlowName: 'Default Start Flow',
            dfPageName: 'Start Page',
            editedUserQuery: null,
            idMessage: '26ebce67-5aab-491c-abd7-980f01cf32ad',
            operatorOutputMessage: null,
            operatorComment: null,
            dfEntityMatch: '{}',
            lang: 'it',
            trusted: true,
            dfSentiment: 0.0,
            dfConfidence: 1.0,
            operatorRatings: null,
            dfOutputContextJson: [],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            editedDfEntityJson: {},
            isShownMessage: true,
        },
        {
            id: 23262,
            created: 1645191559000,
            updated: 1645191559000,
            idConversation: 11624,
            timestamp: 1645191559233,
            fallback: false,
            fromOperator: false,
            edited: false,
            userQuery: 'cosa puoi fare?',
            dfOutputMessage: '',
            dfOutputContext: '[]',
            dfInputContext: null,
            dfEventName: null,
            dfActionName: null,
            dfIntentName: 'intro',
            editedDfIntentName: 'intro',
            dfFlowName: '00000000-0000-0000-0000-000000000000',
            dfPageName:
                'projects/tellya-stage-botgeneral/locations/europe-west1/agents/5b675207-453b-4ab0-ab5a-1746331e8d66/flows/00000000-0000-0000-0000-000000000000/pages/START_PAGE',
            editedUserQuery: 'cosa puoi fare?',
            idMessage: '209fac92-c85a-4290-b4dc-c80c644e0f4b',
            operatorOutputMessage: null,
            operatorComment: null,
            dfEntityMatch: '{}',
            lang: 'it',
            trusted: true,
            dfSentiment: 0.0,
            dfConfidence: 0.5547957420349121,
            operatorRatings: null,
            dfOutputContextJson: [],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            editedDfEntityJson: {},
            isShownMessage: true,
        },
        {
            id: 23263,
            created: 1645191559000,
            updated: 1645191559000,
            idConversation: 11624,
            timestamp: 1645191559404,
            fallback: false,
            fromOperator: false,
            edited: false,
            userQuery: 'cosa puoi fare?',
            dfOutputMessage: 'Intento Intro;Entry ful Intro, ecco il sito di [[NOOVLE]]',
            dfOutputContext: '[]',
            dfInputContext: null,
            dfEventName: null,
            dfActionName: null,
            dfIntentName: 'intro',
            editedDfIntentName: 'intro',
            dfFlowName: 'Default Start Flow',
            dfPageName: 'Intro',
            editedUserQuery: 'cosa puoi fare?',
            idMessage: '3e2175c0-527a-4c19-bfd8-370e5e2e184d',
            operatorOutputMessage: null,
            operatorComment: null,
            dfEntityMatch: '{}',
            lang: 'it',
            trusted: true,
            dfSentiment: 0.0,
            dfConfidence: 0.5547957420349121,
            operatorRatings: null,
            dfOutputContextJson: [],
            dfInputContextJson: null,
            dfEntitiesJson: {},
            editedDfEntityJson: {},
            isShownMessage: true,
        },
    ],
    count: 6,
};

export const TEST_NOTIFIED_USER = [
    {
        id: 1,
        firstName: 'Admin',
        lastName: 'System',
        email: 'admin@localhost',
        resetKey: null,
        enabled: true,
        lang: 'it',
        accountId: 1,
        verified: true,
        authorities: ['ADMIN'],
        fullName: 'Admin System',
    },
];

export const TEST_CONSUPTIONS = [
    {
        name: 'Dialogflow ES',
        billingList: [{ startDate: 1621202399000, endDate: 1652738399000, value: 1124 }],
        total: 6000,
        remaining: 4876,
        characterInspected: null,
        characterTrasformed: null,
    },
    {
        name: 'Dialogflow CX',
        billingList: [{ startDate: 1621202399000, endDate: 1652738399000, value: 1429 }],
        total: 21,
        remaining: -1408,
        characterInspected: null,
        characterTrasformed: null,
    },
    {
        name: 'Data Loss Prevention',
        billingList: [{ startDate: 1621202399000, endDate: 1652738399000, value: 286062 }],
        total: 5000,
        remaining: -281062,
        characterInspected: 284539,
        characterTrasformed: 1523,
    },
    {
        name: 'Natural Language',
        billingList: [{ startDate: 1621202399000, endDate: 1652738399000, value: 125 }],
        total: 6000,
        remaining: 5875,
        characterInspected: null,
        characterTrasformed: null,
    },
    {
        name: 'Translate',
        billingList: [{ startDate: 1621202399000, endDate: 1652738399000, value: 7660 }],
        total: 1020,
        remaining: -6640,
        characterInspected: null,
        characterTrasformed: null,
    },
];

export const TEST_GET_TAG_TREE = {
    otherTopics: [
        'default',
        'prova topic 3',
        'prova topic 1',
        'prova topic 5',
        'nuovo-argomento',
        'arg2',
        'topic prova 4',
        'test',
        'test1',
        'fallback',
        'walcome',
        'dynamic test',
        'codice fiscale',
        'Welcome',
        'topic prova 1',
        'prova topic 2',
        'operatore_vivocha',
        'zendesk',
        'sales',
        'offers',
        'Subito',
    ],
    myTopics: [],
};

export const TEST_FLOW_LIST = [
    {
        id: 1,
        name: 'What is Tellya',
        dialogflowId:
            'projects/tellya-stage-botgeneral/locations/europe-west1/agents/5b675207-453b-4ab0-ab5a-1746331e8d66/flows/00000000-0000-0000-0000-000000000000',
    },
    {
        id: 3,
        name: 'prova',
        dialogflowId:
            'projects/tellya-stage-botgeneral/locations/europe-west1/agents/5b675207-453b-4ab0-ab5a-1746331e8d66/flows/862377c7-3a22-4236-af96-4bf5dc4ae0a9',
    },
    {
        id: 4,
        name: 'Sync ',
        dialogflowId:
            'projects/tellya-stage-botgeneral/locations/europe-west1/agents/5b675207-453b-4ab0-ab5a-1746331e8d66/flows/be9c018d-11a1-42ca-9e58-5c53119ab4a5',
    },
    {
        id: 2,
        name: 'Test lingua',
        dialogflowId:
            'projects/tellya-stage-botgeneral/locations/europe-west1/agents/5b675207-453b-4ab0-ab5a-1746331e8d66/flows/859cbb42-922e-4f02-87e0-22299be4d930',
    },
];

export const TEST_AGENTS_BOTS = [
    {
        id: 130,
        name: 'Agent CX Fulfillment',
        projectId: 'tellya-stage-botgeneral',
        accountId: 1,
        agentType: 'Dialogflow CX',
        agentId: '03e62e76-3a1e-4664-9689-01047eedcf9c',
        locationId: 'global',
        multiLang: false,
        supportedLang: null,
    },
    {
        id: 126,
        name: 'Agent CX',
        projectId: 'tellya-stage-botgeneral',
        accountId: 1,
        agentType: 'Dialogflow CX',
        agentId: 'f2c565ee-cd7d-471b-b856-63cf384f5c1b',
        locationId: 'global',
        multiLang: true,
        supportedLang: ['it', 'en'],
    },
];

export const TEST_DOUGHNUT_DATASET = {
    data: [1, 0, 22, 0, 0, 0, 300, 0, 0, 8, 0, 4, 0],
    labels: [
        ['Not specified ', ' (0.29%)'],
        ['App ', ' (0%)'],
        ['Website ', ' (6.56%)'],
        ['Facebook ', ' (0%)'],
        ['Vivocha ', ' (0%)'],
        ['Whatsapp ', ' (0%)'],
        ['Test ', ' (89.55%)'],
        ['Tim Rcc ', ' (0%)'],
        ['LivePerson ', ' (0%)'],
        ['Google ', ' (2.38%)'],
        ['Voice ', ' (0%)'],
        ['Teams ', ' (1.19%)'],
        ['Telegram ', ' (0%)'],
    ],
    colors: [
        '#000000',
        '#F50000',
        '#F5008C',
        '#1773FA',
        '#00447D',
        '#38C251',
        '#75076C',
        '#54A866',
        '#F66E05',
        '#0B631E',
        '#9717f4',
        '#12ABB0',
        '#3642AD',
    ],
};

export const TEST_TAG_LIST = [
    { name: 'trusted', type: 'STATUS', iconId: '', editable: false, logicTag: true },
    { name: 'untrusted', type: 'STATUS', iconId: '', editable: false, logicTag: true },
    { name: 'sys_parameter_intent', type: 'SYSTEM', iconId: 'brightness_low', editable: false, logicTag: false },
    { name: 'default', type: 'TOPIC', iconId: 'bar_chart', editable: true, logicTag: false },
    { name: 'pippo-cognitive2', type: 'element', iconId: 'call_split', editable: true, logicTag: false },
    { name: 'test123', type: 'ca', iconId: 'bar_chart', editable: true, logicTag: false },
    { name: 'sasasa', type: 'ca', iconId: 'chat_bubble_outline', editable: true, logicTag: false },
    { name: 'dfdfd', type: 'dfdfd', iconId: 'favorite', editable: true, logicTag: false },
    { name: 'asasas', type: 'asasa', iconId: 'cancel', editable: true, logicTag: false },
    { name: 'testName1', type: 'testCategory1', iconId: 'cloud_queue', editable: true, logicTag: false },
    { name: 'testName2', type: 'testCategory2', iconId: 'notifications_active', editable: true, logicTag: false },
    { name: 'pippo-cognitive23', type: 'fsdss', iconId: 'brightness_low', editable: true, logicTag: false },
    { name: 'testcuorenome', type: 'testcuore', iconId: 'favorite', editable: true, logicTag: false },
    { name: '0000000', type: 'disptacher', iconId: 'brightness_low', editable: true, logicTag: false },
];

export const TEST_ENTITIES_NAME = ['entity1', 'entity2'];
