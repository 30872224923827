import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AnalyticsService } from '../services/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class ReportResolver implements Resolve<boolean> {
  constructor(private analyticsService: AnalyticsService) {}

  resolve(): Observable<any> {
    return this.analyticsService.getAllReport();
  }
}
