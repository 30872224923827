<div class="console_modal journey_dialog">
    <div class="modal-header">
        <h4 class="modal-title">Journey settings</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>

    <ng-template [spinnerLoader]="subscriptions['SaveJourney']?.closed"></ng-template>
    <ng-template [spinnerLoader]="subscriptions['DeleteJourney']?.closed"></ng-template>
    <ng-template [spinnerLoader]="subscriptions['Journeys']?.closed"></ng-template>

    <div class="modal-body">
        <div class="body_content">
            <div class="row">
                <div class="col-12">
                    <ul ngbNav #journeyNav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
                        <li [ngbNavItem]="'general'">
                            <a ngbNavLink>General details</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="journeyForm" class="journey_form" novalidate *roleRestriction="'design.journey.write'; disableElements: true">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="mb-3">
                                                <label for="journeyName" class="form-label">Journey name *</label>
                                                <input type="text" id="journeyName" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': journeyForm.get('name').invalid && journeySubmitted }" />
                                                <div class="invalid-feedback" *ngIf="journeyForm.get('name').errors?.required && journeySubmitted">Name is required</div>
                                            </div>
                                            <div class="mb-3">
                                                <label for="journeyDescription" class="form-label">Description</label>
                                                <textarea id="journeyDescription" class="form-control" rows="3" formControlName="description"></textarea>
                                            </div>
                                            <div class="mb-3 row" *ngIf="!journey">
                                                <label for="journeyDispatcher" class="col-6 col-form-label pt-0">Dispatcher journey</label>
                                                <div class="col-6">
                                                    <div class="form-check form-switch">
                                                        <input type="checkbox" class="form-check-input" id="journeyDispatcher" formControlName="dispatcher" />
                                                        <label class="form-check-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3 row" *ngIf="!journey">
                                                <label for="journeyFulfilment" class="col-6 col-form-label pt-0">Fulfilment journey</label>
                                                <div class="col-6">
                                                    <div class="form-check form-switch">
                                                        <input type="checkbox" class="form-check-input" id="journeyFulfilment" formControlName="fulfillment" />
                                                        <label class="form-check-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3 row">
                                                <label for="journeyPublic" class="col-6 col-form-label pt-0">Public</label>
                                                <div class="col-6">
                                                    <div class="form-check form-switch">
                                                        <input type="checkbox" class="form-check-input" id="journeyPublic" [checked]="journeyForm.get('status').value === 'DEPLOYED'" (change)="changeStatus($event)" />
                                                        <label class="form-check-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6" *ngIf="journey">
                                            <dl>
                                                <dt>Creation date</dt>
                                                <dd>{{ journeyForm.value.createdDate | date: 'dd/MM/yyyy' }}</dd>

                                                <dt>Author</dt>
                                                <dd>{{ journeyForm.value.createdBy }}</dd>

                                                <dt>Last update</dt>
                                                <dd>{{ journeyForm.value.lastModifiedDate | date: 'dd/MM/yyyy' }}</dd>

                                                <dt>Journey type</dt>
                                                <dd>{{ journeyForm.get('fulfillment').value ? 'Fulfilment journey' : journeyForm.get('dispatcher').value ? 'Dispatcher journey' : 'Standard journey' }}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </form>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="'fulfillment'" *ngIf="journey && journeyForm.get('fulfillment').value">
                            <a ngbNavLink>Fulfillment</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="fulfillmentForm" class="fulfillment_form" novalidate *roleRestriction="'design.journey.write'; disableElements: true">
                                    <div class="mb-3" *ngIf="journey.fulfillment">
                                        <div class="form-check">
                                            <input type="radio" id="import" name="editMode" class="form-check-input" [value]="'import'" formControlName="editMode" />
                                            <label class="form-check-label" for="import">Import agent data</label>
                                        </div>
                                        <div class="form-check">
                                            <input type="radio" id="export" name="editMode" class="form-check-input" [value]="'export'" formControlName="editMode" />
                                            <label class="form-check-label" for="export">Switch agent</label>
                                        </div>
                                    </div>
                                    <div class="mb-3 row" *ngIf="fulfillmentEditMode === 'export'">
                                        <div class="col-6">
                                            <label for="dialogflowAgent" class="form-label">Agent *</label>
                                            <select name="fulfilmentAgent" id="dialogflowAgent" class="form-select" formControlName="agent" [compareWith]="compareFulfilmentAgent" [ngClass]="{ 'is-invalid': journeySubmitted && fulfillmentForm.get('agent').invalid }">
                                                <option *ngFor="let agent of agents" [ngValue]="agent">{{ agent.name }}</option>
                                            </select>
                                            <div class="invalid-feedback" *ngIf="fulfillmentForm.get('agent').errors?.required">Agent is required</div>
                                        </div>
                                        <div class="col-6 mt-auto mb-2">
                                            <ng-container [ngSwitch]="fulfillmentForm.get('agent').value?.agentType">
                                                <span *ngSwitchCase="'Dialogflow ES'"><i class="dialogflow_icon dialogflow_icon_es" aria-hidden="true"></i> Google Dialogflow ES</span>
                                                <span *ngSwitchCase="'Dialogflow CX'"><i class="dialogflow_icon dialogflow_icon_cx" aria-hidden="true"></i> Google Dialogflow CX</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="fulfillmentEditMode === 'export' && agentChanged">
                                        <div class="col-12">
                                            <p><i class="far fa-exclamation-triangle fa-sm text-warning" aria-hidden="true"></i> Changing the agent will result in the loss of all information and configuration related to the data in it and defined within Tellya (status, responses, tags...).</p>
                                            <p class="mb-0">Before proceeding you can export the information and data related to these configurations.</p>
                                            <p>You can also export the conversational history and analytical data.</p>
                                        </div>
                                        <div class="col d-flex align-items-center" *journeyRestriction="{ id: journey.id, type: 'CX', fulfillment: true }">
                                            <button class="btn text_brand" (click)="exportFlows()" [disabled]="persistentSubscriptions['ExportFlow']?.closed === false">
                                                <p class="import_progress" *ngIf="persistentSubscriptions['ExportFlow']?.closed === false">
                                                    <span class="progress_spinner"><ng-template [spinnerLoader]="false" size="xs"></ng-template></span>
                                                </p>
                                                <i *ngIf="!(persistentSubscriptions['ExportFlow']?.closed === false)" class="fal fa-cloud-download" aria-hidden="true"> </i>
                                                Export page's element data
                                            </button>
                                        </div>
                                        <div class="col d-flex align-items-center" *journeyRestriction="{ id: journey.id, type: 'ES', fulfillment: true }">
                                            <button class="btn text_brand" (click)="exportIntents()" [disabled]="persistentSubscriptions['ExportIntent']?.closed === false">
                                                <p class="import_progress" *ngIf="persistentSubscriptions['ExportIntent']?.closed === false">
                                                    <span class="progress_spinner"><ng-template [spinnerLoader]="false" size="xs"></ng-template></span>
                                                </p>
                                                <i *ngIf="!(persistentSubscriptions['ExportIntent']?.closed === false)" class="fal fa-cloud-download" aria-hidden="true"> </i>
                                                Export intent data
                                            </button>
                                        </div>
                                        <div class="col d-flex align-items-center">
                                            <button class="btn text_brand" (click)="exportHistory()" [disabled]="persistentSubscriptions['ExportHistory']?.closed === false">
                                                <p class="import_progress" *ngIf="persistentSubscriptions['ExportHistory']?.closed === false">
                                                    <span class="progress_spinner"><ng-template [spinnerLoader]="false" size="xs"></ng-template></span>
                                                </p>
                                                <i *ngIf="!(persistentSubscriptions['ExportHistory']?.closed === false)" class="fal fa-cloud-download" aria-hidden="true"> </i>
                                                Export history
                                            </button>
                                        </div>
                                        <div class="col-12 my-3">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="noticeRead" formControlName="noticeRead" [ngClass]="{ 'is-invalid': journeySubmitted && fulfillmentForm.get('noticeRead').invalid }" />
                                                <label class="form-check-label" for="noticeRead">I have read the notice and want to continue with the change of agent.</label>
                                                <div class="invalid-feedback">You must agree before continue.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3" *ngIf="fulfillmentEditMode === 'import'">
                                        <div class="input-group">
                                            <input type="file" class="form-control" id="importIntent" (change)="storeFile($event.target)" />
                                            <label class="form-label" for="importIntent">
                                                <button class="btn btn-sm brand_light"><i class="fal fa-cloud-upload" aria-hidden="true"></i> Browse file</button>
                                                <span class="ms-1" *ngIf="subscriptions['UploadFile']?.closed !== false">{{ file?.name }}</span>
                                                <p class="import_progress" *ngIf="subscriptions['UploadFile']?.closed === false">
                                                    <span class="progress_spinner"><ng-template [spinnerLoader]="subscriptions['UploadFile']?.closed" size="xs"></ng-template></span> Upload in progress...
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="'rest'" *ngIf="journey">
                            <a ngbNavLink>Integration</a>
                            <ng-template ngbNavContent>
                                <form #embeddedForm="ngForm" class="embedded_form" novalidate *roleRestriction="'design.journey.write'; disableElements: true">
                                    <ng-container *ngIf="!journeyForm.get('fulfillment').value; else fulfillmentJourney">
                                        <div class="mb-3">
                                            <label for="journeyUrl" class="form-label">POST request URL</label>
                                            <div class="embedded_area">
                                                <button type="button" class="btn copy_to_clipboard" (click)="commonService.copyToClipboard(embeddedUrl)" ngbTooltip="Copy to Clipboard"><i class="far fa-copy" aria-hidden="true"></i></button>
                                                <input type="text" id="journeyUrl" class="form-control" #embeddedUrl readonly [value]="embeddedBasePath + env.API.orchestrator_chat + '/orchestrate/' + journey.apiKey" />
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="journeyRest" class="form-label">Request Payload</label>
                                            <div class="embedded_area">
                                                <button type="button" class="btn copy_to_clipboard" (click)="commonService.copyToClipboard(embeddedRest)" ngbTooltip="Copy to Clipboard"><i class="far fa-copy" aria-hidden="true"></i></button>
                                                <textarea #embeddedRest id="journeyRest" class="form-control" rows="10" readonly>
                                                {{ restCode }}
                                            </textarea
                                                >
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #fulfillmentJourney>
                                        <div class="mb-3" *ngIf="fulfillmentForm.get('agent').value?.agentType">
                                            <label for="webhookUrl" class="form-label">Webhook URL</label>
                                            <div class="embedded_area">
                                                <button type="button" class="btn copy_to_clipboard" (click)="commonService.copyToClipboard(webhookUrl)" ngbTooltip="Copy to Clipboard"><i class="far fa-copy" aria-hidden="true"></i></button>
                                                <input type="text" id="webhookUrl" class="form-control" #webhookUrl readonly [value]="embeddedBasePath + env.API.orchestrator_chat + '/fulfillment'" />
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="webhookUrl" class="form-label">apiKey</label>
                                            <div class="embedded_area">
                                                <button type="button" class="btn copy_to_clipboard" (click)="commonService.copyToClipboard(apiKey)" ngbTooltip="Copy to Clipboard"><i class="far fa-copy" aria-hidden="true"></i></button>
                                                <input type="text" id="webhookUrl" class="form-control" #apiKey readonly [value]="journey.apiKey" />
                                            </div>
                                        </div>
                                    </ng-template>
                                </form>
                            </ng-template>
                        </li>
                    </ul>
                </div>

                <div class="col-12">
                    <div [ngbNavOutlet]="journeyNav"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer d-flex" *roleRestriction="'design.journey.write'; disableElements: true">
        <button type="button" class="btn btn-danger" *ngIf="journey && activeTab === 'general' && !lastJourney" (click)="deleteJourney()">Delete</button>
        <button type="button" class="btn brand_default ms-auto" (click)="saveJourney()" *ngIf="activeTab !== 'rest'">Save</button>
    </div>
</div>
