<div class="console_modal dispatcher_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Dispatcher</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="connectorSubscription?.closed">
        <div class="body_content" [formGroup]="dispatcherForm">
            <form formArrayName="records" *roleRestriction="'design.processflow.dispatcher.write'; disableElements: true">
                <div class="row mb-2">
                    <div class="col-3">Parameters</div>
                    <div class="col-3">Default journey</div>
                    <div class="col-3">Dispatcher code (Tag)</div>
                    <div class="col-3">Switch journey</div>
                </div>
                <div class="row mb-3" *ngFor="let record of records.controls; let recordIndex = index" [formArrayName]="recordIndex">
                    <div class="col-3">
                        <input type="text" class="form-control" placeholder="Insert param..." formControlName="dispatcherCode" [ngClass]="{ 'is-invalid': record.get('dispatcherCode').invalid && submitted }" />
                    </div>
                    <div class="col-3">
                        <select name="ruleRun" id="ruleRun" class="form-select" formControlName="defaultJourneyApiKey" [ngClass]="{ 'is-invalid': record.get('defaultJourneyApiKey').invalid && submitted }">
                            <option value="">Select default journey...</option>
                            <option *ngFor="let journey of journeys" [value]="journey.apiKey">{{ journey.name }}</option>
                        </select>
                    </div>

                    <div class="col-5" formGroupName="entries">
                        <div class="row mb-3 row_entry" *ngFor="let entry of getEntries(recordIndex).controls; let entryIndex = index" [formArrayName]="entryIndex">
                            <div class="col-11">
                                <div class="row">
                                    <div class="col-6">
                                        <input type="text" class="form-control" placeholder="Insert Tag..." formControlName="dispatcherCode" [ngClass]="{ 'is-invalid': entry.get('dispatcherCode').invalid && submitted }" />
                                    </div>
                                    <div class="col-6">
                                        <select name="ruleRun" id="ruleRun" class="form-select" formControlName="executeJourneyApiKey" [ngClass]="{ 'is-invalid': entry.get('executeJourneyApiKey').invalid && submitted }">
                                            <option value="">Select switch journey...</option>
                                            <ng-template ngFor let-journey [ngForOf]="journeys">
                                                <option *ngIf="!journey.dispatcher && !journey.fulfillment" [value]="journey.apiKey">{{ journey.name }}</option>
                                            </ng-template>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-1">
                                <button *ngIf="getEntries(recordIndex).length > 1" class="btn" (click)="getEntries(recordIndex).removeAt(entryIndex)">
                                    <small><i class="far fa-times-circle" aria-hidden="true"></i></small>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button class="btn" (click)="addEntry(recordIndex)">
                                    <small><i class="fas fa-plus" aria-hidden="true"></i> Add</small>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 text-end">
                        <button *ngIf="records.length > 1" class="btn" (click)="records.removeAt(recordIndex)">
                            <small><i class="far fa-trash-alt" aria-hidden="true"></i></small>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn connector_color" (click)="addRecordWithEntry()">
                            <small><i class="fas fa-plus" aria-hidden="true"></i> Add</small>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="saveConnector()" *roleRestriction="'design.processflow.dispatcher.write'; disableElements: true">Save</button>
    </div>
</div>
