import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ENVIRONMENT } from '@environment-token';
import { environment } from '../environments/environment';
import { CoreModule } from '@core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '@shared';
import { ToneOfVoiceCardComponent } from '@tone-of-voice';
import { ShepherdService } from 'angular-shepherd';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './components/chat/chat.component';
import { ConsoleHeaderComponent } from './components/console-header/console-header.component';
import { ConsoleMenuComponent } from './components/console-menu/console-menu.component';
import { AnalyticsCardComponent } from './components/dashboard/analytics-card/analytics-card.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EventsCardComponent } from './components/dashboard/events-card/events-card.component';
import { MonitoringCardComponent } from './components/dashboard/monitoring-card/monitoring-card.component';
import { OverviewCardComponent } from './components/dashboard/overview-card/overview-card.component';
import { SessionsCardComponent } from './components/dashboard/sessions-card/sessions-card.component';
import { StatusCardComponent } from './components/dashboard/status-card/status-card.component';
import { TrainingCardComponent } from './components/dashboard/training-card/training-card.component';
import { GlossaryComponent } from './components/glossary/glossary.component';
import { MainComponent } from './components/main/main.component';
import { ChatDebugModalComponent } from './components/modals/chat-debug-modal/chat-debug-modal.component';
import { ChatSettingsModalComponent } from './components/modals/chat-settings-modal/chat-settings-modal.component';
import { GraphicPaginationPipe } from './pipes/graphic-pagination.pipe';
import { ChatInteractionComponent } from '@standalone';


registerLocaleData(localeIt, 'it');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ConsoleHeaderComponent,
    ConsoleMenuComponent,
    ChatComponent,
    ChatDebugModalComponent,
    ChatSettingsModalComponent,
    DashboardComponent,
    OverviewCardComponent,
    AnalyticsCardComponent,
    TrainingCardComponent,
    EventsCardComponent,
    StatusCardComponent,
    MonitoringCardComponent,
    GlossaryComponent,
    SessionsCardComponent,
    GraphicPaginationPipe,
  ],
  imports: [
    ToneOfVoiceCardComponent,
    CoreModule,
    SharedModule,
    NgxSliderModule,
    AppRoutingModule,
    CarouselModule,
    ScrollingModule,
    ChatInteractionComponent,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'it' },
    ShepherdService,
    { provide: ENVIRONMENT, useValue: environment }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
