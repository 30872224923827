<div class="console_modal salesforce_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">{{ connector.productName }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="subscriptions.saveConnector?.closed">
        <div class="body_content" [spinnerLoader]="subscriptions.objects?.closed">
            <form [formGroup]="salesforceForm" class="salesforce_form" novalidate autocomplete="off"  *roleRestriction="roleRestrinctionPermission; disableElements: true">
                <steps-completion [phases]="salesforcePhases" [stepsForm]="stepsForm" [onMoveStep]="moveStep" (stepsCompleted)="saveConnector($event)" (stepsInvalid)="showError()"></steps-completion>

                <section class="connector_steps" formArrayName="steps" >
                    <article formGroupName="0" *ngIf="salesforcePhases.current === 0">
                        <salesforce-auth-step [form]="stepOne" [salesforcePhases]="salesforcePhases"></salesforce-auth-step>
                    </article>

                    <article *ngIf="salesforcePhases.current === 1" formGroupName="1">
                        <ng-container formGroupName="actions">
                            <ng-container *ngFor="let action of stepTwo.controls; index as actionIndex" [formGroupName]="actionIndex">
                                <div class="dynamic_fields mb-3">
                                    <div class="fields_head d-flex align-items-center" *ngIf="stepTwo.get([actionIndex, 'expanded']) as expandedForm" (click)="expandedForm.setValue(!expandedForm.value)">
                                        <i *ngIf="!expandedForm.value" class="fas fa-caret-right me-2" aria-hidden="true"></i>
                                        <i *ngIf="expandedForm.value" class="fas fa-caret-down me-2" aria-hidden="true"></i>
                                        {{
                                            stepTwo.get([actionIndex, 'typeOfCall']).value
                                                ? typeQueryLabel[stepTwo.get([actionIndex, 'typeOfCall']).value] + ' ' + stepTwo.get([actionIndex, 'queryObject']).value
                                                : 'Create an action'
                                        }}
                                        <button class="btn p-0 ms-auto" [disabled]="stepTwo.controls.length === 1" (click)="stepTwo.removeAt(actionIndex)">
                                            <small> <i class="fas fa-trash" aria-hidden="true"></i></small>
                                        </button>
                                    </div>

                                    <div class="fields_body" [ngClass]="{ expanded: stepTwo.get([actionIndex, 'expanded']).value }">
                                        <div class="row mb-3">
                                            <div class="col-2">
                                                <label for="QueryObject" class="form-label">Query Object *</label>
                                                <select id="QueryObjectSelect" class="form-select" formControlName="queryObject" [ngClass]="{ 'is-invalid': stepTwo.get([actionIndex, 'queryObject']).invalid && salesforcePhases.steps[1].submitted }">
                                                    <option *ngFor="let queryObject of queryObjects" [value]="queryObject.name">{{ queryObject.label }}</option>
                                                </select>
                                                <div class="invalid-feedback" *ngIf="stepTwo.get([actionIndex, 'queryObject']).invalid">Query Object is required</div>
                                            </div>
                                            <div class="col-2" *ngIf="queryObjectByName(stepTwo.get([actionIndex, 'queryObject']).value) as queryObject">
                                                <label for="typeOfCall" class="form-label">Type Query *</label>
                                                <select [ngClass]="{ 'is-invalid': stepTwo.get([actionIndex, 'typeOfCall']).invalid && salesforcePhases.steps[1].submitted }" id="TypeQuerySelect" class="form-select" formControlName="typeOfCall" (change)="updateActionForm(actionIndex, stepTwo.get([actionIndex, 'typeOfCall']).value)">
                                                    <option *ngIf="queryObject.createable" [value]="typeQueryEnum.CREATE">Create</option>
                                                    <option *ngIf="queryObject.updateable" [value]="typeQueryEnum.UPDATE">Update</option>
                                                    <option *ngIf="queryObject.deletable" [value]="typeQueryEnum.DELETE">Delete</option>
                                                    <option *ngIf="queryObject.queryable" [value]="typeQueryEnum.SELECT">Select</option>
                                                </select>
                                                <div class="invalid-feedback" *ngIf="stepTwo.get([actionIndex, 'typeOfCall']).invalid">Type Query is required</div>
                                            </div>
                                            <div class="col-4" *ngIf="stepTwo.get([actionIndex, 'typeOfCall']).value">
                                                <label for="dfAction" class="form-label">Action value *</label>
                                                <input type="text" id="dfAction" class="form-control" required placeholder="insert action value..." formControlName="dfAction" [ngClass]="{ 'is-invalid': stepTwo.get([actionIndex, 'dfAction']).invalid && salesforcePhases.steps[1].submitted }"/>
                                                <div class="invalid-feedback" *ngIf="stepTwo.get([actionIndex, 'dfAction']).invalid">Action value is required</div>
                                            </div>
                                            <div class="col-4" *ngIf="stepTwo.get([actionIndex, 'typeOfCall']).value === typeQueryEnum.UPDATE || stepTwo.get([actionIndex, 'typeOfCall']).value === typeQueryEnum.DELETE" formGroupName="actionForm">
                                                <label for="recordId" class="form-label">Record ID *</label>
                                                <input type="text" id="recordId" class="form-control" required placeholder="insert Record ID..." formControlName="primaryKeyValue" [ngClass]="{ 'is-invalid': stepTwo.get([actionIndex, 'actionForm', 'primaryKeyValue']).invalid && salesforcePhases.steps[1].submitted }"/>
                                                <div class="invalid-feedback" *ngIf="stepTwo.get([actionIndex, 'actionForm', 'primaryKeyValue']).invalid">Recor ID is required</div>
                                            </div>
                                            <div class="col-2" *ngIf="stepTwo.get([actionIndex, 'typeOfCall']).value === typeQueryEnum.SELECT" formGroupName="actionForm">
                                                <label for="dropdownQueryResult" class="form-label">Query Result *</label>
                                                <div ngbDropdown [placement]="['bottom-left', 'top']" container="body">
                                                    <button type="button" class="btn btn-outline-secondary btn_dropdown" id="dropdownQueryResult" ngbDropdownToggle>
                                                        <span>{{
                                                            stepTwo.get([actionIndex, 'actionForm', 'queryResult']).value?.length > 0
                                                                ? stepTwo.get([actionIndex, 'actionForm', 'queryResult']).value.join(', ')
                                                                : 'Select...'
                                                        }}</span>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownQueryResult" class="sf_dropdown">
                                                        <div class="form-check">Query Result</div>
                                                        <div *ngFor="let field of getFieldsName(stepTwo.get([actionIndex, 'queryObject']).value) | async" class="form-check mx-2">
                                                            <input type="checkbox" class="form-check-input" id="{{ actionIndex }}_{{ field.name }}" [checked]="isSelected(stepTwo.get([actionIndex, 'actionForm', 'queryResult']), field.name)" (change)="selectItem(stepTwo.get([actionIndex, 'actionForm']), 'queryResult', field.name)"/>
                                                            <label class="form-check-label" for="{{ actionIndex }}_{{ field.name }}"> {{ field.name }}</label>
                                                        </div>
                                                        <div class="form-check">Count</div>
                                                        <div *ngFor="let field of getFieldsName(stepTwo.get([actionIndex, 'queryObject']).value) | async" class="form-check mx-2">
                                                            <input type="checkbox" class="form-check-input" id="count_{{ actionIndex }}_{{ field.name }}" [checked]="isSelected(stepTwo.get([actionIndex, 'actionForm', 'queryResult']), 'COUNT(' + field.name + ')')" (change)="selectItem(stepTwo.get([actionIndex, 'actionForm']), 'queryResult', 'COUNT(' + field.name + ')')"/>
                                                            <label class="form-check-label" for="count_{{ actionIndex }}_{{ field.name }}"> Count {{ field.name }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="invalid-feedback" *ngIf="stepTwo.get([actionIndex, 'actionForm', 'queryResult']).invalid">Query Result is required</div>
                                            </div>
                                            <div class="col-2" *ngIf="stepTwo.get([actionIndex, 'typeOfCall']).value === typeQueryEnum.SELECT" formGroupName="actionForm">
                                                <label for="groupBu" class="form-label">Group By</label>
                                                <div ngbDropdown [placement]="['bottom-left', 'top']" container="body">
                                                    <button type="button" class="btn btn-outline-secondary btn_dropdown" id="dropdownGroupBy" ngbDropdownToggle>
                                                        <span>{{
                                                            stepTwo.get([actionIndex, 'actionForm', 'groupBy']).value?.length > 0
                                                                ? stepTwo.get([actionIndex, 'actionForm', 'groupBy']).value.join(', ')
                                                                : 'Select...'
                                                        }}</span>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownGroupBy" class="sf_dropdown">
                                                        <div *ngFor="let fieldName of stepTwo.get([actionIndex, 'actionForm', 'queryResult'])?.value" class="form-check mx-2">
                                                            <input type="checkbox" class="form-check-input" id="groupBy_{{ actionIndex }}_{{ fieldName }}" [checked]="isSelected(stepTwo.get([actionIndex, 'actionForm', 'groupBy']), fieldName)" (change)="selectItem(stepTwo.get([actionIndex, 'actionForm']), 'groupBy', fieldName)"/>
                                                            <label class="form-check-label" for="groupBy_{{ actionIndex }}_{{ fieldName }}"> {{ fieldName }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="stepTwo.get([actionIndex, 'typeOfCall']).value === typeQueryEnum.UPDATE || stepTwo.get([actionIndex, 'typeOfCall']).value === typeQueryEnum.CREATE" formGroupName="actionForm">
                                            <ng-container formArrayName="fields">
                                                <div class="row mb-3" *ngFor="let action of getFieldsArray(actionIndex).controls; index as fieldIndex" [formGroupName]="fieldIndex">
                                                    <div class="col-4">
                                                        <label for="FieldName" class="form-label">Field name *</label>
                                                        <select class="form-select" formControlName="fieldName" [ngClass]="{'is-invalid': stepTwo.get([actionIndex, 'actionForm', 'fields', fieldIndex, 'fieldName']).invalid && salesforcePhases.steps[1].submitted}">
                                                            <option *ngFor="let field of getFieldsName(stepTwo.get([actionIndex, 'queryObject']).value) | async" [value]="field.name">
                                                                {{ field.name }}
                                                            </option>
                                                        </select>
                                                        <div class="invalid-feedback" *ngIf="stepTwo.get([actionIndex, 'actionForm', 'fields', fieldIndex, 'fieldName']).invalid">
                                                            Field Name is required
                                                        </div>
                                                    </div>
                                                    <div class="col-auto flex-grow-1">
                                                        <label for="fieldValue" class="form-label">Value *</label>
                                                        <input id="fieldValue" type="text" class="form-control" required placeholder="insert action name..." formControlName="value" [ngClass]="{'is-invalid': stepTwo.get([actionIndex, 'actionForm', 'fields', fieldIndex, 'value']).invalid && salesforcePhases.steps[1].submitted}"/>
                                                        <div class="invalid-feedback" *ngIf="stepTwo.get([actionIndex, 'actionForm', 'fields', fieldIndex, 'value']).invalid">Value is required</div>
                                                    </div>

                                                    <div class="col-auto d-flex align-items-end">
                                                        <button [disabled]="getFieldsArray(actionIndex).controls.length === 1" class="btn" (click)="getFieldsArray(actionIndex).removeAt(fieldIndex)">
                                                            <small> <i class="far fa-times-circle me-2" aria-hidden="true"></i></small>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <button class="btn" (click)="getFieldsArray(actionIndex).push(emptyFieldForm)">
                                                            <small><i class="fas fa-plus" aria-hidden="true"></i> Add fields</small>
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="stepTwo.get([actionIndex, 'typeOfCall']).value === typeQueryEnum.SELECT" formGroupName="actionForm">
                                            <ng-container formGroupName="whereClause">
                                                <div class="row" *ngFor="let action of getWhereArray(actionIndex).controls; index as whereIndex" [formGroupName]="whereIndex">
                                                    <div class="col-2">
                                                        <label for="FieldName" class="form-label">Field name *</label>
                                                        <select id="FieldName" class="form-select" formControlName="field" [ngClass]="{ 'is-invalid': getWhereArray(actionIndex).get([ whereIndex, 'field']).invalid && salesforcePhases.steps[1].submitted}">
                                                            <option *ngFor="let field of getFieldsName(stepTwo.get([actionIndex, 'queryObject']).value) | async" [value]="field.name">
                                                                {{ field.name }}
                                                            </option>
                                                        </select>
                                                        <div class="invalid-feedback" *ngIf="getWhereArray(actionIndex).get([whereIndex, 'field']).invalid">
                                                            Field Name is required
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <label for="clause" class="form-label">Clause Type *</label>
                                                        <select id="clause" class="form-select" formControlName="clause" [ngClass]="{'is-invalid': getWhereArray(actionIndex).get([ whereIndex, 'clause']).invalid && salesforcePhases.steps[1].submitted}">
                                                            <option value="EQUALS">EQUALS</option>
                                                            <option value="NOT_EQUALS">NOT EQUALS</option>
                                                            <option value="LIKE">LIKE</option>
                                                            <option value="NOT_LIKE">NOT LIKE</option>
                                                        </select>
                                                        <div class="invalid-feedback" *ngIf="getWhereArray(actionIndex).get([ whereIndex, 'clause']).invalid">
                                                            Field Name is required
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <label for="whereValue" class="form-label">Value *</label>
                                                        <input type="text" id="whereValue" class="form-control" required placeholder="insert value.." formControlName="value" [ngClass]="{'is-invalid': getWhereArray(actionIndex).get([ whereIndex, 'value']).invalid && salesforcePhases.steps[1].submitted}"/>
                                                        <div class="invalid-feedback" *ngIf="getWhereArray(actionIndex).get([ whereIndex, 'value']).invalid">
                                                            Action value is required
                                                        </div>
                                                    </div>
                                                    <div class="col-auto flex-grow-1">
                                                        <label for="operatorLogic" class="form-label">Operator</label>
                                                        <select id="operatorLogic" class="form-select" formControlName="operatorLogic">
                                                            <option value="NULL">--</option>
                                                            <option value="AND">AND</option>
                                                            <option value="OR">OR</option>
                                                            <option value="START_AND_PARENT">START AND PARENT</option>
                                                            <option value="START_OR_PARENT">START OR PARENT</option>
                                                            <option value="END_AND_PARENT">END AND PARENT</option>
                                                            <option value="END_OR_PARENT">END OR PARENT</option>
                                                            <option value="END_PARENT">END PARENT</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-auto d-flex align-items-end">
                                                        <button class="btn" (click)="getWhereArray(actionIndex).removeAt(whereIndex)">
                                                            <small> <i class="far fa-times-circle" aria-hidden="true"></i></small>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <button class="btn" (click)="getWhereArray(actionIndex).push(emptyWhereClauseForm)">
                                                            <small><i class="fas fa-plus" aria-hidden="true"></i> Add fields</small>
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div class="row">
                            <div class="col">
                                <button class="btn button_light" (click)="stepTwo.push(getEmptyActionForm(true))">
                                    <small> <i class="fas fa-plus me-2" aria-hidden="true"></i></small>Add Action
                                </button>
                            </div>
                        </div>
                    </article>
                </section>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="nextStep()" [hidden]="salesforcePhases.current === 1">Next</button>
        <button type="button" class="btn" (click)="nextStep()" [hidden]="salesforcePhases.current !== 1" *roleRestriction="roleRestrinctionPermission; disableElements: true">Save</button>
    </div>
</div>
