<div class="box_wrapper">
    <div class="dashboard_head">
        <ul ngbNav #analyticPeriod="ngbNav" class="nav-tabs analytics_tabs" [(activeId)]="activeTab">
            <li class="tabs_title">
                <h6>Analytics</h6>
            </li>
            <li [ngbNavItem]="'today'">
                <a ngbNavLink>Today</a>
                <ng-template ngbNavContent>
                    <ng-container *ngTemplateOutlet="analyticTabContent"></ng-container>
                </ng-template>
            </li>
            <li [ngbNavItem]="'yesterday'">
                <a ngbNavLink>Yesterday</a>
                <ng-template ngbNavContent>
                    <ng-container *ngTemplateOutlet="analyticTabContent"></ng-container>
                </ng-template>
            </li>
        </ul>
    </div>
    <div class="dashboard_chunk">
        <div class="chunk_body">
            <div [ngbNavOutlet]="analyticPeriod"></div>
        </div>
    </div>
</div>
<ng-template #analyticTabContent>
    <div class="row">
        <div class="col">
            <dl class="stats_list">
                <dt>Total sessions</dt>
                <dd>
                    <ng-container *ngIf="statistics$[activeTab] | async as stats; else loading">
                        {{ stats.total | analyticalFormatter: { type: 'number', format: '1.0-2' } }}
                    </ng-container>
                </dd>
                <dt>Session duration</dt>
                <dd>
                    <ng-container *ngIf="statistics$[activeTab] | async as stats; else loading">
                        {{ stats.avgDuration | analyticalFormatter: { type: 'date', format: "m'm' ss's'" } }}
                    </ng-container>
                </dd>
                <dt>Messages per session</dt>
                <dd>
                    <ng-container *ngIf="statistics$[activeTab] | async as stats; else loading">
                        {{ stats.avgMessages | analyticalFormatter: { type: 'number', format: '1.0-2' } }}
                    </ng-container>
                </dd>
            </dl>
        </div>
        <div class="col-lg-6 col-12">
            <dl class="stats_list">
                <dt class="text-center">Channel</dt>
                <dd>
                    <ng-container *ngIf="chart$[activeTab] | async as chartDataset; else loading">
                        <doughnut-chart [dataset]="chartDataset" [legend]="false"> </doughnut-chart>
                    </ng-container>
                </dd>
            </dl>
        </div>
    </div>
</ng-template>
<ng-template #loading>
    <ng-template [spinnerLoader]="false" [size]="'sm'"></ng-template>
</ng-template>
