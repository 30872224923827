import { DatePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { BLUE } from '@shared';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'analytic-sessions',
  templateUrl: './analytic-sessions.component.html',
  styleUrls: ['./analytic-sessions.component.scss'],
})
export class AnalyticSessionsComponent implements OnDestroy {
  subscriptions: object = {};

  channelsDataset: any = {};
  popularTopicDataset: any = {};
  languagesDataset: any = {};

  conversationsStatistics: any;
  singleInteractionSessions: number;
  multipleInteractionSessions: number;
  interactionsAverageSession: number;
  interactionsAverageDuration: number;
  interactionsChannel: any;
  percentageVal: any;

  tags: Array<any> = [];

  toolTipsSession: any = {
    totalSessions: 'A group of interactions (i.e. combined intents) between the chatbot and the end-user.',
    singleSession: 'Sessions where users only trigger the Welcome intent.',
    multipleSession: 'Sessions where users trigger more than an intent.',
    messagesPerSession: 'Average number of interactions (combined intents) throughout recorded sessions.',
    sessionDuration: 'Average session duration (in minutes), starting from the first to the last query of the user.',
    channels: "Source channel of user's interactions.",
    popularTopics: 'Most requested topics by users within conversations.',
  };
  conversationChart: any;

  chartDataset: any[] = [];
  chartLabels: any[] = [];

  constructor(private analyticsService: AnalyticsService, private datePipe: DatePipe) {}

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getStatistics(filter: any) {
    const params = {
      startDate: filter.startDate,
      endDate: filter.endDate,
      tags: filter.intentTag || null,
      agentChannel: filter.agentChannel || null,
    };
    this.getConversationsStatistics(params);
    this.getSingleInteractionSessions(params);
    this.getMultipleInteractionSessions(params);
    this.getInteractionsAverageSession(params);
    this.getInteractionsAverageDuration(params);
    this.getAgentChannelsStats(params);
    this.getPopularTopicsAnalytics(params);
    this.getPrecentageLang(params);
  }

  getConversationsStatistics(params: any) {
    this.subscriptions['ConversationsStatistics'] = this.analyticsService.getConversationsStatistics(params).subscribe((response: any) => {
      this.conversationsStatistics = {
        total: response.multiObject.total.value,
      };
      this.proceddSessionsChartData(response.multiObject.line.chart);
    });
  }

  getSingleInteractionSessions(params: any) {
    this.subscriptions['SingleInteractionSessions'] = this.analyticsService.getSingleInteractionSessions(params).subscribe((response: any) => {
      this.singleInteractionSessions = response.singleResult.value;
    });
  }

  getMultipleInteractionSessions(params: any) {
    this.subscriptions['MultipleInteractionSessions'] = this.analyticsService.getMultipleInteractionSessions(params).subscribe((response: any) => {
      this.multipleInteractionSessions = response.singleResult.value;
    });
  }

  getInteractionsAverageSession(params: any) {
    this.subscriptions['InteractionsAverageSession'] = this.analyticsService.getInteractionsAverageSession(params).subscribe((response: any) => {
      this.interactionsAverageSession = response.singleResult.value;
    });
  }

  getInteractionsAverageDuration(params: any) {
    this.subscriptions['InteractionsAverageDuration'] = this.analyticsService.getInteractionsAverageDuration(params).subscribe((response: any) => {
      this.interactionsAverageDuration = response.singleResult.value;
    });
  }

  getAgentChannelsStats(params: any) {
    this.channelsDataset = {};
    this.subscriptions['InteractionsAgentChannel'] = this.analyticsService
      .getAgentChannels(params.startDate, params.endDate)
      .subscribe((response: any) => {
        this.channelsDataset = this.analyticsService.processDoughnutChartData('Channels', response.singleResult);
      });
  }

  getPopularTopicsAnalytics(params: any) {
    this.popularTopicDataset = {};
    this.subscriptions['PopularTopicsAnalytics'] = this.analyticsService
      .getPercentagePopularTags(params.startDate, params.endDate)
      .subscribe((response: any) => {
        this.popularTopicDataset = this.analyticsService.processDoughnutChartData('PopularTopicsAnalytics', response.singleResult);
      });
  }

  getPrecentageLang(params: any) {
    this.languagesDataset = {};
    this.subscriptions['PercentageLang'] = this.analyticsService.getPrecentageLang(params.startDate, params.endDate).subscribe((response: any) => {
      this.languagesDataset = this.analyticsService.processDoughnutChartData('PercentageLang', response.singleResult);
    });
  }

  proceddSessionsChartData(data) {
    this.chartLabels = [];
    this.chartDataset = [
      {
        data: [],
        label: 'Sessions ',
        color: BLUE,
      },
    ];
    data.forEach((value: any) => {
      this.chartLabels.push(this.datePipe.transform(value.currentDate, 'd MMM yyyy'));
      this.chartDataset[0].data.push(value.value);
    });
  }
}
