<div class="console_modal rasa_dialog {{ connector?.productCode | lowercase }}">
    <ng-template [spinnerLoader]="subscriptions['AgentsBot'].closed"></ng-template>

    <div class="modal-header dialog_head">
        <h4 class="modal-title">Rasa</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="body_content">
            <form class="rasa_form" #rasaForm="ngForm" (ngSubmit)="saveConnector()" novalidate>
                <div class="col-sm-12 col-md-6 mb-3">
                    <label for="rasaAgent" class="form-label">Agent *</label>
                    <select name ="rasaAgent" id="rasaAgent" class="form-select" required  #rasaAgent="ngModel" [(ngModel)]="connector.agentBot" [compareWith]="compareAgent" [ngClass]="{'is-invalid': rasaAgent.invalid && rasaForm.submitted}">
                        <option *ngFor="let agent of agents" [ngValue]="agent">{{ agent.name }}</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="rasaAgent.errors?.required">Agent is required</div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
            <button type="button" class="btn" (click)="rasaForm.onSubmit($event)">
                Save
            </button>
    </div>
</div>
