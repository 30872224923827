import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportModalComponent } from '@common-ui';
@Component({
  selector: 'app-consumption-alert-modal',
  templateUrl: './consumption-alert-modal.component.html',
  styleUrls: ['./consumption-alert-modal.component.scss'],
})
export class ConsumptionAlertModalComponent {
  @Input() services: Array<any>;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) {}

  supportModal() {
    const servicesDescription: string = this.services.reduce((description, service) => `${description}- ${service.serviceNameFull}\n`, '');

    const modalRef = this.modalService.open(SupportModalComponent);
    modalRef.componentInstance.support = {
      title: 'Quota Increase',
      requestType: 'Quota Increase',
      section: 'Billing',
      urgency: 'High',
      description: `I need to increase the following quota:\n${servicesDescription}`,
    };
  }
}
