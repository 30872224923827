import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board, GenericConnector, Product } from '@core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ConnectorsService } from '../../../services/connectors.service';
import { ActionSettingsModalComponent } from './action-settings-modal/action-settings-modal.component';

@Component({
  selector: 'app-action-mapping-connector',
  templateUrl: './action-mapping-connector.component.html',
  styleUrls: ['./action-mapping-connector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActionMappingConnectorComponent implements OnInit {
  @ViewChild('drawBoard') private drawBoard: ElementRef;
  @ViewChild('boardRef') private boardRef: any;

  id: number;
  productCode: string;
  subscriptions: object = {};
  selectedAction: any = {};
  actionList: any = [];
  actionName: string;
  products: Product[] = [];
  connector: GenericConnector;
  board: Board;
  onConnectorDropped = new Subject();
  connectedList = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private connectorsService: ConnectorsService
  ) {}

  ngOnInit() {
    this.id = parseInt(this.route.snapshot.params['id']);
    const journeyId = parseInt(this.route.snapshot.params['journeyId']);

    this.connectorsService.getBoard(journeyId).subscribe((board) => {
      this.board = board;
      this.getConnector();
    });
  }

  getConnector(actionId?) {
    this.subscriptions['Connector'] = this.connectorsService.getConnector('actionMapping', this.id).subscribe((resp) => {
      this.connector = resp;
      this.actionList = resp['actionMappingChilds'];
      this.selectedAction = this.actionList.find((a) => a.id === actionId) || this.actionList[0];
      this.connectedList = ['main-list', ...(this.connector['connectors'] || []).filter((c) => c.productCode === 'RL').map((c) => `list-rule-${c.id}`)];
      this.getAvailableProducts();
    });
  }

  getAvailableProducts() {
    this.subscriptions['Products'] = this.connectorsService
      .getFilteredConnectorList({ journeyApiKey: this.board.journey.apiKey, connectorCode: 'AM' })
      .subscribe((products) => {
        if (this.selectedAction?.id) {
          this.products = products;
        } else {
          this.products = products.map((p) => ({ ...p, visibility: false }));
        }
      });
  }

  back() {
    this.router.navigate([`/process-flow/${this.board.journey.id}`]);
  }

  openModalSettings(selectedAction?) {
    const modaleRef = this.modalService.open(ActionSettingsModalComponent, { size: 'lg' });
    modaleRef.componentInstance.journey = this.board.journey;
    modaleRef.componentInstance.connector = this.connector;
    modaleRef.componentInstance.selectedAction = selectedAction;
    modaleRef.componentInstance.onSaveSettings.subscribe((actionId) => {
      this.getConnector(actionId);
    });
  }

  getPlaceholder(selectAction: NgbPopover): string {
    if (selectAction.isOpen()) {
      return 'Search Action';
    } else {
      return this.selectedAction && this.selectedAction.actionName;
    }
  }

  isActionSelected(actionId: number): boolean {
    return actionId === this.selectedAction.id;
  }

  selectAction(action) {
    this.selectedAction = action;
  }

  addProduct(product) {
    this.boardRef.addToBoard(product);

    setTimeout(() => {
      this.drawBoard.nativeElement.scrollTop = this.drawBoard.nativeElement.scrollHeight;
    }, 100);
  }
}
