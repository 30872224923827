import { ProductCodeType } from './product-code.type';

export interface Connector {
  description?: string;
  enabled?: boolean;
  id?: number;
  journeyId?: number;
  journeyOrder?: number;
  parentChildId?: number;
  parentId?: number;
  parentType?: ProductCodeType;
  productCode: ProductCodeType;
  productId?: number;
  productName: string;
  projectId?: string;
  purchased: boolean;
}

export interface GenericConnector extends Connector {
  [key: string]: any;
}
