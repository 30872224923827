import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationModule } from '@authentication';
import { ConsumptionAlertModalComponent } from './components/consumption-alert-modal/consumption-alert-modal.component';
import { AgentInterceptor } from './http-interceptors/agent-interceptor';
import { JourneyInterceptor } from './http-interceptors/journey-interceptor';
import { LanguageInterceptor } from './http-interceptors/language-interceptor';
import { JourneyService } from './services/journey.service';
import { LicenseService } from './services/license.service';
import { UserAccountService } from './services/user-account.service';
@NgModule({
  declarations: [ConsumptionAlertModalComponent],
  imports: [BrowserModule, BrowserAnimationsModule, AuthenticationModule, HttpClientModule],
  providers: [
    UserAccountService,
    JourneyService,
    LicenseService,
    { provide: HTTP_INTERCEPTORS, useClass: JourneyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AgentInterceptor, multi: true },
  ],
  exports: [BrowserModule, BrowserAnimationsModule, HttpClientModule],
})
export class CoreModule {}
