<div class="glossary_sidebar" [ngClass]="{ active: openGlossary }">
    <header class="sidebar_head">
        <h5>Glossary</h5>
        <button type="button" class="btn" (click)="closeGlossary()">
            <i class="fal fa-times fa-x1" aria-hidden="true"></i>
        </button>
    </header>
    <div class="sidebar_body">
        <input name="searchTerm" type="text" id="searchTerm" class="form-control search_term" placeholder="Search terms" [(ngModel)]="searchQuery" #searchTerm="ngModel" />

        <div class="search_result">
            <ng-container *ngIf="!searchQuery; else searhTermsTemplate">
                <article class="result_box" *ngFor="let category of categories | keyvalue : originalOrder">
                    <h3 class="box_head" (click)="toggleAccordion(category.key)" role="button" [ngClass]="{'show' : glossaryProperties[category.key].status === 'open'}">
                        <i aria-hidden="true" class="{{glossaryProperties[category.key].icon}} section_icon"></i>
                        {{category.key}}
                        <i aria-hidden="true" class="far fa-angle-up accordion_icon"></i>
                    </h3>
                    <dl class="box_body">
                        <div id="{{category.key}}" class="collapse" [ngClass]="{'show' : glossaryProperties[category.key].status === 'open'}">
                            <ng-container *ngFor="let term of category.value">
                                <ng-container *ngTemplateOutlet="termTemplate; context: {$implicit: term}"></ng-container>
                            </ng-container>
                        </div>
                    </dl>
                </article>
            </ng-container>            
            <ng-template #searhTermsTemplate>
                <article class="result_box">
                    <dl class="box_body">
                        <ng-container *ngFor="let term of filterTerms">
                            <ng-container *ngTemplateOutlet="termTemplate; context: {$implicit: term}"></ng-container>
                        </ng-container>
                    </dl>
                </article>
            </ng-template>
        </div>
    </div>
</div>

<ng-template let-term #termTemplate>
    <!-- //NOSONAR --><dt class="result_key">{{ 'GLOSSARY.' + term + '.KEY' | translate }}</dt> 
    <dd class="result_description">{{ 'GLOSSARY.' + term + '.DESCRIPTION' | translate }}</dd>
</ng-template>
