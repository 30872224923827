
<div class="console_modal zendesk_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Zendesk</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="connectorSubscription?.closed">
        <div class="body_content">
            <form [formGroup]="zendeskForm" class="zendesk_form" novalidate autocomplete="off">
                <steps-completion [phases]="zendeskPhases" [stepsForm]="stepsForm" [onMoveStep]="moveStep" (stepsCompleted)="saveConnector($event)"></steps-completion>

                <section class="connector_steps" formArrayName="steps" *roleRestriction="'design.processflow.zendesk.write'; disableElements: true">
                    <article formGroupName="0" *ngIf="zendeskPhases.current === 0">
                        <div class="row mb-3">
                            <div class="col">
                                <label for="zendeskUrl" class="form-label">Zendesk Support URL *</label>
                                <input type="text" id="zendeskUrl" class="form-control" required placeholder="https://domain.zendesk.com" formControlName="url" [ngClass]="{'is-invalid': stepOne.get('url').invalid && zendeskPhases.steps[0].submitted}">
                                <div class="invalid-feedback" *ngIf="stepOne.get('url').errors?.required">Support URL is required</div>
                            </div>
                            <div class="col">
                                <label for="zendeskEmail" class="form-label">Email *</label>
                                <input type="text" id="zendeskEmail" class="form-control" required formControlName="email" [ngClass]="{'is-invalid': stepOne.get('email').invalid && zendeskPhases.steps[0].submitted}">
                                <div class="invalid-feedback" *ngIf="stepOne.get('email').errors?.required">Email is required</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label for="zendeskToken" class="form-label">API token * <i class="fal fa-info-circle fa-sm" aria-hidden="true" ngbTooltip="API tokens are managed in the Zendesk Support admin interface at Admin > Channels > API."></i></label>
                                <input type="text" id="zendeskToken" class="form-control" required formControlName="apiToken" [ngClass]="{'is-invalid': stepOne.get('apiToken').invalid && zendeskPhases.steps[0].submitted}">
                                <div class="invalid-feedback" *ngIf="stepOne.get('apiToken').errors?.required">API Token is required</div>
                            </div>
                        </div>
                    </article>
                    <article formGroupName="1" *ngIf="zendeskPhases.current === 1">
                        <div class="mb-3">
                            <label for="zendeskSubject" class="form-label">Subject *</label>
                            <input type="text" id="zendeskSubject" class="form-control" required formControlName="subject" [ngClass]="{'is-invalid': stepTwo.get('subject').invalid && zendeskPhases.steps[1].submitted}">
                            <div class="invalid-feedback" *ngIf="stepTwo.get('subject').errors?.required">Subject is required</div>
                        </div>
                        <div>
                            <label for="zendeskBody" class="form-label">Body *</label>
                            <textarea id="zendeskBody" rows="5" class="form-control" required formControlName="body" [ngClass]="{'is-invalid': stepTwo.get('body').invalid && zendeskPhases.steps[1].submitted}"></textarea>
                            <div class="invalid-feedback" *ngIf="stepTwo.get('body').errors?.required">Body is required</div>
                        </div>
                    </article>
                </section>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="nextStep()" [hidden]="zendeskPhases.current === 1">Next</button>
        <button type="button" class="btn" (click)="nextStep()" [hidden]="zendeskPhases.current !== 1" *roleRestriction="'design.processflow.zendesk.write'; disableElements: true">Save</button>
    </div>
</div>

