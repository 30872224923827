import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { JourneyRestrictionDirective, LicenseRestrictionDirective, RoleRestrictionDirective } from '@core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { CircleProgressBarComponent } from './components/circle-progress-bar/circle-progress-bar.component';
import { CodeEditorComponent } from './components/code-editor/code-editor.component';
import { ConsumptionCardComponent } from './components/consumption-card/consumption-card.component';
import { DatepickerRangeComponent } from './components/datepicker/datepicker-range/datepicker-range.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { StepsCompletionComponent } from './components/steps-completion/steps-completion.component';
import { ToastNotificationComponent } from './components/toast-notification/toast-notification.component';
import { AgentLanguagesComponent } from './components/agent-languages/agent-languages.component';
import { ExportLogsModalComponent } from './components/export-logs-modal/export-logs-modal.component';
import { ContainerSelectorDirective } from './directives/container-selector.directive';
import { RestrictCharDirective } from './directives/restrict-char.directive';
import { TableSortDirective } from './directives/table-sort.directive';
import { AnalyticalFormatterPipe } from './pipes/analytical-formatter';
import { FilterPropertiesPipe } from './pipes/filter-properties.pipe';
import { FullTextSearchPipe } from './pipes/full-text-search.pipe';
import { UrlSanitizerPipe } from './pipes/url-sanitizer.pipe';
import { CodeEditorService } from './services/code-editor.service';
import { UserSettingsModalComponent } from './components/user-settings-modal/user-settings-modal.component';
import { SettingAlertsComponent } from './components/user-settings-modal/setting-alerts/setting-alerts.component';
import { SettingLicenseComponent } from './components/user-settings-modal/setting-license/setting-license.component';
import { SettingProfileComponent } from './components/user-settings-modal/setting-profile/setting-profile.component';
import { PromptModalComponent } from './components/prompt-modal/prompt-modal.component';
import { CommonUiModule } from '@common-ui';

@NgModule({
  declarations: [
    UrlSanitizerPipe,
    TableSortDirective,
    ToastNotificationComponent,
    DatepickerRangeComponent,
    StarRatingComponent,
    StepsCompletionComponent,
    PaginationComponent,
    DoughnutChartComponent,
    CircleProgressBarComponent,
    LineChartComponent,
    BarChartComponent,
    CodeEditorComponent,
    ExportLogsModalComponent,
    FullTextSearchPipe,
    FilterPropertiesPipe,
    AgentLanguagesComponent,
    LicenseRestrictionDirective,
    JourneyRestrictionDirective,
    RoleRestrictionDirective,
    RestrictCharDirective,
    ContainerSelectorDirective,
    ConsumptionCardComponent,
    AnalyticalFormatterPipe,
    UserSettingsModalComponent,
    SettingAlertsComponent,
    SettingLicenseComponent,
    SettingProfileComponent,
    PromptModalComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule, RouterModule, CarouselModule, CommonUiModule],
  providers: [CodeEditorService],
  exports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonUiModule,
    DatepickerRangeComponent,
    StepsCompletionComponent,
    PaginationComponent,
    DoughnutChartComponent,
    CircleProgressBarComponent,
    LineChartComponent,
    BarChartComponent,
    CodeEditorComponent,
    TableSortDirective,
    ToastNotificationComponent,
    ExportLogsModalComponent,
    FullTextSearchPipe,
    FilterPropertiesPipe,
    AgentLanguagesComponent,
    LicenseRestrictionDirective,
    JourneyRestrictionDirective,
    RoleRestrictionDirective,
    UrlSanitizerPipe,
    RestrictCharDirective,
    ContainerSelectorDirective,
    ConsumptionCardComponent,
    AnalyticalFormatterPipe,
    UserSettingsModalComponent,
    PromptModalComponent
  ],
})
export class SharedModule {}
