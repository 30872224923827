import { CdkDragDrop, CdkDragEnter, CdkDragStart } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgentType, GenericConnector, Journey, Product, ProductCodeType } from '@core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@common-ui';
import { Subscription } from 'rxjs';
import { ConnectorPath } from '../../enums/connector-path.enum';
import { ConnectorsUtilityService } from '../../services/connectors-utility.service';
import { ConnectorsService } from '../../services/connectors.service';
import { DeleteDialogflowConnectorModalComponent } from '../connectors/delete-dialogflow-connector-modal/delete-dialogflow-connector-modal.component';

@Component({
  selector: 'connectors-board',
  templateUrl: './connectors-board.component.html',
  styleUrls: ['./connectors-board.component.scss'],
})
export class ConnectorsBoardComponent implements AfterViewInit {
  @Input() connectors: GenericConnector[];
  @Input() products;
  @Input() journey: Journey;
  @Input() connectedList;
  @Input() parentData: Partial<GenericConnector> = {};
  @Output() updateBoard: EventEmitter<number> = new EventEmitter();
  @Output() droppedConnector: EventEmitter<void> = new EventEmitter();

  @ViewChild('sidebarConnectorContainer', { read: ViewContainerRef }) private sidebarConnectorContainer;
  @ViewChildren('popRuleConnector') private popRuleConnector: QueryList<NgbPopover>;
  @ViewChildren('connectorName') connectorName: QueryList<ElementRef>;

  subscriptions: { [key: string]: Subscription } = {};
  movingConnector;

  constructor(
    private connectorsService: ConnectorsService,
    public boardService: ConnectorsUtilityService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngAfterViewInit() {
    this.subscriptions['EllipsisName'] = this.connectorName.changes.subscribe((elements: QueryList<ElementRef>) => {
      elements.forEach((element: ElementRef) => {
        if (element.nativeElement.offsetWidth < element.nativeElement.scrollWidth) {
          element.nativeElement.classList.add('ellipses');
        }
      });
    });
  }
  connectorDropped(event: CdkDragDrop<{ connectors: GenericConnector[]; parent?: GenericConnector }>) {
    this.movingConnector = undefined;
    this.droppedConnector.emit();
    const connectors = event.container.data.connectors || [];
    const parentConnector = event.container.data.parent || ({} as GenericConnector);
    if (
      this.inValidPosition({
        parentType: parentConnector.productCode,
        currentIndex: event.currentIndex,
        connector: event.item.data,
        connectorList: connectors,
        isInSameList: event.item.dropContainer.id === event.container.id,
      })
    ) {
      return;
    }
    if (event.item.data.id) {
      event.previousContainer.data.connectors.splice(event.previousIndex, 1);

      this.subscriptions['moveConnector'] = this.connectorsService
        .moveConnector({
          connectorId: event.item.data.id,
          connectorType: event.item.data.productCode,
          journeyOrder: connectors.length > 0 && event.currentIndex !== 0 ? connectors[event.currentIndex - 1].journeyOrder + 1 : 0,
          parentId: parentConnector.id || this.parentData.parentId,
          parentType: parentConnector.productCode || this.parentData.parentType,
          parentChildId: this.parentData.parentChildId,
        })
        .subscribe(() => this.updateBoard.emit(this.journey.id));
      event.container.data.connectors?.splice(event.currentIndex, 0, event.item.data);
    } else {
      const newConnector = {
        productCode: event.item.data.code,
        productName: event.item.data.name,
        purchased: event.item.data.purchased,
        parentId: parentConnector.id || this.parentData.parentId,
        parentType: parentConnector.productCode || this.parentData.parentType,
        parentChildId: this.parentData.parentChildId,
        journeyOrder: connectors.length > 0 && event.currentIndex !== 0 ? connectors[event.currentIndex - 1].journeyOrder + 1 : 0,
        journeyId: this.journey.id,
      };

      event.container.data.connectors.splice(event.currentIndex, 0, newConnector);
      this.setupConnector(newConnector);
    }
  }

  async setupConnector(connector: GenericConnector) {
    if (connector.productCode === 'AM') {
      if (!connector.id) {
        connector = await this.connectorsService.addConnector('actionMapping', connector).toPromise();
      }
      this.router.navigate([`/process-flow/${connector.journeyId}/action-mapping/${connector.id}`], { relativeTo: this.route });
      return;
    }
    this.boardService
      .openConnectorModal({
        productCode: connector.productCode,
        containerRef: this.sidebarConnectorContainer,
        connector: connector,
      })
      .subscribe(({ refresh, nextConnector } = { refresh: false }) => {
        if (refresh || !connector.id) {
          this.updateBoard.emit(this.journey.id);
        }
        if (nextConnector) {
          this.setupConnector(nextConnector);
        }
      });
  }

  addToBoard(connector: Product, parentIndex?: number) {
    let newConnector: GenericConnector = {
      journeyId: this.journey.id,
      productCode: connector.code,
      productName: connector.name,
      purchased: connector.purchased,
    };

    if (parentIndex != null) {
      if (!this.connectors[parentIndex]['connectors']) this.connectors[parentIndex]['connectors'] = [];
      const journeyOrder =
        this.connectors[parentIndex]['connectors'].length === 0
          ? 0
          : this.connectors[parentIndex]['connectors'][this.connectors[parentIndex]['connectors'].length - 1].journeyOrder + 1;
      newConnector = {
        ...newConnector,
        parentId: this.connectors[parentIndex].id,
        parentType: this.connectors[parentIndex].productCode,
        journeyOrder,
      };
      if (
        this.inValidPosition({
          parentType: this.connectors[parentIndex].productCode,
          currentIndex: this.connectors[parentIndex]['connectors'].length,
          connector: newConnector,
          connectorList: this.connectors[parentIndex]['connectors'],
          isInSameList: false,
        })
      ) {
        return;
      }
      this.connectors[parentIndex]['connectors'].push(newConnector);
    } else {
      const journeyOrder = this.connectors.length === 0 ? 0 : this.connectors[this.connectors.length - 1].journeyOrder + 1;
      newConnector = { ...newConnector, ...this.parentData, journeyOrder };

      if (
        this.inValidPosition({ currentIndex: this.connectors.length, connector: newConnector, connectorList: this.connectors, isInSameList: false })
      ) {
        return;
      }
      this.connectors.push(newConnector);
    }

    this.setupConnector(newConnector);
  }

  deleteConnector(connector: GenericConnector) {
    if (['ES', 'CX'].indexOf(connector.productCode) !== -1) {
      this.deleteDialogflowModal(connector);
      return;
    }

    this.notificationService
      .openModal({
        title: 'Confirm delete connector',
        message: 'Are you sure to remove connector from the Journey?',
        choice: 'multi',
      })
      .subscribe((confirm: boolean) => {
        if (!confirm) return;
        this.subscriptions['RemoveConnector'] = this.connectorsService.removeConnector(ConnectorPath[connector.productCode], connector.id).subscribe(() => {
          this.updateBoard.emit(this.journey.id);
        });
      });
  }

  deleteDialogflowModal(connector: GenericConnector) {
    const modalRef = this.modalService.open(DeleteDialogflowConnectorModalComponent, { size: 'lg' });
    modalRef.componentInstance.journeyApiKey = this.journey.apiKey;
    modalRef.componentInstance.connector = connector;
    modalRef.componentInstance.onDeleteConnector.subscribe(() => this.updateBoard.emit(this.journey.id));
  }

  togglePopRules(parentIndex: number) {
    if (this.popRuleConnector.toArray()[this.getPopRuleIndex(parentIndex)].isOpen()) {
      this.popRuleConnector.toArray()[this.getPopRuleIndex(parentIndex)].close();
    } else {
      this.popRuleConnector.toArray()[this.getPopRuleIndex(parentIndex)].open({ parentIndex });
    }
  }

  getPopRuleIndex(parentIndex: number) {
    let ruleCount: number;
    let popIndex: number;

    this.connectors.forEach((connector: any, index: number) => {
      if (connector.productCode === 'RL') {
        ruleCount = typeof ruleCount === 'undefined' ? 0 : ruleCount + 1;
      }
      if (connector.productCode === 'RL' && parentIndex === index) {
        popIndex = ruleCount;
      }
    });
    return popIndex;
  }
  startMove(event: CdkDragStart<GenericConnector>) {
    this.movingConnector = event.source.data;

    const connector = event.source.data;
    const connectorList = event.source.dropContainer.data.connectors || [];
    const placeholder = event.source.getPlaceholderElement();
    if (connectorList[connectorList.length - 1]?.id === connector.id) {
      placeholder.querySelector('.custom_placeholder').classList.add('is_last');
    }
    if (connector.parentType === 'RL') {
      placeholder.querySelector('.custom_placeholder').classList.add('rule_placeholder');
      placeholder.querySelector('.flow_arrow').classList.add('rule_direction');
    } else {
      placeholder.querySelector('.flow_arrow').classList.add('row_direction');
    }
  }

  inValidPosition({
    currentIndex,
    connector,
    connectorList,
    isInSameList,
    parentType,
  }: {
    currentIndex: number;
    connector: GenericConnector;
    connectorList: GenericConnector[];
    isInSameList: boolean;
    parentType?: ProductCodeType;
  }) {
    const productCode = connector['code'] || connector.productCode;
    const positionRules = this.products.find((p) => p.code === productCode);
    if (parentType === 'RL') {
      return positionRules?.ruleEnabled === false;
    }
    if (typeof parentType === 'string') {
      return false;
    }
    const offset = isInSameList ? 0 : 1;
    if (this.journey.fulfillment) {
      const agentCode = Object.keys(AgentType).find((key) => AgentType[key] === this.journey.agent?.agentType);
      connectorList = [{ productCode: agentCode } as GenericConnector, ...connectorList];
      currentIndex++;
    }
    const isBefore =
      connectorList.some((c, i) => (positionRules?.orderRules?.AFTER || []).indexOf(c.productCode) !== -1 && i >= currentIndex) ||
      (positionRules?.orderRules?.AFTER?.length > 0 &&
        !connectorList.find((c) => (positionRules?.orderRules?.AFTER || []).indexOf(c.productCode) !== -1) &&
        !connectorList[0]?.parentType);

    const isAfter = connectorList.some(
      (c, i) => (positionRules?.orderRules?.BEFORE || []).indexOf(c.productCode) !== -1 && i + offset <= currentIndex
    );

    return isBefore || isAfter;
  }

  listEntered(event: CdkDragEnter) {
    const connector = event.item.data;
    const connectorList: GenericConnector[] = event.container.data.connectors || [];
    const parentType: ProductCodeType = event.container.data.parent?.productCode;

    const placeholder = event.item.getPlaceholderElement();

    if (
      this.inValidPosition({
        parentType,
        currentIndex: event.currentIndex,
        connector,
        connectorList,
        isInSameList: event.item.dropContainer.id === event.container.id,
      })
    ) {
      placeholder.querySelector('.box').classList.add('invalid');
    } else {
      placeholder.querySelector('.box').classList.remove('invalid');
    }
    if (parentType === 'RL') {
      placeholder.querySelector('.custom_placeholder').classList.add('rule_placeholder');
      placeholder.querySelector('.flow_arrow').classList.add('rule_direction');
      placeholder.querySelector('.flow_arrow').classList.remove('row_direction');
    } else {
      placeholder.querySelector('.custom_placeholder').classList.remove('rule_placeholder');
      placeholder.querySelector('.flow_arrow').classList.remove('rule_direction');
      placeholder.querySelector('.flow_arrow').classList.add('row_direction');
    }

    if (
      (connector.parentType !== 'RL' && connector.id && event.currentIndex === connectorList.length - 1) ||
      event.currentIndex > connectorList.length - 1
    ) {
      placeholder.querySelector('.custom_placeholder').classList.add('last_placeholder');
      if (connectorList[connectorList.length - 1]?.id === connector.id) {
        placeholder.querySelector('.custom_placeholder').classList.add('is_last');
      }
    } else {
      placeholder.querySelector('.custom_placeholder').classList.remove('last_placeholder');
      placeholder.querySelector('.custom_placeholder').classList.remove('is_last');
    }
  }
}
