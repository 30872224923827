

<div class="console_modal dataprocessing_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Data processing</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="subscriptions['SaveConnector']?.closed">
        <div class="body_content">
            <form [formGroup]="dataprocessingForm" class="datatransfer_form" *roleRestriction="'design.processflow.dataprocess.write'; disableElements: true" novalidate>
                <div class="row mb-3">
                    <div class="col-4">
                        <label for="manipulatedField" class="form-label">Field to manipulate *</label>
                        <select id="manipulatedField" class="form-select" formControlName="fieldToManipulate" required>
                            <option [value]="PARAMETER">Parameter</option>
                            <option [value]="PARAMETER_FROM_CONTEXT">Parameter from context</option>
                        </select>
                    </div>
                    <div class="col-4" *ngIf="fieldToManipulate.value === PARAMETER_FROM_CONTEXT">
                        <label for="contextName" class="form-label">Context name *</label>
                        <input id="contextName" class="form-control" formControlName="contextName" [ngClass]="{'is-invalid': dataprocessingForm.errors?.requiredContextName && processingSubmitted}" />
                        <div class="invalid-feedback">Context is required</div>
                    </div>
                    <div class="col-4">
                        <label for="parameterName" class="form-label">Parameter name *</label>
                        <input id="parameterName" class="form-control" formControlName="parameterName" [ngClass]="{'is-invalid': parameterName.invalid && processingSubmitted}" required />
                        <div class="invalid-feedback"  *ngIf="parameterName.errors?.required">Parameter is required</div>
                    </div>
                </div>

                <div class="dynamic_fields wide_fields" formArrayName="actions">
                    <h6 class="fields_head">{{actionsElements.length}} manipulations</h6>
                    
                    <div class="fields_body">
                        <div class="row g-0">
                            <div class="drag-boundary" cdkDropList [ngClass]="actionSelected?.value.actionType === REPLACE ? 'col-6' : 'col-12'" (cdkDropListDropped)="drop($event)">
                                <ng-container *ngIf="actionsElements?.length > 0">
                                    <div class="row g-0 mb-3 p-2 align-items-center draggable-item" *ngFor="let action of actionsElements.controls; let i = index" [formGroupName]="i" [ngClass]="{'field_selected': indexActionSelected === i}" (click)="indexActionSelected = i" cdkDrag cdkDragBoundary=".drag-boundary">
                                        <div [ngClass]="actionSelected?.value.actionType === REPLACE ? 'col-2' : 'col-1'">
                                            <span>{{i + 1}}</span>
                                        </div>
                                        <div class="col" [ngClass]="{'col-8' : actionSelected?.value.actionType === REPLACE, 'col-4' : actionSelected?.value.actionType === TRIM}">
                                            <select class="form-select" formControlName="actionType" *ngIf="indexActionSelected === i">
                                                <option hidden>{{action.value.actionType === TRIM ? 'Remove spaces' : 'Replace letters/words'}}</option>
                                                <option [value]="TRIM">Remove spaces</option>
                                                <option [value]="REPLACE">Replace letters/words</option>
                                            </select>
                                            <span *ngIf="indexActionSelected !== i">{{action.value.actionType === TRIM ? 'Remove spaces' : 'Replace letters/words'}}</span>
                                        </div>
                                        <div class="col text-end" *ngIf="indexActionSelected === i">
                                            <button type="button" class="btn" (click)="removeAction(i)"><i class="fal fa-trash" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button class="btn" (click)="addAction()">
                                                <small class="add_field"><i class="fas fa-plus" aria-hidden="true"></i> Add manipulation</small>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="p-2 text-center manipulation_alert" *ngIf="!actionsElements || actionsElements.length === 0">
                                    <p>Manipulate and clean up data recovered from the conversational session.</p>

                                    <button type="button" class="btn" (click)="addAction()">
                                        <small><i class="fas fa-plus" aria-hidden="true"></i> Add manipulation</small>
                                    </button>
                                    <div class="invalid-feedback d-block" *ngIf="(actionsElements.errors?.required || actionsElements.errors?.minLength) && processingSubmitted">Add almost one manipulation</div>
                                </div>
                            </div>
                            <div class="col-6 p-2 border-start" [formGroup]="actionSelected" *ngIf="actionSelected?.value.actionType === REPLACE">
                                <div class="mb-3">
                                    <label for="replacedField" class="form-label">Letters/words to replace *</label>
                                    <textarea formControlName="inputValue" rows="5" id="replacedField" class="form-control" [ngClass]="{'is-invalid': actionSelected.errors?.requiredInputValue && processingSubmitted}"></textarea>
                                    <div class="invalid-feedback">Field is required</div>
                                    <small class="text-muted">Separate multiple value with comma</small>
                                </div>
                                <div class="mb-3">
                                    <label for="replaceWith" class="form-label">Replace with *</label>
                                    <input formControlName="outputValue" type="text" id="replaceWith" class="form-control" [ngClass]="{'is-invalid': actionSelected.errors?.requiredOutputValue && processingSubmitted}">
                                    <div class="invalid-feedback">Field is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="saveConnector()" *roleRestriction="'design.processflow.dataprocess.write'; disableElements: true">Save</button>
    </div>
</div>


