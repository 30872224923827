import Step from 'shepherd.js/src/types/step';
import { BackButton, Button, ExitButton, NextButton } from './buttons.tutorial';

export class CustomStep implements Step.StepOptions {
  popperOptions = {
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
  };
  canClickTarget = true;
  attachTo;
  beforeShowPromise: () => Promise<any> = () => {
    return new Promise((resolve) => {
      this.beforeShow();
      setTimeout(() => {
        resolve(true);
      }, 500);
    });
  };
  beforeShow: () => void = () => {
    return;
  };
  scrollTo = true;
  title;
  text;
  buttons: Button[] = [new ExitButton(), new BackButton(), new NextButton()];
  modalOverlayOpeningPadding = 0;
  advanceOn;
  when;
  highlightClass;
  showOn = () => {
    if (!this.attachTo) return true;
    return document.querySelector(this.attachTo.element) ? true : false;
  };
  constructor({
    attachTo,
    title,
    text,
    buttons,
    canClickTarget,
    padding,
    advanceOn,
    beforeShow,
    beforeShowPromise,
    when,
    showOn,
    scrollTo,
    highlightClass,
  }: {
    attachTo?: { element: string; on: string };
    title: string;
    text: string;
    buttons?: Button[];
    canClickTarget?: boolean;
    padding?: number;
    advanceOn?: { selector: string; event: string };
    beforeShow?: () => void;
    when?: {
      show?: () => void;
      hide?: () => void;
      complete?: () => void;
      cancel?: () => void;
      start?: () => void;
    };
    showOn?: () => boolean;
    beforeShowPromise?: () => Promise<any>;
    scrollTo?: boolean;
    highlightClass?: string;
  }) {
    this.attachTo = attachTo;
    this.title = title;
    this.text = text;
    this.advanceOn = advanceOn;
    this.when = when;
    this.highlightClass = highlightClass;
    if (showOn) this.showOn = showOn;
    if (beforeShow) this.beforeShow = beforeShow;
    if (beforeShowPromise) this.beforeShowPromise = beforeShowPromise;
    if (canClickTarget !== undefined) this.canClickTarget = false;
    if (buttons) this.buttons = buttons;
    if (scrollTo !== undefined) this.scrollTo = scrollTo;
    if (padding) {
      this.modalOverlayOpeningPadding = padding;
      this.popperOptions = {
        modifiers: [{ name: 'offset', options: { offset: [0, 10 + padding] } }],
      };
    }
  }
}
