export enum SmsCountries {    
    '+61' = 'au',
    '+56' = 'cl',
    '+420' = 'cz',
    '+372' = 'ee',
    '+852' = 'hk',
    '+36' = 'hu',
    '+972' = 'il',
    '+39' = 'it',
    '+370' = 'lt',
    '+63' = 'ph',
    '+48' = 'pl',
    '+351' = 'pt',
    '+65' = 'sg',
    '+421' = 'sk',
    '+41' = 'ch',
    '+46' = 'se',
    '+44' = 'gb'
}