import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GenericConnector, Journey, JourneyService } from '@core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonApiService } from '@shared';
import { ConnectorPath } from '../../../enums/connector-path.enum';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'noovle-rasa-connector-modal',
  templateUrl: './rasa-connector-modal.component.html',
  styleUrls: ['./rasa-connector-modal.component.scss'],
})
export class RasaConnectorModalComponent implements OnInit {
  @Input() connector: GenericConnector;
  @ViewChild('rasaForm') rasaForm: NgForm;
  
  subscriptions: object = {};
  agents: Array<any>;
  
  constructor(
    public activeModal: NgbActiveModal,
    private connectorsService: ConnectorsService,
    private journeyService: JourneyService,
    private commonApiService: CommonApiService) {}

  ngOnInit() {
    
    this.getAgentsBot();
  }

  getAgentsBot() {
    this.subscriptions['AgentsBot'] = this.commonApiService
      .getAgentsBot(`RASA` as any)
      .subscribe((response: any) => {
        this.agents = response;
      });
  }

  compareAgent(agent1: any, agent2: any): boolean{

    return agent1 && agent2 ? agent1.name === agent2.name : agent1 === agent2;
  }

  saveConnector() {
    const successCallback = () => {
      this.subscriptions['Agent'] = this.journeyService.getJourneysList().subscribe(() => this.activeModal.close({ refresh: true }));
    };

    if (this.rasaForm.invalid) return;

    const data = this.connector;

     if (this.connector.id) {
      
      this.subscriptions['Connector'] = this.connectorsService
          .editConnector(ConnectorPath[this.connector.productCode], data)
          .subscribe(() => successCallback());
     } else {
       this.subscriptions['Connector'] = this.connectorsService.addConnector(ConnectorPath[this.connector.productCode], data).subscribe(() => successCallback());
      };
  }
}
