<div class="console_modal datatransfer_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Data transfer</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <ng-template #loader>
        <ng-template [spinnerLoader]="false"></ng-template>
    </ng-template>
    <div class="modal-body" [spinnerLoader]="subscriptions['SaveConnector']?.closed">
        <div class="body_content">
            <form [formGroup]="datatransferForm" class="datatransfer_form" *roleRestriction="'design.processflow.datatransfer.write'; disableElements: true" novalidate>
                <div class="dynamic_fields">
                    <h6 class="fields_head">{{ transferElements.length }} actions</h6>

                    <div class="fields_body" formArrayName="elements">
                        <div class="row">
                            <div class="col-4">
                                <label class="form-label">Path <i class="fal fa-info-circle" aria-hidden="true" ngbTooltip="Use double quotes (“ ”) to set default parameter"></i></label>
                            </div>
                            <div class="col-3">
                                <label class="form-label">Action</label>
                            </div>
                            <div class="col-3">
                                <label class="form-label">Fields</label>
                            </div>
                        </div>
                        <div class="row" *ngFor="let header of transferElements.controls; let i = index; let last = last" [ngClass]="{ 'mb-3': !last }">
                            <div class="col-3" [formGroupName]="i">
                                <input type="text" class="form-control" required placeholder="Enter result of payload path..." formControlName="path" [ngClass]="{ 'is-invalid': transferElements.at(i).get('path').invalid && transferSubmitted }" />
                                <div class="invalid-feedback" *ngIf="transferElements.at(i).get('path').errors?.required">Payload path is required</div>
                                <div class="invalid-feedback" *ngIf="transferElements.at(i).get('path').errors?.pattern">Payload pattern invalid</div>
                            </div>
                            <div class="col-3" [formGroupName]="i">
                                <select class="form-select" required formControlName="action" [ngClass]="{ 'is-invalid': transferElements.at(i).get('action').invalid && transferSubmitted }">
                                    <option disabled [value]="''">Select action...</option>
                                    <option [value]="'cache'">Save in Cache</option>
                                    <ng-container *journeyRestriction="'ES'">
                                        <option [value]="'context'">Save in Context</option>
                                        <option [value]="'parameters'">Save in Parameters</option>
                                    </ng-container>
                                    <ng-container *journeyRestriction="{ type: 'CX', fulfillment: false }">
                                        <option [value]="'session'">Save in Session param</option>
                                    </ng-container>
                                    <ng-container *journeyRestriction="['ES', 'CX']">
                                        <option [value]="'entity'">Save in Session Entity</option>
                                        <option [value]="'clear_entity'">Clear Session Entity</option>
                                    </ng-container>
                                </select>
                                <div class="invalid-feedback" *ngIf="transferElements.at(i).get('action').errors?.required">Action is required</div>
                            </div>
                            <div class="col-3" [formGroupName]="i">
                                <input
                                    type="text"
                                    class="form-control"
                                    required
                                    placeholder="Save in field..."
                                    *ngIf="!(['entity', 'clear_entity'].indexOf(transferElements.at(i).get('action').value) !== -1)"
                                    formControlName="value"
                                    [ngClass]="{ 'is-invalid': transferElements.at(i).get('value').invalid && transferSubmitted }"
                                />
                                <ng-container *ngIf="['entity', 'clear_entity'].indexOf(transferElements.at(i).get('action').value) !== -1">
                                    <select class="form-select" *ngIf="sessionEntitiesObserver | async as entities; else loader" formControlName="value" [ngClass]="{ 'is-invalid': transferElements.at(i).get('value').invalid && transferSubmitted }">
                                        <option disabled [value]="''">Save in field...</option>
                                        <option *ngFor="let entity of entities" [value]="getEntityId(entity.id)">{{ entity.name }}</option>
                                    </select>
                                </ng-container>
                                <div class="invalid-feedback" *ngIf="transferElements.at(i).get('value').errors?.required">Field is required</div>
                                <div class="invalid-feedback" *ngIf="transferElements.at(i).get('value').errors?.pattern">Field pattern not match</div>
                            </div>
                            <div class="col d-flex justify-content-center" [formGroupName]="i">
                                <div class="form-check mt-2" *ngIf="transferElements.at(i).get('action').value === 'entity'" ngbTooltip="Override Session Entity">
                                    <input type="checkbox" class="form-check-input" id="overrideEntity{{ i }}" formControlName="override" />
                                    <label class="form-check-label" for="overrideEntity{{ i }}"></label>
                                </div>
                            </div>
                            <div class="col d-flex justify-content-center" [formGroupName]="i">
                                <div class="form-check form-switch mt-1" *ngIf="['clear_entity', 'entity'].indexOf(transferElements.at(i).get('action').value) === -1" ngbTooltip="Transfer All Keys">
                                    <input type="checkbox" class="form-check-input" id="allKeys{{ i }}" formControlName="allValue" />
                                </div>
                                <label for="allKeys{{ i }}" class="form-check-label"></label>
                            </div>
                            <div class="col text-end">
                                <button class="btn" (click)="removeAction(i)" *ngIf="!(i === 0 && transferElements.length === 1)">
                                    <i class="far fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="col-12 mt-1" [formGroupName]="i">
                                <div class="form-check" *ngIf="transferElements.at(i).get('action').value !== 'clear_entity'">
                                    <input type="checkbox" id="dynamicTransfer{{ i }}" class="form-check-input" formControlName="dynamic" />
                                    <label class="form-check-label" for="dynamicTransfer{{ i }}">Set as dynamic</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button class="btn" (click)="addAction()">
                                    <small><i class="fas fa-plus" aria-hidden="true"></i> Add</small>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="saveConnector()" *roleRestriction="'design.processflow.datatransfer.write'; disableElements: true">Save</button>
    </div>
</div>
