<div class="console_modal rule_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Generic Rule</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="subscriptions['Connector']?.closed">
        <div class="body_content">
            <form [formGroup]="form" class="rule_form"
                *roleRestriction="'design.processflow.rule.write'; disableElements: true" novalidate>
                <div class="row mb-4">
                    <div class="col-12 col-sm-6">
                        <label for="ruleName" class="form-label">Rule name *</label>
                        <input name="ruleName" type="text" id="ruleName" class="form-control" formControlName="name"
                            [ngClass]="{ 'is-invalid': form.get('name').invalid && formSubmitted }" />
                        <div class="invalid-feedback">Name is required</div>
                    </div>
                </div>
                <es-generic-rule-form *journeyRestriction="'ES'" [connector]="connector" [form]="form"
                    [formSubmitted]="formSubmitted"></es-generic-rule-form>
                <cx-generic-rule-form *journeyRestriction="'CX'" [connector]="connector" [form]="form"
                    [formSubmitted]="formSubmitted"></cx-generic-rule-form>
                <rs-generic-rule-form *journeyRestriction="'RS'" [connector]="connector" [form]="form"
                    [formSubmitted]="formSubmitted"></rs-generic-rule-form>
                <div class="row" *ngIf="!connector.id">
                    <div class="col-12 col-sm-6">
                        <label for="ruleRun" class="form-label">Run the connector...</label>
                        <select name="ruleRun" id="ruleRun" class="form-select" formControlName="connectorToRun"
                            [ngClass]="{ 'is-invalid': formSubmitted && form.get('connectorToRun').invalid }">
                            <option value="" disabled>Select connector...</option>
                            <option
                                *ngFor="let connector of connectors$ | async | filterProperties: { visibility: true }"
                                [value]="connector.code">{{ connector.name }}</option>
                        </select>
                        <div class="invalid-feedback">Connector is required</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="saveConnector()"
            *roleRestriction="'design.processflow.rule.write'; disableElements: true">Save</button>
    </div>
</div>