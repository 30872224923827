import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JourneyService, LicenseService, Tutorials } from '@core';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { CustomReportModalComponent } from '../custom-report-modal/custom-report-modal.component';
import { AnalyticsTutorialService } from '../../services/analytics-tutorial.service';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit, OnDestroy {
  @ViewChild('analyticsNav', { static: true }) analyticsNav: NgbNav;

  customReports: any[] = [];
  subscriptions = {};

  get activeTab() { return this.route.snapshot.firstChild.routeConfig.path }

  constructor(
    private tutorial: AnalyticsTutorialService,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
    private journeyService: JourneyService,
    private licenseService: LicenseService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscriptions['tutorialSubscription'] = this.route.queryParams.subscribe(({ tutorial }) => {
      if (tutorial === Tutorials.ANALYTICS) {
        this.tutorial.startTutorial(this);
      }
    });

    this.subscriptions['JourneySubscription'] = this.journeyService.journey$.subscribe(() => {
      this.licenseService
        .getSessionLicense()
        .pipe(first())
        .subscribe(() => {
          if (this.licenseService.isPurchased('M_A', 'Module')) {
            this.getCustomReports();
          } else {
            this.customReports = [];
            if (['sessions', 'intents', 'proficiency', 'conversational_history'].indexOf(this.analyticsNav.activeId) < 0) {
              this.router.navigate(['/analytics/sessions']);
            }
          }
        });
    });

    this.subscriptions['DeleteReportSubscription'] = this.analyticsService.onDeleteReport.subscribe(() => this.getCustomReports());
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  openCustomReportModal() {
    const modalRef = this.modalService.open(CustomReportModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.saved.subscribe((newReport: any) => {
      this.getCustomReports(newReport.id);
    });
  }

  private getCustomReports(reportId?: number) {
    this.subscriptions['getAllReports'] = this.analyticsService.getAllReport().subscribe((data: any[]) => {
      this.customReports = data;

      if (reportId) {
        this.router.navigate(['/analytics/custom-report', reportId]);
      } else if (this.route.snapshot.params['reportId'] && this.customReports.find((report) => report.id === this.route.snapshot.params['reportId'])) {
        this.router.navigate(['/analytics/custom-report', this.route.snapshot.params['reportId']]);
      }
    });
  }
}
