<div class="row">
    <div class="col-12 mb-3">
        <analytics-filter [extendFilters]="filtersAddOn" [manageVisibility]="manageVisibility" (getStatistics)="getStatistics($event)"></analytics-filter>
    </div>

    <div class="col-12">
        <ul ngbNav class="nav-tabs wide_nav" [(activeId)]="idResearchTab" (activeIdChange)="switchIdResearchTab()">
            <li id="triggeredIntentTab" ngbNavItem="intent">
                <a ngbNavLink>INTENTS</a>
            </li>
            <li id="triggeredPageTab" ngbNavItem="page" *journeyRestriction="['CX']">
                <a ngbNavLink>PAGE</a>
            </li>
            <li id="triggeredFlowTab" ngbNavItem="flow" *journeyRestriction="['CX']">
                <a ngbNavLink>FLOW</a>
            </li>
            <li id="triggeredFallbackTab" ngbNavItem="fallback">
                <a ngbNavLink>FALLBACK</a>
            </li>
        </ul>

        <div class="alert alert-warning" role="alert" *ngIf="resultTrend.length === 0">No results for selected filters</div>
        <div class="row">
            <div class="col-12" [ngClass]="{ 'col-sm-12 col-md-9': idResearchTab === 'fallback' }">
                <div class="table-responsive engagement_table">
                    <table class="table table-hover" aria-describedby="Engagement Table" [spinnerLoader]="resultResolved">
                        <thead>
                            <tr>
                                <th scope="col" sortable="name" (sort)="onSort($event)">Name</th>
                                <th scope="col" sortable="sessions" (sort)="onSort($event)">Sessions</th>
                                <th scope="col" sortable="interactions" (sort)="onSort($event)">Interactions</th>
                                <th scope="col" sortable="exits" (sort)="onSort($event)">Exit %</th>
                                <ng-container *ngIf="idResearchTab === 'intent'">
                                    <th scope="col" *licenseRestriction="'NL'; itemType: 'Connector'"> <span ngbTooltip="Sentiment (Magnitude)">Sentiment (Mag.)</span></th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody *ngIf="resultTrend.length">
                            <tr *ngFor="let trend of resultTrend; let i = index">
                                <td>
                                    <span ngbTooltip="{{ trend.name }}" [disableTooltip]="trend.name.length <= 50">{{ trend.name | slice: 0:50 }}</span>
                                    <small class="text-muted" *ngIf="trend.name.length > 50">...</small>
                                </td>
                                <td>{{ trend.sessions }}</td>
                                <td>{{ trend.interactions }}</td>
                                <td>
                                    <span *ngIf="trend.exits"> {{trend.exits + '%' }} </span>
                                    <span class="text-secondary" *ngIf="!trend.exits">-</span>                                
                                </td> 
                                <ng-container *ngIf="idResearchTab === 'intent'">
                                    <td *licenseRestriction="'NL'; itemType: 'Connector'">
                                        <span *ngIf="trend.magnitude!==null" [ngClass]="{'text-success': trend.sentiment>0.25, 'text-danger': trend.sentiment<-0.25 }">{{ trend.sentiment | number : '1.0-2'}} ({{ trend.magnitude | number : '1.0-2'}})</span>
                                        <span class="text-secondary" *ngIf="trend.magnitude===null">-</span>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-sm-12 col-md-3 statistics_box fallback_content" *ngIf="idResearchTab === 'fallback'">
                <dl class="stats_list">
                    <ng-template [spinnerLoader]="subscriptions['FallbackPercentage']?.closed" [size]="'sm'"> </ng-template>
                    <dt ngbTooltip="{{ toolTipsIntents.fallbackPercentage }}" container="body">Fallback percentage</dt>
                    <dd>{{ fallbackPercentage | number: '1.0-2' }}</dd>
                </dl>
            </div>
        </div>
        <div class="row" *ngIf="resultsPagination.totalItems > resultsPagination.pageSize">
            <div class="col-12">
                <pagination [paginationData]="resultsPagination" (paginationCallback)="changePage($event)"></pagination>
            </div>
        </div>
    </div>
</div>
