import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericConnector } from '@core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { ConnectorPath } from '../../../enums/connector-path.enum';
import { ConnectorsService } from '../../../services/connectors.service';


enum TYPE_QUERY {
  CREATE = 'POST',
  UPDATE = 'PUT',
  DELETE = 'DELETE',
  SELECT = 'GET',
}
@Component({
  selector: 'app-salesforce-connector-modal',
  templateUrl: './salesforce-connector-modal.component.html',
  styleUrls: ['./salesforce-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SalesforceConnectorModalComponent implements OnInit {
  @Input() connector: GenericConnector;
  get type(): 'services' | 'sales' {
    return this.connector?.productCode === 'SFS' ? 'sales' : 'services';
  }
  typeQueryEnum = TYPE_QUERY;
  typeQueryLabel = {
    [TYPE_QUERY.CREATE]: 'Create',
    [TYPE_QUERY.UPDATE]: 'Update',
    [TYPE_QUERY.DELETE]: 'Delete',
    [TYPE_QUERY.SELECT]: 'Select',
  };
  queryObjects = [];

  subscriptions: any = {};
  moveStep: Subject<any> = new Subject<any>();
  actions: [];
  fieldNameObservers: { [key: string]: any } = {};
  salesforcePhases: any = {
    steps: [
      { name: 'General settings', submitted: false },
      { name: 'Actions & fields', submitted: false },
    ],
    current: 0,
  };

  salesforceForm = this.fb.group({
    steps: this.fb.array([
      this.fb.group({
        clientId: ['', Validators.required],
        clientSecret: ['', Validators.required],
        apiVersion: [''],
        refreshToken: [''],
        instanceUrl: [''],
        redirectUrl: [`${this.env.BASE_PATH}/salesforce`],
        authCode: [null],
      }),
      this.fb.group({
        actions: this.fb.array([]),
      }),
    ]),
  });

  get stepsForm() {
    return this.salesforceForm.get('steps') as FormArray;
  }

  get stepOne() {
    return this.stepsForm.at(0) as FormGroup;
  }
  get stepTwo() {
    return this.stepsForm.get([1, 'actions']) as FormArray;
  }

  getFieldsArray(i: number) {
    return this.stepTwo.get([i, 'actionForm', 'fields']) as FormArray;
  }

  getWhereArray(i: number) {
    return this.stepTwo.get([i, 'actionForm', 'whereClause']) as FormArray;
  }

  getEmptyActionForm(expanded) {
    return this.fb.group({
      queryObject: [null, Validators.required],
      typeOfCall: ['', Validators.required],
      dfAction: ['', Validators.required],
      expanded: [expanded, Validators.required],
      actionForm: this.fb.group({}),
    });
  }
  get createForm() {
    return this.fb.group({
      fields: this.fb.array([this.emptyFieldForm]),
    });
  }
  get updateForm() {
    return this.fb.group({
      primaryKeyValue: ['', Validators.required],
      fields: this.fb.array([this.emptyFieldForm]),
    });
  }
  get deleteForm() {
    return this.fb.group({
      primaryKeyValue: ['', Validators.required],
    });
  }

  get selectForm() {
    return this.fb.group({
      queryResult: [[], Validators.required],
      groupBy: [[]],
      whereClause: this.fb.array([]),
    });
  }
  get emptyFieldForm() {
    return this.fb.group({
      fieldName: ['', Validators.required],
      value: ['', Validators.required],
    });
  }

  get emptyWhereClauseForm() {
    return this.fb.group({
      field: ['', Validators.required],
      clause: ['', Validators.required],
      value: ['', Validators.required],
      operatorLogic: [''],
    });
  }

  get roleRestrinctionPermission() {
    return this.type === 'sales' ? 'design.processflow.salesforcesales.write' : 'design.processflow.salesforceticket.write';
  }
  constructor(
    public activeModal: NgbActiveModal,
    private connectorsService: ConnectorsService,
    private fb: FormBuilder,
    @Inject(ENVIRONMENT) private env: Environment
  ) {}

  ngOnInit() {
    if (this.connector.id) {
      this.stepsForm.patchValue([
        {
          clientId: this.connector['clientId'],
          clientSecret: this.connector['clientSecret'],
          apiVersion: this.connector['apiVersion'],
          refreshToken: this.connector['refreshToken'],
          instanceUrl: this.connector['instanceUrl'],
          redirectUrl: this.connector['redirectUrl'],
        },
      ]);
      this.buildSecondStepForm();
      this.getObjects();
    } else {
      this.stepTwo.push(this.getEmptyActionForm(true));
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  private buildSecondStepForm() {
    for (let i = 0; i < this.connector['actions'].length; i++) {
      const action = this.connector['actions'][i];
      this.stepTwo.insert(i, this.getEmptyActionForm(false));
      this.updateActionForm(i, action.typeOfCall);
      if (action.whereClause) {
        action.whereClause.forEach(() => (this.stepTwo.get([i, 'actionForm', 'whereClause']) as FormArray).push(this.emptyWhereClauseForm));
      }
      if (action.createUpdateValue) {
        for (let fieldIndex = 1; fieldIndex < Object.keys(action.createUpdateValue).length; fieldIndex++) {
          (this.stepTwo.get([i, 'actionForm', 'fields']) as FormArray).push(this.emptyFieldForm);
        }
      }
      this.stepTwo.at(i).patchValue({
        queryObject: action.queryObject,
        typeOfCall: action.typeOfCall,
        dfAction: action.dfAction,
        actionForm: {
          primaryKeyValue: action.primaryKeyValue,
          queryResult: action.queryResult ? action.queryResult.split(',') : undefined,
          groupBy: action.groupBy ? action.groupBy.split(',') : undefined,
          whereClause: action.whereClause,
          fields: action.createUpdateValue
            ? Object.keys(action.createUpdateValue).map((fieldName) => ({
                fieldName,
                value: action.createUpdateValue[fieldName],
              }))
            : undefined,
        },
      });
    }
  }
  nextStep() {
    if (this.salesforcePhases.current === 0 && this.stepOne.get('authCode').value) {
      const data = {
        clientId: this.stepOne.value.clientId,
        clientSecret: this.stepOne.value.clientSecret,
        code: this.stepOne.value.authCode,
        redirectUrl: this.stepOne.value.redirectUrl,
      };

      this.connectorsService.salesforceLogin(data).subscribe(({ refresh_token, apiVersion, instance_url }) => {
        this.stepOne.get('refreshToken').setValue(refresh_token);
        this.stepOne.get('apiVersion').setValue(apiVersion);
        this.stepOne.get('instanceUrl').setValue(instance_url);
        this.getObjects();

        this.moveStep.next('next');
      });
    } else {
      this.moveStep.next('next');
    }
  }

  getObjects() {
    this.subscriptions['objects'] = this.connectorsService
      .getSalesforceObjects({ ...this.stepOne.value, isTicket: this.type === 'sales' ? false : true })
      .subscribe((item) => (this.queryObjects = item.filter((i) => i.searchable)));
  }

  getFieldsName(objectName) {
    if (!this.fieldNameObservers[objectName]) {
      this.fieldNameObservers[objectName] = this.connectorsService.getSalesforceMetadataObject({ ...this.stepOne.value, objectName }).pipe(share());
    }

    return this.fieldNameObservers[objectName];
  }

  updateActionForm(actionIndex, action) {
    const form: FormGroup = this.stepTwo.at(actionIndex) as FormGroup;
    form.removeControl('actionForm');

    switch (action) {
      case TYPE_QUERY.CREATE:
        form.addControl('actionForm', this.createForm);
        break;
      case TYPE_QUERY.UPDATE:
        form.addControl('actionForm', this.updateForm);
        break;
      case TYPE_QUERY.DELETE:
        form.addControl('actionForm', this.deleteForm);
        break;
      case TYPE_QUERY.SELECT:
        form.addControl('actionForm', this.selectForm);
        break;
    }
  }

  isSelected(form: AbstractControl, value: string) {
    return form.value ? form.value.indexOf(value) !== -1 : undefined;
  }
  selectItem(formGroup: AbstractControl, formName: string, value: string) {
    const form = formGroup.get(formName);
    if (this.isSelected(form, value)) {
      form.setValue([...form.value.filter((v) => v !== value)]);
      if (formName === 'queryResult') {
        formGroup.get('groupBy').setValue([...formGroup.get('groupBy').value.filter((v) => v !== value)]);
      }
    } else {
      form.setValue([...(form.value || []), value]);
    }
  }

  showError() {
    if (this.salesforcePhases.current === 1) {
      for (const form of this.stepTwo.controls) {
        if (form.invalid) form.get('expanded').setValue(true);
      }
    }
  }

  queryObjectByName(name) {
    return this.queryObjects.find((o) => o.name === name);
  }
  saveConnector(formData) {
    const data = {
      ...this.connector,
      ...{
        redirectUrl: formData.redirectUrl,
        clientId: formData.clientId,
        clientSecret: formData.clientSecret,
        apiVersion: formData.apiVersion,
        refreshToken: formData.refreshToken,
        instanceUrl: formData.instanceUrl,
        actions: formData.actions.map((action) => ({
          queryObject: action.queryObject,
          typeOfCall: action.typeOfCall,
          dfAction: action.dfAction,
          primaryKeyValue: action.actionForm.primaryKeyValue,
          queryResult: action.actionForm.queryResult ? action.actionForm.queryResult.join(',') : undefined,
          groupBy: action.actionForm.groupBy ? action.actionForm.groupBy.join(',') : undefined,
          whereClause: action.actionForm.whereClause,
          createUpdateValue: action.actionForm.fields
            ? action.actionForm.fields.reduce((acc, f) => ({ ...acc, [f.fieldName]: f.value }), {})
            : undefined,
        })),
      },
    };
    if (this.connector.id) {
      this.subscriptions['saveConnector'] = this.connectorsService.editConnector(ConnectorPath[this.connector.productCode], data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.subscriptions['saveConnector'] = this.connectorsService.addConnector(ConnectorPath[this.connector.productCode], data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }
}
