import { ProductCodeType } from "./product-code.type";


export interface Product {
  actionMappingEnabled?: boolean;
  category: string | null;
  code: ProductCodeType;
  deployed?: boolean;
  description?: string[] | null;
  descriptionList?: string[] | null;
  enabled?: boolean;
  errorHandlerEnabled?: boolean;
  id: number;
  name: string;
  productLink?: string | null;
  purchased?: boolean;
  ruleEnabled?: boolean;
  subtitle?: string | null;
  type?: string;
}
