
<div class="row">
    <div class="col-12 col-md-6">
        <div class="row">
            <div class="col-12 mb-2">
                <h5>License status</h5>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm-12 col-md-8">
                <div class="row space-between">
                    <dl class="col license_info">
                        <dt>Start date</dt>
                        <dd>{{license?.startDate | date: 'dd/MM/yyyy'}}</dd>
                    </dl>
                    <dl class="col license_info">
                        <dt>End date</dt>
                        <dd>{{license?.endDate | date: 'dd/MM/yyyy'}}</dd>
                    </dl>
                    <div class="col-12">
                        <ngb-progressbar type="{{remainingType()}}" [showValue]="false" height=".5rem" [value]="remainingPeriods.leftDaysPercentage"></ngb-progressbar>
                        <small class="text-muted">{{(remainingPeriods.leftDays > 0) ? remainingPeriods.leftDays : 0}} days left</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="row">
            <div class="col-12 mb-2">
                <h5>Journey limit</h5>
            </div>
            <div class="col-12 mb-3">
                <span>{{license?.journeyLimit}} journeys ({{license?.journeyNumber}} already created)</span>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <h6>Module</h6>
    </div>
    <div class="col-12">
        <ul>
            <li *ngFor="let module of license?.products?.Module">{{module.name}}</li>
        </ul>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <h6>Connectors</h6>
    </div>
    <div class="col-12">
        <ul>
            <li *ngFor="let connector of license?.products?.Connector">{{connector.name}}</li>
        </ul>
    </div>
</div>
