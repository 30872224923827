
<div class="console_modal report_chart_dialog" [spinnerLoader]="subscriptions['createModifyChart']?.closed">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="reportView === 'chart'">New Chart</h4>
        <h4 class="modal-title" *ngIf="reportView === 'table'">Table Configuration</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <form #chartForm="ngForm" id="chartForm" class="chart_form" autocomplete="off" (submit)="saveChart(chartForm)">
            <input type="hidden" [(ngModel)]="chart.type" name="type" *ngIf="reportView === 'table'">

            <div class="row mb-3" *ngIf="reportView === 'chart'">
                <div class="col-4">
                    <label for="chartType" class="form-label">Chart type *</label>
                    <select id="chartType" name="type" [(ngModel)]="chart.type" class="form-select" required>
                        <option *ngFor="let chartType of CHART_TYPES" [value]="chartType.code">{{chartType.description}}</option>
                    </select>
                </div>
                <div class="col mt-auto">
                    <i class="material-icons chart_icon" aria-hidden="true" *ngIf="chart.type === 'BAR_CHART'">bar_chart</i>
                    <i class="material-icons chart_icon" aria-hidden="true" *ngIf="chart.type === 'LINE_CHART'">show_chart</i>
                    <i class="fas fa-chart-pie chart_icon" aria-hidden="true" *ngIf="chart.type === 'PIE_CHART'"></i>
                    <i class="fas fa-table chart_icon" aria-hidden="true" *ngIf="chart.type === 'TABLE'"></i>
                    <div *ngIf="chart.type === 'COUNTER'">
                        <small>Total</small>
                        <h5>1,168</h5>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-4">
                    <label for="chartTitle" class="form-label" *ngIf="reportView === 'chart'">Chart title *</label>
                    <label for="chartTitle" class="form-label" *ngIf="reportView === 'table'">Table title *</label>
                    <input id="chartTitle" name="title" #chartTitleModel="ngModel" [(ngModel)]="chart.title" class="form-control" required [ngClass]="{'is-invalid': chartForm.submitted && chartTitleModel.invalid}" />
                    <div class="invalid-feedback" *ngIf="chartTitleModel.errors?.required">Chart title is required</div>
                </div>
                <div class="col-4" *ngIf="chart.type !== 'TABLE'">
                    <label for="chartSize" class="form-label">Size *</label>
                    <select id="chartSize" name="size" [(ngModel)]="chart.size" class="form-select" required>
                        <option *ngFor="let chartSize of CHART_SIZES" [value]="chartSize.code">{{chartSize.description}}</option>
                    </select>
                </div>
            </div>
            <div class="row mb-3" *ngIf="['COUNTER', 'TABLE', 'LINE_CHART'].indexOf(chart.type) < 0">
                <div class="col-4">
                    <label for="chartMetric" class="form-label">Aggregated by *</label>
                    <select class="form-select" id="chartMetric" name="metric" [(ngModel)]="chart.metric" #chartMetricModel="ngModel" required [ngClass]="{'is-invalid': chartForm.submitted && chartMetricModel.invalid}">
                        <option [value]="null" hidden></option>
                        <option *ngFor="let field of fields">{{field.name}}</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="chartMetricModel.errors?.required">This field is required</div>
                </div>
            </div>
            <div class="row mb-3" *ngIf="['PIE_CHART', 'BAR_CHART'].indexOf(chart.type) >= 0">
                <div class="col-4" *ngIf="chart.type === 'PIE_CHART'">
                    <label class="form-label">Pie style</label>
                    <div class="form-check" *ngFor="let pieStyleRadio of PIE_STYLES; let index = index">
                        <input name="pieStyle" type="radio" [id]="'pieStyle'+index" class="form-check-input" [(ngModel)]="chart.pieStyle" [value]="pieStyleRadio.code">
                        <label class="form-check-label" [for]="'pieStyle'+index">{{pieStyleRadio.description}}</label>
                    </div>
                </div>
                <div class="col-4" *ngIf="chart.type === 'BAR_CHART'">
                    <label class="form-label">Bar style</label>
                    <div class="form-check" *ngFor="let barStyleRadio of BAR_STYLES; let index = index">
                        <input name="barStyle" type="radio" [id]="'barStyle'+index" class="form-check-input" [(ngModel)]="chart.barStyle" [value]="barStyleRadio.code">
                        <label class="form-check-label" [for]="'barStyle'+index">{{barStyleRadio.description}}</label>
                    </div>
                </div>
                <div class="col-4" *ngIf="chart.type === 'BAR_CHART'">
                    <label class="form-label">Order</label>
                    <div class="form-check" *ngFor="let orderRadio of ORDER_STYLES; let index = index">
                        <input name="order" type="radio" [id]="'order'+index" class="form-check-input" [(ngModel)]="chart.order" [value]="orderRadio.code" required>
                        <label class="form-check-label" [for]="'order'+index">{{orderRadio.description}}</label>
                    </div>
                </div>
            </div>
            <div class="row mb-3" *ngIf="['COUNTER', 'LINE_CHART'].indexOf(chart.type) >= 0">
                <div class="col-12">
                    <div class="row mb-3">
                        <div class="col">
                            <div class="form-check">
                                <input name="countMetric" type="radio" id="countNoMetric" class="form-check-input" [(ngModel)]="chart.countMetric" [value]="false">
                                <label class="form-check-label" for="countNoMetric">Count all occurrences</label>
                            </div>
                            <div class="form-check">
                                <input name="countMetric" type="radio" id="countMetric" class="form-check-input" [(ngModel)]="chart.countMetric" (ngModelChange)="addCondition()" [value]="true">
                                <label class="form-check-label" for="countMetric">Count if...</label>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="chart.countMetric" ngModelGroup="countMetricCondition">
                        <ng-container *ngFor="let metricCondition of chart.countMetricCondition; let index = index" ngModelGroup="{{index}}">
                            <div class="col-4">
                                <select class="form-select" name="firstMember" [(ngModel)]="metricCondition.firstMember" #firstMember="ngModel" [ngClass]="{'is-invalid': chartForm.submitted && firstMember.invalid}" required>
                                    <option [value]="null" hidden></option>
                                    <option *ngFor="let field of fields">{{field.name}}</option>
                                </select>
                                <div class="invalid-feedback" *ngIf="firstMember.errors?.required">This field is required</div>
                            </div>
                            <div class="col-4">
                                <select class="form-select" name="condition" [(ngModel)]="metricCondition.condition" #condition="ngModel" [ngClass]="{'is-invalid': chartForm.submitted && condition.invalid}" required>
                                    <option [value]="null" hidden></option>
                                    <option *ngFor="let condition of COUNT_CONDITIONS" [value]="condition.code">{{condition.description}}</option>
                                </select>
                                <div class="invalid-feedback" *ngIf="condition.errors?.required">This field is required</div>
                            </div>
                            <div class="col-4">
                                <input type="text" class="form-control" name="secondMember" [(ngModel)]="metricCondition.secondMember" #secondMember="ngModel" [ngClass]="{'is-invalid': chartForm.submitted && secondMember.invalid}" required/>
                                <div class="invalid-feedback" *ngIf="secondMember.errors?.required">This field is required</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="chart.type === 'TABLE'">
                <div class="col-8">
                    <div class="dynamic_fields wide_fields table_fields">
                        <div class="fields_head">
                            <div class="row align-items-center">
                                <div class="col">Columns *</div>
                                <div class="col-8">
                                    <input class="form-control" [ngbTypeahead]="search" (click)="click$.next($any($event).target.value)" (focus)="focus$.next($any($event).target.value)" placeholder="Search and add metric..." #typeahead="ngbTypeahead" #input (selectItem)="selectTableField($event, input)" [resultFormatter]="fieldFormatter"/>
                                </div>
                            </div>
                        </div>
                        
                        <div class="fields_body">
                            <div class="row g-0 p-2 align-items-center" (click)="indexFieldSelected = index" [ngClass]="{'field_selected': indexFieldSelected === index}" *ngFor="let tableMetric of chart.tableMetric; let index = index">
                                <div class="col-2">{{index + 1}}</div>
                                <div class="col-8">{{tableMetric}}</div>
                                <div class="col text-end" *ngIf="indexFieldSelected === index">
                                    <button type="button" class="btn p-0" (click)="removeTableField(index)"><i class="fal fa-trash" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="invalid-feedback d-block" *ngIf="chartForm.submitted && chart.tableMetric.length === 0">Add almost one field</div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn brand_default" form="chartForm">Save</button>
    </div>
</div>
