import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { JourneyService } from '@core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-dispatcher-connector-modal',
  templateUrl: './dispatcher-connector-modal.component.html',
  styleUrls: ['./dispatcher-connector-modal.component.scss'],
})
export class DispatcherConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private connector: any;

  connectorSubscription: Subscription;
  submitted = false;

  dispatcherForm = this.fb.group({
    records: this.fb.array([]),
  });

  journeys = [];

  get recordForm() {
    return this.fb.group({
      dispatcherCode: ['', Validators.required],
      defaultJourneyApiKey: ['', Validators.required],
      entries: this.fb.array([]),
    });
  }

  get entryForm() {
    return this.fb.group({
      dispatcherCode: ['', Validators.required],
      executeJourneyApiKey: ['', Validators.required],
    });
  }

  get records() {
    return this.dispatcherForm.get('records') as FormArray;
  }

  getEntries(i) {
    return this.records.at(i).get('entries') as FormArray;
  }
  constructor(
    public activeModal: NgbActiveModal,
    private connectorsService: ConnectorsService,
    private fb: FormBuilder,
    private journeyService: JourneyService
  ) {}

  ngOnInit() {
    this.journeys = this.journeyService.journeyList.filter((journey) => !journey.dispatcher && !journey.fulfillment);
    if (this.connector.id) {
      this.processForm();
      this.dispatcherForm.patchValue(this.connector);
    } else {
      this.addRecordWithEntry();
    }
  }

  ngOnDestroy() {
    if (this.connectorSubscription instanceof Subscription) this.connectorSubscription.unsubscribe();
  }

  processForm() {
    this.connector.records.forEach((record, index) => {
      this.addRecord();
      record.entries.forEach(() => this.addEntry(index));
    });
  }

  addRecord() {
    this.records.push(this.recordForm);
  }

  addRecordWithEntry() {
    this.addRecord();
    this.addEntry(this.records.length - 1);
  }

  addEntry(i) {
    this.getEntries(i).push(this.entryForm);
  }
  saveConnector() {
    this.submitted = true;
    if (this.dispatcherForm.invalid) return;
    const data = {
      ...this.connector,
      ...this.dispatcherForm.value,
    };

    if (this.connector.id) {
      this.connectorSubscription = this.connectorsService.editConnector('dispatcher', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.connectorSubscription = this.connectorsService.addConnector('dispatcher', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }
}
