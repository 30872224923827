import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { CountriesISO3166_1 } from '../../../enums/countries-ISO-3166-1.enum';
import { SmsCountries } from '../../../enums/sms-countries.enum';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-twilio-sms-modal',
  templateUrl: './twilio-sms-modal.component.html',
  styleUrls: ['./twilio-sms-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TwilioSmsModalComponent implements OnInit, OnDestroy {
  @Input() private connector: any;

  connectorSubscription: Subscription;
  moveStep: Subject<any> = new Subject<any>();
  smsEnambedCountries = SmsCountries;
  countries = CountriesISO3166_1;
  selectedPrefix = { fromPrefix: '', toPrefix: '' };

  smsForm = this.fb.group({
    steps: this.fb.array([
      this.fb.group({
        accountSid: ['', Validators.required],
        authToken: ['', Validators.required],
      }),
      this.fb.group({
        fromPrefix: [''],
        fromNumber: ['', Validators.required],
        toPrefix: [''],
        toNumber: ['', Validators.required],
        message: ['', Validators.required],
      }),
    ]),
  });

  get stepsForm() {
    return this.smsForm.get('steps') as FormArray;
  }

  get stepOne() {
    return this.stepsForm.at(0) as FormGroup;
  }
  get stepTwo() {
    return this.stepsForm.at(1) as FormGroup;
  }

  smsPhases: any = {
    steps: [
      { name: 'Configuration', submitted: false },
      { name: 'Recipients & Contents', submitted: false },
    ],
    current: 0,
  };

  constructor(public activeModal: NgbActiveModal, private connectorsService: ConnectorsService, private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.connector.id) {
      this.stepsForm.patchValue([
        { accountSid: this.connector.accountSid, authToken: this.connector.authToken },
        {
          fromPrefix: this.connector.fromPrefix,
          fromNumber: this.connector.fromNumber,
          toPrefix: this.connector.toPrefix,
          toNumber: this.connector.toNumber,
          message: this.connector.message,
        },
      ]);
      if (this.connector.fromPrefix)
        this.setPrefix({ key: this.connector.fromPrefix, value: this.smsEnambedCountries[this.connector.fromPrefix] }, 'fromPrefix');
      if (this.connector.toPrefix)
        this.setPrefix({ key: this.connector.toPrefix, value: this.smsEnambedCountries[this.connector.toPrefix] }, 'toPrefix');
    }
  }

  ngOnDestroy() {
    if (this.connectorSubscription instanceof Subscription) this.connectorSubscription.unsubscribe();
  }

  nextStep() {
    this.moveStep.next('next');
  }

  setPrefix(country: any, controlName: 'fromPrefix' | 'toPrefix') {
    this.stepTwo.get(controlName).setValue(country.key || '');
    this.selectedPrefix[controlName] = country.value || '';
  }

  saveConnector($event) {
    const data = { ...this.connector, ...$event };

    if (this.connector.id) {
      this.connectorSubscription = this.connectorsService.editConnector('twiliosms', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.connectorSubscription = this.connectorsService.addConnector('twiliosms', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }
}
