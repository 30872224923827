
<div class="console_modal sms_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Twilio SMS</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="connectorSubscription?.closed">
        <div class="body_content">            
            <form [formGroup]="smsForm" class="sms_form" novalidate>
                <steps-completion [phases]="smsPhases" [stepsForm]="stepsForm" [onMoveStep]="moveStep" (stepsCompleted)="saveConnector($event)"></steps-completion>
                
                <section class="connector_steps" formArrayName="steps" *roleRestriction="'design.processflow.twiliosms.write'; disableElements: true">
                    <article formGroupName="0" *ngIf="smsPhases.current === 0">
                        <div class="mb-3">
                            <label for="account" class="form-label">Account SID*</label>
                            <input type="text" id="account" class="form-control" required placeholder="Enter Sender Numbe..." formControlName="accountSid" [ngClass]="{'is-invalid': stepOne.controls.accountSid.invalid && smsPhases.steps[0].submitted}">
                            <div class="invalid-feedback" *ngIf="stepOne.controls.accountSid.errors?.required">Account SID is required</div>
                        </div>
                        <div>
                            <label for="token" class="form-label">Auth token*</label>
                            <input type="text" id="token" class="form-control" required placeholder="Enter auth token..." formControlName="authToken" [ngClass]="{'is-invalid': stepOne.controls.authToken.invalid && smsPhases.steps[0].submitted}">
                            <div class="invalid-feedback" *ngIf="stepOne.controls.authToken.errors?.required">Auth token is required</div>
                        </div>
                    </article>

                    <article formGroupName="1" *ngIf="smsPhases.current === 1">
                        <div class="row mb-3">
                            <div class="col-6">
                                <label for="senderNumber" class="form-label">Sender*</label>
                                <div class="input-group">
                                    <div ngbDropdown>
                                        <button type="button" class="btn btn-outline-secondary" ngbDropdownToggle>
                                            <span class="fi fi-{{selectedPrefix.fromPrefix}}"></span>
                                        </button>
                                        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="prefix">
                                            <button type="button" class="text-muted" ngbDropdownItem (click)="setPrefix({}, 'fromPrefix')">(No Prefix)</button>
                                            <button type="button" ngbDropdownItem *ngFor="let country of smsEnambedCountries | keyvalue" (click)="setPrefix(country, 'fromPrefix')">
                                                <span class="fi fi-{{country.value}}"></span> - {{countries[country.value]}} ({{country.key}})
                                            </button>
                                        </div>
                                    </div>
                                    <input name="senderNumber" type="text" id="senderNumber" class="form-control" required placeholder="Enter sender number..." formControlName="fromNumber" [ngClass]="{'is-invalid': stepTwo.controls.fromNumber.invalid && smsPhases.steps[1].submitted}">
                                    <div class="invalid-feedback" *ngIf="stepTwo.controls.fromNumber.errors?.required">Sender is required</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="receiverNumber" class="form-label">Receiver*</label>
                                <div class="input-group">
                                    <div ngbDropdown>
                                        <button type="button" class="btn btn-outline-secondary" ngbDropdownToggle>
                                            <span class="fi fi-{{selectedPrefix.toPrefix}}"></span>
                                        </button>
                                        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="prefix">
                                            <button type="button" class="text-muted" ngbDropdownItem (click)="setPrefix({}, 'toPrefix')">(No Prefix)</button>
                                            <button type="button" ngbDropdownItem *ngFor="let country of smsEnambedCountries | keyvalue" (click)="setPrefix(country, 'toPrefix')">
                                                <span class="fi fi-{{country.value}}"></span> - {{countries[country.value]}} ({{country.key}})
                                            </button>
                                        </div>
                                    </div>
                                    <input name="receiverNumber" type="text" id="receiverNumber" class="form-control" required placeholder="Enter receiver number..." formControlName="toNumber" [ngClass]="{'is-invalid': stepTwo.controls.toNumber.invalid && smsPhases.steps[1].submitted}">
                                </div>
                                <div class="invalid-feedback" *ngIf="stepTwo.controls.toNumber.errors?.required">Receiver is required</div>
                            </div>
                        </div>
                        <div>
                            <label for="smsText" class="form-label">SMS Text (Max 1600 char)*</label>
                            <textarea name="smsText" id="smsText" rows="5" class="form-control" required placeholder="Entrer SMS tex..." formControlName="message" [ngClass]="{'is-invalid': stepTwo.controls.message.invalid && smsPhases.steps[1].submitted}"></textarea>
                            <div class="invalid-feedback" *ngIf="stepTwo.controls.message.errors?.required">SMS text is required</div>
                        </div>
                    </article>
                </section>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="nextStep()" [hidden]="smsPhases.current === 1">Next</button>
        <button type="button" class="btn" (click)="nextStep()" [hidden]="smsPhases.current !== 1" *roleRestriction="'design.processflow.twiliosms.write'; disableElements: true">Save</button>
    </div>
</div>
