import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConnectorsService } from '../../../services/connectors.service';


@Component({
  selector: 'code-editor-sidebar',
  templateUrl: './code-editor-sidebar.component.html',
  styleUrls: ['./code-editor-sidebar.component.scss'],
})
export class CodeEditorSidebarComponent implements OnInit, OnDestroy {
  @Input() public connector: any;
  @Output() closed = new EventEmitter<any>();
  subscriptions: object = {};

  preferences: { theme: 'light' | 'dark'; fontSize: string } = {
    theme: 'dark',
    fontSize: '16px',
  };

  constructor(private connectorsService: ConnectorsService) {}

  ngOnInit() {
    if (!this.connector.id) {
      this.connector = { ...this.connector, ...{ script: '', type: 'JAVASCRIPT' } };
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  saveConnector() {
    if (this.connector.id) {
      this.subscriptions['SaveConnector'] = this.connectorsService.editConnector('codeEditor', this.connector).subscribe(() => {
        this.closed.emit({ refresh: true });
      });
    } else {
      this.subscriptions['SaveConnector'] = this.connectorsService.addConnector('codeEditor', this.connector).subscribe(() => {
        this.closed.emit({ refresh: true });
      });
    }
  }
}
