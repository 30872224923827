import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GenericConnector } from '@core';
import { NgbPopover, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { CommonApiService } from '@shared';
import { Observable } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'es-generic-rule-form',
  templateUrl: './es-generic-rule-form.component.html',
  styleUrls: ['./es-generic-rule-form.component.scss'],
})
export class EsGenericRuleFormComponent implements OnInit {
  @Input() connector: GenericConnector;
  @Input() form: FormGroup;
  @Input() formSubmitted: boolean;
  fieldKeys = {
    intent: '$intentName',
    sentiment: '$sentiment',
    magnitude: '$magnitude',
    action: '$actionName',
    param: '${{paramName}}',
    context: '${contextName.paramName}',
    cache: '$[cachePath]',
    topic: '$topic',
  };

  suggestions = Object.values(this.fieldKeys);

  get conditionsArray() {
    return this.form.get('conditions') as FormArray;
  }
  get conditionFormBuilder() {
    const form = this.fb.group({
      id: this.fb.control(''),
      field: this.fb.control('', Validators.required),
      clause: this.fb.control('', Validators.required),
      value: this.fb.control('', Validators.required),
    });
    form.get('field').valueChanges.subscribe((newValue) => {
      if ([this.fieldKeys.intent, this.fieldKeys.topic].indexOf(newValue) != -1) {
        form.get('clause').setValue('IN');
        form.get('value').setValue('');
      } else if (newValue === this.fieldKeys.action) {
        form.get('clause').setValue('');
      } else if (form.get('clause').value === 'IN') {
        form.get('clause').setValue('');
      }
    });

    form.get('clause').valueChanges.subscribe((newValue) => {
      if (newValue === 'IS_PRESENT') {
        form.get('value').disable();
        form.get('value').setValue('');
      } else {
        form.get('value').enable();
      }
    });
    return form;
  }

  typeSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') return this.suggestions;
        if (!this.fieldKeys.intent.includes(term) && !this.fieldKeys.action.includes(term) && term.startsWith('$')) return [];
        return this.suggestions.filter((type) => type.toLowerCase().indexOf(term.toLowerCase()) > -1);
      })
    );
  itemFilter = '';
  items$ = {
    [this.fieldKeys.intent]: this.commonApiService.getIntents({ onlyName: true }).pipe(
      shareReplay(1),
      map((items) => items.data.map((i) => i.name).sort())
    ),
    [this.fieldKeys.topic]: this.commonApiService.getTags({ type: 'TOPIC' }).pipe(
      shareReplay(1),
      map((items) => items.map((i) => i.name).sort())
    ),
  };

  constructor(private fb: FormBuilder, private commonApiService: CommonApiService) {}

  ngOnInit(): void {
    this.form.addControl('operator', this.fb.control('OR', Validators.required));
    this.form.addControl('conditions', this.fb.array([]));

    if (!this.connector.id) {
      this.conditionsArray.push(this.conditionFormBuilder);
    } else {
      this.processConditionArray();
      this.form.patchValue(this.connector);
    }
  }

  processConditionArray() {
    this.connector['conditions'].forEach((_) => this.conditionsArray.push(this.conditionFormBuilder));
  }
  toggleItem(form: FormControl, itemName: string) {
    if (this.isItemSelected(form, itemName)) {
      form.setValue(
        form.value
          .split(',')
          .filter((item) => item !== itemName)
          .join(',')
      );
    } else {
      const value = form.value.split(',').filter((v) => v !== '');
      value.push(itemName);
      form.setValue(value.join(','));
    }
  }

  isItemSelected(form: FormControl, itemName: string): boolean {
    return form.value.includes(itemName);
  }

  selectAll(form: FormControl, items: any[]) {
    const selectedItems = form.value.split(',');
    if (selectedItems.length === items.length) {
      form.setValue('');
    } else {
      form.setValue(items.join(','));
    }
  }

  onSelectSuggestion(elementRef: HTMLInputElement, form: AbstractControl, event: NgbTypeaheadSelectItemEvent) {
    event.preventDefault();
    switch (event.item) {
      case this.fieldKeys.cache:
        form.setValue('$[]');
        elementRef.setSelectionRange(2, 2);
        break;
      case this.fieldKeys.context:
        form.setValue('${}');
        elementRef.setSelectionRange(2, 2);
        break;
      case this.fieldKeys.param:
        form.setValue('${{}}');
        elementRef.setSelectionRange(3, 3);
        break;
      default:
        form.setValue(event.item);
        break;
    }
  }

  getItemsCount(form: AbstractControl) {
    return form.value.split(',').filter((v) => v !== '').length;
  }

  getPlaceholder(fieldValue: string, popItems: NgbPopover, form: AbstractControl): string {
    const key = Object.keys(this.fieldKeys).find((k) => this.fieldKeys[k] === fieldValue);
    if (popItems.isOpen()) {
      return `Search ${key}s...`;
    } else {
      return `${this.getItemsCount(form)} ${key}${this.getItemsCount(form) > 1 ? 's' : ''} selected`;
    }
  }
}
