<div class="console_modal entity_dialog">
    <div class="modal-header">
        <h4 class="modal-title">Alert 75% consumption</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>

    <div class="modal-body">
        <div class="body_content">
            The following services exceeded 75% of quotas:
            <ul>
                <li *ngFor="let service of services">{{service.serviceNameFull}}</li>
            </ul>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn brand_default" (click)="supportModal()">Quota increase</button>
    </div>
</div>
