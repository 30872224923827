import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-zendesk-connector-modal',
  templateUrl: './zendesk-connector-modal.component.html',
  styleUrls: ['./zendesk-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZendeskConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private connector: any;

  connectorSubscription: Subscription;
  moveStep: Subject<any> = new Subject<any>();

  zendeskPhases: any = {
    steps: [
      { name: 'General settings', submitted: false },
      { name: 'Ticket details', submitted: false },
    ],
    current: 0,
  };

  zendeskForm = this.fb.group({
    steps: this.fb.array([
      this.fb.group({
        url: ['', Validators.required],
        email: ['', Validators.required],
        apiToken: ['', Validators.required],
      }),
      this.fb.group({
        subject: ['', Validators.required],
        body: ['', Validators.required],
      }),
    ]),
  });

  get stepsForm() {
    return this.zendeskForm.get('steps') as FormArray;
  }

  get stepOne() {
    return this.stepsForm.at(0) as FormGroup<{ url: FormControl<string>; email: FormControl<string>; apiToken: FormControl<string> }>;
  }
  get stepTwo() {
    return this.stepsForm.at(1);
  }

  constructor(public activeModal: NgbActiveModal, private connectorsService: ConnectorsService, private fb: FormBuilder) {}

  ngOnInit() {
    if (this.connector.id) {
      this.stepsForm.patchValue([
        {
          url: this.connector.url,
          email: this.connector.email,
          apiToken: this.connector.apiToken,
        },
        {
          subject: this.connector.subject,
          body: this.connector.body,
        },
      ]);
    }
  }

  ngOnDestroy() {
    if (this.connectorSubscription instanceof Subscription) this.connectorSubscription.unsubscribe();
  }

  nextStep() {
    this.moveStep.next('next');
  }

  saveConnector($event) {
    const data = { ...this.connector, ...$event };

    if (this.connector.id) {
      this.connectorSubscription = this.connectorsService.editConnector('zendesk', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.connectorSubscription = this.connectorsService.addConnector('zendesk', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }
}
