<div class="row mb-3" [formGroup]="handlerGroup">
    <div class="col-1">
        <button class="btn" (click)="removeGroup()"><i class="far fa-times-circle" aria-hidden="true"></i></button>
    </div>
    <div class="col-5">
        <div class="mb-2">
            <input type="text" id="errorCodes_{{handlerIndex}}" class="form-control" placeholder="Error code..." formControlName="errorFilter" [ngClass]="{ 'is-invalid': handlerGroup.get('errorFilter').invalid && errorSumbitted }" />
            <div class="invalid-feedback" *ngIf="handlerGroup.get('errorFilter').errors?.required">Error code is required</div>
            <div class="invalid-feedback" *ngIf="handlerGroup.get('errorFilter').errors?.pattern">Format not valid, Ie: 4**, 40*, 501</div>
            <small class="form-text text-muted" *ngIf="handlerGroup.get('errorFilter').valid || !errorSumbitted">Ie: 4**, 40*, 501</small>
        </div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="skipJouirney_{{ handlerIndex }}" formControlName="skipJourney" />
            <label class="form-check-label" for="skipJouirney_{{ handlerIndex }}">Skip journey</label>
        </div>
    </div>
    <div class="col-6 d-flex flex-column justify-content-between">
        <div class="drop_area"
            [ngClass]="{ 'order-1': handlerGroup.value.connectors?.length === 0, active_drop_area: moving && handlerGroup.value.connectors?.length === 0 }"
            cdkDropList
            (cdkDropListDropped)="connectorDropped($event)"
            [cdkDropListData]="{ connectors: handlerGroup.value.connectors, parentChildId: handlerGroup.value.id }">
            <div class="btn connector_tile code_{{ c.productCode | lowercase }} mb-3" *ngFor="let c of handlerGroup.value.connectors; index as connettorIndex" cdkDrag [cdkDragData]="c" (cdkDragStarted)="moving = true" cdkDragPreviewContainer="parent">
                <span> {{ c.productName }} </span>
                <button type="button" class="ms-auto p-0 btn me-2" (click)="setupConnector(c)"><i class="fas fa-cog" aria-hidden="true"></i></button>
                <button type="button" class="p-0 btn" (click)="deleteConnector(c, connettorIndex)"><i class="far fa-trash-alt" aria-hidden="true"></i></button>
            </div>
        </div>

        <div ngbDropdown #dropdownComponents="ngbDropdown" class="d-inline-block w-100">
            <button type="button" class="form-select custom_select_dropdown" id="connectors" ngbDropdownAnchor (click)="toggleDropdown()">+ Connector</button>
            <div ngbDropdownMenu aria-labelledby="connectors">
                <button ngbDropdownItem *ngFor="let c of connectors" (click)="addConnector(c, handlerGroup.value.id)">{{ c.name }}</button>
            </div>
        </div>
    </div>
</div>