import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AgentType } from '../enums/agents.enum';
import { AgentLang } from '../models/agent-lang.type';
import { Journey } from '../models/journey.model';


@Injectable({
  providedIn: 'root',
})
export class JourneyService {
  public journeyList: Journey[];

  private onChangeJourney: BehaviorSubject<Journey> = new BehaviorSubject<Journey>(null);
  get journey(): Journey {
    return this.onChangeJourney.value;
  }
  get journey$(): Observable<Journey> {
    return this.onChangeJourney.asObservable().pipe(filter((journey) => journey != null));
  }

  private onChangeAgentLang: BehaviorSubject<any> = new BehaviorSubject<AgentLang>(null);
  get agentLang(): AgentLang {
    return this.onChangeAgentLang.value;
  }
  getAgentLang$(allLangSubscription = false): Observable<AgentLang> {
    return this.onChangeAgentLang.asObservable().pipe(filter((language) => language != null && (allLangSubscription || language !== 'all')));
  }
  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private env: Environment) {}

  setSessionJourney(journeyId: number) {
    const journey = this.journeyList.find((j) => j.id === journeyId) || this.journeyList[0];
    this.onChangeJourney.next(journey);
    this.setSessionAgentLang(journey.agent?.supportedLang?.[0] || 'all');
  }

  setSessionAgentLang(language: AgentLang) {
    this.onChangeAgentLang.next(language);
  }

  getJourneysList(): Observable<Journey[]> {
    return this.http.get(`${this.env.API.orchestrator_configurator}/journey/select`).pipe(
      tap((journeyList: Journey[]) => {
        this.journeyList = journeyList;
        const journeyId = this.journey?.id || this.journeyList[0].id;
        this.setSessionJourney(journeyId);
      })
    );
  }

  createJourney(data: any): Observable<Journey> {
    return this.http
      .post<Journey>(`${this.env.API.orchestrator_configurator}/journey`, data)
      .pipe(switchMap((journey) => this.getJourneysList().pipe(map(() => journey))));
  }

  editJourney(data: any): Observable<boolean> {
    return this.http.put(`${this.env.API.orchestrator_configurator}/journey`, data).pipe(
      switchMap(() => this.getJourneysList()),
      map(() => true)
    );
  }

  deleteJourney(journeyId: number): Observable<boolean> {
    const httpOptions = {
      params: new HttpParams().set('journeyId', journeyId.toString()),
    };
    return this.http.delete(`${this.env.API.orchestrator_configurator}/journey`, httpOptions).pipe(
      switchMap(() => this.getJourneysList()),
      map(() => true)
    );
  }

  isProductConfigured(
    configuration: Array<Partial<Journey>> | Partial<Journey> | Array<keyof typeof AgentType> | keyof typeof AgentType,
    journeyId?: number | null
  ): boolean {
    const journey: Journey = journeyId ? this.journeyList.find((item) => item.id === journeyId) : this.journey;

    if (!Array.isArray(configuration)) configuration = [<Partial<Journey>>configuration];
    if (typeof configuration[0] === 'string') configuration = (configuration as Array<any>).map((item) => ({ type: item }));

    return configuration.some((restriction) =>
      Object.keys(restriction)
        .filter((key) => typeof restriction[key] === 'boolean' || typeof restriction[key] === 'string')
        .every((key) => restriction[key] === journey[key])
    );
  }
}
