import { TEST_FLOW_LIST, TEST_GET_TAG_TREE } from '../mocks/calls.mock';
import { TEST_AGENTS_BOTS } from '../mocks/calls.mock';
import { asyncScheduler, BehaviorSubject, of, scheduled } from 'rxjs';
import { CodeEditorService } from '../services/code-editor.service';
import { CommonApiService } from '../services/common-api.service';
import { TEST_INTEGRATIONS_INTENTS, TEST_INTENTS, TEST_MESSAGES, TEST_MESSAGES_HISTORY, TEST_NOTIFIED_USER, TEST_CONSUPTIONS, TEST_TAG_LIST, TEST_ENTITIES_NAME } from './calls.mock';

export const TEST_CODE_EDITOR_SERVICE: Partial<CodeEditorService> = {
  onLoaded: new BehaviorSubject('') as any,
};

export const TEST_COMMON_API_SERVICE: Partial<CommonApiService> = {
  getInteractionHistory: () => scheduled(of(TEST_MESSAGES_HISTORY), asyncScheduler),
  getInteractions: () => scheduled(of(TEST_MESSAGES), asyncScheduler),
  getIntents: () => scheduled(of(TEST_INTENTS), asyncScheduler),
  getEntities: () => scheduled(of(TEST_INTEGRATIONS_INTENTS), asyncScheduler),
  createIntent: () => scheduled(of(null), asyncScheduler),
  getAgentsBot: () => scheduled(of(TEST_AGENTS_BOTS), asyncScheduler),
  downloadFile: () => scheduled(of(null), asyncScheduler),
  getAllFlow: () => scheduled(of(TEST_FLOW_LIST), asyncScheduler),
  getTrainerTagsTree: () => scheduled(of(TEST_GET_TAG_TREE), asyncScheduler),
  updateNotifiedUser: () => scheduled(of(null), asyncScheduler),
  getNotifiedUser: () => scheduled(of(TEST_NOTIFIED_USER), asyncScheduler),
  getBillingConsumtions: () => scheduled(of(TEST_CONSUPTIONS), asyncScheduler),
  getTags: () => scheduled(of(TEST_TAG_LIST), asyncScheduler),
  getEntityNames: () => scheduled(of(TEST_ENTITIES_NAME), asyncScheduler),
  uploadFlowsData: () => scheduled(of(null), asyncScheduler)
};
