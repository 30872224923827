
<div class="console_modal troove_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Quelix</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="connectorSubscription?.closed">
        <div class="body_content">
            <form [formGroup]="trooveForm" class="troove_form" *roleRestriction="'design.processflow.quelix.write'; disableElements: true" novalidate>
                <steps-completion [phases]="troovePhases" [stepsForm]="stepsForm" [onMoveStep]="moveStep" (stepsCompleted)="saveConnector($event)"></steps-completion>

                <section class="connector_steps" formArrayName="steps">
                    <article formGroupName="0" *ngIf="troovePhases.current === 0">
                        <div class="row mb-3">
                            <div class="col">
                                <label for="searchUrl" class="form-label">URL base path *</label>
                                <input type="text" id="searchUrl" class="form-control" formControlName="urlBasePath" [ngClass]="{'is-invalid': stepOne.get('urlBasePath').invalid && troovePhases.steps[0].submitted}">
                                <div class="invalid-feedback" *ngIf="stepOne.get('urlBasePath').errors?.required">URL is required</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-4">
                                <label for="searchSite" class="form-label">Site *</label>
                                <input type="text" id="searchSite" class="form-control" formControlName="site" [ngClass]="{'is-invalid': stepOne.get('site').invalid && troovePhases.steps[0].submitted}">
                                <div class="invalid-feedback" *ngIf="stepOne.get('site').errors?.required">Site is required</div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <label for="searchClient" class="form-label">Client *</label>
                                <input type="text" id="searchClient" class="form-control" formControlName="client" [ngClass]="{'is-invalid': stepOne.get('client').invalid && troovePhases.steps[0].submitted}">
                                <div class="invalid-feedback" *ngIf="stepOne.get('client').errors?.required">Client is required</div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <label for="searchProxystyle" class="form-label">Proxystylesheet *</label>
                                <input type="text" id="searchProxystyle" class="form-control" formControlName="proxyStyleSheet" [ngClass]="{'is-invalid': stepOne.get('proxyStyleSheet').invalid && troovePhases.steps[0].submitted}">
                                <div class="invalid-feedback" *ngIf="stepOne.get('proxyStyleSheet').errors?.required">Proxystylesheet is required</div>
                            </div>
                        </div>
                    </article>

                    <article class="dynamic_fields" formGroupName="1" *ngIf="troovePhases.current === 1">
                        <h6 class="fields_head">{{metadata.length}} metadata</h6>
                        <div class="alert alert-light" role="alert" *ngIf="metadata.length === 0">No metadata are set</div>

                        <div class="fields_body" formArrayName="metadata">
                            <div class="row mb-3" *ngFor="let meta of metadata.controls; let i = index">
                                <div class="col-5" [formGroupName]="i">
                                    <input type="text" class="form-control" required placeholder="Troove metadata name" formControlName="trooveKey" [ngClass]="{'is-invalid': metadata.at(i).get('trooveKey').invalid && troovePhases.steps[1].submitted}">
                                    <div class="invalid-feedback" *ngIf="metadata.at(i).get('trooveKey').errors?.required">Key is required</div>
                                </div>
                                <div class="col-6" [formGroupName]="i">
                                    <input type="text" class="form-control" required placeholder="Dialogflow parameter name" formControlName="dialogflowKey" [ngClass]="{'is-invalid': metadata.at(i).get('dialogflowKey').invalid && troovePhases.steps[1].submitted}">
                                    <div class="invalid-feedback" *ngIf="metadata.at(i).get('dialogflowKey').errors?.required">Value is required</div>
                                </div>
                                <div class="col-1">
                                    <button class="btn" (click)="metadata.removeAt(i)"><i class="far fa-times-circle" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-12 mt-3" [formGroupName]="i">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="carefulSearch" formControlName="specificSearch">
                                        <label class="form-check-label" for="carefulSearch">Search in specific metadata</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <button class="btn" (click)="addMetadata()"><small><i class="fas fa-plus" aria-hidden="true"></i> Add</small></button>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article formGroupName="2" *ngIf="troovePhases.current === 2">
                        <div class="row mb-3">
                            <div class="col">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="enableSort" formControlName="sortingEnabled">
                                    <label class="form-check-label" for="enableSort">Enable sorting</label>
                                </div>
                                <p class="mt-3" *ngIf="!stepThree.get('sortingEnabled').value">
                                    If sorting is not enabled, the results are sorted by the search engine based on the relevance of the terms searched in the document index.
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="stepThree.get('sortingEnabled').value">
                            <div class="col-sm-12 col-md-4">
                                <label for="sortMetaName" class="form-label">Sorting metadata name *</label>
                                <input type="text" id="sortMetaName" class="form-control" required formControlName="sortingName" [ngClass]="{'is-invalid': stepThree.get('sortingName').invalid && troovePhases.steps[2].submitted}">
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <label for="sortType" class="form-label">Sorting type *</label>
                                <select id="sortType" class="form-select" required formControlName="sortingType" [ngClass]="{'is-invalid': stepThree.get('sortingType').invalid && troovePhases.steps[2].submitted}">
                                    <option [value]="'A'">ASCENDING</option>
                                    <option [value]="'D'">DESCENDING</option>
                                </select>
                            </div>
                        </div>
                    </article>
                </section>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="nextStep()" [hidden]="troovePhases.current === 2">Next</button>
        <button type="button" class="btn" (click)="nextStep()" [hidden]="troovePhases.current != 2" *roleRestriction="'design.processflow.quelix.write'; disableElements: true">Save</button>
    </div>
</div>
