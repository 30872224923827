import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-event-caller-connector-modal',
  templateUrl: './event-caller-connector-modal.component.html',
  styleUrls: ['./event-caller-connector-modal.component.scss'],
})
export class EventCallerConnectorModalComponent implements OnInit {
  @Input() private connector: any;

  connectorSubscription: Subscription;
  submitted = false;
  eventCallerForm = this.fb.group({
    eventName: ['', Validators.required],
    parameters: this.fb.array([]),
  });

  get parameters() {
    return this.eventCallerForm.get('parameters') as FormArray;
  }

  constructor(public activeModal: NgbActiveModal, private connectorsService: ConnectorsService, private fb: FormBuilder) {}

  ngOnInit() {
    if (this.connector.id) {
      this.eventCallerForm.patchValue({
        eventName: this.connector.eventName,
        parameters: this.processParameterArray(this.connector.parameters),
      });
    }
  }

  ngOnDestroy() {
    if (this.connectorSubscription instanceof Subscription) this.connectorSubscription.unsubscribe();
  }

  addParameter() {
    this.parameters.push(
      this.fb.group({
        key: ['', Validators.required],
        value: ['', Validators.required],
      })
    );
  }

  saveConnector() {
    this.submitted = true;
    if (this.eventCallerForm.invalid) return;
    const data = {
      ...this.connector,
      ...{
        eventName: this.eventCallerForm.value.eventName,
        parameters: this.eventCallerForm.value.parameters
          ? this.eventCallerForm.value.parameters.reduce((acc: object, p: { key; value }) => ({ ...acc, [p.key]: p.value }), {})
          : undefined,
      },
    };

    if (this.connector.id) {
      this.connectorSubscription = this.connectorsService.editConnector('eventCaller', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.connectorSubscription = this.connectorsService.addConnector('eventCaller', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }

  processParameterArray(params: object): Array<any> {
    const formArray: Array<{ key; value }> = [];
    if (params) {
      Object.keys(params).forEach((key) => {
        this.addParameter();
        formArray.push({
          key,
          value: params[key],
        });
      });
    }
    return formArray;
  }
}
