<div class="row mb-3">
    <div class="col-12">
        <form #historyFitler="ngForm" class="history_filter" (ngSubmit)="searchInteractions(1)" novalidate>
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <div class="input-group">
                        <div class="search_type" ngbDropdown>
                            <button type="button" id="filterOptions" class="btn btn-outline-secondary" ngbDropdownToggle>
                                {{ filterFor === 'sessionId' ? 'Session ID' : filterFor === 'intent' ? 'Intent' : filterFor === 'userQuery' ? 'User Says' : '' }}
                            </button>
                            <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="filterOptions">
                                <button type="button" ngbDropdownItem (click)="filterFor = 'sessionId'; filter.sortBy = null; filter.userQuery = null; filter.intent = null; searchInteractions(1)">Session ID</button>
                                <button type="button" ngbDropdownItem (click)="filterFor = 'userQuery'; filter.sortBy = null; filter.sessionId = null; filter.intent = null; searchInteractions(1)">User Says</button>
                                <button type="button" ngbDropdownItem (click)="filterFor = 'intent'; filter.sortBy = null; filter.userQuery = null; filter.sessionId = null; searchInteractions(1)">Intent</button>
                            </div>
                        </div>
                        <input type="text" name="filterId" id="filterId" placeholder="Search..." class="form-control" [(ngModel)]="filter.sessionId" #filterId="ngModel" *ngIf="filterFor === 'sessionId'" />
                        <input type="text" name="filterUserQuery" id="filterUserQuery" placeholder="Search..." class="form-control" [(ngModel)]="filter.userQuery" #filterUserQuery="ngModel" *ngIf="filterFor === 'userQuery'" />
                        <input type="text" name="filterIntent" id="filterIntent" placeholder="Search..." class="form-control" [(ngModel)]="filter.intent" #filterIntent="ngModel" *ngIf="filterFor === 'intent'" />
                        <button type="submit" class="btn brand_default"><i class="fas fa-search" aria-hidden="true"></i></button>
                    </div>
                </div>
                <div class="col-2">
                    <select name="filterAgent" class="form-select" placeholder="Agent" [(ngModel)]="filter.agentChannel" #filterAgent="ngModel" (change)="searchInteractions(1)">
                        <option [value]="''">All Channels</option>
                        <option [value]="'APP'">App</option>
                        <option [value]="'WEBSITE'">Web</option>
                        <option [value]="'FACEBOOK'">Facebook</option>
                        <option [value]="'WHATSAPP'">Whatsapp</option>
                        <option [value]="'VIVOCHA'">Vivocha</option>
                        <option [value]="'TIMRCC'">TIM RCC</option>
                        <option [value]="'LIVEPERSON'">Liveperson</option>
                        <option [value]="'GOOGLECHAT'">Google</option>
                        <option [value]="'TEAMS'">Teams</option>
                        <option [value]="'TELEGRAM'">Telegram</option>
                        <option [value]="'TEST'">Test</option>
                    </select>
                </div>
                <div class="col-3 ms-auto">
                    <datepicker-range name="datepicker" [(ngModel)]="filter.period" (ngModelChange)="searchInteractions(1)"></datepicker-range>
                </div>
                <div class="col-auto">
                    <div ngbDropdown class="kebab_menu">
                        <button type="button" class="btn" id="optionsMenu" ngbDropdownToggle><i class="fas fa-ellipsis-v" aria-hidden="true"></i></button>
                        <div ngbDropdownMenu aria-labelledby="optionsMenu">
                            <button ngbDropdownItem (click)="export()">Export Data</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="alert alert-warning" role="alert" *ngIf="interactions.length === 0">No results for selected filters</div>
        <div class="table-responsive history_table">
            <ng-template [spinnerLoader]="interactionsSubscription?.closed"></ng-template>

            <table class="table table-hover" aria-describedby="History Table" *ngIf="interactions.length">
                <thead>
                    <tr>
                        <th scope="col">Session ID</th>
                        <th scope="col">Input</th>
                        <th scope="col" sortable="dfIntentName" (sort)="onSort($event)">Intent</th>
                        <th scope="col">Response</th>
                        <th scope="col">Status</th>
                        <th scope="col" sortable="timestamp" (sort)="onSort($event)">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let interaction of interactions" (click)="viewConversation(interaction.sessionId, interaction.agentBotType)">
                        <td>{{ interaction.sessionId }}</td>
                        <td>
                            <span ngbTooltip="{{ interaction.userQuery }}" [disableTooltip]="interaction.userQuery?.length <= 50">{{ interaction.userQuery | slice: 0:50 }}</span>
                            <small class="text-muted" *ngIf="interaction.userQuery?.length > 50">...</small>
                        </td>
                        <td>{{ interaction.dfIntentName }}</td>
                        <td>
                            <ng-container *ngIf="interaction.dfOutputMessage.split('##') as messages">
                                <span [ngbTooltip]="messages.join(',')" [disableTooltip]="messages.length === 1 && messages[0].length <= 50">{{ messages[0] | slice: 0:50 }}</span>
                                <small class="text-muted" *ngIf="messages[0].length > 50 || messages.length > 1">...</small>
                            </ng-container>
                        </td>
                        <td>{{ interaction.trusted ? 'Trusted' : 'Untrusted' }}</td>
                        <td>{{ interaction.timestamp | date: 'dd/MM/yyyy' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="row" *ngIf="interactionsPagination.totalItems > interactionsPagination.pageSize">
    <div class="col-12">
        <pagination [paginationData]="interactionsPagination" (paginationCallback)="searchInteractions($event)"></pagination>
    </div>
</div>
