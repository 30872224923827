<div class="console_modal dlp_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Data Loss Prevention</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="subscriptions['Connector']?.closed">
        <div class="body_content">
            <form [formGroup]="dlpForm" class="dlp_form" *roleRestriction="'design.processflow.dlp.write'; disableElements: true" novalidate>
                <steps-completion [phases]="dlpPhases" [stepsForm]="stepsForm" [onMoveStep]="moveStep" (stepsCompleted)="saveConnector($event)"></steps-completion>

                <section class="connector_steps" formArrayName="steps">
                    <article formGroupName="0" *ngIf="dlpPhases.current === 0">
                        <div class="row">
                            <div class="col-sm-12 col-lg-4">
                                <label for="fieldsToEncrypt" class="form-label"
                                    >Fields to encrypt *
                                    <i class="far fa-exclamation-circle text-danger" aria-hidden="true" *ngIf="stepOne.get('toBeEncrypt').invalid && dlpPhases.steps[0].submitted"></i>
                                </label>
                                <ul class="list-unstyled">
                                    <li>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="arrayText" [checked]="isItemSelected(stepOne.get('toBeEncrypt'), '$arrayText')" (change)="toggleItem(stepOne.get('toBeEncrypt'), '$arrayText')" />
                                            <label class="form-check-label" for="arrayText">$outputText</label>
                                        </div>
                                    </li>
                                    <li *journeyRestriction="'ES'">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="outputContexts" [checked]="isItemSelected(stepOne.get('toBeEncrypt'), '$outputContexts')" (change)="toggleItem(stepOne.get('toBeEncrypt'), '$outputContexts')" />
                                            <label class="form-check-label" for="outputContexts">$outputContexts</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="outputParameters" [checked]="isItemSelected(stepOne.get('toBeEncrypt'), '$outputParameters')" (change)="toggleItem(stepOne.get('toBeEncrypt'), '$outputParameters')" />
                                            <label class="form-check-label" for="outputParameters">$outputParameters</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="resolvedQuery" [checked]="isItemSelected(stepOne.get('toBeEncrypt'), '$resolvedQuery')" (change)="toggleItem(stepOne.get('toBeEncrypt'), '$resolvedQuery')" />
                                            <label class="form-check-label" for="resolvedQuery">$resolvedQuery</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-sm-12 col-lg-8">
                                <div class="row mb-3">
                                    <div class="col-sm-12 col-md-6">
                                        <label for="minCharacters" class="form-label">Minimum characters*</label>
                                        <input type="number" id="minCharacters" class="form-control" formControlName="minNumberToEncrypt" [ngClass]="{ 'is-invalid': dlpPhases.steps[0].submitted && stepOne.get('minNumberToEncrypt').invalid }" />
                                        <div class="invalid-feedback">Minimum characters is required</div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <label for="maskingCharacter" class="form-label">Masking character</label>
                                        <input type="text" id="maskingCharacter" class="form-control" formControlName="maskingChar" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <label for="charactersSkipped" class="form-label">Characters to skip</label>
                                        <input type="text" id="charactersSkipped" class="form-control" formControlName="charToSkip" />
                                        <small>Separate multiple characters with comma</small>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="redactInResponse" formControlName="redactInResponse" />
                                            <label class="form-check-label" for="redactInResponse">Redact in Response</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article class="dynamic_fields" formGroupName="1" *ngIf="dlpPhases.current === 1">
                        <header class="fields_head">
                            <form #searchForm="ngForm" novalidate>
                                <input name="searchTypes" type="text" class="form-control filter_types" placeholder="Search info Type..." [(ngModel)]="typesFilter" #ctrl="ngModel" />
                                <label class="selected_types">{{ googleInfoTypes.value.length }} selected</label>
                            </form>
                        </header>

                        <div class="fields_body">
                            <div class="row align-items-stretch">
                                <div class="col-7 types_list">
                                    <ul class="list-unstyled">
                                        <li *ngFor="let type of infoTypes$ | async | fullTextSearch: typesFilter:'name'" (click)="typeFocused = type" [ngClass]="{ focus: typeFocused?.id === type.id }">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="type_{{ type.id }}" [checked]="isTypeSelected(type)" (change)="toggleInfoType(type)" />
                                                <label class="form-check-label" for="type_{{ type.id }}">{{ type.name }}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-5 types_description" *ngIf="typeFocused">
                                    <h6>Description</h6>
                                    <p>{{ typeFocused.description }}</p>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="dynamic_fields" formGroupName="2" *ngIf="dlpPhases.current === 2">
                        <header class="fields_head">
                            <form #searchForm="ngForm" novalidate>
                                <input name="searchTypes" type="text" class="form-control filter_types" placeholder="Search info Type..." [(ngModel)]="typesFilter" #ctrl="ngModel" />
                                <button class="btn me-auto connector_color" (click)="addCustomInfoType()">
                                    <i class="fas fa-plus-circle" aria-hidden="true"></i>
                                </button>
                                <label class="selected_types">{{ customInfoTypeSelected }} selected</label>
                            </form>
                        </header>

                        <div class="fields_body">
                            <div class="row align-items-stretch">
                                <div class="col-7 types_list">
                                    <ul class="list-unstyled">
                                        <li
                                            *ngFor="let customTypeForm of customInfoTypes.controls | fullTextSearch: typesFilter:'value.name'"
                                            (click)="customTypeFocused = customTypeForm"
                                            class="d-flex align-items-center"
                                            [ngClass]="{ focus: customTypeFocused === customTypeForm }"
                                            [formGroup]="customTypeForm"
                                        >
                                            <div class="form-check flex-grow-1">
                                                <input type="checkbox" class="form-check-input" id="type_{{ customTypeForm.value.name }}" formControlName="enableCustom" />
                                                <label class="form-check-label" for="type_{{ customTypeForm.value.name }}">{{ customTypeForm.value.name }}</label>
                                            </div>
                                            <i class="far fa-exclamation-circle text-danger me-1" aria-hidden="true" *ngIf="dlpPhases.steps[2].submitted && customTypeForm.invalid"></i>
                                            <button class="btn ms-auto p-0" (click)="removeCustomInfoType(customTypeForm)">
                                                <small><i class="far fa-times-circle" aria-hidden="true"></i></small>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-5 types_description" *ngIf="customTypeFocused" [formGroup]="customTypeFocused">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <label for="name" class="form-label">Name *</label>
                                            <input type="text" id="name" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': dlpPhases.steps[2].submitted && (customTypeFocused.get('name').invalid || customTypeFocused.errors?.uniqueName) }" />
                                            <div *ngIf="customTypeFocused.get('name').errors?.required" class="invalid-feedback">Name is required</div>
                                            <div *ngIf="customTypeFocused.errors?.uniqueName" class="invalid-feedback">Name already used</div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <label for="regex" class="form-label">Regular expression * <i class="far fa-info-circle" ngbTooltip="The regex must be generic and should not have positional constraints (^ or $)." aria-hidden="true"></i></label>
                                            <input type="text" id="regex" class="form-control" formControlName="regex" [ngClass]="{ 'is-invalid': dlpPhases.steps[2].submitted && customTypeFocused.get('regex').invalid }" />
                                            <div class="invalid-feedback">Regex is required</div>
                                        </div>
                                        <div class="col-12">
                                            <label for="description" class="form-label">Description</label>
                                            <textarea class="form-control" formControlName="description" id="description" rows="3"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </section>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <small class="text-danger" *ngIf="dlpPhases.steps[2].submitted && dlpForm.get('steps').errors?.infoTypes">You must select at least one infoType</small>
        <button type="button" class="btn" (click)="nextStep()" [hidden]="dlpPhases.current === 2">Next</button>
        <button type="button" class="btn" (click)="nextStep()" [hidden]="dlpPhases.current !== 2" *roleRestriction="'design.processflow.dlp.write'; disableElements: true">Save</button>
    </div>
</div>
