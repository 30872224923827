import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
@Injectable({
  providedIn: 'root',
})
export class RxStompService extends RxStomp {
  constructor() {
    super();
  }
  exportIntents(journeyApiKey: string) {
    this.publish({ destination: '/app/exportIntentConfiguration', body: journeyApiKey });
    return this.watch('/user/resultExportIntentConfiguration');
  }

  exportFlows(journeyApiKey: string) {
    this.publish({ destination: '/app/exportFlowConfiguration', body: journeyApiKey });
    return this.watch('/user/resultExportFlowConfiguration');
  }

  esportAgentHistory(journeyApiKey: string) {
    this.publish({ destination: '/app/exportDataAnalytics', body: journeyApiKey });
    return this.watch('/user/resultExportDataAnalytics');
  }

  exportAuditLog(body) {
    this.publish({ destination: '/app/executeExportAudit', body: JSON.stringify(body) });
    return this.watch('/user/resultExportAudit');
  }

  exportLog(body) {
    this.publish({ destination: '/app/executeExportLog', body: JSON.stringify(body) });
    return this.watch('/user/resultExportLog');
  }

  exportAnalytics(body) {
    this.publish({ destination: '/app/exportAnalytics', body: JSON.stringify(body) });
    return this.watch('/user/resultExportAnalytics');
  }

  exportConversationHistory(body) {
    this.publish({ destination: '/app/exportHistory', body: JSON.stringify(body) });
    return this.watch('/user/resultExportHistory');
  }

  analyzeFaq(url) {
    this.publish({ destination: '/app/faq-extract', body: url });
    return this.watch('/user/topic/faq');
  }

  runTests(body) {
    this.publish({ destination: '/app/executeTest', body: JSON.stringify(body) });
    return this.watch('/user/resultTest');
  }

  importRouteGroups(body) {
    this.publish({ destination: '/app/createFaqRouteGroups', body: JSON.stringify(body) });
    return this.watch('/user/resultCreateFaqRouteGroups');
  }

  importMultiIntents(body) {
    this.publish({ destination: '/app/createAllIntent', body: JSON.stringify(body) });
    return this.watch('/user/resultCreateAllIntent');
  }

  importTemplate(body) {
    this.publish({ destination: '/app/importTemplate', body: JSON.stringify(body) });
    return this.watch('/user/resultImportTemplate');
  }

  importTemplateOverride(body) {
    this.publish({ destination: '/app/restoreTemplate', body: JSON.stringify(body) });
    return this.watch('/user/resultRestoreTemplate');
  }
}
