import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationService } from '@common-ui';
import { GenericConnector, JourneyService } from '@core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ConnectorPath } from '../../../../enums/connector-path.enum';
import { ConnectorsUtilityService } from '../../../../services/connectors-utility.service';
import { ConnectorsService } from '../../../../services/connectors.service';

@Component({
  selector: 'error-handler-item',
  templateUrl: './error-handler-item.component.html',
  styleUrls: ['./error-handler-item.component.scss'],
})
export class ErrorHandlerItemComponent implements OnInit, OnDestroy {
  @Input() handlerGroup: FormGroup;
  @Input() handlerIndex: number;
  @Input() errorSumbitted: boolean;
  @Input() errorConnector: any;
  @Input() sidebarContainerRef: any;
  @Output() reloadConnector = new EventEmitter();
  @Output() deleteHandler = new EventEmitter();
  @ViewChild('dropdownComponents') dropdownComponents: NgbDropdown;

  subscriptions = {};
  moving = false;
  connectors = [];

  constructor(private connectorsService: ConnectorsService, private journeyService: JourneyService, private boardService: ConnectorsUtilityService, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.subscriptions['ConnectorList'] = this.connectorsService.getFilteredConnectorList({ connectorCode: this.errorConnector.productCode }).subscribe((connectors) => (this.connectors = connectors));
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  removeGroup() {
    this.deleteHandler.emit(this.handlerIndex);
  }

  addConnector(connector, flowId) {
    const newConnector = {
      productCode: connector.code,
      productName: connector.name,
      purchased: connector.purchased,
      parentId: this.errorConnector.id,
      parentType: this.errorConnector.productCode,
      parentChildId: flowId,
      journeyId: this.journeyService.journey.id,
    };
    this.handlerGroup.get('connectors').setValue([...this.handlerGroup.get('connectors').value, newConnector]);
    this.setupConnector(newConnector);
  }

  setupConnector(connector: any) {
    this.boardService.openConnectorModal({
        productCode: connector.productCode,
        connector: connector,
        containerRef: this.sidebarContainerRef,
      }).subscribe(({ refresh } = { refresh: false }) => {
        if (refresh || !connector.id) {
          this.reloadConnector.emit()
        }
      });
  }

  deleteConnector(connector: any, index) {
    if (!connector.id) {
      const connectors = this.handlerGroup.get('connectors').value;
      connectors.splice(index, 1);
      this.handlerGroup.get('connectors').setValue(connectors);
      return;
    }

    this.notificationService.openModal({
        title: 'Confirm delete connector',
        message: 'Are you sure to remove connector from the Error Handler?',
        choice: 'multi',
      }).subscribe((confirm: boolean) => {
        if (!confirm) return;
        
        this.subscriptions['RemoveConnector'] = this.connectorsService.removeConnector(ConnectorPath[connector.productCode], connector.id).subscribe(() => {
          this.reloadConnector.emit()
        });
      });
  }

  connectorDropped(event: CdkDragDrop<{ connectors: GenericConnector[]; parentChildId: number }>) {
    this.moving = false;

    const connectors = event.container.data.connectors || [];
    const parentChildId = event.container.data.parentChildId;

    if (event.item.data.id) {
      event.previousContainer.data.connectors.splice(event.previousIndex, 1);

      this.subscriptions['moveConnector'] = this.connectorsService
        .moveConnector({
          connectorId: event.item.data.id,
          connectorType: event.item.data.productCode,
          journeyOrder: connectors.length > 0 && event.currentIndex !== 0 ? connectors[event.currentIndex - 1].journeyOrder + 1 : 0,
          parentId: this.errorConnector.id,
          parentType: this.errorConnector.productCode,
          parentChildId: parentChildId,
        })
        .subscribe(() => this.reloadConnector.emit());
      event.container.data.connectors?.splice(event.currentIndex, 0, event.item.data);
    }
  }

  toggleDropdown() {
    this.dropdownComponents.toggle();
  }
}
