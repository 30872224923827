<form overviewFitler="ngForm" class="analytics_fitler" novalidate (ngSubmit)="getStats()">
    <ng-template [spinnerLoader]="subscriptions['ExportAnalytics']?.closed" [size]="'2x'"></ng-template>

    <div class="row mb-3">
        <div class="col-4" *ngIf="manageVisibility.inputSearch">
            <div class="input-group">
                <input name="filterElementLike" type="text" id="filterElementLike" class="form-control" placeholder="Search..." [(ngModel)]="filter.elementLike" #filterElementLike="ngModel" />
                <button type="submit" class="btn brand_default"><i class="fas fa-search" aria-hidden="true"></i></button>
            </div>
        </div>
        <div class="col-2" *journeyRestriction="'ES'">
            <div ngbDropdown class="tags_dropdown">
                <button type="button" class="form-select custom_select_dropdown" id="dropdownTags" ngbDropdownToggle>
                    <span>{{ filter.intentTag ? filter.intentTag : 'All Tags' }}</span>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownTags">
                    <button ngbDropdownItem [ngClass]="{ active: filter.intentTag == '' }" (click)="setTag({ name: '' })">All Tags</button>
                    <ng-container *ngFor="let tagList of tags | keyvalue">
                        <span class="ps-3 fw-bold">{{ tagList.key | titlecase}}</span>
                        <button ngbDropdownItem [ngClass]="{ active: filter.intentTag == tag.name }" *ngFor="let tag of tagList.value" (click)="setTag(tag)">
                            {{ tag.name }}
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col-2">
            <select name="filterAgent" class="form-select" placeholder="Agent" [(ngModel)]="filter.agentChannel" #filterAgent="ngModel" (change)="getStats()">
                <option [value]="''">All Channels</option>
                <option [value]="'APP'">App</option>
                <option [value]="'WEBSITE'">Web</option>
                <option [value]="'FACEBOOK'">Facebook</option>
                <option [value]="'WHATSAPP'">Whatsapp</option>
                <option [value]="'VIVOCHA'">Vivocha</option>
                <option [value]="'TIMRCC'">TIM RCC</option>
                <option [value]="'LIVEPERSON'">Liveperson</option>
                <option [value]="'GOOGLECHAT'">Google</option>
                <option [value]="'TEAMS'">Teams</option>
                <option [value]="'TELEGRAM'">Telegram</option>
                <option [value]="'TEST'">Test</option>
            </select>
        </div>
        <div class="col-3 ms-auto">
            <datepicker-range name="datepicker" [(ngModel)]="filter.period" (ngModelChange)="getStats()"></datepicker-range>
        </div>
        <div class="col-auto" *ngIf="manageVisibility.sentiment">
            <div class="filters_menu" ngbDropdown #filtersMenu="ngbDropdown">
                <button type="button" id="filtersMenu" class="btn" ngbDropdownToggle [ngClass]="getSentimentSet() ? 'brand_default' : 'brand_light'">
                    <i class="fas fa-sliders-h" aria-hidden="true"></i>
                </button>

                <div ngbDropdownMenu aria-labelledby="filtersMenu">
                    <div class="d-flex justify-content-between text_brand">
                        <h6 class="fw-normal">SENTIMENT</h6>
                        <a href="javascript:void(0)" (click)="resetSentimentRange()">
                            <small>Clear</small>
                        </a>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="sentiment" id="positiveSentiment" (change)="setSentiment({ min: 0.25, max: 1 })" [checked]="getSentimentSet('positive')">
                        <label class="form-check-label" for="positiveSentiment">Positive</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="sentiment" id="negativeSentiment" (change)="setSentiment({ min: -1, max: -0.25 })" [checked]="getSentimentSet('negative')">
                        <label class="form-check-label" for="negativeSentiment">Negative</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="sentiment" id="neutralSentiment" (change)="setSentiment({ min: -0.25, max: 0.25 })">
                        <label class="form-check-label" for="neutralSentiment">Neutral</label>
                    </div>

                    <div class="dropdown-divider my-3"></div>

                    <div class="d-flex justify-content-between align-items-start">
                        <button type="submit" class="btn brand_default">APPLY FILTERS</button>
                        <a href="javascript:void(0)" (click)="resetFilters()">
                            <small>Clear Filter <i class="fas fa-filter" aria-hidden="true"></i></small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-auto text-end">
            <div ngbDropdown class="kebab_menu">
                <button type="button" class="btn" id="optionsMenu" ngbDropdownToggle><i class="fas fa-ellipsis-v" aria-hidden="true"></i></button>
                <div ngbDropdownMenu aria-labelledby="optionsMenu">
                    <button ngbDropdownItem type="button" (click)="exportAnalytics()">Export Analytics data</button>
                </div>
            </div>
        </div>
        
        <div class="col-sm-12 text-end mt-1" *ngIf="!manageVisibility.sentiment">
            <a href="javascript:void(0)" (click)="resetFilters()">
                <small>Clear Filter <i class="fas fa-filter" aria-hidden="true"></i></small>
            </a>
        </div>
    </div>
</form>
