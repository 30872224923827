import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ConnectorsService } from '../../../services/connectors.service';
import { infoTypesValidator, uniqueNameValidator } from './info-types.validator';

@Component({
  selector: 'app-dlp-connector-modal',
  templateUrl: './dlp-connector-modal.component.html',
  styleUrls: ['./dlp-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DlpConnectorModalComponent implements OnInit, OnDestroy {
  fieldsToEncrypt = ['$outputContexts', '$arrayText', '$outputParameters', '$resolvedQuery'];
  connector: any;
  subscriptions: object = {};
  moveStep: Subject<any> = new Subject<any>();

  infoTypes$ = this.connectorsService.getDlpInfoTypes();

  getInfoTypeFormFactory({ id, custom, enableCustom, name, regex, description }: { id?; custom?; enableCustom?; name?; regex?; description? } = {}) {
    return this.fb.group(
      {
        custom: this.fb.control(custom),
        enableCustom: this.fb.control(enableCustom),
        id: this.fb.control(id),
        name: this.fb.control(name, Validators.required),
        regex: this.fb.control(regex, custom ? Validators.required : undefined),
        description: this.fb.control(description),
      },
      { validators: uniqueNameValidator }
    );
  }

  dlpForm = this.fb.group({
    steps: this.fb.array(
      [
        this.fb.group({
          charToSkip: [''],
          maskingChar: [''],
          toBeEncrypt: ['', Validators.required],
          minNumberToEncrypt: ['', Validators.required],
          redactInResponse: [''],
        }),
        this.fb.group({
          googleInfoType: this.fb.array([]),
        }),
        this.fb.group({
          customInfoType: this.fb.array([]),
        }),
      ],
      { validators: infoTypesValidator }
    ),
  });

  get stepsForm() {
    return this.dlpForm.get('steps') as FormArray;
  }

  get stepOne() {
    return this.stepsForm.at(0) as FormGroup;
  }
  get googleInfoTypes() {
    return this.stepsForm.at(1).get('googleInfoType') as FormArray;
  }

  get customInfoTypes() {
    return this.stepsForm.at(2).get('customInfoType') as FormArray;
  }

  get customInfoTypeSelected() {
    return this.customInfoTypes.value.filter((item) => item.enableCustom).length;
  }

  dlpPhases: any = {
    steps: [
      { name: 'General settings', submitted: false },
      { name: 'Google infoTypes', submitted: false },
      { name: 'Custom infoTypes', submitted: false },
    ],
    current: 0,
  };

  typesFilter = '';
  typeFocused;
  customTypeFocused;
  constructor(public activeModal: NgbActiveModal, private connectorsService: ConnectorsService, private fb: FormBuilder) {}

  ngOnInit() {
    if (this.connector.id) {
      this.stepOne.patchValue(this.connector);
      this.connector.googleInfoType?.forEach((infoType) => this.googleInfoTypes.push(this.getInfoTypeFormFactory(infoType)));
      this.connector.customInfoType?.forEach((infoType) => this.customInfoTypes.push(this.getInfoTypeFormFactory(infoType)));
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  nextStep() {
    this.moveStep.next('next');
  }

  toggleInfoType(infoType: any) {
    if (this.isTypeSelected(infoType)) {
      const index = this.googleInfoTypes.value.findIndex((selected) => selected.id !== infoType.id);
      this.googleInfoTypes.removeAt(index);
    } else {
      this.googleInfoTypes.push(this.getInfoTypeFormFactory(infoType));
    }
  }

  isTypeSelected(infoType: any) {
    return this.googleInfoTypes.value.some((selected) => selected.id === infoType.id);
  }

  addCustomInfoType() {
    if (this.customInfoTypes.value.some((item) => !item.name)) return;
    const newCustomInfo = this.getInfoTypeFormFactory({ custom: true, enableCustom: true });
    this.typesFilter = '';
    this.customTypeFocused = newCustomInfo;
    this.customInfoTypes.insert(0, newCustomInfo);
  }

  removeCustomInfoType(infoTypeForm: FormGroup) {
    const index = this.customInfoTypes.controls.findIndex((form) => form === infoTypeForm);
    this.customInfoTypes.removeAt(index);
    this.customTypeFocused = null;
  }

  saveConnector(formValue) {
    const data = { ...this.connector, ...formValue };

    if (this.connector.id) {
      this.subscriptions['Connector'] = this.connectorsService.editConnector('dlp', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.subscriptions['Connector'] = this.connectorsService.addConnector('dlp', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }

  toggleItem(form: AbstractControl, itemName: string) {
    if (this.isItemSelected(form, itemName)) {
      form.setValue(
        form.value
          .split(',')
          .filter((item) => item !== itemName)
          .join(',')
      );
    } else {
      const value = form.value.split(',').filter((v) => v !== '');
      value.push(itemName);
      form.setValue(value.join(','));
    }
  }

  isItemSelected(form: AbstractControl, itemName: string): boolean {
    return form.value.includes(itemName);
  }
}
