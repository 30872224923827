import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { Observable } from 'rxjs';
import { JourneyService } from '../services/journey.service';

@Injectable()
export class AgentInterceptor implements HttpInterceptor {
  interceptHandlers: Array<string> = [
    `${this.env.API.orchestrator_configurator}/intents`,
    `${this.env.API.orchestrator_configurator}/intents/training`,
    `${this.env.API.orchestrator_configurator}/intents/parameters`,
    `${this.env.API.orchestrator_configurator}/intents/parameters/deleteAll`,
    `${this.env.API.orchestrator_configurator}/intents/flow`,
    `${this.env.API.orchestrator_analysis}/conversations/view`,
    `${this.env.API.orchestrator_analysis}/messages/view/history`,
    `${this.env.API.orchestrator_analysis}/health/intent`,
    `${this.env.API.orchestrator_analysis}/health/entity`,
    `${this.env.API.orchestrator_analysis}/health/agent`,
    `${this.env.API.orchestrator_analysis}/health/flow`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/trend/fallbacks/total`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/percente/fallback/messages`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/requestsForAnOperator`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/trend/intents/total`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/agentAutonomy`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/media/dialogflow/confidence`,
    `${this.env.API.orchestrator_analysis}/analytics/average/feedback`,
    `${this.env.API.orchestrator_analysis}/analytics/percent/precision`,
    `${this.env.API.orchestrator_analysis}/analytics/percent/recall`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/precisionAndRecall`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/precisionAndRecallForTable`,
    `${this.env.API.orchestrator_configurator}/analytics/trustedIntents`,
    `${this.env.API.orchestrator_configurator}/entities/getAll`,
    `${this.env.API.orchestrator_configurator}/regression`,
    `${this.env.API.orchestrator_configurator}/pageElementResponse/flowList`,
    `${this.env.API.orchestrator_configurator}/pageElementResponse/elementResponse`,
    `${this.env.API.orchestrator_configurator}/pageElementResponse/groupResponse`,
    `${this.env.API.orchestrator_configurator}/pageElementResponse/updateResponse`,
    `${this.env.API.orchestrator_configurator}/untrustedConfiguration`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/generic/conversations`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/single/interactions/total`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/multiple/interactions/total`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/media/conversations/messages`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/media/duration/conversations`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/media/duration/conversations`,
    `${this.env.API.orchestrator_analysis}/analytics/percent/agentChannel`,
    `${this.env.API.orchestrator_analysis}/analytics/percent/tags`,
    `${this.env.API.orchestrator_analysis}/analytics/percent/lang`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/percente/fallback/messages`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/engagement/fallbacks`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/engagement/intents`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/engagement/pages`,
    `${this.env.API.orchestrator_analysis}/analytics/graphics/engagement/flows`,
    `${this.env.API.orchestrator_configurator}/tags/getAll`,
    `${this.env.API.orchestrator_configurator}/flow/retrieveCxRouteGroup`,
    `${this.env.API.orchestrator_configurator}/flow/createRouteGroup`,
    `${this.env.API.orchestrator_configurator}/flow/getFullHierarchy`,
  ];

  constructor(private journeyService: JourneyService, @Inject(ENVIRONMENT) private env: Environment) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      this.interceptHandlers.indexOf(req.url) !== -1 &&
      !req.params.has('agentLang') &&
      this.journeyService.agentLang &&
      this.journeyService.agentLang !== 'all'
    ) {
      return next.handle(
        req.clone({
          params: req.params.set('agentLang', this.journeyService.agentLang),
        })
      );
    }

    return next.handle(req);
  }
}
