import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { RxStompService } from '@authentication';
import { NotificationService } from '@common-ui';
import { JourneyService, Languages } from '@core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { aMonthAgo, CommonApiService, CommonService, today } from '@shared';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';



@Component({
  selector: 'analytics-filter',
  templateUrl: './analytics-filter.component.html',
  styleUrls: ['./analytics-filter.component.scss'],
})
export class AnalyticsFilterComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('filtersMenu') filtersMenu: NgbDropdown;
  @Input() allLanguages = false;
  @Input() extendFilters?: any = {};
  @Input() manageVisibility?: { inputSearch: boolean; sentiment: boolean } = { inputSearch: false, sentiment: false };
  @Output() getStatistics = new EventEmitter<any>();

  filter: any = this.stockFilter;
  subscriptions: object = {};
  tags: Array<any> = [];

  languages_emum = Languages;
  stockExtendFilters;
  get stockFilter() {
    return {
      period: {
        startDate: aMonthAgo.setHours(0, 0, 0, 0),
        endDate: today.setHours(23, 59, 59, 0),
      },
      intentTag: '',
      agentChannel: ''
    };
  }

  constructor(
    private notificationService: NotificationService,
    private commonService: CommonService,
    private journeyService: JourneyService,
    private rxStompService: RxStompService,
    private commonApiService: CommonApiService
  ) {}

  ngOnInit() {
    this.stockExtendFilters = this.commonService.cloneJson(this.extendFilters);

    this.subscriptions['JourneySubscription'] = this.journeyService.journey$.subscribe(() => {
      if (this.subscriptions['AgentLanguage'] instanceof Subscription) this.subscriptions['AgentLanguage'].unsubscribe();

      this.subscriptions['AgentLanguage'] = this.journeyService.getAgentLang$(this.allLanguages).subscribe(() => {
        this.resetFilters();
        if (this.journeyService.isProductConfigured('ES')) this.getTags();
      });
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['extendFilters']?.currentValue && !changes?.['extendFilters']?.firstChange) {
      this.filter = { ...this.filter, ...changes['extendFilters'].currentValue };
      this.getStats();
    }
  }

  getTags() {
    this.subscriptions['Tags'] = this.commonApiService.getTags().subscribe((response: any) => {
      this.tags = this.commonService.groupBy(response, 'type');
    });
  }

  setTag(tag) {
    this.filter.intentTag = tag.name;
    this.getStats();
  }

  getSentimentSet(range?: 'positive' | 'negative' | 'neutral'): boolean {
    if (!range) return typeof this.filter.minSentiment === 'number' || typeof this.filter.maxSentiment === 'number';

    switch (range) {
      case 'positive':
        return this.filter.minSentiment === 0.25 && this.filter.maxSentiment === 1;
      case 'negative':
        return this.filter.minSentiment === -1 && this.filter.maxSentiment === -0.25;
      case 'neutral':
        return this.filter.minSentiment === -0.25 && this.filter.maxSentiment === 0.25;
    }
  }

  setSentiment(sentimentRange: { min: number; max: number }) {
    this.filter.minSentiment = sentimentRange.min;
    this.filter.maxSentiment = sentimentRange.max;
  }

  resetSentimentRange() {
    this.filter.minSentiment = null;
    this.filter.maxSentiment = null;
  }

  resetFilters() {
    this.filter = { ...this.stockFilter, ...this.stockExtendFilters };
    this.resetSentimentRange();
    this.getStats();
  }

  getStats() {
    if (this.filtersMenu instanceof NgbDropdown && this.filtersMenu.isOpen()) this.filtersMenu.close();
    this.filter.startDate = this.filter.period.startDate || this.filter.period.endDate;
    this.filter.endDate = this.filter.period.endDate || this.filter.period.startDate;

    this.getStatistics.emit(this.filter);
  }

  exportAnalytics() {
    const params = {
      journeyApiKey: this.journeyService.journey.apiKey,
      startDate: this.filter.period.startDate,
      endDate: this.filter.period.endDate,
    };
    const daysPeriod = Math.floor((params.endDate - params.startDate) / 86400) / 1000;

    if (daysPeriod > 31) {
      this.notificationService
        .openModal({
          type: 'warning',
          title: ' Exceeded maximum period',
          message: 'You can select a maximum period of 31 days. Use datepicker at the top of the page to change the time interval.',
          choice: 'single',
        })
        .subscribe();
      return;
    }

    this.rxStompService
      .exportAnalytics(params)
      .pipe(first())
      .subscribe((response) => {
        this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
          type: 'success',
        });
        this.commonApiService.downloadFile('analytics', response.body, 'Export Analytics.xslx').subscribe();
      });
  }
}
