import { AnalyticsService } from '@analytics';
import { Component } from '@angular/core';
import { JourneyService } from '@core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { DashboardService } from '../../../services/dashboard.service';
const MILLS_IN_A_DAY = 24 * 60 * 60 * 1000;
@Component({
  selector: 'analytics-card',
  templateUrl: './analytics-card.component.html',
  styleUrls: ['./analytics-card.component.scss'],
})
export class AnalyticsCardComponent {
  activeTab = 'today';
  startDate = new Date().setHours(0, 0, 0, 0);
  endDate = new Date().setHours(23, 59, 59, 999);
  statistics$: { [key: string]: Observable<any> } = {
    today: this.journeyService.journey$.pipe(
      switchMap(() => {
        if (this.journeyService.isProductConfigured(['ES', 'CX', 'RS'])) {
          return this.dashboardService.getSessionStatistics(this.startDate, this.endDate).pipe(
            map((res: any) => ({
              total: res.multiObject.genericConversation.total.value,
              avgMessages: res.multiObject.averageConversationMessage,
              avgDuration: res.multiObject.averageConversationDuration * 60 * 1000,
            })),
            startWith(null)
          );
        } else {
          return of({});
        }
      }),
      shareReplay(1)
    ),
    yesterday: this.journeyService.journey$.pipe(
      switchMap(() => {
        if (this.journeyService.isProductConfigured(['ES', 'CX', 'RS'])) {
          return this.dashboardService.getSessionStatistics(this.startDate - MILLS_IN_A_DAY, this.endDate - MILLS_IN_A_DAY).pipe(
            map((res: any) => ({
              total: res.multiObject.genericConversation.total.value,
              avgMessages: res.multiObject.averageConversationMessage,
              avgDuration: res.multiObject.averageConversationDuration * 60 * 1000,
            })),
            startWith(null)
          );
        } else {
          return of({});
        }
      }),
      shareReplay(1)
    ),
  };

  chart$: { [key: string]: Observable<any> } = {
    today: this.journeyService.journey$.pipe(
      switchMap(() => {
        if (this.journeyService.isProductConfigured(['ES', 'CX', 'RS'])) {
          return this.dashboardService.getAgentChannels(this.startDate, this.endDate).pipe(
            map((res: any) => this.analyticsService.processDoughnutChartData('Channels', res.singleResult)),
            startWith(null)
          );
        } else {
          return of(this.analyticsService.processDoughnutChartData('Channels', []));
        }
      }),
      shareReplay(1)
    ),
    yesterday: this.journeyService.journey$.pipe(
      switchMap(() => {
        if (this.journeyService.isProductConfigured(['ES', 'CX', 'RS'])) {
          return this.dashboardService.getAgentChannels(this.startDate - MILLS_IN_A_DAY, this.endDate - MILLS_IN_A_DAY).pipe(
            map((res: any) => this.analyticsService.processDoughnutChartData('Channels', res.singleResult)),
            startWith(null)
          );
        } else {
          return of(this.analyticsService.processDoughnutChartData('Channels', []));
        }
      }),
      shareReplay(1)
    ),
  };
  constructor(private dashboardService: DashboardService, private journeyService: JourneyService, private analyticsService: AnalyticsService) {}
}
