import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ReportChart } from '../../classes/report-chart';
import { AnalyticsService } from '../../services/analytics.service';


@Component({
  selector: 'app-report-chart-modal',
  templateUrl: './report-chart-modal.component.html',
  styleUrls: ['./report-chart-modal.component.scss'],
})
export class ReportChartModalComponent implements OnInit, OnDestroy {
  @Input() chart = new ReportChart().options;
  @Input() fields = [];
  @Input() reportID;
  @Input() reportView: 'chart' | 'table' = 'chart';
  @Output() saved = new EventEmitter<ReportChart>();

  @ViewChild('typeahead') typeahead: NgbTypeahead;
  indexFieldSelected: number;
  subscriptions = {};

  CHART_TYPES = [
    { description: 'Bar chart', code: 'BAR_CHART' },
    { description: 'Counter', code: 'COUNTER' },
    { description: 'Timeline chart', code: 'LINE_CHART' },
    { description: 'Pie chart', code: 'PIE_CHART' },
  ];

  CHART_SIZES = [
    { description: 'Small', code: 'SMALL' },
    { description: 'Medium', code: 'MEDIUM' },
    { description: 'Large', code: 'LARGE' },
  ];

  PIE_STYLES = [
    { description: 'Pie chart', code: 'PIE_CHART' },
    { description: 'Donut chart', code: 'DONUT_CHART' },
  ];

  BAR_STYLES = [
    { description: 'Vertical', code: 'VERTICAL' },
    { description: 'Horizontal', code: 'HORIZONTAL' },
  ];

  ORDER_STYLES = [
    { description: 'Descending', code: 'DESC' },
    { description: 'Ascending', code: 'ASC' },
  ];

  COUNT_CONDITIONS = [
    { description: 'Contains', code: 'CONTAINS' },
    { description: 'Different from', code: 'DIFFERENT_FROM' },
    { description: 'Equal to', code: 'EQUAL_TO' },
    { description: 'Greater than', code: 'GREATER_THAN' },
    { description: 'Greater than or equal to', code: 'GREATER_THAN_OR_EQUAL_TO' },
    { description: 'Less than', code: 'LESS_THAN' },
    { description: 'Less than or equal to', code: 'LESS_THAN_OR_EQUAL_TO' },
  ];

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(public activeModal: NgbActiveModal, private analyticsService: AnalyticsService) {}

  ngOnInit() {
    if (this.reportView === 'table') {
      this.chart.type = 'TABLE';
    }
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focus$;
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.typeahead.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        this.fields.filter(
          (field) => field.name.toLowerCase().indexOf(term.toLowerCase()) >= 0 && !this.chart.tableMetric.find((el) => el === field.name)
        )
      )
    );
  };

  fieldFormatter = (item: any) => item.name;

  selectTableField(el: any, input) {
    el.preventDefault();
    this.chart.tableMetric.push(el.item.name);
    input.value = '';
  }

  removeTableField(index: number) {
    this.chart.tableMetric.splice(index, 1);
  }

  saveChart(form: NgForm) {
    if (form.valid && (this.chart.type !== 'TABLE' || this.chart.tableMetric.length > 0)) {
      let serviceRequest = { ...form.value, customReportId: this.reportID, id: this.chart.id };
      if (this.chart.type === 'TABLE') {
        serviceRequest = { ...serviceRequest, tableMetric: this.chart.tableMetric };
      }
      if (['COUNTER', 'LINE_CHART'].indexOf(this.chart.type) >= 0 && serviceRequest.countMetric) {
        serviceRequest.countMetricCondition = Object.keys(serviceRequest.countMetricCondition).map((key) => serviceRequest.countMetricCondition[key]);
      }
      const service =
        serviceRequest.id != null ? this.analyticsService.updateChart(serviceRequest) : this.analyticsService.createChart(serviceRequest);
      this.subscriptions['createModifyChart'] = service.subscribe(() => {
        this.saved.emit();
        this.activeModal.close();
      });
    }
  }

  addCondition() {
    if (!this.chart.countMetricCondition) {
      this.chart.countMetricCondition = [{}];
    } else if (this.chart.countMetricCondition.length === 0) {
      this.chart.countMetricCondition.push({});
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }
}
