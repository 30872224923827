import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@common-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { aMonthAgo, today } from '@shared';
import { ReportChart } from '../../classes/report-chart';
import { AnalyticsService } from '../../services/analytics.service';
import { CustomReportDetailsModalComponent } from '../custom-report-details-modal/custom-report-details-modal.component';
import { ReportChartModalComponent } from '../report-chart-modal/report-chart-modal.component';

declare let google: any;
@Component({
  selector: 'analytic-reports',
  templateUrl: './analytic-reports.component.html',
  styleUrls: ['./analytic-reports.component.scss'],
})
export class AnalyticReportsComponent implements OnInit {
  filter: any = {};
  charts: ReportChart[] = [];
  tableChart: ReportChart;
  subscriptions = {};
  report: any;
  reportView: 'chart' | 'table' = 'chart';

  constructor(
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    google.charts.load('current', { packages: ['corechart'] });

    this.filter = {
      period: {
        startDate: aMonthAgo.setHours(0, 0, 0, 0),
        endDate: today.setHours(23, 59, 59, 0),
      },
    };

    this.route.parent.data.subscribe((data: any) => {
      this.route.params.subscribe((param) => {
        this.report = data.reports.find((report: any) => report.id === parseInt(param['reportId']));
        this.refreshCharts();
      });
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  addChart() {
    const modalRef = this.modalService.open(ReportChartModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.fields = this.report.fields;
    modalRef.componentInstance.reportID = this.report.id;
    modalRef.componentInstance.reportView = this.reportView;
    modalRef.componentInstance.saved.subscribe(() => {
      this.refreshCharts();
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.charts, event.previousIndex, event.currentIndex);
  }

  deleteReport() {
    this.notificationService
      .openModal({
        title: 'Delete report',
        message: 'Deleting the report you will lost all your charts and table configurations and all the report data saved. Do you want to continue?',
        choice: 'multi',
      })
      .subscribe((confirm: boolean) => {
        if (!confirm) return;

        this.subscriptions['deleteReport'] = this.analyticsService.deleteReport(this.report.id).subscribe(() => {
          this.router.navigate(['/analytics']);
        });
      });
  }

  showDetails() {
    const modalRef = this.modalService.open(CustomReportDetailsModalComponent, {
      size: 'md',
    });
    modalRef.componentInstance.reportId = this.report.bqReportName;
  }

  deleteChart(chartId) {
    this.subscriptions['deleteChart'] = this.analyticsService.deleteChart(chartId).subscribe(() => {
      this.refreshCharts();
    });
  }

  modifyChart(chart: ReportChart) {
    const modalRef = this.modalService.open(ReportChartModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.chart = { ...chart.options };
    modalRef.componentInstance.fields = this.report.fields;
    modalRef.componentInstance.reportID = this.report.id;
    modalRef.componentInstance.reportView = this.reportView;
    modalRef.componentInstance.saved.subscribe(() => this.refreshCharts());
  }

  switchView() {
    this.charts = [];
    this.tableChart = null;
    this.refreshCharts();
  }

  refreshCharts() {
    const params = {
      startDate: this.filter.period.startDate,
      endDate: this.filter.period.endDate,
      onlyTable: this.reportView === 'table',
    };
    if (this.subscriptions['getQueryResult']) {
      this.subscriptions['getQueryResult'].unsubscribe();
    }
    this.subscriptions['getQueryResult'] = this.analyticsService.getQueryResult(this.report.userReportName, params).subscribe((data: any[]) => {
      if (this.reportView === 'chart') {
        this.charts = data.map((el) => new ReportChart(el.chart, el.data));
        setTimeout(() => {
          this.charts.forEach((chart, index) => {
            chart.drawChart(`custom_chart_${index}`);
          });
        });
      } else {
        this.tableChart = data.length > 0 ? new ReportChart(data[0].chart, data[0].data) : null;
        if (this.tableChart) this.tableChart.drawChart('');
      }
    });
  }
}
