import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbPopover, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { GenericConnector } from '@core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ConnectorsService } from '../../../../services/connectors.service';

@Component({
  selector: 'cx-generic-rule-form',
  templateUrl: './cx-generic-rule-form.component.html',
  styleUrls: ['./cx-generic-rule-form.component.scss'],
})
export class CxGenericRuleFormComponent implements OnInit, OnDestroy {
  @Input() connector: GenericConnector;
  @Input() form: FormGroup;
  @Input() formSubmitted: boolean;

  fieldKeys = {
    transitionPage: '$transitionPage',
    landedPage: '$landedPage',
    route: '$route',
    sentiment: '$sentiment',
    magnitude: '$magnitude',
    sessionParams: '${{SessionParams}}',
    cache: '$[cachePath]',
  };

  flowSubscription: Subscription;
  flows: Array<any> = [];
  flowHierarchy: { pages: Array<any>; routes: Array<any>; path: { flow?: string; page?: string } } = { pages: [], routes: [], path: {} };
  suggestions = Object.values(this.fieldKeys);

  get conditionsArray() {
    return this.form.get('conditions') as FormArray;
  }
  get conditionFormBuilder() {
    const form = this.fb.group({
      id: this.fb.control(''),
      field: this.fb.control('', Validators.required),
      clause: this.fb.control('', Validators.required),
      value: this.fb.control('', Validators.required),
    });

    form.get('field').valueChanges.subscribe((newValue) => {
      if ([this.fieldKeys.landedPage, this.fieldKeys.transitionPage, this.fieldKeys.route].indexOf(newValue) != -1) {
        form.get('clause').setValue('IN');
        form.get('value').setValue('');
      } else if (form.get('clause').value === 'IN') {
        form.get('clause').setValue('');
      }
    });

    form.get('clause').valueChanges.subscribe((newValue) => {
      if (newValue === 'IS_PRESENT') {
        form.get('value').disable();
        form.get('value').setValue('');
      } else {
        form.get('value').enable();
      }
    });
    return form;
  }

  typeSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) => {
        if (term === '') return this.suggestions;
        return this.suggestions.filter((type) => type.toLowerCase().indexOf(term.toLowerCase()) > -1);
      })
    );

  constructor(private fb: FormBuilder, private connectorsService: ConnectorsService) {}

  ngOnInit(): void {
    this.getFlows();

    this.form.addControl('operator', this.fb.control('OR', Validators.required));
    this.form.addControl('conditions', this.fb.array([]));

    if (!this.connector.id) {
      this.conditionsArray.push(this.conditionFormBuilder);
    } else {
      this.processConditionArray();
      this.form.patchValue(this.connector);
    }
  }

  ngOnDestroy(): void {
    this.flowSubscription.unsubscribe();
  }

  getFlows() {
    this.flowSubscription = this.connectorsService.getFullHierarchy().subscribe((response: Array<any>) => {
      this.flows = response;
    });
  }

  processConditionArray() {
    this.connector['conditions'].forEach((_) => this.conditionsArray.push(this.conditionFormBuilder));
  }
  toggleItem(conditionValue: FormControl, itemName: string) {
    if (this.isItemSelected(conditionValue.value, itemName)) {
      conditionValue.setValue(
        conditionValue.value
          .split(',')
          .filter((item) => item !== itemName)
          .join(',')
      );
    } else {
      const value = conditionValue.value.split(',').filter((v) => v !== '');
      value.push(itemName);
      conditionValue.setValue(value.join(','));
    }
  }

  isItemSelected(conditionValue: any, itemName: string): boolean {
    return conditionValue.includes(itemName);
  }

  selectAll(conditionForm: FormControl) {
    const selectedItems = conditionForm.get('value').value.split(',');
    const itemsList = conditionForm.get('field').value === this.fieldKeys.route ? this.flowHierarchy.routes : this.flowHierarchy.pages;

    if (conditionForm.get('value').value !== '' && selectedItems.length === itemsList.length) {
      conditionForm.get('value').setValue('');
    } else {
      conditionForm.get('value').setValue(itemsList.map((el) => el.dialogflowId).join(','));
    }
  }

  onSelectSuggestion(elementRef: HTMLInputElement, form: AbstractControl, event: NgbTypeaheadSelectItemEvent) {
    event.preventDefault();
    switch (event.item) {
      case this.fieldKeys.cache:
        form.setValue('$[]');
        elementRef.setSelectionRange(2, 2);
        break;
      case this.fieldKeys.sessionParams:
        form.setValue('${{}}');
        elementRef.setSelectionRange(3, 3);
        break;
      default:
        form.setValue(event.item);
        break;
    }
  }

  openPopValues(popCondition: NgbPopover, conditionGroup: AbstractControl) {
    delete this.flowHierarchy.path.flow;
    delete this.flowHierarchy.path.page;
    this.flowHierarchy.pages = [];
    this.flowHierarchy.routes = [];

    popCondition.open({ conditionForm: conditionGroup });
  }

  getPages(flow: any) {
    this.flowHierarchy.path.flow = flow.name;
    this.flowHierarchy.pages = flow.page;
  }

  getRoutes(page: any) {
    this.flowHierarchy.path.page = page.name;
    this.flowHierarchy.routes = page.routes;
  }

  isActivePath(conditionForm: FormControl, items: any, type: 'flow' | 'page'): boolean {
    if (type === 'page') {
      return items.routes.some((route) => conditionForm.get('value').value.split(',').includes(route.dialogflowId));
    } else if (type === 'flow') {
      if ([this.fieldKeys.landedPage, this.fieldKeys.transitionPage].indexOf(conditionForm.get('field').value) !== -1) {
        return items.page.some((page) => conditionForm.get('value').value.split(',').includes(page.dialogflowId));
      } else {
        return items.page.some((page) => page.routes.some((route) => conditionForm.get('value').value.split(',').includes(route.dialogflowId)));
      }
    }
  }

  hierarchyBack() {
    if (this.flowHierarchy.path.page) {
      delete this.flowHierarchy.path.page;
      this.flowHierarchy.routes = [];
      return;
    }

    delete this.flowHierarchy.path.flow;
    this.flowHierarchy.pages = [];
  }

  getItemsCount(conditionValue: AbstractControl) {
    return conditionValue.value.split(',').filter((v) => v !== '').length;
  }

  getPlaceholder(fieldValue: string, popItems: NgbPopover, form: AbstractControl): string {
    const key = Object.keys(this.fieldKeys).find((k) => this.fieldKeys[k] === fieldValue);
    if (popItems.isOpen()) {
      return `Search ${key}s...`;
    } else {
      return `${this.getItemsCount(form)} ${key}${this.getItemsCount(form) > 1 ? 's' : ''} selected`;
    }
  }
}
