import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@common-ui';
import { JourneyService, LicenseService, Journey, GenericConnector, Board, Product } from '@core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportModalComponent } from '@common-ui';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable, Subject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { ConnectorsUtilityService } from '../../services/connectors-utility.service';
import { ConnectorsService } from '../../services/connectors.service';
import { JourneyModalComponent } from '../journey-modal/journey-modal.component';
import { ConnectorsBoardComponent } from '../connectors-board/connectors-board.component';

@Component({
  selector: 'app-process-flow',
  templateUrl: './process-flow.component.html',
  styleUrls: ['./process-flow.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProcessFlowComponent implements OnInit, OnDestroy {
  @ViewChild('owlCar') public owlCar;
  @ViewChild('drawBoard') private drawBoard: ElementRef;
  @ViewChild(ConnectorsBoardComponent) private boardRef: ConnectorsBoardComponent;

  subscriptions: { [key: string]: Subscription } = {};
  products: Product[] = [];
  activeBoard: Board;
  journeyLimit: number;
  ruleConnectorSubscription: Observable<GenericConnector[]>;
  groupConnectors: { [key: string]: GenericConnector[] } = {};

  cardCarouselOptions: OwlOptions = {
    dots: false,
    mouseDrag: true,
    autoWidth: true,
  };
  isDragging = false;
  showNavButton = true;
  onConnectorDropped = new Subject();
  journeys: Journey[] = [];
  connectedList = [];

  constructor(
    private modalService: NgbModal,
    private licenseService: LicenseService,
    private connectorsService: ConnectorsService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    public boardService: ConnectorsUtilityService,
    private journeyService: JourneyService
  ) {}

  ngOnInit() {
    this.subscriptions['Route'] = this.route.params.subscribe(({ journeyId }) => {
      if (!journeyId) {
        return this.router.navigate([`/process-flow/${this.journeyService.journey.id}`]);
      }

      this.getBoard(journeyId);
    });

    this.subscriptions['SessionJourney'] = this.journeyService.journey$.pipe(skip(1)).subscribe((journey) => {
      this.router.navigate([`/process-flow/${journey.id}`]);
    });

    this.licenseService.getSessionLicense().subscribe((license) => (this.journeyLimit = license.journeyLimit));
  }

  getBoard(journeyId) {
    this.subscriptions['Board'] = this.connectorsService.getBoard(journeyId).subscribe((board: Board) => {
      this.activeBoard = board;
      this.connectedList = [
        'main-list',
        ...(this.activeBoard.connectors || []).filter((c) => c.productCode === 'RL').map((c) => `list-rule-${c.id}`),
      ];

      if (this.activeBoard.journey.id !== this.journeyService.journey.id) {
        this.journeyService.setSessionJourney(this.activeBoard.journey.id);
      }
      if (this.journeys.length !== this.journeyService.journeyList.length) {
        this.journeys = this.journeyService.journeyList;
      }
      this.getAvailableProducts();
    });
  }

  addProduct(product) {
    this.boardRef.addToBoard(product);

    setTimeout(() => {
      this.drawBoard.nativeElement.scrollTop = this.drawBoard.nativeElement.scrollHeight;
    }, 100);
  }
  getAvailableProducts() {
    this.subscriptions['Products'] = this.connectorsService
      .getFilteredConnectorList({ journeyApiKey: this.activeBoard.journey.apiKey })
      .subscribe((products) => {
        this.products = products;
      });
  }
  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  modalJourney(journey?: Journey, activeTab?: string) {
    if (journey || this.journeyLimit == null || this.journeyService.journeyList.length < this.journeyLimit) {
      const modalRef = this.modalService.open(JourneyModalComponent, {
        size: 'lg',
      });
      modalRef.componentInstance.journey = journey ? { ...journey } : null;
      modalRef.componentInstance.activeTab = activeTab || null;
      modalRef.componentInstance.savedJourney.subscribe((journeyId) => {
        if (journeyId) {
          this.router.navigate([`/process-flow/${journeyId}`]);
        } else {
          this.getBoard(this.activeBoard.journey.id);
        }
      });
      modalRef.componentInstance.deletedJourney.subscribe(() => this.router.navigate([`/process-flow`]));
    } else {
      this.notificationService
        .openModal({
          title: 'WARNING',
          message: 'You have reached the maximum number of journeys under your licence. Contact support for quota increase.',
          choice: 'support',
          type: 'warning',
        })
        .subscribe(async (confirm: boolean) => {
          if (!confirm) return;

          this.modalService.open(SupportModalComponent, {
            size: 'lg',
          });
        });
    }
  }

  setCaroselSlide() {
    const sildeWidth = this.owlCar.slidesData.reduce((acc, s) => acc + s.width, 0);
    this.showNavButton = this.owlCar.carouselWindowWidth < sildeWidth;
    if (this.activeBoard) {
      this.owlCar.to(this.activeBoard.journey.id.toString());
    }
  }

  onDrag({ dragging }) {
    setTimeout(() => (this.isDragging = dragging));
  }
  getWidth(journey: Journey) {
    const width = journey.name.length * 10 + 25;
    return journey.dispatcher ? width + 25 : width;
  }
}
