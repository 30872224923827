import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { Observable } from 'rxjs';
import { OrchestrateFulfillmentResponse } from '../models/orchestrate-fulfillment-response.model';
import { OrchestrateResponse } from '../models/orchestrate-response.model';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private env: Environment) {}

  sendMessage(apiKey: string, data: any): Observable<OrchestrateResponse> {
    const httpOptions = {
      params: new HttpParams().set('cleanResponse', 'false').set('debugMode', 'true'),
    };

    return this.http.post<OrchestrateResponse>(`${this.env.API.orchestrator_chat}/orchestrate/${apiKey}`, data, httpOptions);
  }

  sendFulfillmentMessage(apiKey: string, data: any): Observable<OrchestrateFulfillmentResponse> {
    const httpOptions = {
      params: new HttpParams(),
    };
    return this.http.post<OrchestrateFulfillmentResponse>(`${this.env.API.orchestrator_chat}/fulfillmentIntegration/${apiKey}`, data, httpOptions);
  }

  uploadFile(apiKey: string, sessionId: string, file: File): Observable<any> {
    const httpOptions: any = {
      params: new HttpParams().set('sessionId', sessionId),
      reportProgress: true,
      observe: 'events',
    };

    const data = new FormData();
    data.append('file', file, file.name);

    return this.http.post(`${this.env.API.orchestrator_chat}/upload/${apiKey}`, data, httpOptions);
  }
}
