import { Component, Input } from '@angular/core';

@Component({
  selector: 'drag-preview',
  templateUrl: './drag-preview.component.html',
  styleUrls: ['./drag-preview.component.scss'],
})
export class DragPreviewComponent {
  @Input() name: string;
  @Input() code: string;
  @Input() movingConnector;
}
