<div class="code_editor_sidebar" [spinnerLoader]="subscriptions['SaveConnector']?.closed">
    <div class="sidebar_content" *roleRestriction="'design.processflow.codeconnector.write'; disableElements: true">
        <div class="row mb-3">
            <div class="col-3">
                <select name="filterType" id="filterType" class="form-select" [(ngModel)]="connector.type" #filterType="ngModel">
                    <option value="JAVASCRIPT">Javascript</option>
                    <option value="GROOVY">Groovy</option>
                </select>
            </div>
            <div class="col-3 ms-auto">
                <select name="fontSize" id="fontSize" class="form-select" [(ngModel)]="preferences.fontSize" #fontSize="ngModel">
                    <option value="12px">12px</option>
                    <option value="16px">16px</option>
                    <option value="20px">20px</option>
                </select>
            </div>
            <div class="col-3">
                <select name="theme" id="theme" class="form-select" [(ngModel)]="preferences.theme" #theme="ngModel">
                    <option value="dark">Dark</option>
                    <option value="light">Light</option>
                </select>
            </div>
        </div>

        <div class="code_container">
            <code-editor [(ngModel)]="connector.script" [language]="connector.type === 'GROOVY' ? 'java' : 'javascript'" [theme]="preferences.theme" [fontSize]="preferences.fontSize"></code-editor>
        </div>
    </div>
    <div class="sidebar_bottom">
        <button class="btn" (click)="saveConnector()" *roleRestriction="'design.processflow.codeconnector.write'; disableElements: true">Save</button>
    </div>
</div>
