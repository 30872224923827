import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { RxStompService } from '@authentication';
import { NotificationService } from '@common-ui';
import { JourneyService } from '@core';
import { Agent, AgentType, Journey } from '@core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonApiService, CommonService } from '@shared';
import { first } from 'rxjs/operators';
import { ConnectorsService } from '../../services/connectors.service';

@Component({
  selector: 'app-journey-modal',
  templateUrl: './journey-modal.component.html',
  styleUrls: ['./journey-modal.component.scss'],
})
export class JourneyModalComponent implements OnInit, OnDestroy {
  @Input() journey?: Journey;
  @Input() activeTab?: string = 'general';
  @Output() private savedJourney = new EventEmitter<any>();
  @Output() private deletedJourney = new EventEmitter<any>();

  subscriptions: object = {};
  persistentSubscriptions: object = {};

  journeyForm = this.fb.group({
    id: [null],
    name: ['', Validators.required],
    description: [''],
    status: ['PROCESSING'],
    fulfillment: [false],
    dispatcher: [false],
    embedded: [''],
    createdDate: [null],
    createdBy: [''],
    lastModifiedDate: [null],
  });

  fulfillmentForm = this.fb.group<{
    agent: FormControl<Agent>;
    editMode: FormControl<string>;
    noticeRead?: FormControl<string>;
  }>({
    agent: this.fb.control(null, Validators.required),
    editMode: this.fb.control('export'),
  });
  journeySubmitted = false;
  agents: Array<any>;

  importedFileName: string;
  file: File;
  get lastJourney() {
    return this.journeyService.journeyList.length === 1;
  }
  
  embeddedBasePath: string = window.location.origin;
  restCode = `
  {
    "lang": "it",
    "sessionId": UNIQUE_TIMESTAMP,
    "event": { "name": "Welcome" },
    "userData": null
  }`;

  get agentChanged() {
    return this.fulfillmentForm.get('agent')?.dirty && this.journey.agent;
  }

  get fulfillmentEditMode() {
    return this.fulfillmentForm.get('editMode').value;
  }

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private journeyService: JourneyService,
    private notificationService: NotificationService,
    public commonService: CommonService,
    private rxStompService: RxStompService,
    private connectorsService: ConnectorsService,
    private commonApiService: CommonApiService,
    @Inject(ENVIRONMENT) public env: Environment
  ) {}

  ngOnInit() {
    if (this.journey) {
      this.journeyForm.patchValue(this.journey);
      if (this.journey.fulfillment) {
        this.getAgentsBot();
        if (this.journey.agent) {
          this.fulfillmentForm.patchValue({ agent: this.journey.agent });
          this.fulfillmentForm.addControl('noticeRead', this.fb.control('', Validators.requiredTrue));
        }
      }
    } else {
      this.handleSwitchSubscription();
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getAgentsBot() {
    let type;
    if (this.journey.agent?.agentType === AgentType.ES) {
      type = 'DIALOGFLOW_ES';
    } else if (this.journey.agent?.agentType === AgentType.CX) {
      type = 'DIALOGFLOW_CX';
    }
    this.subscriptions['AgentsBot'] = this.commonApiService.getAgentsBot(type).subscribe((response: any) => {
      this.agents = response;
    });
  }

  compareFulfilmentAgent(a: any, b: any): boolean {
    return a && b ? a.id === b.id : a === b;
  }

  changeStatus($event: any) {
    if ($event.target.checked) this.journeyForm.get('status').setValue('DEPLOYED');
    else this.journeyForm.get('status').setValue('PROCESSING');
  }

  saveJourney() {
    this.journeySubmitted = true;
    if (this.activeTab === 'general') {
      if (this.journeyForm.invalid) return;

      if (this.journey) {
        this.subscriptions['SaveJourney'] = this.journeyService.editJourney(this.journeyForm.value).subscribe(() => this.successModalCallback());
      } else {
        this.subscriptions['SaveJourney'] = this.journeyService
          .createJourney(this.journeyForm.value)
          .subscribe((journey) => this.successModalCallback(journey.id));
      }
    } else if (this.activeTab === 'fulfillment') {
      this.handleFullfilmentMode();
    }
  }
  private successModalCallback(journeyId?: number) {
    this.activeModal.close();
    this.savedJourney.emit(journeyId);
  }
  private handleFullfilmentMode() {
    if (this.fulfillmentEditMode === 'export') {
      if (this.fulfillmentForm.invalid) return;

      const params = {
        agentId: this.fulfillmentForm.get('agent').value.id,
        journeyId: this.journey.id,
      };
      this.subscriptions['SaveJourney'] = this.connectorsService.changeFulfilmentAgent({}, params).subscribe(() => this.successModalCallback());
    } else if (this.fulfillmentEditMode === 'import') {
      const params = {
        journeyApiKey: this.journey.apiKey,
        fileName: this.importedFileName,
      };
      if (this.journey.agent.agentType === 'Dialogflow ES') {
        this.subscriptions['SaveJourney'] = this.commonApiService.importIntentsData({}, params).subscribe(() => this.successModalCallback());
      } else if (this.journey.agent.agentType === 'Dialogflow CX') {
        this.subscriptions['SaveJourney'] = this.commonApiService.importFlowsData({}, params).subscribe(() => this.successModalCallback());
      }
    }
  }
  deleteJourney() {
    this.notificationService
      .openModal({
        title: 'Confirm delete Journey',
        message: 'Are you sure to delete Journey?',
        choice: 'multi',
      })
      .subscribe((confirm: boolean) => {
        if (!confirm) return;

        this.subscriptions['DeleteJourney'] = this.journeyService.deleteJourney(this.journey.id).subscribe(() => {
          this.activeModal.close();
          this.deletedJourney.emit();
        });
      });
  }

  handleSwitchSubscription() {
    this.journeyForm.get('dispatcher').valueChanges.subscribe((value) => {
      if (value === true) {
        this.journeyForm.get('fulfillment').disable({ emitEvent: false });
      } else {
        this.journeyForm.get('fulfillment').enable({ emitEvent: false });
      }
    });

    this.journeyForm.get('fulfillment').valueChanges.subscribe((value) => {
      if (value === true) {
        this.journeyForm.get('dispatcher').disable({ emitEvent: false });
      } else {
        this.journeyForm.get('dispatcher').enable({ emitEvent: false });
      }
    });
  }

  exportIntents() {
    this.persistentSubscriptions['ExportIntent'] = this.rxStompService
      .exportIntents(this.journey.apiKey)
      .pipe(first())
      .subscribe((response) => {
        this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
          type: 'success',
        });
        this.commonApiService.downloadFile('intents', response.body, 'Export Intents.xlsx').subscribe();
      });
  }

  storeFile(target: EventTarget) {
    const files = (target as HTMLInputElement).files;
    if (!files?.[0]) return;

    this.file = files[0];

    const params = {
      journeyApiKey: this.journey.apiKey,
    };
    if (this.journey.agent.agentType === 'Dialogflow ES') {
      this.subscriptions['UploadFile'] = this.commonApiService.uploadIntentsData(this.file, params).subscribe((response: string) => {
        this.importedFileName = response;
      });
    } else if (this.journey.agent.agentType === 'Dialogflow CX') {
      this.subscriptions['UploadFile'] = this.commonApiService.uploadFlowsData(this.file, params).subscribe((response: string) => {
        this.importedFileName = response;
      });
    }
  }

  exportHistory() {
    this.persistentSubscriptions['ExportHistory'] = this.rxStompService
      .esportAgentHistory(this.journey.apiKey)
      .pipe(first())
      .subscribe((response) => {
        this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
          type: 'success',
        });
        this.commonApiService.downloadFile('history', response.body, 'Export History.zip').subscribe();
      });
  }
  exportFlows() {
    this.persistentSubscriptions['ExportFlow'] = this.rxStompService
      .exportFlows(this.journey.apiKey)
      .pipe(first())
      .subscribe((response) => {
        this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
          type: 'success',
        });
        this.commonApiService.downloadFile('flow', response.body, 'Export Flows.xlsx').subscribe();
      });
  }
}
