import { Component, OnDestroy, OnInit } from '@angular/core';
import { LicenseService, Product } from '@core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MonitoringService } from '../../services/monitoring.service';
import { HealthStatusModalComponent } from '../health-status-modal/health-status-modal.component';



@Component({
  selector: 'health-status',
  templateUrl: './health-status.component.html',
  styleUrls: ['./health-status.component.scss'],
})
export class HealthStatusComponent implements OnInit, OnDestroy {
  availablePods = ['Chat', 'Analysis', 'Configurator', 'Console', 'Rasa Model Server'];
  availableChannels: Product[] = [];

  pods = [];
  channels = [];

  componentLoaded = false;

  subscriptions = {};
  constructor(private monitoringService: MonitoringService, private modalService: NgbModal, private licenseService: LicenseService) {}

  ngOnInit() {
    this.subscriptions['license'] = this.licenseService.getSessionLicense().subscribe((license) => {
      this.availableChannels = license.products.Channel.filter((c) => c.purchased);
      this.loadPodStatuses();
    });
  }

  ngOnDestroy(): void {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  async loadPodStatuses() {
    this.pods = [];
    this.channels = [];
    for (const name of this.availablePods) {
      this.pods.push(await this.getComponentStatus(name, name));
    }
    for (const channel of this.availableChannels) {
      this.channels.push(await this.getComponentStatus(channel.code, channel.name));
    }
    this.componentLoaded = true;
  }
  private async getComponentStatus(code, name) {
    try {
      let status;
      switch (code) {
        case 'Chat':
          status = await this.monitoringService.getChatStatus().toPromise();
          break;
        case 'Analysis':
          status = await this.monitoringService.getAnalysisStatus().toPromise();
          break;
        case 'Configurator':
          status = await this.monitoringService.getConfiguratorStatus().toPromise();
          break;
        case 'Console':
          status = await this.monitoringService.getConsoleStatus().toPromise();
          break;
        case 'Rasa Model Server':
          status = await  this.monitoringService.getRasaModelServerStatus().toPromise();
          break;
        case 'C_W':
          status = await this.monitoringService.getWhatsAppStatus().toPromise();
          break;
        case 'C_V':
          status = await this.monitoringService.getVivochaStatus().toPromise();
          break;
        case 'C_T':
          status = await this.monitoringService.getTimrccStatus().toPromise();
          break;
        case 'C_F':
          status = await this.monitoringService.getFacebookStatus().toPromise();
          break;
        case 'C_L':
          status = await this.monitoringService.getLivepersonStatus().toPromise();
          break;
        case 'C_G':
          status = await this.monitoringService.getGoogleStatus().toPromise();
          break;
        case 'C_TE':
          status = await this.monitoringService.getTeamsStatus().toPromise();
          break;
        case 'C_TG':
          status = await this.monitoringService.getTelegramStatus().toPromise();
          break;
      }
      return { ...status, name };
    } catch (error) {
      return {
        name,
        status: 'DOWN',
      };
    }
  }

  statusModal(statuses) {
    if (statuses.components) {
      const modalRef = this.modalService.open(HealthStatusModalComponent);
      modalRef.componentInstance.statuses = statuses;
    }
  }
}
