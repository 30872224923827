import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-nlu-connector-modal',
  templateUrl: './nlu-connector-modal.component.html',
  styleUrls: ['./nlu-connector-modal.component.scss'],
})
export class NluConnectorModalComponent implements OnInit, OnDestroy {
  @Input() connector: any;
  @ViewChild('nluForm') nluForm: NgForm;

  connectorSubscription: Subscription;

  constructor(public activeModal: NgbActiveModal, private connectorsService: ConnectorsService) {}

  ngOnInit() {
    if (!this.connector) this.connector = {};
  }

  ngOnDestroy() {
    if (this.connectorSubscription instanceof Subscription) this.connectorSubscription.unsubscribe();
  }

  saveConnector() {
    if (this.nluForm.invalid) return;

    const data = this.connector;

    if (this.connector.id) {
      this.connectorSubscription = this.connectorsService.editConnector('naturalLanguage', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.connectorSubscription = this.connectorsService.addConnector('naturalLanguage', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }
}
