export enum ConnectorPath {
    E = 'email',
    R = 'restHttp',
    NL = 'naturalLanguage',
    RL = 'rule',
    ES = 'dialogFlow',
    CX = 'dialogFlow',
    DLP = 'dlp',
    T = 'translate',
    Q = 'troove',
    DT = 'dataTransfer',
    DP = 'dataProcessing',
    ZD = 'zendesk',
    SF = 'salesforce',
    SG = 'sendgrid',
    SFS = 'salesforce/sales',
    D = 'dispatcher',
    EC = 'eventCaller',
    TW = 'twiliosms',
    CE = 'codeEditor',
    EH = 'httpError',
    AM = 'actionMapping',
    RS = 'rasa'
}