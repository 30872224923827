<div class="row mb-3">
    <div class="col-12">
        <analytics-filter (getStatistics)="getStatistics($event)"></analytics-filter>
    </div>
</div>

<div class="row mb-5 align-items-stretch" id="agentAutonomyStats">
    <div class="col-sm-12 col-md-6">
        <div class="row mb-3">
            <div class="col-4 statistics_box">
                <dl class="stats_list">
                    <ng-template [spinnerLoader]="subscriptions['TrustedIntents']?.closed" [size]="'sm'"></ng-template>
                    <dt ngbTooltip="{{ toolTipsProficiency.trustedIntents }}">Trusted intents</dt>
                    <dd>{{ (trustedIntents.totalTrusted | number: '1.0-2') || 0 }}/{{ (trustedIntents.totalIntent | number: '1.0-2') || 0 }}</dd>
                </dl>
            </div>
            <div class="col-8">
                <ngb-progressbar [showValue]="true" height="2.5rem" [value]="parseBarValue(trustedIntents.percTrusted)"></ngb-progressbar>
            </div>
        </div>
        <div class="row">
            <div class="col-4 statistics_box">
                <dl class="stats_list">
                    <ng-template [spinnerLoader]="subscriptions['AgentAutonomy']?.closed" [size]="'sm'"></ng-template>
                    <dt ngbTooltip="{{ toolTipsProficiency.virtualAgentAutonomy }}">Virtual agent autonomy</dt>
                    <dd>{{ agentAutonomy.percAgentAutonomy }}%</dd>
                </dl>
            </div>
            <div class="col-8">
                <ngb-progressbar [showValue]="true" height="2.5rem" [value]="parseBarValue(agentAutonomy.percAgentAutonomy)"></ngb-progressbar>
            </div>
        </div>
    </div>
    <div class="col-sm-12 div col-md-6">
        <div class="row mb-3">
            <div class="col-6 statistics_box operator_content">
                <dl class="stats_list">
                    <ng-template [spinnerLoader]="subscriptions['PercentagePrecision']?.closed" [size]="'sm'"></ng-template>
                    <dt ngbTooltip="{{ toolTipsProficiency.precision }}" container="body" tooltipClass="analytics_tooltip">Precision</dt>
                    <dd>{{ (percentagePrecision * 100 | number: '1.0-2') || 0 }}%</dd>
                </dl>
            </div>
            <div class="col-6 statistics_box operator_content">
                <dl class="stats_list">
                    <ng-template [spinnerLoader]="subscriptions['AverageScore']?.closed" [size]="'sm'"></ng-template>
                    <dt ngbTooltip="{{ toolTipsProficiency.confidence }}" container="body" tooltipClass="analytics_tooltip">Confidence</dt>
                    <dd>{{ averageScore | number: '1.0-2' }}</dd>
                </dl>
            </div>
        </div>
        <div class="row">
            <div class="col-6 statistics_box operator_content">
                <dl class="stats_list">
                    <ng-template [spinnerLoader]="subscriptions['PercentageRecall']?.closed" [size]="'sm'"></ng-template>
                    <dt ngbTooltip="{{ toolTipsProficiency.recall }}" container="body" tooltipClass="analytics_tooltip">Recall</dt>
                    <dd>{{ (percentageRecall * 100 | number: '1.0-2') || 0 }}%</dd>
                </dl>
            </div>
        </div>
    </div>
</div>

<div class="row mb-5">
    <div class="col-12" id="scoreDistributionStats">
        <ul ngbNav class="nav-tabs" [(activeId)]="chartDistribution" (activeIdChange)="drawProficiencyChart()">
            <li class="ms-2 me-auto">Score distribution</li>
            <li ngbNavItem="precision">
                <a ngbNavLink>Precision</a>
            </li>
            <li ngbNavItem="recall">
                <a ngbNavLink>Recall</a>
            </li>
        </ul>
        <div class="bar_chart_box">
            <bar-chart [labels]="labels" [dataset]="dataset"></bar-chart>
        </div>
    </div>
</div>

<div class="row" id="intentsDetailsStats">
    <div class="col-12">
        <div class="row mb-3">
            <div class="col-12">
                <form #intentsFilter="ngForm" class="intents_filter" (ngSubmit)="searchPrecisionAndRecall(1)" novalidate>
                    <div class="row">
                        <div class="col-sm-12 col-md-3">
                            <div class="input-group">
                                <input name="filterName" type="text" id="filterName" class="form-control" placeholder="Search Intent.." [(ngModel)]="intentFilter.intentName" #filterName="ngModel" />
                                <button type="submit" class="btn brand_default"><i class="fas fa-search" aria-hidden="true"></i></button>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <select name="filterStatus" id="filterStatus" class="form-select" [(ngModel)]="intentFilter.status" #filterStatus="ngModel" (change)="searchPrecisionAndRecall(1)">
                                <option [value]="''">All</option>
                                <option [value]="'trusted'">Trusted</option>
                                <option [value]="'untrusted'">Untrusted</option>
                            </select>
                        </div>
                        <div class="col-sm-12 col-md-3 ms-auto">
                            <label>Precision %</label>
                            <ngx-slider [(value)]="intentFilter.startPrecision" [(highValue)]="intentFilter.endPrecision" [options]="sliderOptions" (userChangeEnd)="searchPrecisionAndRecall(1)"></ngx-slider>
                        </div>
                        <div class="col-sm-12 col-md-3 ms-auto">
                            <label>Recall %</label>
                            <ngx-slider [(value)]="intentFilter.startRecall" [(highValue)]="intentFilter.endRecall" [options]="sliderOptions" (userChangeEnd)="searchPrecisionAndRecall(1)"></ngx-slider>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="alert alert-warning" role="alert" *ngIf="!precisionAndRecallForTable?.length && subscriptions['PrecisionAndRecallForTable']?.closed">No results for selected filters</div>
                <div class="table-responsive proficiency_table">
                    <ng-template [spinnerLoader]="subscriptions['PrecisionAndRecallForTable']?.closed"></ng-template>
                    <table class="table table-hover" aria-describedby="Proficiency Table" *ngIf="precisionAndRecallForTable?.length">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Intent</th>
                                <th scope="col">Status</th>
                                <th scope="col">Precision %</th>
                                <th scope="col">Recall %</th>
                                <th scope="col">Confidence</th>
                                <th scope="col">
                                    Detected <br />
                                    Occurences
                                </th>
                                <th scope="col">
                                    Managed <br />
                                    Occurences
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let interaction of precisionAndRecallForTable">
                                <td><i class="fas fa-circle fa-xs" aria-hidden="true" [ngClass]="{ 'text-success': interaction.precision >= 80, 'text-danger': interaction.precision <= 39, 'text-warning': interaction.precision >= 40 && interaction.precision <= 79 }"></i></td>
                                <td>{{ interaction.intentName }}</td>
                                <td>{{ interaction.status }}</td>
                                <td>{{ (interaction.precision | number: '1.0-2') || 0 }}</td>
                                <td>{{ (interaction.recall | number: '1.0-2') || 0 }}</td>
                                <td>{{ (interaction.confidence | number: '1.0-2') || 0 }}</td>
                                <td>{{ interaction.occurence }}</td>
                                <td>{{ interaction.managedOccurence }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="precisionAndRecallPagination.totalItems > precisionAndRecallPagination.pageSize">
    <div class="col-12">
        <pagination [paginationData]="precisionAndRecallPagination" (paginationCallback)="searchPrecisionAndRecall($event)"></pagination>
    </div>
</div>
