<div class="design_sidebar">
    <div class="board_components">
        <div class="box_wrapper component_record" *ngFor="let category of productsMap | keyvalue; let index = index">
            <div class="card">
                <div class="card-header record_head">
                    {{ category.key }}
                </div>
                <div class="card-body" (cdkDropListDropped)="movingConnector = undefined" cdkDropList cdkDropListSortingDisabled [cdkDropListConnectedTo]="connectedList" [cdkDropListData]="{ connectors: category.value }">
                    <ng-container *ngFor="let product of category.value">
                        <article (cdkDragStarted)="startMove($event)" cdkDrag [cdkDragData]="product" [cdkDragDisabled]="!product.visibility" class="component_item" [ngClass]="{ disabled: !product.visibility }" (click)="product.visibility ? addToBoard(product) : null">
                            <div class="component_grab">
                                <div class="grab_handle" cdkDragHandle (click)="$event.stopPropagation()">
                                    <button class="btn"><i aria-hidden="true" class="fas fa-grip-vertical"></i></button>
                                </div>
                                <figure class="item_image">
                                    <img src="./images/design-connectors/connector_{{ product.code }}.png" alt="" />
                                </figure>
                            </div>

                            <div class="item_description">
                                <h6 [ngClass]="{ 'text-muted': !product.visibility }">{{ product.name }}</h6>
                                <small class="text-muted">{{ product.subtitle }}</small>
                            </div>
                            <ng-container *cdkDragPlaceholder>
                                <drag-placeholder></drag-placeholder>
                            </ng-container>
                            <ng-container *cdkDragPreview>
                                <drag-preview [name]="product.name" [code]="product.code"></drag-preview>
                            </ng-container>
                        </article>
                        <article *ngIf="movingConnector?.code === product.code" class="component_item" [ngClass]="{ disabled: !product.visibility }">
                            <div class="component_grab">
                                <figure class="item_image">
                                    <img src="./images/design-connectors/connector_{{ product.code }}.png" alt="" />
                                </figure>
                            </div>
                            <div class="item_description">
                                <h6>{{ product.name }}</h6>
                                <small class="text-muted">{{ product.subtitle }}</small>
                            </div>
                        </article>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
