
<form #alertForm="ngForm" class="alert_form" novalidate (ngSubmit)="setEmailAlert()" [spinnerLoader]="subscriptions['AlertEmail']?.closed">
    <ng-template [spinnerLoader]="subscriptions['NotifiedUser'].closed"></ng-template>

    <div class="row mb-3">
        <div class="col offset-3">
            <h4>Consumption alerts</h4>
            <p>Send an email message when the consumption service usage thresholds (25%, 50% and 75%) displayed in the Billing section are exceeded.</p>
        </div>
    </div>
    <div class="row mb-3">
        <label class="form-label col-3">Email notification</label>
        <div class="col">                                    
            <div class="form-check form-switch">
                <input name="notificationStatus" type="checkbox" class="form-check-input" id="notificationStatus" [(ngModel)]="notificationAlert.enabled" #notificationStatus="ngModel">
                <label class="form-check-label" for="notificationStatus"></label>
            </div>
        </div>
    </div>
    <div class="row">
        <label class="form-label col-3">Email recipients</label>
        <div class="col-4">
            <input name="userSelected" type="text" class="form-control" placeholder="Search user..." [(ngModel)]="userEmail" #userSelected="ngModel" [disabled]="!notificationAlert.enabled" [ngbTypeahead]="userSuggestions" [resultFormatter]="userSuggestionFormat" [inputFormatter]="userSuggestionSelect" [editable]="false" (selectItem)="chooseUser($event)" [ngClass]="{'is-invalid': !notificationAlert.users.length && notificationAlert.enabled && alertForm.submitted}">
            <div class="invalid-feedback">At least one email is required</div>
        </div>
        <div class="col-9 offset-3 mailing_list" *ngIf="notificationAlert.users">
            <span class="badge rounded-pill mb-1" [ngClass]="{'brand_light': user.email !== notificationAlert.defaultNotified, 'brand_default': user.email === notificationAlert.defaultNotified}" *ngFor="let user of notificationAlert.users">
                {{user.email}}
                <button type="button" class="btn close_badge" *ngIf="user.email !== notificationAlert.defaultNotified" (click)="removeUser(user.id)"><span class="material-icons">close</span></button>
            </span>
        </div>
    </div>
    <div class="text-end">
        <button type="submit" class="btn brand_default">Save</button>
    </div>
</form>
