import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationStart, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NotificationService } from '@common-ui';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LicenseService } from '../services/license.service';



@Injectable({
  providedIn: 'root'
})
export class LicenseRestrictionGuard implements CanActivate {
  licenseSubscription: Subscription;
  routeSubscription: Subscription;

  constructor(private router: Router, private licenseService: LicenseService, private notificationService: NotificationService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
      if (this.licenseSubscription instanceof Subscription) {
        this.licenseSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
      }
    });

    return new Promise<boolean>((resolve, reject) => {
      this.licenseSubscription = this.licenseService.getSessionLicense().subscribe(() => {
        if (this.licenseService.isPurchased(next.data['licenseRestriction'].itemCode, next.data['licenseRestriction'].itemType)) {
          resolve(true);
        } else {        
          this.router.navigate(['']);
          this.notificationService.showToast('Upgrade your license to access this module', { type: 'warning' });
          reject(false);
        }
      })
    })
  }
  
}
