import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { Board, GenericConnector } from '@core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ConnectorsService {
  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private env: Environment) {}

  getBoard(id): Observable<Board> {
    return this.http.get<Board>(`${this.env.API.orchestrator_configurator}/journey/${id}`);
  }
  getConnector(connectorName: string, connectorId: number): Observable<GenericConnector> {
    return this.http.get<GenericConnector>(`${this.env.API.orchestrator_configurator}/${connectorName}/${connectorId}`);
  }

  getFilteredConnectorList(params: { journeyApiKey?: string; connectorCode?: string }): Observable<any> {
    const httpOptions = {
      params: new HttpParams(),
    };
    if (params.connectorCode) httpOptions.params = httpOptions.params.append('connectorCode', params.connectorCode);
    if (params.journeyApiKey) httpOptions.params = httpOptions.params.append('journeyApiKey', params.journeyApiKey);

    return this.http
      .get(`${this.env.API.orchestrator_configurator}/license/getFilteredConnectors`, httpOptions)
      .pipe(map((connectors: any[]) => [...connectors].sort((c1, c2) => c2.name - c1.name)));
  }

  getFullHierarchy(): Observable<any> {
    return this.http.get(`${this.env.API.orchestrator_configurator}/flow/getFullHierarchy`);
  }

  addConnector(connectorName: string, data: any, params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams(),
    };

    if (params && params.code) httpOptions.params = httpOptions.params.append('code', params.code);

    return this.http.post(`${this.env.API.orchestrator_configurator}/${connectorName}`, data, httpOptions);
  }

  removeConnector(connectorName: string, connectorId: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('connectorId', connectorId.toString()),
    };
    return this.http.delete(`${this.env.API.orchestrator_configurator}/${connectorName}`, httpOptions);
  }

  editConnector(connectorName: string, data: any, params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams(),
    };

    if (params && params.clearAnalytics) httpOptions.params = httpOptions.params.append('clearAnalytics', params.clearAnalytics);
    if (params && params.code) httpOptions.params = httpOptions.params.append('code', params.code);

    return this.http.put(`${this.env.API.orchestrator_configurator}/${connectorName}`, data, httpOptions);
  }

  moveConnector({
    connectorId,
    connectorType,
    journeyOrder,
    parentChildId,
    parentId,
    parentType,
  }: {
    connectorId;
    connectorType;
    journeyOrder;
    parentChildId?;
    parentId?;
    parentType?;
  }): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('connectorId', connectorId.toString())
        .set('connectorType', connectorType.toString())
        .set('journeyOrder', journeyOrder.toString()),
    };

    if (parentChildId) httpOptions.params = httpOptions.params.append('parentChildId', parentChildId);
    if (parentId) httpOptions.params = httpOptions.params.append('parentId', parentId);
    if (parentType) httpOptions.params = httpOptions.params.append('parentType', parentType);

    return this.http.put(`${this.env.API.orchestrator_configurator}/journey/moveConnector`, {}, httpOptions);
  }

  addErrorHandlerFlow(connectorId, body) {
    return this.http.post(`${this.env.API.orchestrator_configurator}/httpError/${connectorId}/errorFlow`, body);
  }

  removeErrorHandlerFlow(connectorId, flowId) {
    const httpOptions = {
      params: new HttpParams().set('id', flowId.toString()),
    };
    return this.http.delete(`${this.env.API.orchestrator_configurator}/httpError/${connectorId}/errorFlow`, httpOptions);
  }

  getDlpInfoTypes(): Observable<any> {
    return this.http.get(`${this.env.API.orchestrator_configurator}/dlp/getGoogleInfoType`);
  }

  salesforceLogin(data: any): Observable<any> {
    return this.http.post(`${this.env.API.orchestrator_configurator}/salesforce/login`, data);
  }
  getSalesforceObjects(params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('clientId', params.clientId)
        .set('apiVersion', params.apiVersion)
        .set('clientSecret', params.clientSecret)
        .set('refreshToken', params.refreshToken)
        .set('isTicket', params.isTicket),
    };

    return this.http.get(`${this.env.API.orchestrator_configurator}/salesforce/getListOfSalesforceObjects`, httpOptions);
  }
  getSalesforceMetadataObject(params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('clientId', params.clientId)
        .set('apiVersion', params.apiVersion)
        .set('clientSecret', params.clientSecret)
        .set('refreshToken', params.refreshToken)
        .set('objectName', params.objectName),
    };

    return this.http.get(`${this.env.API.orchestrator_configurator}/salesforce/getMetadata`, httpOptions);
  }

  getTranslateLanguages(): Observable<any> {
    return this.http.get(`${this.env.API.orchestrator_configurator}/translate/languages`);
  }

  getPages(flowId): Observable<any[]> {
    const httpOptions = {
      params: new HttpParams().set('flowId', flowId),
    };
    return this.http.get<any[]>(`${this.env.API.orchestrator_configurator}/flow/pageElement/page`, httpOptions);
  }

  getRoutes(pageId) {
    const httpOptions = {
      params: new HttpParams().set('pageId', pageId),
    };
    return this.http.get<any[]>(`${this.env.API.orchestrator_configurator}/flow/pageElement/page/route`, httpOptions);
  }

  saveActionMappingAction(body): Observable<any> {
    return this.http.put(`${this.env.API.orchestrator_configurator}/actionMapping/child`, body);
  }

  deleteActionMappingAction(childId) {
    const httpOptions = {
      params: new HttpParams().set('childId', childId),
    };
    return this.http.delete(`${this.env.API.orchestrator_configurator}/actionMapping/child`, httpOptions);
  }

  getSessionEntities(): Observable<any> {
    return this.http.get(`${this.env.API.orchestrator_analysis}/nlu/integrations/entities/search`);
  }

  changeFulfilmentAgent(data: any, params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('agentId', params.agentId).set('journeyId', params.journeyId),
    };
    return this.http.put(`${this.env.API.orchestrator_configurator}/journey/changeAgent`, data, httpOptions);
  }
}
