
<div class="console_modal training_dialog conversation_interactions">
    <div class="modal-header">
        <section class="head_top">
            <h4 class="modal-title">Report details</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
                <i class="fal fa-times" aria-hidden="true"></i>
            </button>
        </section>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-auto">Report ID</div><div class="col-auto">{{reportId}}</div>
        </div>
    </div>
</div>