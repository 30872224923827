<div class="console_modal action_settings_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Action settings</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="subscriptions['_finalizers'].length === 0">
        <div class="body_content">
            <form [formGroup]="actionForm" *roleRestriction="'design.processflow.actionmapping.write'; disableElements: true">
                <section class="connector_steps">
                    <div class="row mb-3">
                        <div class="col-6">
                            <label class="form-label" for="actionName">Action name<span *ngIf="journeyService.isProductConfigured({ type: 'CX', fulfillment: true }, journey.id)"> (tag)</span> *</label>
                            <input type="text" id="actionName" class="form-control" formControlName="actionName" [ngClass]="{ 'is-invalid': actionForm.get('actionName').invalid && formSubmitted }" />
                            <div class="invalid-feedback" *ngIf="actionForm.get('actionName').errors?.required">Action name is required</div>
                        </div>
                    </div>

                    <div *ngIf="journeyService.isProductConfigured({ type: 'CX', fulfillment: false }, journey.id) && !this.journey.fulfillment" class="dynamic_fields" formArrayName="actionMappingChildElements">
                        <h6 class="fields_head">{{ actionMappingChildElementsForm.length }} mapped</h6>

                        <div class="fields_body">
                            <div class="row mb-3" *ngFor="let childElement of actionMappingChildElementsForm.controls; let i = index" [formArrayName]="i">
                                <div class="col-3">
                                    <select type="text" id="flow" formControlName="flowId" requied class="form-control" (change)="updatePageList(childElement.value.flowId, i)" [ngClass]="{ 'is-invalid': childElement.get('flowId').invalid && formSubmitted }">
                                        <option value="" disabled selected>Flow *</option>
                                        <option *ngFor="let flowEl of flowList" [value]="flowEl.id">{{ flowEl.name }}</option>
                                    </select>
                                </div>
                                <div class="col-3">
                                    <select type="text" id="page" formControlName="pageElementId" requied class="form-control" (change)="updateRouteList(childElement.value.flowId, childElement.value.pageElementId, i)" [ngClass]="{ 'is-invalid': childElement.get('pageElementId').invalid && formSubmitted }">
                                        <option value="" disabled selected>Page *</option>
                                        <option *ngFor="let pageEl of pageList[childElement.value.flowId]" [value]="pageEl.id">{{ pageEl.name }}</option>
                                    </select>
                                </div>
                                <div class="col-3">
                                    <select type="text" id="page" formControlName="transitionRouteId" requied class="form-control" [ngClass]="{ 'is-invalid': childElement.get('transitionRouteId').invalid && formSubmitted }">
                                        <option [value]="null" selected>Route</option>
                                        <option *ngFor="let routeEl of routeList[childElement.value.pageElementId]" [value]="routeEl.id">{{ routeEl.name }}</option>
                                    </select>
                                </div>

                                <div class="col-2">
                                    <ng-template #tipContent>
                                        <ng-container *ngIf="getEqualAction(i) as actionList; else noActionConfiguredTemplate">
                                            <span class="text-nowrap">Other actions:</span>
                                            <ul class="ps-3">
                                                <li *ngFor="let action of actionList" class="text-nowrap">{{ action.actionName }}, PRI: {{ action.priority }}</li>
                                            </ul>
                                        </ng-container>
                                        <ng-template #noActionConfiguredTemplate> No similar action configured</ng-template>
                                    </ng-template>
                                    <input [ngbTooltip]="tipContent" [openDelay]="500" [closeDelay]="50000" type="number" placeholder="Priority..." id="priority" class="form-control" formControlName="priority" [ngClass]="{ 'is-invalid': childElement.get('priority').invalid && formSubmitted }" />
                                </div>
                                <div class="col-1">
                                    <button class="btn" (click)="removeChildElement(i)"><i class="far fa-times-circle" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <button class="btn" (click)="addChildElement()">
                                        <small><i class="fas fa-plus" aria-hidden="true"></i> Add</small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
    </div>
    <div class="modal-footer justify-content-start">
        <ng-container  *ngIf="selectedAction?.id">
            <button type="button" class="btn btn-danger" (click)="deleteAction()" *roleRestriction="'design.processflow.actionmapping.write'; disableElements: true">Delete</button>
        </ng-container>
        <button type="button" class="btn connector_color ms-auto" (click)="saveAction()" *roleRestriction="'design.processflow.actionmapping.write'; disableElements: true">Save</button>
    </div>
</div>
