import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { RxStompService } from '@authentication';
import { NotificationService } from '@common-ui';
import { JourneyService } from '@core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CommonApiService } from '@shared';
import { first, switchMap } from 'rxjs/operators';
import { ConnectorsService } from '../../../services/connectors.service';


@Component({
  selector: 'app-delete-dialogflow-connector-modal',
  templateUrl: './delete-dialogflow-connector-modal.component.html',
  styleUrls: ['./delete-dialogflow-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteDialogflowConnectorModalComponent implements OnDestroy {
  @Output() private onDeleteConnector = new EventEmitter<any>();
  @Input() private journeyApiKey: string;
  public connector: any;

  subscriptions: object = {};
  persistentSubscriptions: object = {};

  constructor(
    public activeModal: NgbActiveModal,
    private connectorsService: ConnectorsService,
    private rxStompService: RxStompService,
    private notificationService: NotificationService,
    private journeyService: JourneyService,
    private commonApiService: CommonApiService
  ) {}

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  deleteConnector() {
    this.subscriptions['DeleteConnector'] = this.connectorsService
      .removeConnector('dialogFlow', this.connector.id)
      .pipe(switchMap(() => this.journeyService.getJourneysList()))
      .subscribe(() => {
        this.activeModal.close();
        this.onDeleteConnector.emit();
      });
  }

  exportIntents() {
    this.persistentSubscriptions['ExportIntent'] = this.rxStompService
      .exportIntents(this.journeyApiKey)
      .pipe(first())
      .subscribe((response) => {
        this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
          type: 'success',
        });
        this.commonApiService.downloadFile('intents', response.body, 'Export Intents.xlsx').subscribe();
      });
  }

  exportHistory() {
    this.persistentSubscriptions['ExportHistory'] = this.rxStompService
      .esportAgentHistory(this.journeyApiKey)
      .pipe(first())
      .subscribe((response) => {
        this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
          type: 'success',
        });
        this.commonApiService.downloadFile('history', response.body, 'Export History.zip').subscribe();
      });
  }

  exportFlows() {
    this.persistentSubscriptions['ExportFlow'] = this.rxStompService
      .exportFlows(this.journeyApiKey)
      .pipe(first())
      .subscribe((response) => {
        this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
          type: 'success',
        });
        this.commonApiService.downloadFile('flow', response.body, 'Export Flows.xlsx').subscribe();
      });
  }
}
