
<div class="console_modal sendgrid_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">SendGrid</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="connectorSubscription?.closed">
        <div class="body_content">            
            <form [formGroup]="sendgridForm" class="sendgrid_form" novalidate>
                <steps-completion [phases]="sendgridPhases" [stepsForm]="stepsForm" [onMoveStep]="moveStep" (stepsCompleted)="saveConnector($event)"></steps-completion>
                
                <section class="connector_steps" formArrayName="steps" *roleRestriction="'design.processflow.sendgrid.write'; disableElements: true">
                    <article formGroupName="0" *ngIf="sendgridPhases.current === 0">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <label for="apiKey" class="form-label">Api Key* (Get from SendGrid console)</label>
                                <input type="text" id="apiKey" class="form-control" required formControlName="apiKey" [ngClass]="{'is-invalid': stepOne.controls.apiKey.invalid && sendgridPhases.steps[0].submitted}">
                                <div class="invalid-feedback" *ngIf="stepOne.controls.apiKey.errors?.required">apiKey name is required</div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <label for="sender" class="form-label">Sender *</label>
                                <input type="email" id="sender" class="form-control" required formControlName="sender" [ngClass]="{'is-invalid': stepOne.controls.sender.invalid && sendgridPhases.steps[0].submitted}">
                                <div class="invalid-feedback" *ngIf="stepOne.controls.sender.errors?.required">Server sender is required</div>
                            </div>
                        </div>
                    </article>

                    <article formGroupName="1" *ngIf="sendgridPhases.current === 1">
                        <div class="row mb-3">
                            <div class="col-sm-12 col-md-6">
                                <label for="emailRecipent" class="form-label">Email recipient *</label>
                                <input name="emailRecipent" type="email" id="emailRecipent" class="form-control" required formControlName="recipient" [ngClass]="{'is-invalid': stepTwo.controls.recipient.invalid && sendgridPhases.steps[1].submitted}">
                                <div class="invalid-feedback" *ngIf="stepTwo.controls.recipient.errors?.required">Email recipient is required</div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <label for="emailCc" class="form-label">CC</label>
                                <input name="emailCc" type="email" id="emailCc" class="form-control" formControlName="cc">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <label for="emailCcn" class="form-label">Ccn</label>
                                <input name="emailCcn" type="email" id="emailCcn" class="form-control" formControlName="ccn">
                            </div>
                        </div>
                    </article>

                    <article formGroupName="2" *ngIf="sendgridPhases.current === 2">
                        <div class="row mb-3">
                            <div class="col-4">
                                <label for="emailSubject" class="form-label">Subject *</label>
                                <input name="emailSubject" type="text" id="emailSubject" class="form-control" required formControlName="subject" [ngClass]="{'is-invalid': stepThree.controls.subject.invalid && sendgridPhases.steps[2].submitted}">
                                <div class="invalid-feedback" *ngIf="stepThree.controls.subject.errors?.required">Email Subject is required</div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-check form-check-inline">
                                    <input name="isTemplate" type="radio" formControlName="isTemplate" id="plainText" class="form-check-input" required [value]="false" [ngClass]="{'is-invalid': stepThree.controls.isTemplate.invalid && sendgridPhases.steps[2].submitted}">
                                    <label class="form-check-label" for="plainText" >Plain Text</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input name="isTemplate" type="radio" formControlName="isTemplate" id="htmlTemplate" class="form-check-input" required [value]="true" [ngClass]="{'is-invalid': stepThree.controls.isTemplate.invalid && sendgridPhases.steps[2].submitted}">
                                    <label class="form-check-label" for="htmlTemplate">HTML template</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <textarea id="emailBody" placeholder="{{stepThree.controls.isTemplate.value ? '<html> email body...': 'Text email body...'}}" class="form-control" rows="5" required formControlName="body" [ngClass]="{'is-invalid': stepThree.controls.body.invalid && sendgridPhases.steps[2].submitted}"></textarea>
                                <div class="invalid-feedback" *ngIf="stepThree.controls.body.errors?.required">Body is required</div>
                            </div>
                        </div>
                        <div class="dynamic_fields" *ngIf="stepThree.controls.isTemplate.value">
                            <h6 class="fields_head">Template Keys</h6>
                            <div class="alert alert-light" role="alert" *ngIf="sendgridTemplateKeys.length === 0">No Template Keys are set</div>

                            <div class="fields_body" formArrayName="templateKeys">
                                <div class="row" *ngFor="let key of sendgridTemplateKeys.controls; let i = index; let last = last" [ngClass]="{'mb-3': !last}">
                                    <div class="col-5" [formGroupName]="i">
                                        <input type="text" class="form-control" placeholder="Key" formControlName="key" [ngClass]="{'is-invalid': sendgridTemplateKeys.get([i, 'key']).invalid && sendgridPhases.steps[2].submitted}">
                                        <div class="invalid-feedback" *ngIf="sendgridTemplateKeys.get([i, 'key']).errors?.required">Key is required</div>
                                    </div>
                                    <div class="col-6" [formGroupName]="i">
                                        <input type="text" class="form-control" placeholder="Value" formControlName="value" [ngClass]="{'is-invalid': sendgridTemplateKeys.get([i, 'value']).invalid && sendgridPhases.steps[2].submitted}">
                                        <div class="invalid-feedback" *ngIf="sendgridTemplateKeys.get([i, 'value']).errors?.required">Value is required</div>
                                    </div>
                                    <div class="col-1">
                                        <button class="btn" (click)="sendgridTemplateKeys.removeAt(i)"><i class="far fa-times-circle" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                            <button class="btn" (click)="addTemplateKey()"><small><i class="fas fa-plus" aria-hidden="true"></i> Add</small></button>
                        </div>
                    </article>
                </section>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="nextStep()" [hidden]="sendgridPhases.current === 2">Next</button>
        <button type="button" class="btn" (click)="nextStep()" [hidden]="sendgridPhases.current != 2" *roleRestriction="'design.processflow.sendgrid.write'; disableElements: true">Save</button>
    </div>
</div>
