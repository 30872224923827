
<div class="console_modal user_settings_dialog">
    <div class="modal-header">
        <h4 class="modal-title">General settings</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-3">
                <div class="btn-group settings_menu" role="group">
                    <input type="radio" [(ngModel)]="settingsMenu" name="menuFields" value="profile" class="btn-check" id="profileRadio" autocomplete="off" >
                    <label class="btn" for="profileRadio"><i class="fal fa-user-circle" aria-hidden="true"></i>Profile</label>
                    <input type="radio" [(ngModel)]="settingsMenu" name="menuFields" value="license" class="btn-check" id="licenseRadio" autocomplete="off" >
                    <label class="btn" for="licenseRadio"><i class="fal fa-medal" aria-hidden="true"></i>License</label>
                    <ng-container *roleRestriction="['admin.user.view', 'admin.user.editor']">
                        <input type="radio" [(ngModel)]="settingsMenu" name="menuFields" value="email" class="btn-check" id="emailRadio" autocomplete="off" >
                        <label class="btn" for="emailRadio"><i class="fal fa-envelope" aria-hidden="true"></i>Email alerts</label>
                    </ng-container>
                </div>
            </div>
            <div class="col" [ngSwitch]="settingsMenu">
                <ng-container *ngSwitchCase="'profile'">
                    <setting-profile></setting-profile>
                </ng-container>
                <ng-container *ngSwitchCase="'email'">
                    <setting-alerts></setting-alerts>
                </ng-container>
                <ng-container *ngSwitchCase="'license'">
                    <setting-license></setting-license>
                </ng-container>
            </div>
        </div>
    </div>
</div>
