import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss'],
})
export class SupportModalComponent implements OnDestroy {
  @ViewChild('supportForm', { static: true }) supportForm: NgForm;
  @Input() support?: any = {};

  supportSubscription: Subscription;

  constructor(public activeModal: NgbActiveModal, private notificationService: NotificationService) {}

  ngOnDestroy() {
    if (this.supportSubscription instanceof Subscription) this.supportSubscription.unsubscribe();
  }

  sendRequest() {
    if (this.supportForm.invalid) return;

    this.supportSubscription = this.notificationService.sendSupportRequest(this.support).subscribe(() => {
      this.activeModal.close();
      this.notificationService.showToast('Request correctly sended', { type: 'success' });
    });
  }
}
