<div class="row">
    <div class="col-12">
        <div class="row">
            <div id="sessionsFilters" class="col-12">
                <analytics-filter [allLanguages]="true" (getStatistics)="getStatistics($event)"></analytics-filter>
            </div>
        </div>

        <div class="row">
            <div class="col-12 statistics_box">
                <dl class="stats_list">
                    <ng-template [spinnerLoader]="subscriptions['ConversationsStatistics']?.closed" [size]="'sm'"></ng-template>
                    <dt ngbTooltip="{{ toolTipsSession.totalSessions }}" container="body" tooltipClass="analytics_tooltip">Total sessions</dt>
                    <dd>{{ conversationsStatistics?.total | number: '1.0-2' }}</dd>
                </dl>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-12 line_chart_box">
                <ng-template [spinnerLoader]="subscriptions['ConversationsStatistics']?.closed" [size]="'sm'"></ng-template>
                <line-chart [labels]="chartLabels" [dataset]="chartDataset"> </line-chart>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-12 col-lg-auto col-xl-auto statistics_box">
                <dl class="stats_list">
                    <ng-template [spinnerLoader]="subscriptions['SingleInteractionSessions']?.closed" [size]="'sm'"></ng-template>
                    <dt ngbTooltip="{{ toolTipsSession.singleSession }}" container="body" tooltipClass="analytics_tooltip">Single interactions</dt>
                    <dd>{{ singleInteractionSessions | number: '1.0-2' }}</dd>
                </dl>
            </div>
            <div class="col-12 col-lg-auto col-xl-auto statistics_box">
                <dl class="stats_list">
                    <ng-template [spinnerLoader]="subscriptions['MultipleInteractionSessions']?.closed" [size]="'sm'"></ng-template>
                    <dt ngbTooltip="{{ toolTipsSession.multipleSession }}" container="body" tooltipClass="analytics_tooltip">Multiple interactions</dt>
                    <dd>{{ multipleInteractionSessions | number: '1.0-2' }}</dd>
                </dl>
            </div>
            <div class="col-12 col-lg-auto col-xl-auto statistics_box">
                <dl class="stats_list">
                    <ng-template [spinnerLoader]="subscriptions['InteractionsAverageSession']?.closed" [size]="'sm'"></ng-template>
                    <dt ngbTooltip="{{ toolTipsSession.messagesPerSession }}" container="body" tooltipClass="analytics_tooltip">Message per sessions</dt>
                    <dd>{{ interactionsAverageSession | number: '1.0-2' }}</dd>
                </dl>
            </div>
            <div class="col-12 col-lg-auto col-xl-auto statistics_box">
                <dl class="stats_list">
                    <ng-template [spinnerLoader]="subscriptions['InteractionsAverageDuration']?.closed" [size]="'sm'"></ng-template>
                    <dt ngbTooltip="{{ toolTipsSession.sessionDuration }}" container="body" tooltipClass="analytics_tooltip">Session duration</dt>
                    <dd>{{ interactionsAverageDuration * 60 * 1000 | date: "m'm' ss's'" }}</dd>
                </dl>
            </div>
        </div>
        <div class="row justify-content-around">
            <div class="col-xl-4 col-6" id="channelsPie">
                <div [spinnerLoader]="subscriptions['InteractionsAgentChannel']?.closed"></div>
                <div class="doughnut_chart_box">
                    <doughnut-chart [dataset]="channelsDataset" [legend]="true" chartTitle="Channel"></doughnut-chart>
                    <header class="box_head chart_box_title" ngbTooltip="{{ toolTipsSession.channels }}" container="body"></header>
                </div>
            </div>
            <div class="col-xl-4 col-6 chart_box" id="popularTopicsPie" *journeyRestriction="'ES'">
                <div class="doughnut_chart_box">
                    <div [spinnerLoader]="subscriptions['PopularTopicsAnalytics']?.closed"></div>
                    <doughnut-chart [dataset]="popularTopicDataset" [legend]="true" chartTitle="Popular Topics"></doughnut-chart>
                    <header class="box_head chart_box_title" ngbTooltip="{{ toolTipsSession.popularTopics }}" container="body"></header>
                </div>
            </div>

            <div class="col-xl-4 col-6 chart_box" id="languagesPie">
                <div class="doughnut_chart_box">
                    <div [spinnerLoader]="subscriptions['PercentageLang']?.closed"></div>
                    <doughnut-chart [dataset]="languagesDataset" [legend]="true" chartTitle="Languages"></doughnut-chart>
                    <header class="box_head chart_box_title" ngbTooltip="{{ toolTipsSession.popularTopics }}" container="body"></header>
                </div>
            </div>
        </div>
    </div>
</div>
