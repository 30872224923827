import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ConnectorsBoardComponent } from './components/connectors-board/connectors-board.component';
import { ActionMappingConnectorComponent } from './components/connectors/action-mapping-connector/action-mapping-connector.component';
import { ActionSettingsModalComponent } from './components/connectors/action-mapping-connector/action-settings-modal/action-settings-modal.component';
import { CodeEditorSidebarComponent } from './components/connectors/code-editor-sidebar/code-editor-sidebar.component';
import { DataProcessingConnectorModalComponent } from './components/connectors/data-processing-connector-modal/data-processing-connector-modal.component';
import { DataTransferConnectorModalComponent } from './components/connectors/data-transfer-connector-modal/data-transfer-connector-modal.component';
import { DeleteDialogflowConnectorModalComponent } from './components/connectors/delete-dialogflow-connector-modal/delete-dialogflow-connector-modal.component';
import { DialogflowConnectorModalComponent } from './components/connectors/dialogflow-connector-modal/dialogflow-connector-modal.component';
import { DispatcherConnectorModalComponent } from './components/connectors/dispatcher-connector-modal/dispatcher-connector-modal.component';
import { DlpConnectorModalComponent } from './components/connectors/dlp-connector-modal/dlp-connector-modal.component';
import { ErrorHandlerConnectorSidebarComponent } from './components/connectors/error-handler-connector-sidebar/error-handler-connector-sidebar.component';
import { ErrorHandlerItemComponent } from './components/connectors/error-handler-connector-sidebar/error-handler-item/error-handler-item.component';
import { EventCallerConnectorModalComponent } from './components/connectors/event-caller-connector-modal/event-caller-connector-modal.component';
import { CxGenericRuleFormComponent } from './components/connectors/generic-rule-modal/cx-generic-rule-form/cx-generic-rule-form.component';
import { EsGenericRuleFormComponent } from './components/connectors/generic-rule-modal/es-generic-rule-form/es-generic-rule-form.component';
import { GenericRuleModalComponent } from './components/connectors/generic-rule-modal/generic-rule-modal.component';
import { HttpConnectorModalComponent } from './components/connectors/http-connector-modal/http-connector-modal.component';
import { NluConnectorModalComponent } from './components/connectors/nlu-connector-modal/nlu-connector-modal.component';
import { SalesforceAuthStepComponent } from './components/connectors/salesforce-connector-modal/salesforce-auth-step/salesforce-auth-step.component';
import { SalesforceConnectorModalComponent } from './components/connectors/salesforce-connector-modal/salesforce-connector-modal.component';
import { SendgridConnectorModalComponent } from './components/connectors/sendgrid-connector-modal/sendgrid-connector-modal.component';
import { SidebarConnectorContainerComponent } from './components/connectors/sidebar-connector-container/sidebar-connector-container.component';
import { SmtpConnectorModalComponent } from './components/connectors/smtp-connector-modal/smtp-connector-modal.component';
import { TranslateConnectorModalComponent } from './components/connectors/translate-connector-modal/translate-connector-modal.component';
import { TrooveConnectorModalComponent } from './components/connectors/troove-connector-modal/troove-connector-modal.component';
import { TwilioSmsModalComponent } from './components/connectors/twilio-sms-modal/twilio-sms-modal.component';
import { ZendeskConnectorModalComponent } from './components/connectors/zendesk-connector-modal/zendesk-connector-modal.component';
import { DragPlaceholderComponent } from './components/drag-placeholder/drag-placeholder.component';
import { DragPreviewComponent } from './components/drag-preview/drag-preview.component';
import { JourneyModalComponent } from './components/journey-modal/journey-modal.component';
import { ProcessFlowComponent } from './components/process-flow/process-flow.component';
import { ProductsSidebarComponent } from './components/products-sidebar/products-sidebar.component';
import { SalesforceComponent } from './components/salesforce/salesforce.component';
import { ProcessFlowRoutingModule } from './process-flow-routing.module';
import { ConnectorsService } from './services/connectors.service';
import { RasaConnectorModalComponent } from './components/connectors/rasa-connector-modal/rasa-connector-modal.component';
import { RsGenericRuleFormComponent } from './components/connectors/generic-rule-modal/rs-generic-rule-form/rs-generic-rule-form.component';

@NgModule({
  declarations: [
    SalesforceComponent,
    ActionSettingsModalComponent,
    CodeEditorSidebarComponent,
    ConnectorsBoardComponent,
    ActionMappingConnectorComponent,
    DataProcessingConnectorModalComponent,
    DataTransferConnectorModalComponent,
    DeleteDialogflowConnectorModalComponent,
    DialogflowConnectorModalComponent,
    DispatcherConnectorModalComponent,
    DlpConnectorModalComponent,
    ErrorHandlerConnectorSidebarComponent,
    EventCallerConnectorModalComponent,
    CxGenericRuleFormComponent,
    EsGenericRuleFormComponent,
    GenericRuleModalComponent,
    HttpConnectorModalComponent,
    NluConnectorModalComponent,
    SalesforceAuthStepComponent,
    SalesforceConnectorModalComponent,
    SendgridConnectorModalComponent,
    SidebarConnectorContainerComponent,
    SmtpConnectorModalComponent,
    TranslateConnectorModalComponent,
    TrooveConnectorModalComponent,
    TwilioSmsModalComponent,
    ZendeskConnectorModalComponent,
    ProcessFlowComponent,
    DragPlaceholderComponent,
    DragPreviewComponent,
    ProductsSidebarComponent,
    JourneyModalComponent,
    ErrorHandlerItemComponent,
    RasaConnectorModalComponent,
    RsGenericRuleFormComponent,
  ],
  imports: [
    ProcessFlowRoutingModule,
    SharedModule,
    NgxSliderModule,
    NgxGraphModule,
    DragDropModule,
    CarouselModule,
  ],
  providers: [ConnectorsService],
})
export class ProcessFlowModule {}
