import { animate, query, style, transition, trigger } from '@angular/animations';
import { Component, ComponentRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ContainerSelectorDirective } from '@shared';

@Component({
  selector: 'app-sidebar-connector-container',
  templateUrl: './sidebar-connector-container.component.html',
  styleUrls: ['./sidebar-connector-container.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        query('.sidebar_container', style({ transform: 'translateX(100%)', 'box-shadow': 'none' })),
        query('.sidebar_container', animate('500ms', style({ transform: 'translateY(0)' }))),
      ]),
      transition(':leave', [query('.sidebar_container', animate('500ms', style({ transform: 'translateX(100%)', 'box-shadow': 'none' })))]),
    ]),
  ],
  host: { '[@slideInOut]': 'true' },
  encapsulation: ViewEncapsulation.None,
})
export class SidebarConnectorContainerComponent implements OnInit {
  public connector: any;
  public connectorInstance: any;
  @ViewChild(ContainerSelectorDirective, { static: true }) connectorContainer!: ContainerSelectorDirective;

  @Output() closed = new EventEmitter<any>();

  ngOnInit(): void {
    const viewContainerRef = this.connectorContainer.viewContainerRef;
    viewContainerRef.clear();

    const componentRef: ComponentRef<any> = viewContainerRef.createComponent(this.connectorInstance);
    componentRef.instance.connector = this.connector;
    componentRef.instance.closed.subscribe(($event) => this.closed.emit($event));
  }

  close() {
    this.closed.emit({ refresh: true });
  }
}
