<ng-container [formGroup]="form">
    <div class="row mb-3">
        <label class="form-label">
            <div class="form-check form-check-inline mb-0">
                <input type="radio" id="orClause" class="form-check-input" value="OR" formControlName="operator" />
                <label for="orClause" class="form-check-label">Match <strong>AT LEAST ONE</strong> rule (OR) </label>
            </div>
            <div class="form-check form-check-inline mb-0">
                <input type="radio" id="andClause" class="form-check-input" value="AND" formControlName="operator" />
                <label for="andClause" class="form-check-label">Match <strong>EVERY</strong> rule (AND)</label>
            </div>
        </label>
    </div>
    <ng-container formArrayName="conditions">
        <div class="row" [ngClass]="lastCondition ? 'mb-2' : 'mb-3'"
            *ngFor="let conditionForm of conditionsArray.controls; let conditionIndex = index; let lastCondition = last"
            [formGroupName]="conditionIndex">
            <div class="col-auto pe-0 pt-2">If</div>
            <div class="col-xl-4 col-3">
                <input #input name="ruleName" type="text" id="ruleName" placeholder="Field type...*"
                    class="form-control" formControlName="field"
                    [ngClass]="{ 'is-invalid': conditionForm.get('field').invalid && formSubmitted }"
                    (selectItem)="onSelectSuggestion(input, conditionForm.get('field'), $event)"
                    [ngbTypeahead]="typeSuggestions" />
                <div class="invalid-feedback" *ngIf="conditionForm.get('field').invalid">Field is required</div>
            </div>
            <div class="col-auto px-0 pt-2">is</div>
            <div class="col-auto flex-grow-1">
                <select id="dialogflowAgent" class="form-select" formControlName="clause"
                    [ngClass]="{ 'is-invalid': conditionForm.get('clause').invalid && formSubmitted }">
                    <option value="" disabled>Operand...*</option>
                    <option value="IS_PRESENT" [hidden]="[fieldKeys.landedPage, fieldKeys.transitionPage, fieldKeys.route].indexOf(conditionForm.get('field').value) !== -1">is present</option>
                    <option value="EQUAL" [hidden]="[fieldKeys.landedPage, fieldKeys.transitionPage, fieldKeys.route].indexOf(conditionForm.get('field').value) !== -1">=</option>
                    <option value="NOT_EQUAL" [hidden]="[fieldKeys.landedPage, fieldKeys.transitionPage, fieldKeys.route].indexOf(conditionForm.get('field').value) !== -1">!=</option>
                    <option value="MAJOR" [hidden]="[fieldKeys.landedPage, fieldKeys.transitionPage, fieldKeys.route].indexOf(conditionForm.get('field').value) !== -1">></option>
                    <option value="MAJOR_EQUAL" [hidden]="[fieldKeys.landedPage, fieldKeys.transitionPage, fieldKeys.route].indexOf(conditionForm.get('field').value) !== -1">>=</option>
                    <option value="MINOR" [hidden]="[fieldKeys.landedPage, fieldKeys.transitionPage, fieldKeys.route].indexOf(conditionForm.get('field').value) !== -1">< </option>
                    <option value="MINOR_EQUAL" [hidden]="[fieldKeys.landedPage, fieldKeys.transitionPage, fieldKeys.route].indexOf(conditionForm.get('field').value) !== -1"><= </option>
                    <option value="IN" [hidden]="[fieldKeys.sentiment, fieldKeys.magnitude, '$[]'].indexOf(conditionForm.get('field').value) !== -1">IN</option>
                </select>
                <div class="invalid-feedback" *ngIf="conditionForm.get('clause').invalid">Operand is required</div>
            </div>
            <div class="col-4">
                <div class="position-relative">
                    <div *ngIf="[fieldKeys.route, fieldKeys.transitionPage, fieldKeys.landedPage].indexOf(conditionForm.get('field').value) === -1; else flowTemplate">
                        <input name="valueCondition"
                            [type]="[fieldKeys.sentiment, fieldKeys.magnitude].indexOf(conditionForm.get('field').value) !== -1 ? 'number' : 'text'"
                            id="valueCondition" placeholder="Value...*" class="form-control" formControlName="value"
                            [ngClass]="{ 'is-invalid': conditionForm.get('value').invalid && formSubmitted }" />
                        <div class="invalid-feedback" *ngIf="conditionForm.get('value').errors?.required">Value is required</div>
                    </div>
                    <ng-template #flowTemplate>
                        <input autocomplete="off" type="text" id="valueCondition_{{conditionIndex}}" class="form-control"
                            [ngbPopover]="flowItems" #popFlows="ngbPopover" triggers="manual"
                            [popoverClass]="'popover_flows'" [placement]="'bottom-left'" [autoClose]="'outside'"
                            (click)="openPopValues(popFlows, conditionForm)"
                            [attr.placeholder]="getPlaceholder(conditionForm.get('field').value, popFlows, conditionForm.get('value'))"
                            [ngClass]="{ 'is-invalid': conditionForm.get('value').invalid && formSubmitted }" />
                        <div class="invalid-feedback" *ngIf="conditionForm.get('value').errors?.required">{{conditionForm.get('field').value}} is required</div>
                    </ng-template>
                </div>
            </div>
            <div class="col-auto ps-0">
                <button *ngIf="conditionsArray.length > 1" (click)="conditionsArray.removeAt(conditionIndex)"class="btn px-0">
                    <i class="far fa-times-circle" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </ng-container>
    <div class="col-12 mb-3">
        <button class="btn p-0" (click)="conditionsArray.push(conditionFormBuilder)">
            <small class="connector_color"><i class="fas fa-plus" aria-hidden="true"></i> Add condition</small>
        </button>
    </div>
</ng-container>

<ng-template #flowItems let-conditionForm="conditionForm">
    <header class="pop_head" *ngIf="flowHierarchy.path?.flow">
        <button type="button" class="btn back" (click)="hierarchyBack()">
            <i class="far fa-arrow-left" aria-hidden="true"></i>
        </button>
        <div class="head_path">
            {{flowHierarchy.path.flow}} <span class="path_page" *ngIf="flowHierarchy.path.page">> {{flowHierarchy.path.page}}</span>
        </div>
        <button type="button" class="btn" (click)="selectAll(conditionForm)" *ngIf="(conditionForm.get('field').value === fieldKeys.landedPage || fieldKeys.transitionPage) && !flowHierarchy.path.page">
            <small>{{ getItemsCount(conditionForm.get('value')) === flowHierarchy.pages.length ? 'Deselect All' : 'Select All' }}</small>
        </button>
        <button type="button" class="btn" (click)="selectAll(conditionForm)" *ngIf="conditionForm.get('field').value === fieldKeys.route && flowHierarchy.path.page">
            <small>{{ getItemsCount(conditionForm.get('value')) === flowHierarchy.routes.length ? 'Deselect All' : 'Select All' }}</small>
        </button>
    </header>

    <ul class="list-unstyled pop_list flows" *ngIf="!flowHierarchy.pages.length && !flowHierarchy.routes.length">
        <li *ngFor="let flow of flows">
            <button type="button" class="btn" (click)="getPages(flow)" [ngClass]="{'fw-bold': isActivePath(conditionForm, flow, 'flow')}">{{flow.name}}</button>
        </li>
    </ul>

    <ul class="list-unstyled pop_list pages" *ngIf="flowHierarchy.pages.length && !flowHierarchy.routes.length">
        <li *ngFor="let page of flowHierarchy.pages">
            <button type="button" class="btn" (click)="getRoutes(page)" *ngIf="[fieldKeys.landedPage, fieldKeys.transitionPage].indexOf(conditionForm.get('field').value) === -1; else pageSelection" [ngClass]="{'fw-bold': isActivePath(conditionForm, page, 'page')}">{{page.name}}</button>
            <ng-template #pageSelection>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="{{page.name}}" [checked]="isItemSelected(conditionForm.get('value').value, page.dialogflowId)" (change)="toggleItem(conditionForm.get('value'), page.dialogflowId)" />
                    <label class="form-check-label" for="{{page.name}}">{{page.name}}</label>
                </div>
            </ng-template>
        </li>
    </ul>

    <ul class="list-unstyled pop_list routes" *ngIf="flowHierarchy.routes.length && conditionForm.get('field').value === fieldKeys.route">
        <li *ngFor="let route of flowHierarchy.routes">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="{{ route.name }}" [checked]="isItemSelected(conditionForm.get('value').value, route.dialogflowId)" (change)="toggleItem(conditionForm.get('value'), route.dialogflowId)" />
                <label class="form-check-label" for="{{ route.name }}">{{route.name}}</label>
            </div>
        </li>
    </ul>
</ng-template>