<ng-container [formGroup]="form">
    <div class="row mb-3">
        <label class="form-label">
            <div class="form-check form-check-inline mb-0">
                <input type="radio" id="orClause" class="form-check-input" value="OR" formControlName="operator" />
                <label for="orClause" class="form-check-label">Match <strong>AT LEAST ONE</strong> rule (OR) </label>
            </div>
            <div class="form-check form-check-inline mb-0">
                <input type="radio" id="andClause" class="form-check-input" value="AND" formControlName="operator" />
                <label for="andClause" class="form-check-label">Match <strong>EVERY</strong> rule (AND)</label>
            </div>
        </label>
    </div>
    <ng-container formArrayName="conditions">
        <div class="row align-items-center" [ngClass]="lastCondition ? 'mb-2' : 'mb-3'" *ngFor="let conditionForm of conditionsArray.controls; let conditionIndex = index; let lastCondition = last" [formGroupName]="conditionIndex">
            <div class="col-auto pe-0">If</div>
            <div class="col-xl-4 col-3">
                <input
                    #input
                    name="ruleName"
                    type="text"
                    id="ruleName"
                    placeholder="Field type...*"
                    class="form-control"
                    formControlName="field"
                    [ngClass]="{ 'is-invalid': conditionForm.get('field').invalid && formSubmitted }"
                    (selectItem)="onSelectSuggestion(input, conditionForm.get('field'), $event)"
                    [ngbTypeahead]="typeSuggestions"
                />
                <div class="invalid-feedback" *ngIf="conditionForm.get('field').invalid">Field is required</div>
            </div>
            <div class="col-auto px-0">is</div>
            <div class="col-auto flex-grow-1">
                <select id="dialogflowAgent" class="form-select" formControlName="clause" [ngClass]="{ 'is-invalid': conditionForm.get('clause').invalid && formSubmitted }">
                    <option value="" disabled>Operand...*</option>
                    <option value="IS_PRESENT" [hidden]="[fieldKeys.intent, fieldKeys.topic].indexOf(conditionForm.get('field').value) !== -1">is present</option>
                    <option value="EQUAL" [hidden]="[fieldKeys.intent, fieldKeys.topic].indexOf(conditionForm.get('field').value) !== -1">=</option>
                    <option value="NOT_EQUAL" [hidden]="[fieldKeys.action, fieldKeys.intent, fieldKeys.topic].indexOf(conditionForm.get('field').value) !== -1">!=</option>
                    <option value="MAJOR" [hidden]="[fieldKeys.action, fieldKeys.intent, fieldKeys.topic].indexOf(conditionForm.get('field').value) !== -1">></option>
                    <option value="MAJOR_EQUAL" [hidden]="[fieldKeys.action, fieldKeys.intent, fieldKeys.topic].indexOf(conditionForm.get('field').value) !== -1">>=</option>
                    <option value="MINOR" [hidden]="[fieldKeys.action, fieldKeys.intent, fieldKeys.topic].indexOf(conditionForm.get('field').value) !== -1"><</option>
                    <option value="MINOR_EQUAL" [hidden]="[fieldKeys.action, fieldKeys.intent, fieldKeys.topic].indexOf(conditionForm.get('field').value) !== -1"><=</option>
                    <option value="IN" [hidden]="[fieldKeys.sentiment, fieldKeys.magnitude, fieldKeys.action, fieldKeys.param, fieldKeys.context, fieldKeys.cache].indexOf(conditionForm.get('field').value) !== -1">IN</option>
                </select>
                <div class="invalid-feedback" *ngIf="conditionForm.get('clause').invalid">Operand is required</div>
            </div>
            <div class="col-4">
                <div *ngIf="[fieldKeys.intent, fieldKeys.topic].indexOf(conditionForm.get('field').value) === -1; else itemsTemplate">
                    <input
                        name="valueCondition"
                        [type]="[fieldKeys.sentiment, fieldKeys.magnitude].indexOf(conditionForm.get('field').value) !== -1 ? 'number' : 'text'"
                        id="valueCondition"
                        placeholder="Value...*"
                        class="form-control"
                        formControlName="value"
                        [ngClass]="{ 'is-invalid': conditionForm.get('value').invalid && formSubmitted }"
                    />
                    <div class="invalid-feedback" *ngIf="conditionForm.get('value').errors?.required">Value is required</div>
                </div>
                <ng-template #itemsTemplate>
                    <form *ngIf="items$[conditionForm.get('field').value] | async as items">
                        <input
                            autocomplete="off"
                            [(ngModel)]="itemFilter"
                            name="ruleIntent"
                            type="text"
                            id="ruleIntent"
                            class="form-control"
                            [ngbPopover]="popItemsContent"
                            triggers="manual"
                            #popItems="ngbPopover"
                            [popoverClass]="'popover_intents'"
                            [placement]="'bottom-left'"
                            [autoClose]="'outside'"
                            (click)="popItems.open({ fieldValue: conditionForm.get('field').value, items: items, form: conditionForm.get('value') }); itemFilter = ''"
                            [attr.placeholder]="getPlaceholder(conditionForm.get('field').value, popItems, conditionForm.get('value'))"
                        />
                        <small class="text-warning" *ngIf="connector.intentNames?.length === items?.length" ngbTooltip="If you want to apply a behavior to all intents, you don't need to use a rule. You can consider deselecting some intents or removing the rule.">
                            <i class="fas fa-exclamation-triangle" aria-hidden="true"></i> This rule is applied to all intents
                        </small>
                    </form>
                </ng-template>
            </div>
            <div class="col-auto ps-0">
                <button *ngIf="conditionsArray.length > 1" (click)="conditionsArray.removeAt(conditionIndex)" class="btn px-0"><i class="far fa-times-circle" aria-hidden="true"></i></button>
            </div>
        </div>
    </ng-container>
    <div class="col-12 mb-3">
        <button class="btn p-0" (click)="conditionsArray.push(conditionFormBuilder)">
            <small class="connector_color"><i class="fas fa-plus" aria-hidden="true"></i> Add condition</small>
        </button>
    </div>
</ng-container>
<ng-template #popItemsContent let-items="items" let-form="form" let-fieldValue="fieldValue">
    <ul class="list-unstyled pop_list">
        <li *ngFor="let item of items | fullTextSearch: itemFilter">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="{{ item }}" [checked]="isItemSelected(form, item)" (change)="toggleItem(form, item)" />
                <label class="form-check-label" for="{{ item }}">{{ item }}</label>
            </div>
        </li>
    </ul>
    <footer class="pop_bottom">
        <span class="text-muted">{{ getItemsCount(form) }} selected {{ fieldValue === fieldKeys.intent ? 'intent' : 'topic' }}</span>
        <button type="button" class="btn" (click)="selectAll(form, items)">
            {{ getItemsCount(form) === items.length ? 'Deselect All' : 'Select All' }}
        </button>
    </footer>
</ng-template>
