import { CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericConnector, Product } from '@core';
import { CommonService } from '@shared';
import { Subject } from 'rxjs';

@Component({
  selector: 'products-sidebar',
  templateUrl: './products-sidebar.component.html',
  styleUrls: ['./products-sidebar.component.scss'],
})
export class ProductsSidebarComponent implements OnInit {
  @Input() onConnectorDropped: Subject<any>;
  @Input() connectedList;
  @Input() set products(value: Product[]) {
    if (value?.length > 0) {
      this.productsMap = this.commonService.groupBy(value, 'category');
    }
  }
  @Output() addProduct: EventEmitter<Product> = new EventEmitter();
  movingConnector;

  productsMap: { [key: string]: GenericConnector[] } = {};

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.onConnectorDropped.subscribe(() => (this.movingConnector = null));
  }
  startMove(event: CdkDragStart<GenericConnector>) {
    this.movingConnector = event.source.data;
  }
  addToBoard(product) {
    this.addProduct.emit(product);
  }
}
