<div class="interaction_details col-12">
    <div class="row mb-3" id="userInputWrapper" *ngIf="showUserSays(interaction)">
        <label for="" class="col-3 col-form-label">User says</label>
        <div class="col-9">
            <div class="input_phrase">
                <ng-container *journeyRestriction="['ES', 'CX']">
                    <div class="entity_pop_toggle" [ngbPopover]="popEntityContent" triggers="manual" #popEntity="ngbPopover" [popoverClass]="'popover_entity'" [placement]="'top'" [autoClose]="'outside'" [ngStyle]="{ left: entityPopOffset }"></div>
                    <div
                        [attr.contenteditable]="interaction.forwardNlu == null && !forbiddenTraining"
                        class="form-control input_entity"
                        [ngClass]="{ disabled: interaction.forwardNlu != null || forbiddenTraining }"
                        [entitySelection]="interaction.editedDfEntityJson"
                        [userQuery]="interaction.editedUserQuery"
                        [updateEntities]="onUpdateEntities"
                        (entitySelected)="togglePopEntity($event)"
                        *ngIf="!uneditableView"
                        (input)="updateUserQuery($event.target)"
                    >
                        {{ interaction.editedUserQuery }}
                    </div>
                    <div class="form-control input_entity disabled" *ngIf="uneditableView" [entitySelection]="interaction.dfEntitiesJson" [userQuery]="interaction.userQuery">{{ interaction.userQuery }}</div>
                </ng-container>
                <ng-container *journeyRestriction="'RS'">
                <div
                        [attr.contenteditable]="interaction.forwardNlu == null && !forbiddenTraining"
                        class="form-control input_entity"
                        [ngClass]="{ disabled: interaction.forwardNlu != null || forbiddenTraining }"
                        [entitySelection]="interaction.editedDfEntityJson"
                        [userQuery]="interaction.editedUserQuery"
                        *ngIf="!uneditableView"
                        (input)="updateUserQuery($event.target)"
                    >
                        {{ interaction.editedUserQuery }}
                    </div>
                    <div class="form-control input_entity disabled" *ngIf="uneditableView" [entitySelection]="interaction.dfEntitiesJson" [userQuery]="interaction.userQuery">{{ interaction.userQuery }}</div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row mb-3" *ngIf="showEvent(interaction)">
        <label class="col-3 col-form-label">Event name</label>
        <div class="col-9 d-flex align-items-center">
            <span class="interaction_context">
                {{ interaction.dfEventName }}
            </span>
        </div>
    </div>
    <div class="row mb-3">
        <div class="offset-3 col-9">
            <div class="table-responsive mt-3" *ngIf="showParameter && viewFilters.parameters">
                <table class="table parameters_table" aria-describedby="Parameters Table">
                    <thead>
                        <tr>
                            <th scope="col">Parameter Name</th>
                            <th scope="col">Resolved Value</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="!uneditableView">
                            <tr *ngFor="let parameter of interaction.editedDfEntityJson | keyvalue: orderBlank; let i = index">
                                <td [ngClass]="{ last_parameter: isLastParameter(interaction.editedDfEntityJson, i) }">
                                    <span class="matches_{{ i }}">{{ parameter.key }}</span>
                                </td>
                                <td [ngClass]="{ last_parameter: isLastParameter(interaction.editedDfEntityJson, i) }">{{ parameter.value | json }}</td>
                                <td class="remove_parameter" [ngClass]="{ last_parameter: isLastParameter(interaction.editedDfEntityJson, i) }">
                                    <button class="btn p-0" (click)="removeParameter(interaction, parameter.key)" *ngIf="interaction.forwardNlu == null && !forbiddenTraining"><i class="fas fa-times" aria-hidden="true"></i></button>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="uneditableView">
                            <tr *ngFor="let parameter of interaction.dfEntitiesJson | keyvalue: orderBlank; let i = index">
                                <td [ngClass]="{ last_parameter: isLastParameter(interaction.dfEntitiesJson, i) }">
                                    <span class="matches_{{ i }}">{{ parameter.key }}</span>
                                </td>
                                <td colspan="2" [ngClass]="{ last_parameter: isLastParameter(interaction.dfEntitiesJson, i) }">{{ parameter.value | json }}</td>
                            </tr>
                        </ng-container>
                        <tr class="context_parameters" *ngFor="let parameter of getContextParameters(interaction.dfOutputContextJson) | keyvalue">
                            <td>{{ parameter.key }}</td>
                            <td colspan="2">{{ parameter.value | json }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-3" id="intentWrapper" *ngIf="showIntent(interaction)">
        <label for="" class="col-3 col-form-label">Intent</label>
        <div class="col-9 d-flex align-items-center">
            <a
                id="trainingIntent"
                href="javascript:void(0)"
                class="edit_intent"
                [ngClass]="{ disable_intent_changing: !trainableIntent(interaction) }"
                [ngbPopover]="popIntentContent"
                triggers="manual"
                #popIntent="ngbPopover"
                [popoverClass]="'popover_intent'"
                [placement]="'right-top'"
                [autoClose]="'outside'"
                [disablePopover]="!trainableIntent(interaction) || forbiddenTraining"
                (click)="togglePopIntent()"
            >
                <span *ngIf="!uneditableView">{{ interaction.editedDfIntentName || 'Select correct intent' }}</span>
                <span *ngIf="uneditableView">{{ interaction.dfIntentName || 'No matched intent' }}</span>
            </a>
        </div>
    </div>

    <div class="row mb-3" *ngIf="showPage && viewFilters.page">
        <label class="col-3 col-form-label">Page</label>
        <div class="col-9 d-flex align-items-center">
            <p class="mb-0">{{ interaction.dfPageName }}</p>
        </div>
    </div>

    <div class="row mb-3" *ngIf="showFlow && viewFilters.flow">
        <label class="col-3 col-form-label">Flow</label>
        <div class="col-9 d-flex align-items-center">
            <p class="mb-0">{{ interaction.dfFlowName }}</p>
        </div>
    </div>

    <div class="row mb-3" *ngIf="showLang">
        <label class="col-3 col-form-label">Language</label>
        <div class="col-9 d-flex align-items-center">
            <p class="mb-0">{{ Languages[interaction.lang] || interaction.lang }}</p>
        </div>
    </div>

    <div class="row mb-3" *ngIf="showContextOut && viewFilters.contextOut">
        <label class="col-3 col-form-label">Context out</label>
        <div class="col-9">
            <span class="interaction_context" *ngFor="let outputContext of interaction.dfOutputContextJson">
                <span class="badge rounded-pill bg-secondary">{{ outputContext.lifespan }}</span>
                {{ parseContextName(outputContext.name) }}
            </span>
        </div>
    </div>

    <div class="row mb-3">
        <label class="col-3 col-form-label">Confidence</label>
        <div class="col-9 d-flex align-items-center">
            <p class="mb-0">{{ interaction.dfConfidence | number: '1.0-2' }}</p>
        </div>
    </div>

    <ng-container *licenseRestriction="'NL'; itemType: 'Connector'"></ng-container>
    <div class="row mb-3" *ngIf="viewFilters.sentiment && interaction.dfSentiment !== null">
        <label class="col-3 col-form-label">Sentiment</label>
        <div class="col-9 d-flex align-items-center">
            <p class="mb-0">{{ interaction.dfSentiment | number: '1.0-2' }} (Magnitude {{ interaction.dfMagnitude | number: '1.0-2' }})</p>
        </div>
    </div>

    <div class="row mb-3" *ngIf="viewFilters.status && interaction.trusted !== null">
        <label class="col-3 col-form-label">Status</label>
        <div class="col-9 d-flex align-items-center">
            <p class="mb-0">{{ interaction.trusted ? 'Trusted' : 'Untrusted' }}</p>
        </div>
    </div>

    <div class="row" *ngIf="viewFilters.response">
        <label class="col-3 col-form-label">Response</label>
        <div class="col-9">
            <p class="mb-1" [ngClass]="{ 'text-muted': !interaction.isShownMessage }" *ngFor="let message of getSplittedMessages(interaction.dfOutputMessage)">{{ message }}</p>
        </div>
    </div>
</div>

<ng-template #popIntentContent>
    <div id="intentPopover">
        <form #searchIntentForm="ngForm" novalidate>
            <input type="text" name="intentFilter" class="form-control" [(ngModel)]="intentFilter" #ctrl="ngModel" />
        </form>

        <ul class="list-unstyled popover_list">
            <li *ngFor="let intent of intents | fullTextSearch: intentFilter:'name'">
                <a href="javascript:void(0)" (click)="setIntent(intent)">{{ intent.name }}</a>
            </li>
        </ul>
    </div>
</ng-template>

<ng-template #popEntityContent let-entityEvent="$event">
    <ng-template [spinnerLoader]="subscriptions['Entities'].closed" [size]="'2x'"></ng-template>

    <div role="alert" class="alert alert-warning mb-0" *ngIf="!entities.length && subscriptions['Entities'].closed">No entity for this Intent</div>

    <form novalidate *ngIf="entities.length && subscriptions['Entities'].closed">
        <input type="text" name="entityFitler" class="form-control" [(ngModel)]="entityFilter" #ctrl="ngModel" />
    </form>

    <ul class="list-unstyled entities_list" *ngIf="entities.length && subscriptions['Entities'].closed">
        <li *ngFor="let entity of entities | fullTextSearch: entityFilter:'name'">
            <a href="javascript:void(0)" (click)="setEntity(entity.name, entityEvent)">{{ entity.name }}</a>
        </li>
    </ul>
</ng-template>
