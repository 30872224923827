<div class="console_modal delete_dialogflow_dialog">
    <div class="modal-header">
        <h4 class="modal-title">WARNING</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="body_content">
            <div class="row">
                <div class="col-2">
                    <i class="fal fa-exclamation-triangle fa-4x text-warning" aria-hidden="true"></i>
                </div>

                <div class="col">
                    <p>Are you sure you want to delete the Google Dialogflow {{ connector.productCode }} connector? All data related to the agent will be deleted!</p>

                    <p>You can export the intents data and conversational history before proceeding.</p>

                    <div class="row">
                        <div class="col d-flex align-items-center" *ngIf="connector.productCode === 'CX'">
                            <button class="btn text_brand" (click)="exportFlows()" [disabled]="persistentSubscriptions['ExportFlow']?.closed === false">
                                <p class="import_progress" *ngIf="persistentSubscriptions['ExportFlow']?.closed === false">
                                    <span class="progress_spinner"><ng-template [spinnerLoader]="false" size="xs"></ng-template></span>
                                </p>
                                <i *ngIf="!(persistentSubscriptions['ExportFlow']?.closed === false)" class="fal fa-cloud-download" aria-hidden="true"> </i>
                                Export page's element data
                            </button>
                        </div>
                        <div class="col d-flex align-items-center" *ngIf="connector.productCode === 'ES'">
                            <button class="btn text_brand" (click)="exportIntents()" [disabled]="persistentSubscriptions['ExportIntent']?.closed === false">
                                <p class="import_progress" *ngIf="persistentSubscriptions['ExportIntent']?.closed === false">
                                    <span class="progress_spinner"><ng-template [spinnerLoader]="false" size="xs"></ng-template></span>
                                </p>
                                <i *ngIf="!(persistentSubscriptions['ExportIntent']?.closed === false)" class="fal fa-cloud-download" aria-hidden="true"> </i>
                                Export intent data
                            </button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <button class="btn text_brand" (click)="exportHistory()" [disabled]="persistentSubscriptions['ExportHistory']?.closed === false">
                                <p class="import_progress" *ngIf="persistentSubscriptions['ExportHistory']?.closed === false">
                                    <span class="progress_spinner"><ng-template [spinnerLoader]="false" size="xs"></ng-template></span>
                                </p>
                                <i *ngIf="!(persistentSubscriptions['ExportHistory']?.closed === false)" class="fal fa-cloud-download" aria-hidden="true"> </i>
                                Export history
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn brand_light" (click)="activeModal.close()">Cancel</button>
        <button type="button" class="btn brand_default" [disabled]="persistentSubscriptions['ExportHistory']?.closed === false || persistentSubscriptions['ExportIntent']?.closed === false || persistentSubscriptions['ExportFlow']?.closed === false" (click)="deleteConnector()">Yes, delete</button>
    </div>
</div>
