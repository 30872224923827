<div class="console_modal nlu_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Natural Language</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="connectorSubscription?.closed">
        <div class="body_content">
            <form #nluForm="ngForm" (ngSubmit)="saveConnector()"
                *roleRestriction="'design.processflow.naturallanguage.write'; disableElements: true" novalidate>
                <div class="row">
                    <div class="col-12">
                        <p>
                            This connector it's used to perform sentiment analysis on the question posed by the user,
                            using Google's Natural Language API service.<br>
                            <span class="fw-bold">Sentiment analysis</span> attempts to determine the overall attitude (positive or negative)
                            expressed within the text.
                        </p>
                        <p>Sentiment is represented by numerical score and magnitude values</p>
                        <ul>
                            <li><span class="fw-bold">Score</span> of the sentiment ranges between -1.0
                                (negative) and 1.0 (positive) and corresponds to the overall emotional leaning of the
                                text</li>
                            <li><span class="fw-bold">Magnitude</span> indicates the overall strength of
                                emotion (both positive and negative) within the given text. Each expression of emotion
                                within the text (both positive and negative) contributes to the text's magnitude(so
                                longer text blocks may have greater magnitudes).</li>
                        </ul>
                        <p> For more information follow <a
                                href="https://cloud.google.com/natural-language/docs/basics#sentiment_analysis"
                                target="_blank" rel="noopener">Google Documentation</a>.</p>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="nluForm.onSubmit($event)"
            *roleRestriction="'design.processflow.naturallanguage.write'; disableElements: true">Save</button>
    </div>
</div>