export const TEST_CONSUMPTIONS_DF = [
  {
    name: 'Dialogflow ES',
    billingList: [
      { startDate: 1652084999000, endDate: 1652171399000, value: 10 },
      { startDate: 1652171399000, endDate: 1652257799000, value: 4 },
      { startDate: 1652257799000, endDate: 1652344199000, value: 4 },
      { startDate: 1652344199000, endDate: 1652430599000, value: 6 },
      { startDate: 1652430599000, endDate: 1652516999000, value: 24 },
      { startDate: 1652486400000, endDate: 1652572800000, value: 0 },
      { startDate: 1652572800000, endDate: 1652659200000, value: 0 },
    ],
    total: null,
    remaining: null,
    characterInspected: null,
    characterTrasformed: null,
  },
  {
    name: 'Dialogflow Audio ES',
    billingList: [
      { startDate: 1652054400000, endDate: 1652140800000, value: 0 },
      { startDate: 1652140800000, endDate: 1652227200000, value: 0 },
      { startDate: 1652227200000, endDate: 1652313600000, value: 0 },
      { startDate: 1652313600000, endDate: 1652400000000, value: 0 },
      { startDate: 1652400000000, endDate: 1652486400000, value: 0 },
      { startDate: 1652486400000, endDate: 1652572800000, value: 0 },
      { startDate: 1652572800000, endDate: 1652659200000, value: 0 },
    ],
    total: null,
    remaining: null,
    characterInspected: null,
    characterTrasformed: null,
  },
];

export const TEST_CONSUPTIONS = [
  {
    name: 'Dialogflow ES',
    billingList: [{ startDate: 1621202399000, endDate: 1652738399000, value: 1124 }],
    total: 6000,
    remaining: 4876,
    characterInspected: null,
    characterTrasformed: null,
  },
  {
    name: 'Dialogflow CX',
    billingList: [{ startDate: 1621202399000, endDate: 1652738399000, value: 1429 }],
    total: 21,
    remaining: -1408,
    characterInspected: null,
    characterTrasformed: null,
  },
  {
    name: 'Data Loss Prevention',
    billingList: [{ startDate: 1621202399000, endDate: 1652738399000, value: 286062 }],
    total: 5000,
    remaining: -281062,
    characterInspected: 284539,
    characterTrasformed: 1523,
  },
  {
    name: 'Natural Language',
    billingList: [{ startDate: 1621202399000, endDate: 1652738399000, value: 125 }],
    total: 6000,
    remaining: 5875,
    characterInspected: null,
    characterTrasformed: null,
  },
  {
    name: 'Translate',
    billingList: [{ startDate: 1621202399000, endDate: 1652738399000, value: 7660 }],
    total: 1020,
    remaining: -6640,
    characterInspected: null,
    characterTrasformed: null,
  },
];

export const TEST_COMPONENT_STATUS = {
  status: 'UP',
  components: {
    liveness: 'UP',
  },
};

export const TEST_LOGS_CONNECTORS = [
  'ACTIONMAPPING',
  'DATAPROCESSING',
  'DATATRANSFER',
  'DLP',
  'NATURALLANGUAGE',
  'RESTHTTP',
  'RULE',
  'SMTP',
  'TRANSLATE',
  'ZENDESK',
  'DIALOGFLOWCX',
  'DIALOGFLOWES',
  'SALESFORCESALESCLOUD',
  'SALESFORCESERVICECLOUD',
  'ERRORHANDLER',
  'EVENTCALLER',
  'DISPATCHER',
  'SENDGRID',
  'TWILIOSMS',
  'CODEEDITOR',
];

export const TEST_GET_LOGS = [
  {
    logName:
      '- 2022-12-23 15:18:57,817 - botResponse - chat - <<< [TX][Response]: OrchestratorResponse(status=200, results={dlpResponse=DlpResult(objectDeidentify={$arrayText=["Ehi in cosa posso esserti utile oggi?","$[title1]"]}, minLangToEncrypt=32, overrideResponse=false, noValues=false), aiResponse=DialogflowResult(idMessage=0187e06a-9cb4-43e4-a850-54c5f732d49a, lang=it, id=26a2b059-45ff-49b6-b454-e144a339cfa9, timestamp=2022-12-23T15:18:57Z, sessionId=167180872663064D610F1885)}, extraResult=ExtraResult(singleResult=null, details=Details(skipIntent=false, detectWithInputContext=false, trusted=false, manageByOperator=false, switchedAgent=null, currentJourneyCode=null, warningList=[], topics=[default, default, default, default, default, default, default]), logs=[com.noovle.chat.controller.model.response.OrchestratorLog@4feca57, com.noovle.chat.controller.model.response.OrchestratorLog@5a1112b4, com.noovle.chat.controller.model.response.OrchestratorLog@76445db8, com.noovle.chat.controller.model.response.OrchestratorLog@3b7310b7, com.noovle.chat.controller.model.response.OrchestratorLog@49adc9fc, com.noovle.chat.controller.model.response.OrchestratorLog@1b7bf6cf, com.noovle.chat.controller.model.response.OrchestratorLog@4f06c185, com.noovle.chat.controller.model.response.OrchestratorLog@6c53867a, com.noovle.chat.controller.model.response.OrchestratorLog@4b964025]))',
    timestamp: 1671808737817,
    severity: 'INFO',
  },
  {
    logName: '- 2022-12-23 15:18:57,813 - userRequest - chat - message uuid[0187e06a-9cb4-43e4-a850-54c5f732d49a] saved',
    timestamp: 1671808737813,
    severity: 'INFO',
  },
  {
    logName: '- 2022-12-23 15:18:57,628 - userRequest - chat - End request create obj: [MessageEntity] at 2022/12/23 16:18:57',
    timestamp: 1671808737628,
    severity: 'INFO',
  },
  {
    logName: '- 2022-12-23 15:18:57,619 - userRequest - chat - binding parameter [26] as [BOOLEAN] - [false]',
    timestamp: 1671808737619,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,619 - userRequest - chat - binding parameter [28] as [VARCHAR] - [0187e06a-9cb4-43e4-a850-54c5f732d49a]',
    timestamp: 1671808737619,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,619 - userRequest - chat - binding parameter [25] as [TIMESTAMP] - [Fri Dec 23 15:18:57 GMT 2022]',
    timestamp: 1671808737619,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,619 - userRequest - chat - binding parameter [27] as [VARCHAR] - [prova un due tre]',
    timestamp: 1671808737619,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,618 - userRequest - chat - binding parameter [24] as [VARCHAR] - [it]',
    timestamp: 1671808737618,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,618 - userRequest - chat - binding parameter [22] as [BOOLEAN] - [true]',
    timestamp: 1671808737618,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,618 - userRequest - chat - binding parameter [21] as [BIGINT] - [241770]',
    timestamp: 1671808737618,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,618 - userRequest - chat - binding parameter [23] as [BOOLEAN] - [true]',
    timestamp: 1671808737618,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,617 - userRequest - chat - binding parameter [19] as [BOOLEAN] - [false]',
    timestamp: 1671808737617,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,617 - userRequest - chat - binding parameter [17] as [FLOAT] - [null]',
    timestamp: 1671808737617,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,617 - userRequest - chat - binding parameter [20] as [BOOLEAN] - [false]',
    timestamp: 1671808737617,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,617 - userRequest - chat - binding parameter [18] as [BOOLEAN] - [false]',
    timestamp: 1671808737617,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,616 - userRequest - chat - binding parameter [14] as [VARCHAR] - [[]]',
    timestamp: 1671808737616,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,616 - userRequest - chat - binding parameter [13] as [FLOAT] - [null]',
    timestamp: 1671808737616,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,616 - userRequest - chat - binding parameter [16] as [VARCHAR] - [Start Page]',
    timestamp: 1671808737616,
    severity: 'DEBUG',
  },
  {
    logName:
      '- 2022-12-23 15:18:57,616 - userRequest - chat - binding parameter [15] as [VARCHAR] - [Ehi in cosa posso esserti utile oggi?##$[title1]##[Graphic Response]##[Graphic Response]##[Graphic Response]]',
    timestamp: 1671808737616,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,616 - userRequest - chat - binding parameter [12] as [VARCHAR] - [Default Welcome Intent]',
    timestamp: 1671808737616,
    severity: 'DEBUG',
  },
  {
    logName:
      '- 2022-12-23 15:18:57,615 - userRequest - chat - binding parameter [11] as [VARCHAR] - [projects/tellya-stage-botgeneral/locations/global/agents/03e62e76-3a1e-4664-9689-01047eedcf9c/intents/00000000-0000-0000-0000-000000000000]',
    timestamp: 1671808737615,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,615 - userRequest - chat - binding parameter [9] as [VARCHAR] - [Default Start Flow]',
    timestamp: 1671808737615,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,615 - userRequest - chat - binding parameter [8] as [VARCHAR] - [null]',
    timestamp: 1671808737615,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,615 - userRequest - chat - binding parameter [10] as [VARCHAR] - [null]',
    timestamp: 1671808737615,
    severity: 'DEBUG',
  },
  {
    logName: '- 2022-12-23 15:18:57,614 - userRequest - chat - binding parameter [7] as [VARCHAR] - [{"agentchannel":"TEST"}]',
    timestamp: 1671808737614,
    severity: 'DEBUG',
  },
];
