import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Connector, JourneyService } from '@core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-generic-rule-modal',
  templateUrl: './generic-rule-modal.component.html',
  styleUrls: ['./generic-rule-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GenericRuleModalComponent implements OnInit, OnDestroy {
  @Input() connector: any;
  formSubmitted = false;
  form = this.fb.group<{
    name: FormControl<string>;
    connectorToRun?: FormControl<string>;
  }>({
    name: this.fb.control('', Validators.required),
  });
  subscriptions: object = {};

  connectors$: Observable<Array<Connector>>;

  constructor(
    public activeModal: NgbActiveModal,
    private connectorsService: ConnectorsService,
    private fb: FormBuilder,
    private journeyService: JourneyService
  ) {}

  ngOnInit() {
    this.setupConnectors();
    if (this.connector.id) {
      this.form.patchValue({ name: this.connector.name });
    } else {
      this.form.addControl('connectorToRun', this.fb.control('', Validators.required));
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  setupConnectors() {
    this.connectors$ = this.connectorsService.getFilteredConnectorList({
      connectorCode: this.connector.productCode,
    });
  }

  saveConnector() {
    this.formSubmitted = true;
    if (this.form.invalid) return;

    const data = {
      ...this.connector,
      ...this.form.value,
    };

    if (this.connector.id) {
      if (data.connectors) delete data.connectors;

      this.subscriptions['Connector'] = this.connectorsService.editConnector('rule', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.subscriptions['Connector'] = this.connectorsService.addConnector('rule', data).subscribe((response: any) => {
        this.activeModal.close({
          refresh: true,
          nextConnector: {
            productCode: this.form.get('connectorToRun').value,
            parentId: response.id,
            parentType: response.productCode,
            productType: response.productCode,
            journeyId: this.journeyService.journey.id,
          },
        });
      });
    }
  }
}
