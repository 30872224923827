import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { BackButton, CompleteButton, CustomStep, ExitButton, NextButton } from '@shared';

@Injectable({
  providedIn: 'root',
})
export class PlatformOverviewTutorialService {
  constructor(private router: Router, private shepherdService: ShepherdService, @Inject(DOCUMENT) private document: Document) {
    this.shepherdService.modal = true;
    this.shepherdService.defaultStepOptions = { scrollTo: true };
  }

  startTutorial() {
    if (this.shepherdService.isActive) {
      this.shepherdService.complete();
    }
    this.shepherdService.addSteps([
      new CustomStep({
        padding: 5,
        title: `Platform overview`,
        text: 'Welcome to Tellya, a general tour of the platform will be presented below.',
        buttons: [new ExitButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#homeMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/dashboard']),
        title: `Home`,
        text: `This is the "Home" section, inside you will find general information on the agent's progress, costs and the status of the platform. From the Home you can also perform the Tone of Voice test.`,
      }),
      new CustomStep({
        attachTo: {
          element: '#intentsDesignMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/design/conversation-flow']),
        title: `Intent design`,
        text: `The Intent Design section provides a workspace that allows you to view and modify the flow of the NLU engine and the intents that compose it.`,
      }),
      new CustomStep({
        attachTo: {
          element: '#intentsSettingsMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/design/nlu-settings/intent-settings']),
        title: `Intent settings`,
        text: `The “Intent Settings” section allows you to define and configure particular behaviors associated with individual intents.`,
      }),
       new CustomStep({
         attachTo: {
           element: '#pageSettingsMenu',
           on: 'right',
         },
         beforeShow: () => this.router.navigate(['/design/nlu-settings/pages-settings']),
         title: `Pages settings`,
         text: `The "Pages Settings” section allows you to define and configure particular behaviors associated with individual page and flow.`,
       }),
      new CustomStep({
        attachTo: {
          element: '#entitiesMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/design/nlu-settings/entities']),
        title: `Entities`,
        text: `The Entities section allows you to define and configure new custom entities or modify entities already present within the NLU engine associated with the journey.`,
      }),
      new CustomStep({
        attachTo: {
          element: '#processFlowsMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/process-flow']),
        title: `Process flows`,
        text: `The Design section allows you to define and customize the agent's behavior, configuring the different types of services and technologies to be integrated within the flow.`,
      }),
      new CustomStep({
        attachTo: {
          element: '#analyticsMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/analytics']),
        title: `Analytics`,
        text: `The Analytics section allows you to analyze statistics and performance of the virtual agent in relation to the different objectives, KPIs and peculiarities of the project.`,
      }),
      new CustomStep({
        attachTo: {
          element: '#trainingMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/training']),
        title: `Training`,
        text: `The section lists the real conversations that took place between users and agents and it is therefore possible to verify and correct the associations made by the NLU platform.`,
      }),
      new CustomStep({
        attachTo: {
          element: '#validationMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/health-check/validation']),
        title: `Validation`,
        text: `Within the section, all the problems of the NLU platform are reported. They are collected on different levels which are: agent, intent and entity.`,
      }),
      new CustomStep({
        attachTo: {
          element: '#regressionTestMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/health-check/regression-test']),
        title: `Regression test`,
        text: `Within the section, tests can be defined that simulate conversations, useful for monitoring the quality of the bot after making changes to the agent.`,
      }),
      new CustomStep({
        attachTo: {
          element: '#monitoringMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/monitoring']),
        title: `Monitoring`,
        text: 'In this section you can check the status of the Tellya platform and its microservices. It is also possible to check the logs collected by the platform.',
      }),
      new CustomStep({
        attachTo: {
          element: '#billingMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/billing']),
        title: `Billing`,
        text: 'The Billing section allows you to monitor the use of resources, services and the various APIs and components integrated into the project (eg Dialogflow, Google Natural Language).',
      }),
      new CustomStep({
        attachTo: {
          element: '#adminMenu',
          on: 'right',
        },
        beforeShow: () => this.router.navigate(['/admin']),
        title: `Admin`,
        text: 'The Admin section allows you to control the users who have access to the platform, give them specific permissions and check their work by analyzing the system logs.',
      }),
      new CustomStep({
        attachTo: {
          element: '#channelsMenu',
          on: 'top',
        },
        beforeShow: () => this.router.navigate(['/channels']),
        title: `Channels`,
        text: 'The Channels section allows you to quickly integrate the journeys configured within different distribution channels that support virtual agents.',
      }),
      new CustomStep({
        attachTo: {
          element: '#marketplaceMenu',
          on: 'top',
        },
        beforeShow: () => this.router.navigate(['/marketplace']),
        title: `Market place`,
        text: 'In this section you can buy new channels, connectors and modules.',
      }),
      new CustomStep({
        attachTo: {
          element: '#chatButton',
          on: 'bottom',
        },
        beforeShow: () => {
          this.router.navigate(['/dashboard']);
          this.document.querySelector<any>('#chatButton').click();
        },
        when: {
          hide: () => this.document.querySelector<any>('#chatButton').click(),
        },
        padding: 5,
        title: `Test chat`,
        text: 'Clicking here opens the test chat where you can try the bot you have configured or consult the support bot.',
      }),
      new CustomStep({
        attachTo: {
          element: '#glossaryButton',
          on: 'bottom',
        },
        beforeShow: () => this.router.navigate(['/dashboard']),
        padding: 5,
        title: `Glossary`,
        text: 'Clicking here opens the glossary section which contains the definitions of the terms found within the platform.',
      }),
      new CustomStep({
        attachTo: {
          element: '#tutorialButton',
          on: 'bottom',
        },
        beforeShow: () => this.router.navigate(['/dashboard']),
        padding: 5,
        title: `Tour`,
        text: 'You can run this tour or another at any time by clicking on this icon.',
        buttons: [new ExitButton(), new BackButton(), new CompleteButton()],
      }),
    ]);

    this.shepherdService.start();
  }
}
