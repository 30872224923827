<div class="console_modal dialogflow_dialog {{ connector?.productCode | lowercase }}">
    <ng-template [spinnerLoader]="subscriptions['AgentsBot'].closed"></ng-template>

    <div class="modal-header dialog_head">
        <h4 class="modal-title">{{ connector.productName }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [spinnerLoader]="subscriptions['Connector']?.closed">
        <div class="body_content">
            <form class="dialogflow_form" [formGroup]="dialogflowForm" novalidate>
                <steps-completion [phases]="dialogflowPhases" [stepsForm]="stepsForm" [onMoveStep]="moveStep" (stepsCompleted)="saveConnector($event)" *ngIf="connector.id"></steps-completion>

                <section class="connector_steps" formArrayName="steps" *roleRestriction="roleRestrictionPermission; disableElements: true">
                    <article formGroupName="0" *ngIf="dialogflowPhases.current === 0">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 mb-3" *ngIf="connector.id">
                                <form #editModeForm="ngForm" novalidate>
                                    <div class="form-check">
                                        <input type="radio" id="import" name="editMode" class="form-check-input" [value]="'import'" [(ngModel)]="formEditMode" #editMode="ngModel" (change)="setEditMode()" />
                                        <label class="form-check-label" for="import">Import agent data</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" id="export" name="editMode" class="form-check-input" [value]="'export'" [(ngModel)]="formEditMode" #editMode="ngModel" (change)="setEditMode()" />
                                        <label class="form-check-label" for="export">Switch agent</label>
                                    </div>
                                </form>
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3" *ngIf="formEditMode === 'export'">
                                <label for="dialogflowAgent" class="form-label">Agent *</label>
                                <select id="dialogflowAgent" class="form-select" required formControlName="agentBot" [compareWith]="compareAgent" [ngClass]="{ 'is-invalid': stepsOne.get('agentBot').invalid && dialogflowPhases.steps[0].submitted }">
                                    <option *ngFor="let agent of agents" [ngValue]="agent">{{ agent.name }}</option>
                                </select>
                                <div class="invalid-feedback" *ngIf="stepsOne.get('agentBot').errors?.required">Agent is required</div>
                            </div>
                        </div>
                    </article>
                    <article formGroupName="1" *ngIf="dialogflowPhases.current === 1 && formEditMode === 'export'">
                        <p class="mb-0"><i class="far fa-exclamation-triangle fa-sm text-warning" aria-hidden="true"></i> Changing the agent will result in the loss of all information and configuration related to the intents in it and defined within Tellya (status, tags...).</p>
                        <p>You can decide whether you want to keep the conversation history, the training and statistics information or delete this data.</p>
                        <div class="form-check mb-3">
                            <input type="checkbox" class="form-check-input" id="eraseConversations" formControlName="clearAnalytics" />
                            <label class="form-check-label" for="eraseConversations">Delete all conversational history and analytical data of this agent</label>
                        </div>
                        <p class="mb-0">Before proceeding you can export the information and data related to these configurations.</p>
                        <p>You can also export the conversational history and analytical data.</p>
                        <div class="row justify-content-around mb-3">
                            <div class="col-auto" *ngIf="connector.productCode === 'ES'">
                                <button class="btn text_brand" (click)="exportIntents()" [disabled]="persistentSubscriptions['ExportIntent']?.closed === false">
                                    <p class="import_progress" *ngIf="persistentSubscriptions['ExportIntent']?.closed === false">
                                        <span class="progress_spinner"><ng-template [spinnerLoader]="false" size="xs"></ng-template></span>
                                    </p>
                                    <i *ngIf="!(persistentSubscriptions['ExportIntent']?.closed === false)" class="fal fa-cloud-download" aria-hidden="true"> </i>
                                    Export intent data
                                </button>
                            </div>
                            <div class="col-auto" *ngIf="connector.productCode === 'CX'">
                                <button class="btn text_brand" (click)="exportFlows()" [disabled]="persistentSubscriptions['ExportFlow']?.closed === false">
                                    <p class="import_progress" *ngIf="persistentSubscriptions['ExportFlow']?.closed === false">
                                        <span class="progress_spinner"><ng-template [spinnerLoader]="false" size="xs"></ng-template></span>
                                    </p>
                                    <i *ngIf="!(persistentSubscriptions['ExportFlow']?.closed === false)" class="fal fa-cloud-download" aria-hidden="true"> </i>
                                    Export page's element data
                                </button>
                            </div>
                            <div class="col-auto">
                                <button class="btn text_brand" (click)="exportHistory()" [disabled]="persistentSubscriptions['ExportHistory']?.closed === false">
                                    <p class="import_progress" *ngIf="persistentSubscriptions['ExportHistory']?.closed === false">
                                        <span class="progress_spinner"><ng-template [spinnerLoader]="false" size="xs"></ng-template></span>
                                    </p>
                                    <i *ngIf="!(persistentSubscriptions['ExportHistory']?.closed === false)" class="fal fa-cloud-download" aria-hidden="true"> </i>
                                    Export history
                                </button>
                            </div>
                        </div>
                        <div class="form-check mb-3">
                            <input type="checkbox" class="form-check-input" id="noticeRead" formControlName="noticeRead" [ngClass]="{ 'is-invalid': dialogflowPhases.steps[1].submitted && stepsTwo.controls.noticeRead.invalid }" />
                            <label class="form-check-label" for="noticeRead">I have read the notice and want to continue with the change of agent.</label>
                            <div class="invalid-feedback">You must agree before continue.</div>
                        </div>
                    </article>
                    <article formGroupName="1" *ngIf="dialogflowPhases.current === 1 && formEditMode === 'import'">
                        <div class="row">
                            <div class="col-sm-12 col-md-8">
                                <form #importIntentsForm="ngForm">
                                    <label class="form-label">Import data</label>
                                    <div class="input-group">
                                        <input type="file" class="form-control" id="importIntent" (change)="storeFile($event.target)" />
                                        <label class="form-label" for="importIntent">
                                            <button class="btn btn-sm brand_light"><i class="fal fa-cloud-upload" aria-hidden="true"></i> Browse file</button>
                                            <span class="ms-1" *ngIf="stepsTwo.controls.file.valid">{{ stepsTwo.controls.file.value.name }}</span>
                                            <span *ngIf="dialogflowPhases.steps[1].submitted && stepsTwo.controls.file.invalid" class="text-danger">
                                                <i class="far fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                                Select file...
                                            </span>
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </article>
                </section>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <ng-container *ngIf="connector.id">
            <button type="button" class="btn" (click)="nextStep()" [hidden]="dialogflowPhases.current === dialogflowPhases.steps.length - 1">Next</button>
            <button
                type="button"
                class="btn"
                (click)="nextStep()"
                [disabled]="persistentSubscriptions['ExportHistory']?.closed === false || persistentSubscriptions['ExportIntent']?.closed === false"
                [hidden]="dialogflowPhases.current !== dialogflowPhases.steps.length - 1"
                *roleRestriction="roleRestrictionPermission; disableElements: true"
            >
                Save
            </button>
        </ng-container>
        <ng-container *ngIf="!connector.id">
            <button type="button" class="btn" (click)="saveConnector()" *roleRestriction="roleRestrictionPermission; disableElements: true">Save</button>
        </ng-container>
    </div>
</div>
