import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GlossaryComponent implements OnInit {
  @Input() openGlossary: boolean;
  @Output() switchGlossary = new EventEmitter<any>();

  categories = {
    HOME: ['CHANNELS', 'CONSUMPTION', 'FALLBACKS', 'SESSION', 'TONE_OF_VOICE', 'TRUSTED_INTERACTIONS', 'VIRTUAL_AGENT_AUTONOMY'],
    DESIGN: ['ACTION', 'AUTOMATED_EXPANSION', 'CONTEXT', 'DYNAMIC_RESPONSES', 'END_OF_CONVERSATION', 'ENTITY', 'EVENT', 'FULFILLMENT', 'FULFILLMENT_JOURNEY', 'FUZZY_MATCHING', 'INTENT', 'JOURNEY', 'PARAMETRIC_RESPONSE', 'REGEX_ENTITY', 'STATIC_RESPONSES', 'SYNONYMS', 'TAGS', 'TRAINING_PHRASES', 'TRUSTED_INTENT'],
    ANALYTICS : ['CONFIDENCE', 'CUSTOM_REPORT', 'DETECTED_OCCURRENCES', 'FALLBACKS', 'FEEDBACK', 'INTENT', 'MANAGED_OCCURRENCES', 'PRECISION', 'PROFICIENCY', 'RECALL', 'SESSION', 'STATUS', 'TRUSTED_INTERACTIONS', 'VIRTUAL_AGENT_AUTONOMY'],
    TRAINING : ['INTENT','TRAINING'],
    "HEALTH CHECK"  : ['ISSUES','TEST_CASE']
  };
  glossaryProperties = {
    HOME : {icon : "fas fa-home", status : "closed", path : ['/dashboard']},
    DESIGN : {icon : "fas fa-pencil-ruler", status : "closed", path : ['/conversation-flow','/design','/entities','/intent-settings']},
    ANALYTICS : {icon : "fas fa-analytics", status : "closed", path : ['/analytics']},
    TRAINING : {icon : "fas fa-graduation-cap", status : "closed", path : ['/training']},
    "HEALTH CHECK" : {icon : "fas fa-first-aid", status : "closed", path : ['/validation', '/regression-test']}
  }

  // flatStage;
  searchQuery;
  constructor(public translateService: TranslateService, private router: Router) {}

  ngOnInit() {
    this.toggleAccordionOnInit();
    this.toggleAccordionOnChange();
  }
  toggleAccordionOnChange(){
    this.router.events.pipe(filter((e: Event): e is RouterEvent => e instanceof NavigationEnd)).subscribe((e: RouterEvent) => {
      this.checkGlossaryKeyExists(e.url);
    });
  }
  toggleAccordionOnInit(){
    setTimeout(() => {
      this.checkGlossaryKeyExists(this.router.url)
    })
  }

  checkGlossaryKeyExists(e){
    Object.keys(this.glossaryProperties).forEach(element => {
      if(this.glossaryProperties[element].path.includes(e)){
        this.closeAllAccordion();
        this.glossaryProperties[element].status = 'open';
      }
    }); 
  }

  closeAllAccordion(){
    Object.keys(this.glossaryProperties).forEach(element => {
      this.glossaryProperties[element].status = 'closed';
    })
  }

  // Preserve original property order
  originalOrder = (a: KeyValue<string,string[]>, b: KeyValue<string,string[]>): number => {
    return 0;
  }

  get filterTerms() {
    return this.flatItems.filter((term: any) => {
      if (!this.searchQuery) return true;
      return this.translateService.instant(`GLOSSARY.${term}.KEY`).toLowerCase().includes(this.searchQuery.toLocaleLowerCase());
    });
  }

  get flatItems() {
    const flattenedTerms = Object.values(this.categories).reduce((items, arr) => [...arr, ...items], []);
    return flattenedTerms.filter((v, i, arr) => arr.indexOf(v) === i);
  }

  closeGlossary(){
    this.switchGlossary.emit(false);
  }
  toggleAccordion(key){
    let currentStatus = '';
    currentStatus = this.glossaryProperties[key].status === 'closed' ? 'open' : 'closed';
    this.glossaryProperties[key].status = currentStatus;
  }
}
