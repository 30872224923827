import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Journey, JourneyService } from '@core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConnectorsService } from '../../../../services/connectors.service';
import { CommonApiService } from '@shared';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-action-settings-modal',
  templateUrl: './action-settings-modal.component.html',
  styleUrls: ['./action-settings-modal.component.scss'],
})
export class ActionSettingsModalComponent implements OnInit, OnDestroy {
  @Output() private onSaveSettings = new EventEmitter<any>();
  public journey: Journey;
  public connector: any;
  public selectedAction: any;
  subscriptions: Subscription = new Subscription();

  formSubmitted = false;
  flowList = [];
  pageList = {};
  routeList = {};
  action = {};

  actionForm = this.fb.group({
    id: '',
    actionName: ['', Validators.required],
    actionMappingChildElements: this.fb.array([]),
  });

  get actionMappingChildElementsForm() {
    return this.actionForm.get('actionMappingChildElements') as FormArray;
  }
  get actionMappingChildElementsFormFactor() {
    return this.fb.group({
      flowId: ['', Validators.required],
      id: '',
      pageElementId: ['', Validators.required],
      priority: ['', Validators.required],
      transitionRouteId: null,
    });
  }
  constructor(
    private fb: FormBuilder,
    private connectorsService: ConnectorsService,
    public activeModal: NgbActiveModal,
    public journeyService: JourneyService,
    private commonApiService: CommonApiService
  ) {}

  ngOnInit() {
    this.getFlowList();

    if (this.selectedAction) {
      this.initForm();
      this.actionForm.patchValue({ ...this.selectedAction, ...{ actionMappingChildElements: this.selectedAction.actionMappingChildElements || [] } });
    } else if (this.journeyService.isProductConfigured('CX', this.journey.id) && !this.journey.fulfillment) {
      this.addChildElement();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getEqualAction(i) {
    const actionList = [];
    this.connector.actionMappingChilds.forEach((action) => {
      if (this.selectedAction && action.id === this.selectedAction.id) return;
      const actionChildValue = this.actionMappingChildElementsForm.at(i).value;
      const childAction = action.actionMappingChildElements.find(
        (actionChild) =>
          actionChild.flowId == actionChildValue.flowId &&
          actionChild.pageElementId == actionChildValue.pageElementId &&
          actionChild.transitionRouteId == actionChildValue.transitionRouteId
      );
      if (childAction) {
        actionList.push({ actionName: action.actionName, priority: childAction.priority });
      }
    });
    return actionList.length > 0 ? actionList : null;
  }
  initForm() {
    if (this.selectedAction.actionMappingChildElements) {
      for (const el of this.selectedAction.actionMappingChildElements) {
        this.getPageList(el.flowId);
        this.getRouteList(el.pageId, el.pageElementId);
        this.addChildElement();
      }
    }
  }
  getFlowList() {
    this.subscriptions.add(
      this.commonApiService.getAllFlow(this.journey.apiKey).subscribe((data: any[]) => {
        this.flowList = data;
      })
    );
  }
  getPageList(flowId) {
    this.subscriptions.add(
      this.connectorsService.getPages(flowId).subscribe((data: any[]) => {
        this.pageList[flowId] = data;
      })
    );
  }

  getRouteList(pageId, pageElId) {
    this.subscriptions.add(
      this.connectorsService.getRoutes(pageId).subscribe((data: any[]) => {
        this.routeList[pageElId] = data;
      })
    );
  }

  updatePageList(flowId, i) {
    this.actionMappingChildElementsForm.at(i).get('pageElementId').setValue('');
    this.actionMappingChildElementsForm.at(i).get('transitionRouteId').setValue(null);

    this.getPageList(flowId);
  }
  updateRouteList(flowId, pageElId, i) {
    this.actionMappingChildElementsForm.at(i).get('transitionRouteId').setValue(null);

    const { pageId } = this.pageList[flowId].find((el) => el.id === parseInt(pageElId));
    this.getRouteList(pageId, pageElId);
  }

  addChildElement() {
    this.actionMappingChildElementsForm.push(this.actionMappingChildElementsFormFactor);
  }

  removeChildElement(i) {
    if (this.actionMappingChildElementsForm.length === 1) return;
    this.actionMappingChildElementsForm.removeAt(i);
  }

  saveAction() {
    this.formSubmitted = true;
    if (this.actionForm.invalid) return;
    const data = {
      ...this.actionForm.value,
      ...{
        actionMappingId: this.connector.id,
      },
    };

    this.subscriptions.add(
      this.connectorsService.saveActionMappingAction(data).subscribe((resp) => {
        this.activeModal.close();
        this.onSaveSettings.emit(resp.id);
      })
    );
  }

  deleteAction() {
    this.subscriptions.add(
      this.connectorsService.deleteActionMappingAction(this.selectedAction.id).subscribe(() => {
        this.activeModal.close();
        this.onSaveSettings.emit();
      })
    );
  }
}
