import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Languages } from '@core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { CHANNELS_COLOR, GREY, LANG_CHART, TOPICS_CHART } from '@shared';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  onDeleteReport = new EventEmitter<number>();

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private env: Environment) {}

  getAxisScale(chartData: Array<Array<any>>): any {
    const valuesFound = (item: Array<any>, index: number) => index !== 0 && item[1] > 0;
    if (!chartData.some(valuesFound)) {
      return {
        max: 100,
        min: 0,
      };
    }
    return {};
  }

  processDoughnutChartData(chart: 'Channels' | 'PopularTopicsAnalytics' | 'PercentageLang', rowData: any[]) {
    if (rowData.filter((d) => d.number).length === 0) {
      return {
        colors: [GREY],
        labels: [['No data']],
        data: [100],
      };
    }
    const dataset = { data: [], labels: [], colors: [] };

    switch (chart) {
      case 'Channels':
        for (const item of rowData) {
          const channelData = CHANNELS_COLOR[item.name] || {};
          dataset.colors.push(channelData.color);
          dataset.labels.push([`${channelData.label} `, ` (${item.perc}%)`]);
          dataset.data.push(item.number);
        }

        break;
      case 'PopularTopicsAnalytics':
        for (const i in rowData) {
          const item = rowData[i];
          dataset.colors.push(TOPICS_CHART[i]);
          dataset.labels.push([`${item.name} `, ` (${item.perc}%)`]);
          dataset.data.push(item.number);
        }

        break;
      case 'PercentageLang':
        for (const i in rowData) {
          const item = rowData[i];
          dataset.colors.push(LANG_CHART[i]);
          dataset.labels.push([`${Languages[item.name] || item.name} `, ` (${item.perc}%)`]);
          dataset.data.push(item.number);
        }

        break;
      default:
        break;
    }
    return dataset;
  }

  getConversationsStatistics(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate).set('fillEmptyDate', 'true'),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/generic/conversations`, httpOptions);
  }

  getSingleInteractionSessions(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/single/interactions/total`, httpOptions);
  }

  getMultipleInteractionSessions(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/multiple/interactions/total`, httpOptions);
  }

  getInteractionsAverageSession(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/media/conversations/messages`, httpOptions);
  }

  getInteractionsAverageDuration(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/media/duration/conversations`, httpOptions);
  }

  getTopIntent(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', params.startDate)
        .set('endDate', params.endDate)
        .set('pageSize', pageSize.toString())
        .set('pageIndex', pageNumber.toString()),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    if (params.elementLike) httpOptions.params = httpOptions.params.append('elementLike', params.elementLike);
    if (params.minSentimentMedia) httpOptions.params = httpOptions.params.append('minSentimentMedia', params.minSentimentMedia);
    if (params.maxSentimentMedia) httpOptions.params = httpOptions.params.append('maxSentimentMedia', params.maxSentimentMedia);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/engagement/intents`, httpOptions);
  }

  getTopFallback(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', params.startDate)
        .set('endDate', params.endDate)
        .set('pageSize', pageSize.toString())
        .set('pageIndex', pageNumber.toString())
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    if (params.elementLike) httpOptions.params = httpOptions.params.append('elementLike', params.elementLike);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/engagement/fallbacks`, httpOptions);
  }

  getCxPages(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', params.startDate)
        .set('endDate', params.endDate)
        .set('pageSize', pageSize.toString())
        .set('pageIndex', pageNumber.toString())
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    if (params.elementLike) httpOptions.params = httpOptions.params.append('elementLike', params.elementLike);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/engagement/pages`, httpOptions);
  }

  getCxFlows(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', params.startDate)
        .set('endDate', params.endDate)
        .set('pageSize', pageSize.toString())
        .set('pageIndex', pageNumber.toString())
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    if (params.elementLike) httpOptions.params = httpOptions.params.append('elementLike', params.elementLike);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/engagement/flows`, httpOptions);
  }

  getAverageScore(params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/media/dialogflow/confidence`, httpOptions);
  }

  getFallbackPercentage(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate)
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/percente/fallback/messages`, httpOptions);
  }

  getAgentAutonomy(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/agentAutonomy`, httpOptions);
  }

  getTrustedIntents(): Observable<any> {
    return this.http.get(`${this.env.API.orchestrator_configurator}/analytics/trustedIntents`);
  }

  getAgentChannels(startDate: number, endDate: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString()),
    };

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/percent/agentChannel`, httpOptions);
  }

  getPercentagePopularTags(startDate: number, endDate: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString()),
    };

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/percent/tags`, httpOptions);
  }

  getPrecentageLang(startDate: number, endDate: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString())
    };

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/percent/lang`, httpOptions);
  }

  getPercentagePrecision(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/percent/precision`, httpOptions);
  }

  getPercentageRecall(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/percent/recall`, httpOptions);
  }

  getPrecisionAndRecall(): Observable<any> {
    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/precisionAndRecall`);
  }

  getPrecisionAndRecallForTable(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('pageIndex', pageNumber.toString()).set('pageSize', pageSize.toString()),
    };
    if (Number.isInteger(params.startPrecision)) httpOptions.params = httpOptions.params.append('startPrecision', params.startPrecision);
    if (Number.isInteger(params.endPrecision)) httpOptions.params = httpOptions.params.append('endPrecision', params.endPrecision);
    if (Number.isInteger(params.startRecall)) httpOptions.params = httpOptions.params.append('startRecall', params.startRecall);
    if (Number.isInteger(params.endRecall)) httpOptions.params = httpOptions.params.append('endRecall', params.endRecall);
    if (params.intentName) httpOptions.params = httpOptions.params.append('intentName', params.intentName);
    if (params.status) httpOptions.params = httpOptions.params.append('status', params.status);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/precisionAndRecallForTable`, httpOptions);
  }

  getAllReport() {
    return this.http.get(`${this.env.API.orchestrator_analysis}/customkpi/getAllReport`);
  }

  createReport(data: any) {
    return this.http.post(`${this.env.API.orchestrator_analysis}/customkpi/createReport`, data);
  }

  deleteReport(reportId: any) {
    const httpOptions = {
      params: new HttpParams().set('reportId', reportId),
    };
    return this.http
      .delete(`${this.env.API.orchestrator_analysis}/customkpi/deleteReport`, httpOptions)
      .pipe(tap(() => this.onDeleteReport.emit(reportId)));
  }

  getQueryResult(reportName: string, params: any) {
    const httpOptions = {
      params: new HttpParams().set('reportName', reportName),
    };
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.onlyTable != null) httpOptions.params = httpOptions.params.append('onlyTable', params.onlyTable);

    return this.http.get(`${this.env.API.orchestrator_analysis}/customkpi/getQueryResult`, httpOptions);
  }

  createChart(data: any) {
    return this.http.post(`${this.env.API.orchestrator_analysis}/customkpi/createChart`, data);
  }

  updateChart(data: any) {
    return this.http.put(`${this.env.API.orchestrator_analysis}/customkpi/updateChart`, data);
  }

  deleteChart(chartId: any) {
    const httpOptions = {
      params: new HttpParams().set('chartId', chartId),
    };
    return this.http.delete(`${this.env.API.orchestrator_analysis}/customkpi/deleteChart`, httpOptions);
  }
}
