import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-smtp-connector-modal',
  templateUrl: './smtp-connector-modal.component.html',
  styleUrls: ['./smtp-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SmtpConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private connector: any;

  connectorSubscription: Subscription;
  moveStep: Subject<any> = new Subject<any>();

  smtpForm = this.fb.group({
    steps: this.fb.array([
      this.fb.group({
        sender: ['', Validators.required],
        host: ['', Validators.required],
        port: ['', Validators.required],
        address: ['', Validators.required],
        password: ['', Validators.required],
      }),
      this.fb.group({
        recipient: ['', Validators.required],
        cc: [''],
        ccn: [''],
      }),
      this.fb.group({
        subject: ['', Validators.required],
        body: ['', Validators.required],
        isTemplate: [false, Validators.required],
        templateKeys: this.fb.array([]),
      }),
    ]),
  });

  get stepsForm() {
    return this.smtpForm.get('steps') as FormArray;
  }

  get stepOne() {
    return this.stepsForm.at(0) as FormGroup;
  }
  get stepTwo() {
    return this.stepsForm.at(1) as FormGroup;
  }
  get stepThree() {
    return this.stepsForm.at(2) as FormGroup;
  }

  get smtpTemplateKeys() {
    return this.stepThree.get('templateKeys') as FormArray;
  }

  get stepThreeArray() {
    return this.stepsForm.at(2) as FormArray;
  }

  smtpPhases: any = {
    steps: [
      { name: 'SMTP server', submitted: false },
      { name: 'Recipients', submitted: false },
      { name: 'Content', submitted: false },
    ],
    current: 0,
  };

  constructor(public activeModal: NgbActiveModal, private connectorsService: ConnectorsService, private fb: FormBuilder) {}

  ngOnInit() {
    if (this.connector.id) {
      this.stepsForm.patchValue([
        {
          host: this.connector.host,
          sender: this.connector.sender,
          port: this.connector.port,
          address: this.connector.address,
          password: this.connector.password,
        },
        { recipient: this.connector.recipient, cc: this.connector.cc, ccn: this.connector.ccn },
        {
          subject: this.connector.subject,
          body: this.connector.body,
          isTemplate: this.connector.isTemplate,
          templateKeys: this.processFormArray(this.connector.templateKeys, 'input'),
        },
      ]);
    }
  }

  ngOnDestroy() {
    if (this.connectorSubscription instanceof Subscription) this.connectorSubscription.unsubscribe();
  }

  nextStep() {
    this.moveStep.next('next');
  }

  addTemplateKey() {
    this.smtpTemplateKeys.push(
      this.fb.group({
        key: ['', Validators.required],
        value: ['', Validators.required],
      })
    );
  }

  resetForm() {
    while (this.smtpTemplateKeys.length !== 0) {
      this.smtpTemplateKeys.removeAt(0);
    }
    this.stepThreeArray.reset('body');
    this.stepThreeArray.reset('subject');
  }

  saveConnector($event) {
    $event.templateKeys = this.processFormArray($event.templateKeys, 'output');
    const data = { ...this.connector, ...$event };

    if (this.connector.id) {
      this.connectorSubscription = this.connectorsService.editConnector('email', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.connectorSubscription = this.connectorsService.addConnector('email', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }

  processFormArray(formData: any, process: 'input' | 'output') {
    if (process === 'output') {
      return JSON.stringify(formData);
    } else if (process === 'input') {
      const formArray: Array<any> = [];
      const formFields = JSON.parse(formData);

      if (formFields) {
        formFields.forEach((field) => {
          this.addTemplateKey();
          formArray.push({ key: field.key, value: field.value });
        });
      }
      return formArray;
    }
  }
}
