import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleRestrictionGuard } from '@core';
import { ActionMappingConnectorComponent } from './components/connectors/action-mapping-connector/action-mapping-connector.component';
import { ProcessFlowComponent } from './components/process-flow/process-flow.component';

const routes: Routes = [
  {
    path: '',
    component: ProcessFlowComponent,
    canActivate: [RoleRestrictionGuard],
    data: {
      roleRestriction: ['design.journey.write', 'design.journey.read'],
    },
  },
  {
    path: ':journeyId',
    component: ProcessFlowComponent,
    canActivate: [RoleRestrictionGuard],
    data: {
      roleRestriction: ['design.journey.write', 'design.journey.read'],
    },
  },
  {
    path: ':journeyId/action-mapping/:id',
    component: ActionMappingConnectorComponent,
    canActivate: [RoleRestrictionGuard],
    data: {
      roleRestriction: ['design.processflow.actionmapping.write', 'design.processflow.actionmapping.read'],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProcessFlowRoutingModule {}
