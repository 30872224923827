import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'error-handler-connector-sidebar',
  templateUrl: './error-handler-connector-sidebar.component.html',
  styleUrls: ['./error-handler-connector-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorHandlerConnectorSidebarComponent implements OnInit, OnDestroy {
  @Input() public connector: any;
  @Output() closed = new EventEmitter<any>();
  @ViewChild('sidebarConnectorContainer', { read: ViewContainerRef }) public sidebarConnectorContainer;

  newFlow: string;
  submitted = false;
  errForm = this.errFormFactory;
  subscriptions: object = {};

  get errFormFactory() {
    return this.fb.group({
      flows: this.fb.array([]),
    });
  }
  getFlowFormFactory(errorFilter = '') {
    return this.fb.group({
      errorFilter: [
        errorFilter,
        [Validators.required, Validators.pattern('(\\d{1}(\\d{2}|\\d{1}\\*{1}|\\*{2}))(,\\s*(\\d{1}(\\d{2}|\\d{1}\\*{1}|\\*{2})))*')],
      ],
      skipJourney: false,
      id: '',
      connectors: [],
    });
  }
  get flows() {
    return this.errForm.get('flows') as FormArray<FormGroup>;
  }

  constructor(private fb: FormBuilder, private connectorsService: ConnectorsService) {}

  ngOnInit() {
    if (this.connector.id) {
      this.initForm();
    } else {
      this.createConnector();
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  initForm() {
    this.errForm = this.errFormFactory;
    this.connector.flows && this.connector.flows.forEach(() => this.flows.push(this.getFlowFormFactory()));
    this.errForm.patchValue(this.connector);
  }

  addFlow(form: NgForm) {
    if (form.invalid) return;
    const flowForm = this.getFlowFormFactory(form.value.errorFlow);
    this.flows.push(flowForm);

    this.subscriptions['AddFlow'] = this.connectorsService.addErrorHandlerFlow(this.connector.id, flowForm.value).subscribe((res) => {
      flowForm.patchValue(res);
      form.resetForm();
    });
  }

  removeFlow(index) {
    if (this.flows.length === 1) return;
    const flow = this.flows.at(index).value;
    if (!flow.id) {
      this.flows.removeAt(index);
      return;
    }
    this.subscriptions['RemoveFlow'] = this.connectorsService.removeErrorHandlerFlow(this.connector.id, flow.id).subscribe(() => this.getConnector());
  }

  getConnector() {
    this.subscriptions['Connector'] = this.connectorsService.getConnector('httpError', this.connector.id).subscribe((connector) => {
      this.connector = connector;
      this.initForm();
    });
  }

  createConnector() {
    this.subscriptions['Connector'] = this.connectorsService.addConnector('httpError', this.connector).subscribe((response: any) => {
      this.connector = response;
    });
  }

  updateConnector() {
    this.submitted = true;
    if (this.errForm.invalid) return;

    const data = { ...this.connector, ...this.errForm.value };

    this.subscriptions['Connector'] = this.connectorsService.editConnector('httpError', data).subscribe(() => this.closed.emit({ refresh: true }));
  }
}
