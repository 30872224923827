export enum ConnectorPermissions {
    E = 'smtp',
    R = 'httprest',
    NL = 'naturallanguage',
    RL = 'rule',
    ES = 'dialogflowes',
    CX = 'dialogflowcx',
    DLP = 'dlp',
    T = 'translate',
    Q = 'quelix',
    DT = 'datatransfer',
    DP = 'dataprocess',
    ZD = 'zendesk',
    SF = 'salesforceticket',
    SFS = 'salesforcesales',
    D = 'dispatcher',
    EC = 'eventcaller',
    CE = 'codeconnector',
    EH = 'httperrorhandler',
    AM = 'actionmapping',
    SG = 'sendgrid',
    TW = 'twiliosms',
    RS = 'rasa'
}