import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-translate-connector-modal',
  templateUrl: './translate-connector-modal.component.html',
  styleUrls: ['./translate-connector-modal.component.scss'],
})
export class TranslateConnectorModalComponent implements OnInit, OnDestroy {
  @Input() connector: any;
  @ViewChild('translateForm') translateForm: NgForm;

  subscriptions: object = {};

  languageSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        this.connectorsService.getTranslateLanguages().pipe(
          map((languages) => languages.filter((language) => language.toLowerCase().indexOf(term.toLocaleLowerCase()) > -1)),
          catchError(() => {
            return of([]);
          })
        )
      )
    );

  constructor(public activeModal: NgbActiveModal, private connectorsService: ConnectorsService) {}

  ngOnInit() {
    if (!this.connector.id) {
      this.connector = { ...this.connector, isDetectLanguage: true, onlyFirstInteraction: false };
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  switchTranslateType() {
    delete this.connector.minChars;
    delete this.connector.languages;
  }

  saveConnector() {
    if (this.translateForm.invalid) return;

    const data = this.connector;

    if (this.connector.id) {
      this.subscriptions['Connector'] = this.connectorsService.editConnector('translate', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.subscriptions['Connector'] = this.connectorsService.addConnector('translate', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }
}
