<ng-template [spinnerLoader]="subscriptions['deleteChart']?.closed"></ng-template>
<ng-template [spinnerLoader]="subscriptions['getQueryResult']?.closed"></ng-template>

<div class="row mb-3">
    <div class="col-12">
        <form class="reports_filter">
            <div class="row">
                <div class="col">
                    <div class="btn-group fitler_type" role="group">
                        <input type="radio" [(ngModel)]="reportView" (ngModelChange)="switchView()" name="menuFields" value="chart" class="btn-check" id="chartRadio" autocomplete="off" >
                        <label class="btn" for="chartRadio">Charts view</label>
                        <input type="radio" [(ngModel)]="reportView" (ngModelChange)="switchView()" name="menuFields" value="table" class="btn-check" id="tableRadio" autocomplete="off" >
                        <label class="btn" for="tableRadio">Table view</label>
                    </div>
                </div>
                <div class="col-3 ms-auto">
                    <datepicker-range name="datepicker"  [(ngModel)]="filter.period" (ngModelChange)="refreshCharts()"></datepicker-range>
                </div>
                <div class="col-auto">
                    <div ngbDropdown class="kebab_menu">
                        <button type="button" class="btn" id="reportOptions" ngbDropdownToggle><i class="fas fa-ellipsis-v" aria-hidden="true"></i></button>
                        <div ngbDropdownMenu aria-labelledby="reportOptions">
                            <button ngbDropdownItem (click)="showDetails()">Report details</button>
                            <button ngbDropdownItem (click)="deleteReport()">Delete this report</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-container *ngIf="(!charts || charts.length === 0 && !tableChart)">
    <div class="row">
        <div class="col-12 my-auto" *ngIf="subscriptions['getQueryResult']?.closed">
            <div class="row justify-content-center">
                <div class="col-auto text-center">
                    <h5>Custom report</h5>
                    <p *ngIf="reportView === 'chart'"><em>Configure charts to monitor custom behaviors and metrics.</em></p>
                    <p *ngIf="reportView === 'table'"><em>Configure your table view.</em></p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <button class="btn brand_light" (click)="addChart()" *ngIf="reportView === 'chart'">
                        <span class="align-middle pe-1"><i class="material-icons" aria-hidden="true">leaderboard</i></span>
                        <span>New Chart</span>
                    </button>
                    <button class="btn brand_light" (click)="addChart()" *ngIf="reportView === 'table'">
                        <span class="align-middle pe-1"><i class="fas fa-table" aria-hidden="true"></i></span>
                        <span>New Table</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="subscriptions['getQueryResult']?.closed">
        <div class="col-auto"> Report ID: {{report.bqReportName}}</div>
    </div>
</ng-container>
<ng-container *ngIf="charts?.length > 0 || tableChart">
    <div class="row" *ngIf="reportView === 'chart'">
        <div class="box_chart col-12 mt-3"
            *ngFor="let chart of charts; let index = index"
            [ngClass]="{'col-md-4': chart.options.size === 'SMALL',
                        'col-md-8': chart.options.size === 'MEDIUM'}"
        >
            <span class="chart_handle">
                <div ngbDropdown class="d-inline-block">
                    <button class="btn fas fa-ellipsis-v" id="dropdownBasic1" ngbDropdownToggle></button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="modifyChart(chart)">Edit</button>
                        <button ngbDropdownItem (click)="deleteChart(chart.options.id)">Delete</button>
                    </div>
                </div>
            </span>
            <ng-container *ngIf="['TABLE', 'COUNTER'].indexOf(chart.options.type) < 0">
                <div class="chart_header">
                    <div class=" chart_title text-break mt-3">{{chart.options.title}}</div>
                </div>
                <div [id]="'custom_chart_' + index"></div>
            </ng-container>
            <ng-container *ngIf="chart.options.type === 'COUNTER'">
                <div class="row chart_total">
                    <div class="col-12 my-auto text-center">
                        <div>{{chart.options.title}}</div>
                        <div><h2>{{chart.data.counter.total | number: '1.0-2'}}</h2></div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-12 col-md-4 box_add">
            <div class="row">
                <div class="col-12 my-auto">
                    <button class="btn brand_light" (click)="addChart()">
                        <span class="align-middle pe-1"><i class="material-icons" aria-hidden="true">leaderboard</i></span>
                        <span>New Chart</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="reportView === 'table'">
        <div class="col-12 box_chart" *ngIf="tableChart.pagination">
            <span class="chart_handle">
                <div ngbDropdown class="d-inline-block">
                    <button class="btn fas fa-ellipsis-v" id="dropdownBasic1" ngbDropdownToggle></button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="modifyChart(tableChart)">Modify</button>
                        <button ngbDropdownItem (click)="deleteChart(tableChart.options.id)">Delete</button>
                    </div>
                </div>
            </span>
            <div class="chart_header">
                <div class=" chart_title text-break mt-3">{{tableChart.options.title}}</div>
            </div>
            <div class="table-responsive">
                <table class="table table-hover" aria-describedby="Report Table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col" *ngFor="let column of tableChart.data.table.columns">{{column.field}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of tableChart.data.table.rows | slice: (tableChart.pagination.currentPage - 1) * tableChart.pagination.pageSize : tableChart.pagination.currentPage * tableChart.pagination.pageSize; let index = index">
                            <td>{{(index + 1) + (tableChart.pagination.currentPage - 1) * tableChart.pagination.pageSize}}</td>
                            <ng-container *ngFor="let rowColumn of tableChart.data.table.columns">
                                <td *ngIf="rowColumn.fieldType === 'string'">{{row[rowColumn.field]}}</td>
                                <td *ngIf="rowColumn.fieldType === 'number'">{{row[rowColumn.field] | number: '1.0-2'}}</td>
                                <td *ngIf="rowColumn.fieldType === 'Date'">{{row[rowColumn.field] | date: 'dd/MM/yyyy'}}</td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-12">
                    <pagination [paginationData]="tableChart.pagination" (paginationCallback)="tableChart.pagination.updateTotals(tableChart.data.table.rows.length); tableChart.pagination.onSelectPage($event)" [resultPerPage]="true"></pagination>
                </div>
            </div>
        </div>
    </div>
</ng-container>
