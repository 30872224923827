<div class="agent_languages">
    <button type="button" class="btn brand_default" [ngbPopover]="languagesBody" [popoverTitle]="languagesHead" [placement]="position" popoverClass="pop_agent_languages" container="body">{{ journeyService.agentLang }}</button>
</div>
<ng-template #languagesHead>Language of interaction</ng-template>
<ng-template #languagesBody>
    <ul class="list-unstyled languages_list {{ layoutClass || '' }}">
        <li *ngFor="let language of activeAgent.supportedLang" [ngClass]="{ active: language === journeyService.agentLang }">
            <button type="button" class="btn" (click)="changeAgentLang(language)">{{ language }}</button>
        </li>
        <li class="listing_all" *ngIf="allLanguages" [ngClass]="{ active: journeyService.agentLang === 'all' }">
            <button type="button" class="btn" (click)="changeAgentLang('all')">All</button>
        </li>
    </ul>
</ng-template>
