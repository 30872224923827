<div class="container-fluid salesforce_container">
    <div class="row justify-content-center pt-5">
        <div class="col-sm-12 col-md-6 forms_section">
            <figure class="head_section d-flex">
                <h1 class="login_logo"></h1>
                <h1 class="salesforce_logo"></h1>
            </figure>
            <form #messengerForm="ngForm" class="mb-5 mt-3">
                <div class="row auth_code_form">
                    <div class="col-12 input_copy">
                        <label for="authCode" class="form-label">AUTH_CODE</label>
                        <input type="text" name="authCode" #authCodeInput class="form-control" id="authCode" [ngModel]="authCode" readonly/>
                        <i aria-hidden="true" (click)="commonService.copyToClipboard(authCodeInput)" ngbTooltip="Copy to Clipboard" class="far fa-copy"></i>
                    </div>
                </div>
                <div class="form_foot">Copy this code and paste in into the Salesforce configuration in Tellya</div>
            </form>
        </div>
    </div>
</div>
