export const BLUE = '#647dfc';
export const GREY = '#e3e3e3';
export const REQUEST_LINE_CHART = ['#647dfc', '#ff0000'];
export const TOPICS_CHART = ['#9816F4', '#1DC9B7', '#FD397A', '#00AFF0', '#FFB822', '#343A40'];
export const LANG_CHART = ['#284CFC', '#E31E1E', '#01C756', '#BA4AFF', '#FFEB00'];
export const CHANNELS_COLOR = {
  WEBSITE: {
    label: 'Website',
    color: '#F5008C',
  },
  APP: {
    label: 'App',
    color: '#F50000',
  },
  FACEBOOK: {
    label: 'Facebook',
    color: '#1773FA',
  },
  WHATSAPP: {
    label: 'Whatsapp',
    color: '#38C251',
  },
  VIVOCHA: {
    label: 'Vivocha',
    color: '#00447D',
  },
  TIMRCC: {
    label: 'Tim Rcc',
    color: '#54A866',
  },
  LIVEPERSON: {
    label: 'LivePerson',
    color: '#F66E05',
  },
  GOOGLECHAT: {
    label: 'Google',
    color: '#0B631E',
  },
  TEAMS: {
    label: 'Teams',
    color: '#12ABB0',
  },
  TELEGRAM: {
    label: 'Telegram',
    color: '#3642AD',
  },
  TEST: {
    label: 'Test',
    color: '#75076C',
  },
  UNDEFINED: {
    label: 'Not specified',
    color: '#000000',
  },
  VOICE: {
    label: 'Voice',
    color: '#9717f4',
  },
};
