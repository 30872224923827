import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Languages, AgentLang, Journey, JourneyService } from '@core';

@Component({
  selector: 'agent-languages',
  templateUrl: './agent-languages.component.html',
  styleUrls: ['./agent-languages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgentLanguagesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() position?: string = 'left';
  @Input() layoutClass?: string;

  @Input() allLanguages?: boolean = false;

  agentSubscription: Subscription;

  activeAgent: any = {};

  get languages() {
    return Languages;
  }

  constructor(public journeyService: JourneyService) {}

  ngOnInit() {
    this.agentSubscription = this.journeyService.journey$.subscribe((journey: Journey) => {
      if (this.journeyService.agentLang === 'all') {
        this.changeAgentLang(journey.agent?.supportedLang[0]);
      }
      this.activeAgent = journey.agent;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allLanguages']?.currentValue === false && !changes['allLanguages'].firstChange && this.journeyService.agentLang === 'all') {
      this.changeAgentLang(this.activeAgent.supportedLang[0]);
    }
  }
  ngOnDestroy() {
    this.agentSubscription.unsubscribe();
  }

  changeAgentLang(language: AgentLang) {
    this.journeyService.setSessionAgentLang(language);
  }
}
