export enum CountriesISO3166_1 {    
    au = 'Australia',
    cl = 'Chile',
    cz = 'Czechia',
    ee = 'Estonia',
    hk = 'Hong Kong',
    hu = 'Hungary',
    il = 'Israel',
    it = 'Italy',
    lt = 'Lithuania',
    ph = 'Philippines',
    pl = 'Poland',
    pt = 'Portugal',
    sg = 'Singapore',
    sk = 'Slovakia',
    ch = 'Switzerland',
    se = 'Sweden',
    gb = 'United Kingdom of Great Britain and Northern Ireland'
}