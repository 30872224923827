import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-troove-connector-modal',
  templateUrl: './troove-connector-modal.component.html',
  styleUrls: ['./troove-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TrooveConnectorModalComponent implements OnInit {
  @Input() private connector: any;

  connectorSubscription: Subscription;
  moveStep: Subject<any> = new Subject<any>();

  trooveForm = this.fb.group({
    steps: this.fb.array([
      this.fb.group({
        urlBasePath: ['', Validators.required],
        site: ['', Validators.required],
        client: ['', Validators.required],
        proxyStyleSheet: ['', Validators.required],
      }),
      this.fb.group({
        metadata: this.fb.array([]),
      }),
      this.fb.group({
        sortingEnabled: [false],
        sortingName: [''],
        sortingType: [''],
      }),
    ]),
  });

  get stepsForm() {
    return this.trooveForm.get('steps') as FormArray;
  }

  get stepOne() {
    return this.stepsForm.at(0);
  }
  get stepTwo() {
    return this.stepsForm.at(1);
  }
  get stepThree() {
    return this.stepsForm.at(2);
  }

  get metadata() {
    return this.stepTwo.get('metadata') as FormArray;
  }

  troovePhases: any = {
    steps: [
      { name: 'General Settings', submitted: false },
      { name: 'Metadata', submitted: false },
      { name: 'Sorting', submitted: false },
    ],
    current: 0,
  };

  constructor(public activeModal: NgbActiveModal, private connectorsService: ConnectorsService, private fb: FormBuilder) {}

  ngOnInit() {
    if (this.connector.id) {
      this.stepsForm.patchValue([
        {
          urlBasePath: this.connector.urlBasePath,
          site: this.connector.site,
          client: this.connector.client,
          proxyStyleSheet: this.connector.proxyStyleSheet,
        },
        { metadata: this.processMetaArray(this.connector.metadata) },
        { sortingEnabled: this.connector.sortingEnabled, sortingName: this.connector.sortingName, sortingType: this.connector.sortingType },
      ]);
    }

    this.stepThree.get('sortingEnabled').valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.stepThree.get('sortingName').setValidators(Validators.required);
        this.stepThree.get('sortingType').setValidators(Validators.required);
      } else {
        this.stepThree.get('sortingName').clearValidators();
        this.stepThree.get('sortingType').clearValidators();
      }
      this.stepThree.get('sortingName').updateValueAndValidity();
      this.stepThree.get('sortingType').updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    if (this.connectorSubscription instanceof Subscription) this.connectorSubscription.unsubscribe();
  }

  nextStep() {
    this.moveStep.next('next');
  }

  addMetadata() {
    this.metadata.push(
      this.fb.group({
        dialogflowKey: ['', Validators.required],
        trooveKey: ['', Validators.required],
        specificSearch: [false],
      })
    );
  }

  saveConnector($event) {
    const data = { ...this.connector, ...$event };

    if (this.connector.id) {
      this.connectorSubscription = this.connectorsService.editConnector('troove', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.connectorSubscription = this.connectorsService.addConnector('troove', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }

  processMetaArray(arrayData: Array<any>): Array<any> {
    const formArray: Array<any> = [];
    if (arrayData.length) {
      arrayData.forEach((field: any) => {
        this.addMetadata();
        formArray.push({
          dialogflowKey: field.dialogflowKey,
          trooveKey: field.trooveKey,
          specificSearch: field.specificSearch,
        });
      });
    }
    return formArray;
  }
}
