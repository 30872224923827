<div class="row section_design">
    <ng-template [spinnerLoader]="subscriptions['Board']?.closed"></ng-template>
    <ng-template [spinnerLoader]="subscriptions['Products']?.closed"></ng-template>

    <div class="col-9 design_body">
        <div class="box_wrapper board_journeys">
            <div #drawBoard class="drawing_board" cdkScrollable>
                <header class="board_head" *ngIf="activeBoard?.journey?.fulfillment">
                    <button class="btn fulfillment_journey" (click)="modalJourney(activeBoard?.journey, 'fulfillment')">
                        <small [ngClass]="{ 'text-warning': !activeBoard?.journey.fulfillment }">
                            <i class="fal fa-bolt" aria-hidden="true" *ngIf="activeBoard?.journey.fulfillment"></i>
                            <i class="fal fa-exclamation-triangle" aria-hidden="true" *ngIf="!activeBoard?.journey.fulfillment"></i>
                            Fulfillment Journey
                        </small>
                    </button>
                </header>
                <connectors-board (updateBoard)="getBoard($event)" (droppedConnector)="onConnectorDropped.next($event)" [connectors]="activeBoard?.connectors" [journey]="activeBoard?.journey" [connectedList]="connectedList" [products]="products"></connectors-board>
            </div>

            <nav class="nav_board">
                <div class="btn-group carousel_nav" *ngIf="showNavButton">
                    <button class="btn brand_light" (click)="owlCar.prev()"><i class="fas fa-angle-left" aria-hidden="true"></i></button>
                    <button class="btn brand_light" (click)="owlCar.next()"><i class="fas fa-angle-right" aria-hidden="true"></i></button>
                </div>
                <owl-carousel-o class="journey_list" [options]="cardCarouselOptions" #owlCar (dragging)="onDrag($event)" (initialized)="setCaroselSlide()">
                    <ng-template *ngFor="let journey of journeys" carouselSlide [width]="getWidth(journey)" id="{{ journey.id }}">
                        <button [ngbTooltip]="journey.description" [openDelay]="500" [disableTooltip]="isDragging" container="body" type="button" class="btn nav_button" [ngClass]="{ active: activeBoard?.journey.id === journey.id }" [owlRouterLink]="['/process-flow/' + journey.id]" [stopLink]="isDragging">
                            <i class="far fa-network-wired me-2" aria-hidden="true" *ngIf="journey.dispatcher"></i>{{ journey.name }}
                        </button>
                    </ng-template>
                </owl-carousel-o>
                <div class="journey_action">
                    <button class="btn" (click)="modalJourney()" *roleRestriction="'design.journey.write'; disableElements: true">
                        <i class="fas fa-plus-circle" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn text_brand" (click)="getBoard(activeBoard.journey.id)"><i class="fas fa-redo" aria-hidden="true"></i></button>
                    <button type="button" class="btn brand_light" (click)="modalJourney(activeBoard?.journey)"><i class="fas fa-cog" aria-hidden="true"></i></button>
                </div>
            </nav>
        </div>
    </div>
    <div class="col-3 h-100">
        <products-sidebar [onConnectorDropped]="onConnectorDropped" (addProduct)="addProduct($event)" [connectedList]="connectedList" [products]="products"></products-sidebar>
    </div>
</div>
