import { Component, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { JourneyService } from '@core';
import { Pagination, TableSortDirective } from '@shared';
import { AnalyticsService } from '../../services/analytics.service';
import { AnalyticsFilterComponent } from '../analytics-filter/analytics-filter.component';

@Component({
  selector: 'analytic-intents',
  templateUrl: './analytic-intents.component.html',
  styleUrls: ['./analytic-intents.component.scss'],
})
export class AnalyticIntentsComponent implements OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;
  @ViewChild(AnalyticsFilterComponent) filterComponent: AnalyticsFilterComponent;

  subscriptions: object = {};
  resultsPagination: Pagination = new Pagination();

  filtersAddOn: any = {
    sortBy: null,
    pageSelected: this.resultsPagination.currentPage,
  };
  resultResolved: boolean;
  resultTrend: Array<any> = [];
  fallbackPercentage: number;
  tags: Array<any> = [];
  idResearchTab: 'flow' | 'fallback' | 'intent' | 'page' = 'intent';
  manageVisibility = {
    inputSearch: true,
    sentiment: this.idResearchTab === 'intent',
  };
  toolTipsIntents: any = {
    mostTriggeredIntents: 'Top 5 of the most used intents among all sessions.',
    mostTriggeredFallbacks: "When the virtual agent doesn't recognize an end-user expression.",
    fallbackPercentage: 'How often the virtual agent could not understand the users’ requests.',
    operatorRequest: 'How many times the user has expressed an explicit desire to talk to an operator.',
  };

  constructor(private analyticsService: AnalyticsService, private journeyService: JourneyService) {}

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  switchIdResearchTab() {
    this.manageVisibility.sentiment = this.idResearchTab === 'intent';

    this.filterComponent.resetFilters();
  }

  onSort({ column, direction }: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filtersAddOn = { ...this.filtersAddOn, sortBy: direction ? `${column}:${direction}` : null };
  }

  changePage(pageNumber: number) {
    this.filtersAddOn = { ...this.filtersAddOn, pageSelected: pageNumber };
  }

  getStatistics(filter: any) {
    this.resultResolved = false;
    this.resultsPagination.onSelectPage(filter.pageSelected);

    const params = {
      startDate: filter.startDate,
      endDate: filter.endDate,
      tags: filter.intentTag || null,
      agentChannel: filter.agentChannel || null,
      sortBy: filter.sortBy || null,
      elementLike: filter.elementLike || null,
      minSentimentMedia: filter.minSentiment || null,
      maxSentimentMedia: filter.maxSentiment || null,
    };

    if (this.idResearchTab === 'fallback') {
      this.getTopFallback(params);
      this.getFallbackPercentage(params);
    }

    if (this.idResearchTab === 'intent') this.getTopIntent(params);
    if (this.idResearchTab === 'page' && this.journeyService.isProductConfigured('CX')) this.getCxPages(params);
    if (this.idResearchTab === 'flow' && this.journeyService.isProductConfigured('CX')) this.getCxFlows(params);
  }

  processResultData(response) {
    this.resultTrend = response.singleResult.content;
    this.resultsPagination.updateTotals(response.singleResult.totalElements);
    this.resultResolved = true;
  }

  getFallbackPercentage(params: any) {
    this.subscriptions['FallbackPercentage'] = this.analyticsService.getFallbackPercentage(params).subscribe((response: any) => {
      this.fallbackPercentage = response.singleResult.value;
    });
  }

  getTopFallback(params: any) {
    this.subscriptions['CxTopFallback'] = this.analyticsService
      .getTopFallback(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize)
      .subscribe((response: any) => {
        this.processResultData(response);
      });
  }

  getTopIntent(params: any) {
    this.subscriptions['CxTopIntent'] = this.analyticsService
      .getTopIntent(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize)
      .subscribe((response: any) => {
        this.processResultData(response);
      });
  }

  getCxPages(params: any) {
    this.subscriptions['CxPages'] = this.analyticsService
      .getCxPages(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize)
      .subscribe((response: any) => {
        this.processResultData(response);
      });
  }

  getCxFlows(params: any) {
    this.subscriptions['CxFlows'] = this.analyticsService
      .getCxFlows(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize)
      .subscribe((response: any) => {
        this.processResultData(response);
      });
  }
}
