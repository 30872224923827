
<div class="console_modal support_dialog">
    <div class="modal-header">
        <h4 class="modal-title"><i class="far fa-question-circle" aria-hidden="true"></i> Support</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="body_content">
            <form #supportForm="ngForm" class="support_form" (ngSubmit)="sendRequest()" novalidate [spinnerLoader]="supportSubscription?.closed">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                            <label for="supportTitle" class="form-label">Title *</label>
                            <input name="supportTitle" type="text" id="supportTitle" class="form-control" required [(ngModel)]="support.title" #supportTitle="ngModel" [ngClass]="{'is-invalid': supportTitle.invalid && supportForm.submitted}">
                            <div class="invalid-feedback" *ngIf="supportTitle.errors?.required">Title is required</div>
                        </div>
                        <div class="mb-3">
                            <label for="supportType" class="form-label">Request type *</label>
                            <select name="supportType" id="supportType" class="form-select" required [(ngModel)]="support.requestType" #supportType="ngModel" [ngClass]="{'is-invalid': supportType.invalid && supportForm.submitted}">
                                <option value="Troubleshooting">Troubleshooting</option>
                                <option value="Guidance">Guidance</option>
                                <option value="Bug">Bug</option>
                                <option value="Feature Request">Feature Request</option>
                                <option value="Quota Increase">Quota Increase</option>
                            </select>
                            <div class="invalid-feedback" *ngIf="supportType.errors?.required">Request type id required</div>
                        </div>
                        <div class="mb-3">
                            <label for="supportUrgency" class="form-label">Urgency *</label>
                            <select name="supportUrgency" id="supportUrgency" class="form-select" required [(ngModel)]="support.urgency" #supportUrgency="ngModel" [ngClass]="{'is-invalid': supportUrgency.invalid && supportForm.submitted}">
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                                <option value="Critical">Critical</option>
                            </select>
                            <div class="invalid-feedback" *ngIf="supportUrgency.errors?.required">Urgency is required</div>
                        </div>
                        <div>
                            <label for="supportSection" class="form-label">Console section</label>
                            <select name="supportSection" id="supportSection" class="form-select" [(ngModel)]="support.section" #supportSection="ngModel">
                                <option value="Design">Design</option>
                                <option value="Training">Training</option>
                                <option value="Analytics">Analytics</option>
                                <option value="Monitoring">Monitoring</option>
                                <option value="Billing">Billing</option>
                                <option value="Administration">Administration</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="supportDescription" class="form-label">Description *</label>
                        <textarea name="supportDescription" id="supportDescription" rows="11" class="form-control" required [(ngModel)]="support.description" #supportDescription="ngModel" [ngClass]="{'is-invalid': supportDescription.invalid && supportForm.submitted}"></textarea>
                        <div class="invalid-feedback" *ngIf="supportDescription.errors?.required">Description is required</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn brand_default" (click)="supportForm.onSubmit($event)">Send</button>
    </div>
</div>
