<ng-container [formGroup]="form">
    <div class="row mb-3">
        <div class="col">
            <label for="salesforceUrl" class="form-label">Client ID *</label>
            <input type="text" id="salesforceUrl" class="form-control" required placeholder="insert your Client ID" formControlName="clientId" [ngClass]="{ 'is-invalid': form.get('clientId').invalid && salesforcePhases.steps[0].submitted }"/>
            <div class="invalid-feedback" *ngIf="form.get('clientId').errors?.required">Client ID is required</div>
        </div>
        <div class="col">
            <label for="salesforceEmail" class="form-label">Client Secret *</label>
            <input type="text" id="salesforceEmail" class="form-control" required placeholder="insert your Client Secret" formControlName="clientSecret" [ngClass]="{ 'is-invalid': form.get('clientSecret').invalid && salesforcePhases.steps[0].submitted }"/>
            <div class="invalid-feedback" *ngIf="form.get('clientSecret').errors?.required">Client Secret is required</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-6 input_copy">
            <label for="redirectUrl" class="form-label">Callback URL</label>
            <input type="text" name="redirectUrl" #redirectUrl class="form-control" id="redirectUrl" formControlName="redirectUrl" readonly />
            <i aria-hidden="true" (click)="commonService.copyToClipboard(redirectUrl)" ngbTooltip="Copy to Clipboard" class="far fa-copy"></i>
        </div>

        <div class="col-6" *ngIf="authCodeRequired">
            <label for="salesforceToken" class="form-label">
                AUTH_CODE *
                <i class="fal fa-info-circle fa-sm" aria-hidden="true" ngbTooltip='Click on "Authorize on Salesforce" and paste here the AUTH_CODE that will be generated.'></i>
            </label>
            <input type="text" id="salesforceToken" class="form-control" formControlName="authCode" placeholder="Paste your AUTH_CODE..." [ngClass]="{ 'is-invalid': form.get('authCode').errors?.required && salesforcePhases.steps[0].submitted }"/>
            <div class="invalid-feedback" *ngIf="form.get('authCode').errors?.required">AUTH_CODE is required</div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <a href="javascript:void(0)" class="connector_color d-inline-block" (click)="requestCode()"
                ><small>Authorize on Salesforce <i class="fas fa-external-link-alt" aria-hidden="true"></i></small
            ></a>
        </div>
    </div>
</ng-container>
