
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JourneyRestrictionGuard, RoleRestrictionGuard } from '@core';

import { AnalyticIntentsComponent } from './components/analytic-intents/analytic-intents.component';
import { AnalyticProficiencyComponent } from './components/analytic-proficiency/analytic-proficiency.component';
import { AnalyticReportsComponent } from './components/analytic-reports/analytic-reports.component';
import { AnalyticSessionsComponent } from './components/analytic-sessions/analytic-sessions.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { ConversationalHistoryComponent } from './components/conversational-history/conversational-history.component';
import { ReportResolver } from './resolvers/report.resolver';

const routes: Routes = [
  {
    path: '',
    component: AnalyticsComponent,
    canActivate: [RoleRestrictionGuard, JourneyRestrictionGuard],
    data: {
      roleRestriction: ['analytics.view', 'analytics.customreport.write'],
      journeyRestriction: ['ES', 'CX'],
    },
    resolve: {
      reports: ReportResolver,
    },
    children: [
      { path: '', redirectTo: 'sessions', pathMatch: 'full' },
      {
        canActivate: [JourneyRestrictionGuard],
        path: 'sessions',
        component: AnalyticSessionsComponent,
        data: {
          journeyRestriction: ['ES', 'CX'],
        },
      },
      {
        canActivate: [JourneyRestrictionGuard],
        path: 'engagement',
        component: AnalyticIntentsComponent,
        data: {
          journeyRestriction: ['ES', 'CX'],
        },
      },
      {
        canActivate: [JourneyRestrictionGuard],
        path: 'proficiency',
        component: AnalyticProficiencyComponent,
        data: {
          journeyRestriction: ['ES'],
        },
      },
      {
        canActivate: [JourneyRestrictionGuard],
        path: 'conversational-history',
        component: ConversationalHistoryComponent,
        data: {
          journeyRestriction: [{ type: 'ES' }, { type: 'CX', fulfillment: false }],
        },
      },
      {
        path: 'custom-report/:reportId',
        component: AnalyticReportsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnalyticsRoutingModule {}
