import { Options } from '@angular-slider/ngx-slider';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Pagination } from '@shared';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'analytic-proficiency',
  templateUrl: './analytic-proficiency.component.html',
  styleUrls: ['./analytic-proficiency.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnalyticProficiencyComponent implements OnDestroy {
  subscriptions: object = {};
  agentAutonomy: any = {};
  averageScore: number;
  percentagePrecision: number;
  percentageRecall: number;
  trustedIntents: any = {};
  proficiencyStatistics: any;
  precisionAndRecallForTable: any;

  chartDistribution: 'precision' | 'recall' = 'precision';
  precisionAndRecall: any;
  precisionAndRecallPagination: Pagination = new Pagination();
  intentFilter: any = {
    startPrecision: 0,
    endPrecision: 100,
    startRecall: 0,
    endRecall: 100,
    status: '',
  };

  sliderOptions: Options = {
    floor: 0,
    ceil: 100,
    hideLimitLabels: true,
  };

  toolTipsProficiency: any = {
    virtualAgentAutonomy:
      'Number of conversation where one or more untrusted intents are not present. Indicates the degree of autonomy of the agent in handle conversations.',
    trustedIntents: 'Number of trusted intents in relations to the total intents.',
    precision:
      'Average percentage of positive identifications that are actually correct, i.e. the ratio between true positives and true positives added to false positives.',
    recall:
      'Average percentage of effective positives correctly identified, i.e. the ratio between true positives and true positives added to false negatives.',
    confidence: 'Average virtual agent confidence in intent detection. Values range from 0.0 (completely uncertain) to 1.0 (completely certain).',
  };

  scoreRange: number = 5;
  get distribution(): Array<any> {
    let rangeDistribution: Array<any> = [];
    let distributionIndex: number = 0;

    while (distributionIndex < 100) {
      rangeDistribution.push({
        min: distributionIndex,
        max: distributionIndex === 95 ? distributionIndex + this.scoreRange : distributionIndex + (this.scoreRange - 1),
      });
      distributionIndex += this.scoreRange;
    }

    return rangeDistribution;
  }

  labels = [];
  dataset = [];

  constructor(private analyticsService: AnalyticsService) {}

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getStatistics(filter: any) {
    const params = {
      startDate: filter.startDate,
      endDate: filter.endDate,
      tags: filter.intentTag || null,
      agentChannel: filter.agentChannel || null,
    };
    this.getAgentAutonomy(params);
    this.getTrustedIntents();
    this.getAverageScore(params);
    this.getPercentagePrecision(params);
    this.getPercentageRecall(params);
    this.getPrecisionAndRecall();
    this.searchPrecisionAndRecall(1);
  }

  parseBarValue(value: number): number {
    if (isNaN(value)) return 0;
    return Number(value.toFixed(2));
  }

  getAgentAutonomy(params: any) {
    this.subscriptions['AgentAutonomy'] = this.analyticsService.getAgentAutonomy(params).subscribe((response: any) => {
      this.agentAutonomy = response.singleResult;
    });
  }

  getTrustedIntents() {
    this.subscriptions['TrustedIntents'] = this.analyticsService.getTrustedIntents().subscribe((response: any) => {
      this.trustedIntents = response;
    });
  }

  getPercentagePrecision(params: any) {
    this.subscriptions['PercentagePrecision'] = this.analyticsService.getPercentagePrecision(params).subscribe((response: any) => {
      this.percentagePrecision = response.singleResult;
    });
  }

  getPercentageRecall(params: any) {
    this.subscriptions['PercentageRecall'] = this.analyticsService.getPercentageRecall(params).subscribe((response: any) => {
      this.percentageRecall = response.singleResult;
    });
  }

  getAverageScore(params: any) {
    this.subscriptions['AverageScore'] = this.analyticsService.getAverageScore(params).subscribe((response: any) => {
      this.averageScore = response.singleResult;
    });
  }

  getPrecisionAndRecall() {
    this.subscriptions['PrecisionAndRecall'] = this.analyticsService.getPrecisionAndRecall().subscribe((response: any) => {
      this.precisionAndRecall = response.singleResult;
      this.drawProficiencyChart();
    });
  }

  drawProficiencyChart() {
    this.labels = [];
    this.dataset = [
      {
        data: [],
        colors: [],
      },
    ];
    const scoreDistribution = this.processScoreDistribution(this.precisionAndRecall, this.chartDistribution);

    scoreDistribution.forEach(([label, value, color]) => {
      this.labels.push(label);
      this.dataset[0].data.push(value);
      this.dataset[0].colors.push(color);
    });
  }

  processScoreDistribution(data: any, target: 'precision' | 'recall'): Array<Array<any>> {
    let chartData: Array<any> = [];
    let intervalValues: any = {};

    data.forEach((intent: any) => {
      if (isNaN(intent[target])) return;

      if (intervalValues[this.getInterval(intent[target])]) intervalValues[this.getInterval(intent[target])] += 1;
      else intervalValues[this.getInterval(intent[target])] = 1;
    });

    this.distribution.forEach((interval: any) => {
      if (!intervalValues[`${interval.min}-${interval.max}`]) intervalValues[`${interval.min}-${interval.max}`] = 0;
    });

    for (const key in intervalValues) {
      if (intervalValues.hasOwnProperty(key)) {
        chartData.push([key, intervalValues[key], this.getRangeColor(key)]);
      }
    }

    return this.sortInterval(chartData);
  }

  getInterval(value: number): string {
    for (const interval of this.distribution) {
      if (Math.round(value * 100) <= interval.max && Math.round(value * 100) >= interval.min) {
        return `${interval.min}-${interval.max}`;
      }
    }
  }

  sortInterval(chartData: Array<Array<any>>): Array<Array<any>> {
    return chartData.sort((a, b) => {
      if (a[0] === 'Interval') return -1;

      let minIntervalA = parseInt(a[0].split('-')[0]);
      let minIntervalB = parseInt(b[0].split('-')[0]);

      if (minIntervalA < minIntervalB) return -1;
      if (minIntervalA > minIntervalB) return 1;
      return 0;
    });
  }

  getRangeColor(interval: string): string {
    let minRange = parseInt(interval.split('-')[0]);
    let maxRange = parseInt(interval.split('-')[1]);

    if (maxRange <= 39) {
      return '#dc3545';
    } else if (minRange > 39 && maxRange <= 79) {
      return '#ffc107';
    } else if (maxRange > 79) {
      return '#28a745';
    }
  }

  searchPrecisionAndRecall(pageSelected: number) {
    this.precisionAndRecallPagination.onSelectPage(pageSelected);

    this.subscriptions['PrecisionAndRecallForTable'] = this.analyticsService
      .getPrecisionAndRecallForTable(this.intentFilter, this.precisionAndRecallPagination.getPageIndex(), this.precisionAndRecallPagination.pageSize)
      .subscribe((response: any) => {
        this.precisionAndRecallForTable = response.singleResult.content;
        this.precisionAndRecallPagination.updateTotals(response.singleResult.totalElements);
      });
  }
}
