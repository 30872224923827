
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { BackButton, CompleteButton, ExitButton, NextButton } from '@shared';
import { CustomStep } from '@shared';
import { AnalyticsComponent } from '../components/analytics/analytics.component';
import { Tutorials } from '@core';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsTutorialService {
  private component: AnalyticsComponent;
  private exitCallback;

  constructor(private shepherdService: ShepherdService, private router: Router) {
    this.shepherdService.modal = true;
    this.exitCallback = () => {
      this.router.navigate(['/analytics']);
      this.shepherdService.cancel();
    };
  }
  startTutorial(component) {
    this.component = component;
    this.shepherdService.tourName = Tutorials.ANALYTICS;
    this.shepherdService.addSteps([
      new CustomStep({
        attachTo: {
          element: '#analyticsMenu',
          on: 'right',
        },
        title: 'Analytics',
        text: 'The Analytics section allows you to analyze statistics and performance of the virtual agent in relation to the different objectives, KPIs and peculiarities of the project.',
        buttons: [new ExitButton(this.exitCallback), new NextButton(
          ()=>{
            this.router.navigate(['/analytics/sessions']);
            this.shepherdService.next();
          }
        )],
      }),
      new CustomStep({
        attachTo: {
          element: '#sessionsTab',
          on: 'bottom',
        },
        beforeShow: () => {
          this.router.navigate(['/analytics/sessions']);
          window.scrollTo(0, 0);
        },
        scrollTo: false,
        padding: 5,
        title: 'Sessions',
        text: 'This section groups together the metrics and data related to conversational sessions. A session is a set of interactions between the user and the virtual agent.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#sessionsFilters',
          on: 'bottom',
        },
        beforeShow: () => {
          this.router.navigate(['/analytics/sessions']);
          window.scrollTo(0, 0);
        },
        scrollTo: false,
        padding: 5,
        title: 'Filters',
        text: 'In this section and in the other sections of the analytics it is possible to filter the information by time interval, topics and channels. It is also possible to export data, even with active filters.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#channelsPie',
          on: 'top',
        },
        padding: 5,
        title: 'Channels',
        text: 'This graph shows, as a percentage, the distribution of channels chosen by users to interact with the virtual agent.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#popularTopicsPie',
          on: 'top',
        },
        padding: 5,
        title: 'Popular topics',
        text: 'This graph shows, as a percentage, which are the topics most requested by users in conversations.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#languagesPie',
          on: 'top',
        },
        padding: 5,
        title: 'Languages',
        text: 'This graph shows, as a percentage, which languages ​​are most used by users.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#engagementTab',
          on: 'bottom',
        },
        beforeShow: () => {
          this.router.navigate(['/analytics/engagement']);
          window.scrollTo(0, 0);
        },
        scrollTo: false,
        padding: 5,
        title: 'Engagement',
        text: 'The Engagement section presents more detailed data and information regarding the elements most recalled by users within the various conversational sessions.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#triggeredIntentTab',
          on: 'right',
        },
        beforeShow: () => {
          document.querySelector<any>('#triggeredIntentTab > a').click();
          window.scrollTo(0, 0);
        },
        padding: 5,
        scrollTo: false,
        title: 'Triggered Intent',
        text: 'In this section you can find a table that represents the most triggered intents within conversational sessions, interaction and exit percentage.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#triggeredPageTab',
          on: 'right',
        },
        beforeShow: () => {
          document.querySelector<any>('#triggeredPageTab > a').click();
          window.scrollTo(0, 0);
        },
        padding: 5,

        title: 'Triggered Page',
        text: 'In this section you can find a table represents the most triggered pages within conversational sessions, interaction and exit percentage',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#triggeredFlowTab',
          on: 'right',
        },
        beforeShow: () => {
          document.querySelector<any>('#triggeredFlowTab > a').click();
          window.scrollTo(0, 0);
        },
        padding: 5,
        scrollTo: false,
        title: 'Triggered Flow',
        text: 'In this section you can find a table represents the most triggered flow within conversational sessions, interaction and exit percentage.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#triggeredFallbackTab',
          on: 'right',
        },
        beforeShow: () => {
          document.querySelector<any>('#triggeredFallbackTab > a').click();
          window.scrollTo(0, 0);
        },
        padding: 5,

        title: 'Triggered Fallbacks',
        text: 'In this section you can find a table represents the most triggered Fallback intents or events, allowing you to highlight the points in the conversation where the agent was unable to understand a request.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#proficiencyTab',
          on: 'bottom',
        },
        beforeShow: () => {
          this.router.navigate(['/analytics/proficiency']);
          window.scrollTo(0, 0);
        },
        scrollTo: false,
        padding: 5,
        title: 'Proficiency',
        text: 'The Proficiency section focuses on the level of effectiveness and efficiency achieved by the agent in correctly and independently identifying the intentions and therefore the answers to be provided to users.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#agentAutonomyStats',
          on: 'bottom',
        },
        padding: 5,
        scrollTo: false,
        title: 'Agent autonomy',
        text: `This section shows the autonomy of the agent in managing conversations without the intervention of an operator and the number of "Trusted" intents. In addition to some data on the agent's progress following his training.`,
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#scoreDistributionStats',
          on: 'top',
        },
        padding: 5,
        scrollTo: false,
        title: 'Score distribution',
        text: `This graph represents the distribution of Precision and Recall, which is the number of intents that fall within a range. These metrics are calculated after agent training.`,
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#intentsDetailsStats',
          on: 'top',
        },
        padding: 5,
        when: {
          show: () => {
            setTimeout(() => {
              window.scrollTo(0, 450);
            });
          },
        },
        scrollTo: false,
        title: 'Intents details',
        text: `This table allows you to view different metrics and information previously displayed for each individual intent. It also indicates its status and the number of times it has been triggered in conversations.`,
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new CustomStep({
        attachTo: {
          element: '#historyTab',
          on: 'bottom',
        },
        scrollTo: false,
        beforeShow: () => {
          this.router.navigate(['/analytics/conversational-history']);
          window.scrollTo(0, 0);
        },
        padding: 10,
        title: 'History',
        text: 'The History section presents a table containing the details of all the interactions between user and virtual agent.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new CompleteButton(this.exitCallback)],
      }),
    ]);
    this.shepherdService.start();
  }
}
