import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared';
import { ToneOfVoiceSupportModalComponent } from './components/tone-of-voice-support-modal/tone-of-voice-support-modal.component';
import { ToneOfVoiceSurveyComponent } from './components/tone-of-voice-survey/tone-of-voice-survey.component';
import { ToneOfVoiceRoutingModule } from './tone-of-voice-routing.module';

@NgModule({
  declarations: [ToneOfVoiceSurveyComponent, ToneOfVoiceSupportModalComponent],
  imports: [ToneOfVoiceRoutingModule, TranslateModule, RouterModule, SharedModule],
})
export class ToneOfVoiceModule {}
