import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@shared';

@Component({
  selector: 'salesforce-auth-step',
  templateUrl: './salesforce-auth-step.component.html',
  styleUrls: ['./salesforce-auth-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SalesforceAuthStepComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() salesforcePhases;

  get authCodeRequired() {
    return (
      this.form.get('authCode').validator &&
      this.form
        .get('authCode')
        .validator({} as AbstractControl)
        .hasOwnProperty('required')
    );
  }
  constructor(public commonService: CommonService) {}

  ngOnInit() {
    this.form.get('clientId').valueChanges.subscribe(() => this.setAuthCodeRequired());
    this.form.get('clientSecret').valueChanges.subscribe(() => this.setAuthCodeRequired());
  }
  requestCode() {
    this.salesforcePhases.steps[0].submitted = true;
    if (this.form.get('clientId').valid && this.form.get('clientSecret').valid) {
      this.setAuthCodeRequired();
      window.open(
        `https://login.salesforce.com/services/oauth2/authorize?client_id=${this.form.get('clientId').value}&redirect_uri=${
          this.form.get('redirectUrl').value
        }&response_type=code`,
        '_blank'
      );
    }
  }

  setAuthCodeRequired() {
    if (!this.authCodeRequired) {
      this.form.get('authCode').setValidators(Validators.required);
      this.form.get('authCode').updateValueAndValidity();
    }
  }
}
