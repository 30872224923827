<div class="box_wrapper">
    <div class="dashboard_chunk">
        <h6 class="chunk_title">Last Events</h6>
        <div class="chunk_body">
            <table class="table table-sm stats_table" aria-hidden="true">
                <tbody>
                    <tr>
                        <td><i class="dialogflow_icon dialogflow_icon_es" aria-hidden="true"></i></td>
                        <td>Training</td>
                        <td>alice.smith@noovle.com</td>
                    </tr>
                    <tr>
                        <td><i class="far fa-user-circle fa-xs" aria-hidden="true"></i></td>
                        <td>User created</td>
                        <td>Alexander.williams@noovle.com</td>
                    </tr>
                    <tr>
                        <td><i class="dialogflow_icon dialogflow_icon_es" aria-hidden="true"></i></td>
                        <td>Training</td>
                        <td>bob.jones@noovle.com</td>
                    </tr>
                    <tr>
                        <td><i class="dialogflow_icon dialogflow_icon_es" aria-hidden="true"></i></td>
                        <td>Training</td>
                        <td>bob.jones@noovle.com</td>
                    </tr>
                    <tr>
                        <td><i class="far fa-user-circle fa-xs" aria-hidden="true"></i></td>
                        <td>User created</td>
                        <td>alice.smith@noovle.com</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="chunk_bottom">
            <button type="button" class="btn brand_light" [routerLink]="['/admin']">View logs</button>
        </footer>
    </div>
</div>