import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Subject, Subscription } from 'rxjs';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-http-connector-modal',
  templateUrl: './http-connector-modal.component.html',
  styleUrls: ['./http-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HttpConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private connector: any;

  connectorSubscription: Subscription;
  moveStep: Subject<any> = new Subject<any>();

  httpPhases: any = {
    steps: [
      { name: 'General settings', submitted: false },
      { name: 'Headers', submitted: false },
      { name: 'Query Params', submitted: false },
      { name: 'Payload', submitted: false },
    ],
    current: 0,
  };

  httpForm = this.fb.group({
    steps: this.fb.array([
      this.fb.group({
        method: ['POST', Validators.required],
        url: ['', Validators.required],
      }),
      this.fb.group({
        headers: this.fb.array([]),
      }),
      this.fb.group({
        queryParams: this.fb.array([]),
      }),
      this.fb.group({
        payload: [''],
      }),
    ]),
  });

  get stepsForm() {
    return this.httpForm.get('steps') as UntypedFormArray;
  }

  get stepOne() {
    return this.stepsForm.at(0) as UntypedFormGroup;
  }
  get stepTwo() {
    return this.stepsForm.at(1) as UntypedFormGroup;
  }
  get stepThree() {
    return this.stepsForm.at(2) as UntypedFormGroup;
  }
  get stepFour() {
    return this.stepsForm.at(3) as UntypedFormGroup;
  }

  get httpHeaders() {
    return this.stepTwo.get('headers') as UntypedFormArray;
  }
  get httpParams() {
    return this.stepThree.get('queryParams') as UntypedFormArray;
  }

  constructor(public activeModal: NgbActiveModal, private connectorsService: ConnectorsService, private fb: UntypedFormBuilder) {}

  ngOnInit() {
    if (this.connector.id) {
      this.stepsForm.patchValue([
        { method: this.connector.method, url: this.connector.url },
        { headers: this.processFormArray(this.connector.headers, 'input', 'header') },
        { queryParams: this.processFormArray(this.connector.queryParams, 'input', 'param') },
        { payload: this.connector.payload },
      ]);
    } else {
      this.addHeader();
      this.addParam();
    }
  }

  ngOnDestroy() {
    if (this.connectorSubscription instanceof Subscription) this.connectorSubscription.unsubscribe();
  }

  addHeader() {
    this.httpHeaders.push(
      this.fb.group({
        key: ['', Validators.required],
        value: ['', Validators.required],
      })
    );
  }

  addParam() {
    this.httpParams.push(
      this.fb.group({
        key: ['', Validators.required],
        value: ['', Validators.required],
      })
    );
  }

  nextStep() {
    this.moveStep.next('next');
  }

  saveConnector($event) {
    $event.headers = this.processFormArray($event.headers, 'output');
    $event.queryParams = this.processFormArray($event.queryParams, 'output');
    const data = { ...this.connector, ...$event };

    if (this.connector.id) {
      this.connectorSubscription = this.connectorsService.editConnector('restHttp', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    } else {
      this.connectorSubscription = this.connectorsService.addConnector('restHttp', data).subscribe(() => {
        this.activeModal.close({ refresh: true });
      });
    }
  }

  processFormArray(formData: any, process: 'input' | 'output', formField?: 'header' | 'param') {
    if (process === 'output') {
      return formData.reduce((accumulator, currentValue) => {
        accumulator[currentValue.key] = currentValue.value;
        return accumulator;
      }, {});
    } else if (process === 'input') {
      const formArray: Array<any> = [];
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          if (formField === 'header') this.addHeader();
          if (formField === 'param') this.addParam();
          formArray.push({ key: key, value: formData[key] });
        }
      }
      return formArray;
    }
  }
}
