
<div class="console_modal translate_dialog">
    <div class="modal-header dialog_head">
        <h4 class="modal-title">Translate</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="body_content" >
            <form #translateForm="ngForm" class="translate_form" (ngSubmit)="saveConnector()" *roleRestriction="'design.processflow.translate.write'; disableElements: true" novalidate>
                <div class="row mb-3">
                    <div class="col">
                        <div class="form-check form-check-inline">
                            <input name="translateType" type="radio" id="detectLanguage" class="form-check-input" required [(ngModel)]="connector.isDetectLanguage" #translateType="ngModel" [value]="true" (change)="switchTranslateType()" [ngClass]="{'is-invalid': translateType.invalid && translateForm.submitted}">
                            <label class="form-check-label" for="detectLanguage">Detect language</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input name="translateType" type="radio" id="translateLanguage" class="form-check-input" required [(ngModel)]="connector.isDetectLanguage" #translateType="ngModel" [value]="false" (change)="switchTranslateType()" [ngClass]="{'is-invalid': translateType.invalid && translateForm.submitted}">
                            <label class="form-check-label" for="translateLanguage">Translate language</label>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-6">
                        <label for="analyzedField" class="form-label">Field to analyze *</label>
                        <input name="analyzedField" type="text" id="analyzedField" class="form-control" required [(ngModel)]="connector.field" #analyzedField="ngModel" [ngClass]="{'is-invalid': analyzedField.invalid && translateForm.submitted}">
                        <div class="invalid-feedback" *ngIf="analyzedField.errors?.required">Field is required</div>
                    </div>
                    <div class="col-sm-12 col-md-6" *ngIf="connector.isDetectLanguage">
                        <label for="minCharacters" class="form-label">Minimum characters</label>
                        <input name="minCharacters" type="number" id="minCharacters" class="form-control" [(ngModel)]="connector.minChar" #minCharacters="ngModel">
                    </div>
                    <div class="col-sm-12 col-md-6" *ngIf="!connector.isDetectLanguage && !connector.originalUserLanguage">
                        <label for="translationLanguage" class="form-label">Translation Language</label>
                        <input name="translationLanguage" type="text" id="translationLanguage" class="form-control" [(ngModel)]="connector.languages" #translationLanguage="ngModel" required [ngClass]="{'is-invalid': translationLanguage.invalid && translateForm.submitted}" [ngbTypeahead]="languageSuggestions">
                        <div class="invalid-feedback" *ngIf="translationLanguage.errors?.required">Language is required</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col" *ngIf="!connector.isDetectLanguage">
                        <div class="form-check">
                            <input name="originalLanguage" type="checkbox" id="originalLanguage" class="form-check-input" [(ngModel)]="connector.originalUserLanguage">
                            <label class="form-check-label" for="originalLanguage">Use original user language</label>
                        </div>
                    </div>                    
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <div class="form-check">
                            <input name="overrideClient" type="checkbox" id="overrideClient" class="form-check-input" [(ngModel)]="connector.ignoreFeLanguage">
                            <label class="form-check-label" for="overrideClient">Override Front End language detection</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input name="firstInteraction" type="checkbox" id="firstInteraction" class="form-check-input" [(ngModel)]="connector.onlyFirstInteraction">
                            <label class="form-check-label" for="firstInteraction">Detects only at the first interaction</label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer dialog_bottom">
        <button type="button" class="btn" (click)="translateForm.onSubmit($event)" *roleRestriction="'design.processflow.translate.write'; disableElements: true">Save</button>
    </div>
</div>
