import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared';
import { ChatInteractionComponent } from '@standalone';
import { AnalyticsRoutingModule } from './analytics-routing.module';
import { AnalyticIntentsComponent } from './components/analytic-intents/analytic-intents.component';
import { AnalyticProficiencyComponent } from './components/analytic-proficiency/analytic-proficiency.component';
import { AnalyticReportsComponent } from './components/analytic-reports/analytic-reports.component';
import { AnalyticSessionsComponent } from './components/analytic-sessions/analytic-sessions.component';
import { AnalyticsFilterComponent } from './components/analytics-filter/analytics-filter.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { ConversationalHistoryComponent } from './components/conversational-history/conversational-history.component';
import { CustomReportDetailsModalComponent } from './components/custom-report-details-modal/custom-report-details-modal.component';
import { CustomReportModalComponent } from './components/custom-report-modal/custom-report-modal.component';
import { RatingModalComponent } from './components/rating-modal/rating-modal.component';
import { ReportChartModalComponent } from './components/report-chart-modal/report-chart-modal.component';

@NgModule({
  declarations: [
    AnalyticsComponent,
    AnalyticsFilterComponent,
    AnalyticSessionsComponent,
    ConversationalHistoryComponent,
    AnalyticIntentsComponent,
    AnalyticProficiencyComponent,
    AnalyticReportsComponent,
    CustomReportModalComponent,
    CustomReportDetailsModalComponent,
    ReportChartModalComponent,
    RatingModalComponent,
  ],
  imports: [
    CommonModule,
    AnalyticsRoutingModule,
    FormsModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    NgxSliderModule,
    ChatInteractionComponent,
  ],
})
export class AnalyticsModule {}
