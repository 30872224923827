<section class="custom_preview flow_row">
    <article class="connector_box code_{{ code || movingConnector?.productCode | lowercase }}">
        <header class="box_status"></header>
        <div class="box_grab">
            <button class="btn"><i class="fas fa-grip-vertical" aria-hidden="true"></i></button>
        </div>
        <figure class="box_image d-block">
            <img src="./images/design-connectors/connector_{{ code || movingConnector?.productCode }}.png" alt="" />
        </figure>
        <footer class="box_description">
            <h6>
                <span>{{ name || movingConnector?.name || movingConnector?.productName }}</span>
            </h6>
        </footer>
    </article>
</section>