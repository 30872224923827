import { Pagination } from "@shared";

declare let google: any;

export type ChartOptions = {
    id?: number,
    reportID?: number,
    type: 'BAR_CHART' | 'COUNTER' | 'LINE_CHART' | 'PIE_CHART' | 'TABLE',
    title: string,
    size: 'SMALL' | 'MEDIUM' | 'LARGE',
    metric?: string,
    pieStyle?: 'PIE_CHART' | 'DONUT_CHART',
    barStyle?: 'VERTICAL' | 'HORIZONTAL',
    order?: 'DESC' | 'ASC',
    countMetric?: boolean,
    countMetricCondition?: {
      firstMember?: string,
      condition?: string,
      secondMember?: string
    }[],
    tableMetric?: string[]
}
export type ChartData = {
  chart?: {
    field: string;
    fieldType: 'Date' | 'number' | 'string' | 'timestamp';
    chartData: {
      aggregatedValue: any;
      reportValue: number,
    }[]
  },
  table?: {
    columns: {field: string, fieldType: 'Date' | 'number' | 'string'}[];
    rows: {[name: string]: any}[];
  },
  counter?: {
    total: number;
  }
}
export class ReportChart {

    options: ChartOptions = {
        type: 'BAR_CHART',
        title: '',
        size: 'SMALL',
        pieStyle: 'PIE_CHART',
        barStyle: 'VERTICAL',
        order: 'DESC',
        countMetric: false,
        countMetricCondition: [],
        tableMetric: []
    }
    data: ChartData;
    templateId: string;
    chartTotal = 0;
    pagination: Pagination;

    constructor(options?: ChartOptions, data?: any,) {
        if (options) {
            this.options = {...this.options, ...options};
            if (data) {
              this.data = data;
            }
        }
    }

    drawChart(templateId: string) {
        this.templateId = templateId;
        switch(this.options.type) {
          case 'BAR_CHART':
            this.drawBarChart();
            break;
          case 'LINE_CHART':
            this.drawLineChart();
            break;
          case 'PIE_CHART':
            this.drawPieChart();
            break;
          case 'TABLE':
            this.pagination = new Pagination();
            this.pagination.updateTotals(this.data.table.rows.length);
            break;
          default:
            break;
        }
      }

    drawBarChart() {
        google.charts.setOnLoadCallback(() => {
    
          let data = google.visualization.arrayToDataTable(this.sortData(this.convertDataToChart(), this.options.order));
    
          let options = {
            legend: { position: 'none' },
            chartArea: { width: '90%'}
          };
          let chart;
          if (this.options.barStyle === 'HORIZONTAL') {
            chart = new google.visualization.BarChart(document.getElementById(this.templateId));
          } else {
            chart = new google.visualization.ColumnChart(document.getElementById(this.templateId));
          }
          chart.draw(data, options);
        });
      }
    
      drawLineChart() {
        google.charts.setOnLoadCallback(() => {
    
          let data = google.visualization.arrayToDataTable(this.convertDataToChart());
    
          let options = {
            legend: { position: 'none' },
            chartArea: { width: '90%'},
            hAxis: {
              format: 'dd MMM y'
            },
          };
          let chart = new google.visualization.LineChart(document.getElementById(this.templateId));
          chart.draw(data, options);
        });
      }
    
      drawPieChart() {
        google.charts.setOnLoadCallback(() => {
    
          let data = google.visualization.arrayToDataTable(this.convertDataToChart());
    
          let options: any = {
            chartArea: { height: '80%', width: '90%'},
            pieHole: undefined
          };
          if (this.options.pieStyle === 'DONUT_CHART') {
            options.pieHole = 0.4;
          }
          if (this.isEmptyChart()) {
            data.addColumn({type: 'string', role: 'tooltip'});
            data.addRows([
              ['No data', 100, 'No data']
            ]);
            options.colors = ['#E3E3E3'];
          }
          let chart = new google.visualization.PieChart(document.getElementById(this.templateId));
          chart.draw(data, options);
        });
      }

      private sortData(data, sortOrder) {
        const sortValue = sortOrder === 'ASC' ? 1 : -1;
        let retVal = data.splice(1);
        retVal.sort((a, b) => {
          if(a[1] === b[1]) return 0;
          return a[1] > b[1] ? sortValue : -sortValue;
        })
        return [data[0], ...retVal];
      }

      private convertDataToChart() {
        const retVal = [[this.data.chart.field, 'Value']];
        this.data.chart.chartData.forEach(el => {
          let label;
          if (this.data.chart.fieldType === 'Date' || this.data.chart.fieldType === 'timestamp') {
            let date = new Date(el.aggregatedValue)
            label = this.options.type !== 'PIE_CHART' ? date : `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          } else {
            label = el.aggregatedValue.toString();
          }
          retVal.push([label, parseInt(el.reportValue as any)])
        })
        return retVal;
      }

      private isEmptyChart(): boolean {
          return this.data.chart.chartData && this.data.chart.chartData.length === 1 && this.data.chart.chartData[0].aggregatedValue === 'N/A';
      }
}