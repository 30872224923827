
<div class="row section_wrapper" [spinnerLoader]="subscriptions['getAllReports']?.closed">
    <ng-template [spinnerLoader]="subscriptions['deleteReport']?.closed"></ng-template>
    <div class="col-12">
        <ul ngbNav #analyticsNav="ngbNav" class="nav-tabs analytic_tabs" [activeId]="activeTab">
            <li id="sessionsTab" [ngbNavItem]="'sessions'" *journeyRestriction="['ES', 'CX']">
                <a ngbNavLink [routerLinkActive]="['active']" [routerLink]="['/analytics/sessions']">Sessions</a>
            </li>
            <li id="engagementTab" [ngbNavItem]="'engagement'" *journeyRestriction="['ES', 'CX']">
                <a ngbNavLink [routerLinkActive]="['active']" [routerLink]="['/analytics/engagement']">Engagement</a>
            </li>
            <li id="proficiencyTab" [ngbNavItem]="'proficiency'" *journeyRestriction="'ES'">
                <a ngbNavLink [routerLinkActive]="['active']" [routerLink]="['/analytics/proficiency']">Proficiency</a>
            </li>
            <li id="historyTab" [ngbNavItem]="'conversational-history'" *journeyRestriction="[{type: 'ES'}, {type: 'CX', fulfillment: false}]">
                <a ngbNavLink [routerLinkActive]="['active']" [routerLink]="['/analytics/conversational-history']">History</a>
            </li>
            <ng-container *licenseRestriction="'M_A'; itemType: 'Module'">
                <li *ngFor="let customReport of customReports; let index = index" [ngbNavItem]="customReport.bqReportName">
                    <a ngbNavLink [routerLinkActive]="['active']" [routerLink]="['/analytics/custom-report', customReport.id]">{{customReport.userReportName}}</a>
                </li>
            </ng-container>
            <li class="custom_report" *licenseRestriction="'M_A'; itemType: 'Module'">
                <button class="btn" (click)="openCustomReportModal()"><i class="fal fa-plus fa-sm" aria-hidden="true"></i> Custom report</button>
            </li>
            <li class="change_language" *ngIf="['sessions', 'engagement', 'intents', 'proficiency', 'conversational_history'].indexOf(analyticsNav.activeId) >= 0">
                <agent-languages [allLanguages]="['sessions'].indexOf(analyticsNav.activeId) >= 0"></agent-languages>
            </li>
        </ul>
    </div>

    <div class="col-12">
        <router-outlet></router-outlet>
    </div>
</div>
