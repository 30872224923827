import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxStompService } from '@authentication';
import { NotificationService } from '@common-ui';
import { GenericConnector, Journey, JourneyService } from '@core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonApiService } from '@shared';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ConnectorPath } from '../../../enums/connector-path.enum';
import { ConnectorsService } from '../../../services/connectors.service';

@Component({
  selector: 'app-dialogflow-connector-modal',
  templateUrl: './dialogflow-connector-modal.component.html',
  styleUrls: ['./dialogflow-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogflowConnectorModalComponent implements OnInit, OnDestroy {
  connector: GenericConnector;
  private get journey(): Journey {
    return this.journeyService.journey;
  }
  subscriptions: object = {};
  persistentSubscriptions: object = {};

  agents: Array<any>;
  formEditMode: 'import' | 'export' = 'export';
  importedFileName: string;

  moveStep: Subject<any> = new Subject<any>();
  dialogflowPhases: any = {
    steps: [{ name: 'General settings', submitted: false }],
    current: 0,
  };

  dialogflowForm = this.fb.group({
    steps: this.fb.array([
      this.fb.group({
        agentBot: ['', Validators.required],
      }),
    ]),
  });

  get stepsForm() {
    return this.dialogflowForm.get('steps') as FormArray;
  }
  get stepsOne() {
    return this.stepsForm.at(0) as FormGroup;
  }
  get stepsTwo() {
    return this.stepsForm.at(1) as FormGroup;
  }
  get roleRestrictionPermission() {
    return `design.processflow.${this.connector.productCode === 'ES' ? 'dialogflowes' : 'dialogflowcx'}.write`;
  }
  constructor(
    public activeModal: NgbActiveModal,
    private rxStompService: RxStompService,
    private connectorsService: ConnectorsService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private journeyService: JourneyService,
    private commonApiService: CommonApiService
  ) {}

  ngOnInit() {
    if (this.connector.id) {
      this.stepsForm.patchValue([{ agentBot: this.connector['agentBot'] }]);
      this.setEditMode();
    }
    this.getAgentsBot();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getAgentsBot() {
    this.subscriptions['AgentsBot'] = this.commonApiService
      .getAgentsBot(`DIALOGFLOW_${this.connector.productCode}` as any)
      .subscribe((response: any) => {
        this.agents = response;
      });
  }

  setEditMode() {
    if (this.dialogflowPhases.steps[1] && this.stepsForm.at(1)) {
      this.dialogflowPhases.steps.splice(1, 1);
      this.stepsForm.removeAt(1);
    }

    if (this.formEditMode === 'export') {
      this.stepsOne.get('agentBot').enable();

      this.dialogflowPhases.steps.push({ name: 'Export data & history', submitted: false });
      this.stepsForm.push(this.fb.group({ clearAnalytics: [''], noticeRead: ['', Validators.requiredTrue] }));
    } else if (this.formEditMode === 'import') {
      this.stepsOne.get('agentBot').disable();

      this.dialogflowPhases.steps.push({ name: 'Import data', submitted: false });
      this.stepsForm.push(this.fb.group({ file: ['', Validators.required] }));
    }
  }

  nextStep() {
    this.moveStep.next('next');
  }

  saveConnector($event?) {
    const successCallback = () => {
      this.subscriptions['Agent'] = this.journeyService.getJourneysList().subscribe(() => this.activeModal.close({ refresh: true }));
    };

    let data;
    if ($event) {
      data = { ...this.connector, ...$event };
    } else {
      if (this.dialogflowForm.invalid) return;
      data = { ...this.connector, agentBot: this.stepsOne.get('agentBot').value };
    }

    if (this.connector.id) {
      if (this.formEditMode === 'export') {
        const params: any = {
          clearAnalytics: this.stepsForm.at(1).get('clearAnalytics').value || 'false',
        };
        this.subscriptions['Connector'] = this.connectorsService
          .editConnector(ConnectorPath[this.connector.productCode], data, params)
          .subscribe(() => successCallback());
      } else {
        const params = {
          journeyApiKey: this.journey.apiKey,
          fileName: this.importedFileName,
        };
        if (this.connector.productCode == 'ES') {
          this.subscriptions['Connector'] = this.commonApiService.importIntentsData({}, params).subscribe(() => successCallback());
        } else if (this.connector.productCode == 'CX') {
          this.subscriptions['Connector'] = this.commonApiService.importFlowsData({}, params).subscribe(() => successCallback());
        }
      }
    } else {
      this.subscriptions['Connector'] = this.connectorsService
        .addConnector(ConnectorPath[this.connector.productCode], data)
        .subscribe(() => successCallback());
    }
  }

  exportIntents() {
    this.persistentSubscriptions['ExportIntent'] = this.rxStompService
      .exportIntents(this.journey.apiKey)
      .pipe(first())
      .subscribe((response) => {
        this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
          type: 'success',
        });
        this.commonApiService.downloadFile('intents', response.body, 'Export Intents.xlsx').subscribe();
      });
  }

  exportFlows() {
    this.persistentSubscriptions['ExportFlow'] = this.rxStompService
      .exportFlows(this.journey.apiKey)
      .pipe(first())
      .subscribe((response) => {
        this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
          type: 'success',
        });
        this.commonApiService.downloadFile('flow', response.body, 'Export Flows.xlsx').subscribe();
      });
  }

  storeFile(target: EventTarget) {
    const files = (target as HTMLInputElement).files;
    if (!files?.[0]) return;

    this.stepsForm.at(1).get('file').setValue(files[0]);

    const params = {
      journeyApiKey: this.journey.apiKey,
    };
    if (this.connector.productCode == 'ES') {
      this.subscriptions['UploadFile'] = this.commonApiService.uploadIntentsData(files[0], params).subscribe((response) => {
        this.importedFileName = response;
      });
    } else if (this.connector.productCode == 'CX') {
      this.subscriptions['UploadFile'] = this.commonApiService.uploadFlowsData(files[0], params).subscribe((response: string) => {
        this.importedFileName = response;
      });
    }
  }

  exportHistory() {
    this.persistentSubscriptions['ExportHistory'] = this.rxStompService
      .esportAgentHistory(this.journey.apiKey)
      .pipe(first())
      .subscribe((response) => {
        this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
          type: 'success',
        });
        this.commonApiService.downloadFile('history', response.body, 'Export History.zip').subscribe();
      });
  }

  compareAgent(agent1: any, agent2: any) {
    return agent1.id === agent2.id;
  }
}
